import { ConnectionStatus as CapacitorConnectionStatus } from 'capacitor-stripe-terminal/dist/esm/definitions';

/**
 * Connection Status for the web stripe plugin. (Android uses Capacitor connection statuses)
 */
export enum CONNECTION_STATUS {
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
  NOT_CONNECTED = 'not_connected',
}

// ------------------------------------------------------------------------
//
// Android Terminal
//
// ------------------------------------------------------------------------
/** Capacitor Stripe Plugin Enum
export declare enum ConnectionStatus {
    NotConnected = 0,
    Connected = 1,
    Connecting = 2
} */
export const ConnectionStatusAndroidMap: Record<CapacitorConnectionStatus, CONNECTION_STATUS> = {
  0: CONNECTION_STATUS.NOT_CONNECTED,
  1: CONNECTION_STATUS.CONNECTED,
  2: CONNECTION_STATUS.CONNECTING,
};
