import Order from 'models/Order';
import Check from 'models/Check';
import { GiftCard, GiftCardCharge, OrderFeeFragment, OrderItemFragment, BackendCharge } from 'models/Types';
import OrderItem from 'models/OrderItem';
import { CHARGE_TYPE } from 'constants/Checkout';
import { TODO } from './Types';

/**
 * Gets any charges associated with an order that are gift card charges
 * Finds the total gift card amount authorized on the gift card by searching through the fee and item fragments.
 * Returns an object of {giftCardDisplay: `Gift Card 0000`, totalAmountForOrder: X}
 *
 * Don't use Charge frontend model because of inconsistencies with how the Charge is passed back along with an order
 * @param order
 */
export const getGiftCardCharges = (order: Order): Array<GiftCardCharge> => {
  const orderItemsInThisOrder = order.items.map((item: OrderItem) => item.orderitemid);
  return order.charges
    .filter((charge: BackendCharge) => charge.tender_type === 'gift_card')
    .map((charge: BackendCharge) => {
      const totalAmountForOrderItems = charge.item_charge_fragments
        .filter((fragment: OrderItemFragment) => orderItemsInThisOrder.includes(fragment.orderitem_id))
        .reduce((total: number, fragment: OrderItemFragment) => total + fragment.total_cents, 0);

      const totalAmountForOrderFees = charge.fee_charge_fragments
        .filter((fragment: OrderFeeFragment) => order.fees_on_my_receipt.includes(fragment.orderfee_id))
        .reduce((total: number, fragment: OrderFeeFragment) => total + fragment.total_cents, 0);

      return {
        id: charge.id,
        giftCardDisplay: `Gift Card ${charge.gift_card_last4}`,
        totalAmountForOrder: totalAmountForOrderItems + totalAmountForOrderFees,
      };
    });
};

/**
 * Returns positive int of cents authorized by gift cards or 0
 * Don't use Charge frontend model because of inconsistencies with how the Charge is passed back along with an order
 * @param chargesFromGiftCards
 */
export const calculateTotalAuthorizedGiftCardCents = (chargesFromGiftCards: Array<TODO>): number =>
  chargesFromGiftCards.reduce((total: number, charge: GiftCardCharge) => total + charge.totalAmountForOrder, 0);

/**
 * Formats relevant gift card info into event properties for segment tracking. Called for checkout success events
 * @param giftCards
 * @param checks
 */
export const formatGiftCardInfoForTracking = (giftCards: Array<GiftCard>, checks: Array<Check>): TODO => {
  const formattedGiftCards = checks
    .filter((check: Check) => check?.charge?.tender_type === CHARGE_TYPE.GIFT_CARD)
    .map((check: Check) => ({
      amount: check?.charge?.amount_cents,
      provider: giftCards.find((giftCard: GiftCard) => giftCard.id === check?.charge?.id)?.provider,
      id: check?.charge?.id,
    }));
  return {
    gift_card_amount: formattedGiftCards.reduce(
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      (total: number, giftCardInfo: TODO) => total + giftCardInfo?.amount || 0,
      0
    ),
    gift_card_amount_breakdown: formattedGiftCards.map((giftCardInfo) => giftCardInfo.amount).join('|') || 'N/A',
    gift_card_provider: formattedGiftCards.map((giftCardInfo) => giftCardInfo.provider).join('|') || 'N/A',
    gift_card_id: formattedGiftCards.map((giftCardInfo) => giftCardInfo.id).join('|'),
  };
};
