import Icon from '@ant-design/icons';

const LeftCaretSVG = () => (
  <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path className="stroke-regular" d="M6 1L2 5.5L6 10" stroke="#292929" strokeWidth="1.5" />
    </g>
  </svg>
);

const CaretLeft = (props) => <Icon component={LeftCaretSVG} {...props} />;

export default CaretLeft;
