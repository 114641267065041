import React from 'react';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { v4 as uuidv4 } from 'uuid';

// Components

// Utils

// Components
import { AppUrlListener } from 'capacitor/index';

// Constants
import { DEVICE_ID } from 'constants/Environments';

// Contexts
import { ContextWrapper } from 'contexts';

// Utils
import { retrieveFromLocalStorage, saveToLocalStorage } from 'utils/LocalStorage';

// Integrations
import { sendWebVitalToGTM } from 'integrations/google-tag-manager/instrumentation';
import { initializeSegmentTracking } from 'integrations/segment';
import * as Sentry from '@sentry/react';
import Integrations from './integrations';
import reportWebVitals from './reportWebVitals';
import App from './App';
import 'GlobalTypes';

// Sets the device id for tracking
if (Capacitor.getPlatform() === 'web') {
  let deviceId = retrieveFromLocalStorage(DEVICE_ID.LABEL);
  if (!deviceId) {
    deviceId = uuidv4();
    saveToLocalStorage(DEVICE_ID.LABEL, deviceId);
  }
  Sentry.setExtra(DEVICE_ID.LABEL, deviceId);
}

ReactDOM.render(
  <React.StrictMode>
    <Integrations>
      <HelmetProvider>
        <Router>
          <ContextWrapper>
            <AppUrlListener />
            <App />
          </ContextWrapper>
        </Router>
      </HelmetProvider>
    </Integrations>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
);

initializeSegmentTracking();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendWebVitalToGTM);
