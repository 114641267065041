import { track } from 'integrations/segment/instrumentation/Track';
import { FieldsType } from './types/types';

// Account DropDown
export const trackClickAccountDropDown = (fields: FieldsType = {}): void =>
  track('track_click_account_dropdown', fields);

export const trackClickAccountDetails = (fields: FieldsType = {}): void =>
  track('track_click_account_dropdown_account_details', fields);

export const trackClickOrderHistory = (fields: FieldsType = {}): void =>
  track('track_click_account_dropdown_order_history', fields);

export const trackClickManageTabs = (fields: FieldsType = {}): void =>
  track('track_click_account_dropdown_manage_tabs', fields);

export const trackClickReturnToHome = (fields: FieldsType = {}): void =>
  track('track_click_account_dropdown_return_to_home', fields);

export const trackClickSignOut = (fields: FieldsType = {}): void =>
  track('track_click_account_dropdown_sign_out', fields);

// Sign In
export const trackClickSignIn = (fields: FieldsType = {}): void => track('track_click_sign_in', fields);

// Miscellaneous
export const trackClickPrivacyPolicy = (fields: FieldsType = {}): void => track('track_click_privacy_policy', fields);

export const trackClickTermsAndConditions = (fields: FieldsType = {}): void =>
  track('track_click_terms_and_conditions', fields);
