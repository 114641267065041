import { retrieveFromLocalStorage, saveToLocalStorage } from './LocalStorage';

const LAST_LOCATION_STORAGE_KEY = 'last_location';

export function getLastLocationFromLocalStorage(): string {
  return retrieveFromLocalStorage(LAST_LOCATION_STORAGE_KEY, '/');
}

export function saveLocationToLocalStorage(location: string): void {
  saveToLocalStorage(LAST_LOCATION_STORAGE_KEY, location);
}

// https://stackoverflow.com/questions/26156292/trim-specific-character-from-a-string
export function trimCharacters(s: string, c: string): string {
  return s.replace(new RegExp(`^[${c}]+|[${c}]+$`, 'g'), '');
}
