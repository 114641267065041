import { v4 as uuidv4 } from 'uuid';

import Cart from 'models/Cart';
import CartItem from 'models/CartItem';
import Customer from 'models/Customer';

// Legacy
interface LegacyGoogleEcommCart {
  transactionId: string;
  transactionAffiliation: string;
  transactionTotal: number;
  transactionShipping: number;
  transactionTax: number;
  transactionProducts: Array<LegacyGoogleEcommCartItem>;
}

interface LegacyGoogleEcommCartItem {
  name: string;
  sku: string;
  category: string;
  price: number;
  quantity: number;
}

/**
 * Formats a cart into a valid legacy transaction event
 * @param cart
 * @param customer
 * @returns
 */
export const formatCartForLegacyGTM = (cart: Cart, customer: Customer): LegacyGoogleEcommCart => ({
  transactionId: uuidv4(),
  transactionAffiliation: customer.customer_name,
  transactionTotal: cart ? cart.cartTotal / 100 : 0,
  transactionShipping: 0,
  transactionTax: cart ? cart.getTax() / 100 : 0,
  transactionProducts: cart ? cart.items.map((item) => formatCartItemForLegcayGTM(item)) : [],
});

/**
 * Formats a cart item for GTM ecommerce events both prior to purchase and during purchase.
 * @param cartItem
 * @returns
 */
export const formatCartItemForLegcayGTM = (cartItem: CartItem): LegacyGoogleEcommCartItem => ({
  name: cartItem.menuItem?.name_for_customer,
  sku: cartItem.menuItem?.id,
  price: cartItem.getTotal() / 100,
  category: cartItem.menuItem?.menu_item_class,
  quantity: cartItem.qty,
});

// GA4
interface GoogleEcommCart {
  value: number;
  tax: number;
  tip: number;
  cartId: string;
  items: Array<GoogleEcommCartItem>;
}

interface GoogleEcommCartItem {
  item_id: string;
  item_name: string;
  price: number;
  item_brand: string;
  item_category: string;
  item_category2: string;
  item_list_name: string;
  item_list_id: string;
  index: number;
  quantity: number;
}

/**
 * Formats a cart and its items for GTM events prior to a purchase event occuring. For example for viewing the checkout page.
 * @param cart
 * @returns
 */
export const formatCartForGTM = (cart: Cart): GoogleEcommCart => ({
  value: cart ? cart.cartTotal / 100 : 0, // Total transaction value (incl. tax, tip, and shipping)
  tax: cart ? cart.getTax() / 100 : 0,
  tip: cart ? cart.getTip() / 100 : 0,
  cartId: cart.id,
  items: cart.items.map((item) => formatCartItemForGTM(item)),
});

/**
 * Formats a cart item for GTM ecommerce events both prior to purchase and during purchase.
 * Reference: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#view_item_details
 * @param cartItem
 * @returns
 */
export const formatCartItemForGTM = (cartItem: CartItem): GoogleEcommCartItem => ({
  item_name: cartItem.menuItem?.name_for_customer,
  item_id: cartItem.menuItem?.id,
  price: cartItem.getTotal() / 100,
  item_brand: cartItem.customer?.customer_name,
  item_category: cartItem.menuItem?.menu_item_class, // Food or Drink
  item_category2: cartItem.menuItem?.report_category,
  item_list_name: cartItem.menuItem?.primary_type,
  item_list_id: cartItem.menuItem?.menu_heading_id,
  index: cartItem.menuItem?.display_position,
  quantity: cartItem.qty,
});
