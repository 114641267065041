import styled from 'styled-components';
import { Form } from 'top-component-library';

export const FormItemText = styled(Form.Item)`
  &.ant-form-item {
    .ant-form-item-label {
      overflow: visible;
      label {
        font-weight: var(--font-weight__label);
        font-size: var(--font-size__label);
        color: var(--color-label-primary__regular);
      }
    }

    &.ant-form-item-has-error {
      input {
        color: var(--color-error__regular);

        &:not(:focus) {
          box-shadow: var(--box-shadow-input__error);
        }

        :focus {
          outline: 0;
          border-color: var(--color-input-border__selected);
          box-shadow: var(--box-shadow-input__selected);
        }
      }
    }

    .ant-form-item-control-input-content {
      input {
        font-weight: var(--font-weight__input);
        font-size: var(--font-size__input);
      }
    }
  }
`;

export const FormItemPhone = styled(Form.Item)`
  &.ant-form-item {
    .ant-form-item-label {
      overflow: visible;
      label {
        font-weight: var(--font-weight__label);
        font-size: var(--font-size__label);
        color: var(--color-label-primary__regular);
      }
    }

    &.ant-form-item-has-error {
      > input {
        color: var(--color-error__regular);

        &:not(:focus) {
          box-shadow: var(--box-shadow-input__error);
        }

        :focus {
          outline: 0;
          box-shadow: var(--box-shadow-input__selected);
        }
      }
    }

    .ant-input-wrapper {
      input,
      span {
        height: var(--height__input);
        font-weight: var(--font-weight__input);
        font-size: var(--font-size__input);
      }

      > input {
        border-top-right-radius: var(--border-radius__regular);
        border-bottom-right-radius: var(--border-radius__regular);

        &:focus {
          outline: 0;
          box-shadow: var(--box-shadow-input__selected);
        }
      }

      span {
        border-top-left-radius: var(--border-radius__regular);
        border-bottom-left-radius: var(--border-radius__regular);
      }
    }
  }
`;
