export const button = {
  small: 40,
  regular: 50,
  large: 60,
} as const;

export const container = {
  width: 1140,
} as const;

export const customerLogosBanner = {
  height: 104,
} as const;

// Checkout Drawer (desktop) and View Cart Button (Mobile) on Menu Page
export const drawer = {
  width: 440,
} as const;

export const filterSearchMenu = {
  width: 200,
} as const;

export const footer = {
  desktop: {
    height: 70,
  },
  mobile: {
    height: 64,
  },
} as const;

export const header = {
  desktop: {
    height: 80,
  },
  mobile: {
    height: 60,
    underlineImage: {
      height: 10,
    },
  },
} as const;

export const menuBannerImage = {
  desktop: {
    height: 334,
  },
  mobile: {
    height: 167,
  },
} as const;

export const menuItemCard = {
  desktop: 134,
  mobile: 104,
} as const;
