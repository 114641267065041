import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { getEnvironment, getAPIUrl } from 'utils/Host';

export const sentryIntegrations = () => {
  const apiUrl = new URL(getAPIUrl());
  return [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', '127.0.0.1', window.location.hostname, apiUrl.hostname, /^\//],
    }),
  ];
};

export const getSentryHash = async () =>
  await fetch('commit.json')
    .then(async (res) => await res.text())
    .then((hash) => hash)
    .catch((err) => `${err} localdev`);

export const clientOptions = {
  dsn: process.env.REACT_APP_SENTRY_ORG_ID,
  integrations: sentryIntegrations(),
  tracesSampler: (samplingContext) => {
    // Examine provided context data (including parent decision, if any) along
    // with anything in the global namespace to compute the sample rate or
    // sampling decision for this transaction
    // https://docs.sentry.io/platforms/javascript/configuration/sampling/#custom-sampling-context-data
    switch (samplingContext?.transactionContext?.op) {
      case 'navigation':
        return 0.1; // Dont need to take samples of page loads because we have google analytics for that
      case 'pageload':
        return 0.1; // Dont need to take samples of page loads because we have google analytics for that
      default:
        return 1;
    }
  },
  environment: getEnvironment(),
  release: getSentryHash(),
};

export const InitSentry = (options) => {
  Sentry.init(options);
};

export const captureErrorInSentry = (error) => {
  if (process.env.REACT_APP_ENABLE_SENTRY) {
    Sentry.captureException(error);
  }
};
