import styled from 'styled-components';

// Components
import { CaretDownFilled } from '@ant-design/icons';
import { Select } from 'top-component-library';

// Constants
import breakpoints from 'constants/breakpoints';
import { button } from 'constants/style';

export const CustomSelectContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CustomSelectDropdownArrow = styled(CaretDownFilled)`
  align-items: center;
  color: var(--color-neutral__title-active);
  display: flex;
  font-size: 0.875rem;
  pointer-events: none;
  position: absolute;
  right: 14px;
  top: 14px;
`;

export const CustomSelectImage = styled.img`
  margin-right: 0.625rem;
  max-height: 2rem;
  padding: 0;
  width: 30%;
  object-fit: contain;
  min-width: 30%;

  .ant-select-dropdown & {
    margin-right: 1rem;
  }
`;

export const CustomSelectInlineLabel = styled.label`
  background: white;
  color: var(--color-neutral__title-active);
  font-size: 0.625rem;
  left: 0.5rem;
  letter-spacing: 0.5px;
  padding: 0 0.45rem;
  position: absolute;
  top: -0.5rem;
  z-index: 10;

  // Semi-transparent overlay (required in lieu of setting 'opacity: 0.75' on the label itself,
  // which would undesirably cause the border around the select dropdown to be partially visible).
  &::after {
    background: white;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.25;
    position: absolute;
    width: 100%;
  }
`;

export const CustomSelectOption = styled(Select.Option)``;

export const CustomizedSelect = styled(Select)`
  && {
    background-color: var(--color-neutral__input-background);
    display: block;
    width: 100%;

    .ant-select-selection-item > img {
      max-height: 1.25rem;
    }

    .ant-select-item {
      padding: 2px 12px;

      &.ant-select-item-option {
        &:not(.ant-select-item-option-selected) {
          &:hover {
            background-color: var(--color-neutral__accent-background);
          }
        }

        &.ant-select-item-option-selected {
          &:not(.ant-select-item-option-disabled) {
            background-color: var(--color-neutral__accent-background);
          }

          .ant-select-item-option-state {
            color: var(--color-primary__regular);
          }
        }
      }
    }

    .ant-select-item-option-content,
    .ant-select-selection-search-input {
      padding-left: 0;
    }

    .ant-select-selection-item {
      span:not(.ant-select-selection-item-remove) {
        font-size: 0.75rem;
        font-weight: 500;
        letter-spacing: 0.8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .ant-select-selection-item-content {
      img {
        height: 100%;
      }
    }

    .ant-select-selection-item-remove {
      display: inline-flex;

      .anticon {
        display: inline-flex;
      }
    }

    .ant-select-selection-placeholder {
      font-size: 0.75rem;
      color: var(--color-neutral__placeholder);

      @media (min-width: ${breakpoints.md}px) {
        font-size: 0.875rem;
        font-weight: 500;
        letter-spacing: 0.5px;
      }
    }

    .ant-select-selection-search {
      // Prevent unused <input> from being focusable on older iOS devices when the select is not meant to be searchable.
      display: ${({ mode }) => (mode === 'multiple' || mode === 'tags' ? 'block' : 'none')};
      margin-inline-start: -1px;

      @media (min-width: ${breakpoints.md}px) {
        display: block;
      }
    }

    .ant-select-selector {
      border-color: var(--color-neutral__line);
      border-radius: var(--input__border-radius);
      min-height: ${button.small}px;
      padding: 0.25rem 2.25rem 0.25rem 0.875rem;
    }
  }
`;
