import { track } from 'integrations/segment/instrumentation/Track';
import { FieldsType } from './types/types';

enum ManageTabTrackingEvent {
  trackManualCloseTabSuccess = 'track_manual_close_tab_success',
  trackManualCloseTabFailure = 'track_manual_close_tab_failure',
  trackOpenConsumerTab = 'track_open_consumer_tab',
  trackClickViewTabButton = 'track_click_view_tab_button',
}

export const trackClickViewTabButton = (fields: FieldsType = {}): void => {
  track(ManageTabTrackingEvent.trackClickViewTabButton, fields);
};

export const trackManualCloseTabSuccess = (fields: FieldsType = {}): void => {
  track(ManageTabTrackingEvent.trackManualCloseTabSuccess, fields);
};

export const trackManualCloseTabFailure = (fields: FieldsType = {}): void => {
  track(ManageTabTrackingEvent.trackManualCloseTabFailure, fields);
};

export const trackOpenConsumerTab = (fields: FieldsType = {}): void => {
  track(ManageTabTrackingEvent.trackOpenConsumerTab, fields);
};
