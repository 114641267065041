import Icon from '@ant-design/icons';

const TadaCircleSVG = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="28.5" fill="#EFF8FF" stroke="#D1E9FF" strokeWidth="3" />
    <path
      d="M21.4841 24.3401L13.6353 40.969C12.8017 42.5987 14.4677 44.3762 16.15 43.652L32.6403 37.8313"
      stroke="#194185"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.1361 27.6534C33.3394 31.3496 34.7821 35.6776 33.3584 37.3204C31.9347 38.9631 28.1837 37.2985 24.9804 33.6023C21.777 29.9062 20.3344 25.5781 21.7581 23.9354C23.1818 22.2926 26.9327 23.9573 30.1361 27.6534Z"
      stroke="#194185"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.6745 40.6622C21.5087 39.9172 20.2785 38.8462 19.1199 37.5094C18.357 36.629 17.6939 35.7129 17.1457 34.8057"
      stroke="#194185"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.5815 15.9768C26.5815 16.5162 26.1442 16.9535 25.6048 16.9535C25.0653 16.9535 24.628 16.5162 24.628 15.9768C24.628 15.4373 25.0653 15 25.6048 15C26.1442 15 26.5815 15.4373 26.5815 15.9768Z"
      stroke="#194185"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.8838 24.2791L35.1352 24.0277C37.4238 21.739 37.4238 18.0284 35.1352 15.7398L34.8838 15.4884"
      stroke="#194185"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.3256 31.6046L37.8978 31.0325C38.6607 30.2696 39.8976 30.2696 40.6605 31.0325L41.2326 31.6046"
      stroke="#194185"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.5466 23.4698L45.7839 23.74C44.767 24.1003 43.6505 23.5679 43.2902 22.551L43.02 21.7883"
      stroke="#194185"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.3659 30.4362C49.3659 30.9756 48.9286 31.4129 48.3892 31.4129C47.8497 31.4129 47.4124 30.9756 47.4124 30.4362C47.4124 29.8967 47.8497 29.4594 48.3892 29.4594C48.9286 29.4594 49.3659 29.8967 49.3659 30.4362Z"
      stroke="#194185"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const TadaCircle = (props) => (
  <Icon title="Tada Circle" aria-label="Tada Circle" component={TadaCircleSVG} {...props} />
);

export default TadaCircle;
