import { SVGIconWrapper } from './styles';

const AlcoholSVG: React.FC<React.SVGProps<SVGElement>> = ({ fill }) => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={fill ?? 'var(--color-dd-system-gray__50)'}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1381 1.47141C11.3984 1.21107 11.3984 0.788955 11.1381 0.528606C10.8777 0.268256 10.4556 0.268256 10.1953 0.528606L9.05721 1.66668H1.40685C0.282011 1.66668 -0.337386 2.97375 0.374903 3.84433L5.33335 9.90466V11.3333H3.33335C2.96516 11.3333 2.66669 11.6318 2.66669 12C2.66669 12.3682 2.96516 12.6667 3.33335 12.6667H8.66669C9.03488 12.6667 9.33335 12.3682 9.33335 12C9.33335 11.6318 9.03488 11.3333 8.66669 11.3333H6.66669V9.90465L11.6251 3.84433C12.2679 3.05867 11.8262 1.91751 10.9071 1.70236L11.1381 1.47141ZM7.72388 3.00001H1.40685L6.00002 8.61389L10.5932 3.00001H9.6095L7.88523 4.72428C7.95933 4.91294 8.00002 5.11839 8.00002 5.33334C8.00002 6.25382 7.25383 7.00001 6.33335 7.00001C5.41288 7.00001 4.66669 6.25382 4.66669 5.33334C4.66669 4.41287 5.41288 3.66668 6.33335 3.66668C6.54831 3.66668 6.75376 3.70737 6.94242 3.78147L7.72388 3.00001ZM6.33335 5.00001C6.14926 5.00001 6.00002 5.14925 6.00002 5.33334C6.00002 5.51744 6.14926 5.66668 6.33335 5.66668C6.51745 5.66668 6.66669 5.51744 6.66669 5.33334C6.66669 5.14925 6.51745 5.00001 6.33335 5.00001Z"
    />
  </svg>
);

const Alcohol: React.FC<React.SVGProps<SVGElement>> = ({ fill }) => (
  <SVGIconWrapper width={12} height={13} data-testid="alcohol-tag" title="Alcohol" aria-label="Alcohol">
    <AlcoholSVG fill={fill} />
  </SVGIconWrapper>
);

export default Alcohol;
