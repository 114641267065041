export const retrieveFromLocalStorage = (key: string, defaultVal?: any): any | undefined => {
  try {
    const serializedData = localStorage.getItem(key);

    // Not found or null
    if (serializedData === null || serializedData === 'null' || serializedData === 'undefined') {
      removeFromLocalStorage(key);
      return defaultVal || undefined;
    } else if (serializedData === 'false' || serializedData === 'true') {
      // Is a boolean
      return serializedData === 'true';
    } else {
      // Return JSON if the string can be parsed
      // If an error is thrown then assume it is not valid JSON and return the serialized data
      try {
        const data = JSON.parse(serializedData);
        return data;
      } catch (err) {
        return serializedData;
      }
    }
  } catch (err) {
    return undefined;
  }
};

export const saveToLocalStorage = (key: string, data: any): void => {
  try {
    const serializedData = JSON.stringify(data);
    localStorage.setItem(key, serializedData);
  } catch (err) {
    console.error(err);
  }
};

export const removeFromLocalStorage = (key: string): void => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    console.error(err);
  }
};
