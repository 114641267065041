import { Spacing } from '@doordash/design-language';
import styled from 'styled-components';

const RoundButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing.XxSmall}px;
  padding: ${Spacing.XxSmall}px ${Spacing.Small}px;
  box-shadow: 0px ${Spacing.XxxxSmall}px ${Spacing.XxSmall}px var(--bose-group-order__button-shadow);
  border-radius: ${Spacing.XxLarge}px;
`;

export default RoundButton;
