// Styles
import { Icon } from '@doordash/design-language';
import { BackButton } from './styles';

const BackButtonComponent = ({
  className,
  onClick,
  testId,
}: {
  className?: string;
  onClick: () => void;
  testId?: string;
}) => (
  <BackButton
    data-testid={testId}
    aria-label="Back"
    // `className` is required to pass styles when used as a styled component.
    className={className}
    icon={<Icon type={Icon.Types.ArrowLeft} size={Icon.Sizes.Small} shouldAdjustSmallSizesWhitespace />}
    onClick={onClick}
  />
);

export default BackButtonComponent;
