import {
  CanMakePaymentResult,
  CreateTokenCardData,
  loadStripe,
  PaymentRequest,
  PaymentRequestTokenEvent,
  Stripe,
  StripeElements,
} from '@stripe/stripe-js';

// Constants
import {
  CheckoutValidationError,
  StripeCardSetupError,
  StripeCardValidationError,
  StripeError,
  TOPLoggedError,
} from 'constants/Errors';

// Types
import Customer from 'models/Customer';

// Utils
import { formatCurrencyCode } from 'utils/Currency';

interface CanMakePaymentResultForLocalDev extends CanMakePaymentResult {
  validToken: boolean; // Used to show valid token payment request button in localdev
  invalidToken: boolean; // Used to show invalid token payment request button in localdev
}

export enum PaymentRequestPaymentMethod {
  validToken = 'validToken',
  invalidToken = 'invalidToken',
  applePay = 'applePay',
  googlePay = 'googlePay',
}

export enum GoogleApplePaymentMethod {
  applePay = 'applePay',
  googlePay = 'googlePay',
}

let stripeInstance: Stripe | null;

export const getStripeInstance = async (stripeAPIKey: string): Promise<Stripe> => {
  if (stripeInstance) {
    return stripeInstance;
  }
  if (!stripeAPIKey) {
    // We didn't load a key in from the backend, so default to the TOP key
    console.error(
      'The backend did not provide a Stripe API key. Assuming this is not a DD Mx, defaulting to the TOP key'
    );
    stripeAPIKey = process.env.REACT_APP_STRIPE_API_KEY!;
  }
  stripeInstance = await loadStripe(stripeAPIKey);
  return stripeInstance!;
};

export const confirmSetup = async (elements: StripeElements | undefined, userHasGiftCardSelected: boolean) => {
  if (!stripeInstance || !elements) {
    if (userHasGiftCardSelected) {
      throw new CheckoutValidationError('Please add another payment method to cover the rest of this charge.', {
        endpoint: 'checkoutStore.checkout',
      });
    } else {
      throw new StripeError('Please wait for the credit card form to load and try again.');
    }
  }

  const { setupIntent, error } = await stripeInstance.confirmSetup({
    elements,
    confirmParams: {
      return_url: window.location.href,
    },
    redirect: 'if_required',
  });

  if (error) {
    if (error.type === 'validation_error') {
      throw new StripeCardValidationError(error.message!);
    }
    throw new StripeCardSetupError(error.message ?? 'Please check your card details or try refreshing');
  }

  return setupIntent;
};

export const retrieveToken = async (
  elements: StripeElements | undefined,
  stipeTokenOptions: CreateTokenCardData | undefined,
  expectingStripeFieldErrors: boolean
) => {
  if (!stripeInstance || !elements) {
    throw new StripeError('Please wait for the credit card form to load and try again.');
  }
  const cardElement = elements.getElement('cardNumber');

  if (!cardElement) {
    throw new StripeError('Failed to find card element. Please refresh and try again.');
  }

  // If we don't expect the stripe fields to error out, but the zip is invalid then throw an error before submitting stripe values
  if (!expectingStripeFieldErrors && (!stipeTokenOptions || !stipeTokenOptions?.address_zip)) {
    throw new StripeError('Your postal code is incomplete.');
  }

  const { token, error } = await stripeInstance.createToken(cardElement, stipeTokenOptions);

  if (!token) {
    if (error) {
      // eslint-disable-next-line no-console
      console.log('While attempting to retrieve the token, we encountered the following error: ', error);
      throw new StripeError(error?.message);
    }
    throw new StripeError('Failed to retrieve token. Please refresh and try again.');
  }
  return token.id;
};

/**
 * Used to configure the Google Pay, Apple Pay button. We pass the customer to this function because the payment
 * request could use either the host_customer or the location_customer. We do not allow Google Pay / Apple Pay for
 * Party Tab creation because of incremental authorization but to make this easy to read and debug we pass the
 * customer.
 *
 * @param customer
 * @param onToken
 * @param onCancel
 * @returns {PaymentRequest | undefined}
 */
export const setUpPaymentRequest = (
  customer: Customer,
  onToken: (event: PaymentRequestTokenEvent) => void,
  onCancel: (event: Event) => void
): PaymentRequest | undefined => {
  try {
    // NOTE: The Payment request options, in this case the customer, can be updated as per docs
    // https://stripe.com/docs/js/payment_request/create#stripe_payment_request-options
    const pr = stripeInstance?.paymentRequest({
      country: customer?.physical_address?.country_code || 'US', // NOTE: Must be uppercase. Not all customers have their physical address inputted
      currency: formatCurrencyCode(customer?.currency), // NOTE: must be lowercase
      total: {
        label: customer?.customer_name || '',
        amount: 0, // total gets updated by payment request update method.
      },
      requestPayerPhone: true,
      // requestPayerName: true, //makes Apple Pay submit zip code, which reduces Visa penalties. It turns out a LOT of people have the wrong zip in their Apple Pay account, so turning this off until we add a specific error message for it.
    });

    if (pr) {
      pr.on('token', onToken);
      pr.on('cancel', onCancel);
    }

    return pr;
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

export const updatePaymentRequestTotal = (
  pr: PaymentRequest | undefined,
  customer: Customer,
  newCartTotal: number
): void => {
  try {
    if (!pr) {
      return;
    }
    const currency = formatCurrencyCode(customer?.currency); // NOTE: must be lowercase
    const label = customer?.customer_name ?? 'Cart Total';

    pr.update({
      currency,
      total: {
        label,
        amount: newCartTotal,
      },
    });
  } catch (error) {
    if (!(error instanceof TOPLoggedError)) {
      console.error(error);
    }
  }
};

export const canMakePaymentForEnvironment = async (
  pr: PaymentRequest
): Promise<CanMakePaymentResultForLocalDev | CanMakePaymentResult> => (await pr.canMakePayment()) ?? {};

export const validPaymentMethodsForEnvironment = (
  canMakePayment: CanMakePaymentResultForLocalDev | CanMakePaymentResult
): Array<{ id: PaymentRequestPaymentMethod }> =>
  Object.entries(canMakePayment)
    .filter(([key, value]) => Object.keys(PaymentRequestPaymentMethod).includes(key) && value)
    .map(([key, value]) => ({
      id: key as PaymentRequestPaymentMethod,
    }));
