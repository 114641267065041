import styled from 'styled-components';
import { animated, AnimatedComponent } from 'react-spring';

import { LeftOutlined, FileSearchOutlined } from '@ant-design/icons';
import { Button, Typography, ButtonProps } from 'antd';

export const Body = styled.div`
  display: flex;
  height: 0;
  overflow: hidden;
  padding: 0;
  transition: 1s height ease-in-out;
  width: 100%;

  * {
    font-size: 0.875rem;
  }

  ul {
    padding-inline-start: 1rem;
    margin-bottom: 0;

    li:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &[data-expanded='true'] {
    padding: 1rem;
    height: auto;
  }
`;

export const Container = styled.div`
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  min-width: 25vw;
  max-width: calc(100vw - 2rem);
  overflow: hidden;
`;

export const FileSearchOutlinedIcon = styled(FileSearchOutlined)`
  &:not(:first-child) {
    margin-left: 1rem;
  }
`;

export const Header = styled.div`
  align-items: center;
  background-color: #f1f3f4;
  display: flex;
  font-size: 1.5rem;
  justify-content: space-between;
  padding: 0.5rem 0 0.5rem 1rem;
  width: 100%;

  & > span {
    display: flex;
    align-items: center;
  }
`;

export const DraggableElement: AnimatedComponent<React.ComponentPropsWithRef<React.ElementType>> = styled(animated.div)`
  border-radius: 0.25rem;
  position: fixed;
  z-index: 1004;
  bottom: 1rem;
  right: 1rem;
  height: auto;
  width: auto;
  cursor: grab;
  touch-action: none;
`;

export const FinishButton: React.FunctionComponent<ButtonProps> = styled(Button)`
  &&& {
    background-color: transparent;
    border-radius: 0.25rem;
    color: var(--color-neutral__body);
    padding: 0 0.5rem;
    transition: 0.3s all ease-in-out;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);

      & > span {
        text-decoration: none;
      }
    }

    & > span {
      color: var(--color-neutral__body);
    }
  }
`;

export const LeftOutlinedIcon = styled(LeftOutlined)`
  font-size: 1rem;
  position: relative;
  transform: rotate(0deg);
  transition-duration: 5s;
  transition-property: rotate;
  transition-timing-function: ease-in-out;
  z-index: auto;

  > svg {
    color: var(--color-neutral__body);
  }

  &[data-expanded='true'] {
    transform: rotate(-90deg);
  }

  &:after {
    background-color: #000;
    border-radius: 100px;
    content: '';
    height: 2rem;
    left: -0.5rem;
    opacity: 0;
    position: absolute;
    top: -0.5rem;
    width: 2rem;
    z-index: 3;
  }

  &:hover {
    color: var(--color-neutral__body);

    &:after {
      opacity: 0.1;
    }
  }
`;

export const Title = styled(Typography.Title)`
  &&& {
    display: inline-block;
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 0;

    &:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
`;

export const ActionList = styled.div`
  background-color: #f1f3f4;
  box-sizing: border-box;
  display: none;
  justify-content: flex-end;
  padding: 0.5rem 1rem;

  &[data-expanded='true'] {
    display: flex;
  }

  &:empty {
    padding: 0;
  }
`;
