enum breakpoints {
  xxs = 360,
  xs = 480,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1600,
  ultraWide = 1920, // The bottom threshold for ultra wide
}

export default breakpoints;
