import Cart from 'models/Cart';
import CartItem from 'models/CartItem';
import Customer from 'models/Customer';
import Check from 'models/Check';

/** Universal Analytics Enhanced Ecommerce
 * https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce?hl=en
 */

// https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce?hl=en#impression-data
// interface ImpressionFieldObject {
//   id: string // The product ID or SKU (e.g. P67890). * One of id or name must be set.
//   name: string // The name of the product (e.g. Android T-Shirt). * One of id or name must be set.
//   list_name: string // The list or collection to which the product belongs (e.g. Search Results)
//   brand: string // The brand associated with the product (e.g. Google).
//   category: string // The category to which the product belongs (e.g. Apparel). Use / as a delimiter to specify up to 5-levels of hierarchy (e.g. Apparel/Men/T-Shirts).
//   variant: string // The variant of the product (e.g. Black).
//   list_position: number // The product's position in a list or collection (e.g. 2).
//   price: number // The price of a product (e.g. 29.20).
// }

// https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce?hl=en#product-data
interface ProductFieldObject {
  id: string;
  name: string;
  brand: string;
  category: string;
  variant?: string;
  price: string;
  quantity: number;
  coupon?: string;
  list_position: number;
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce?hl=en#action-data
interface ActionFieldObject {
  id?: string; // The transaction ID * Required if the action type is purchase or refund
  affiliation?: string;
  value?: string;
  tax?: string;
  shipping?: string;
  items?: Array<ProductFieldObject>;
  checkout_step?: number;
  checkout_option?: string;

  // Only available for 'detail' action
  list?: string; // Name of the list showing the item

  // Only available for 'purchase' action
  revenue?: string;
  coupon?: string;
}

interface UniversalAnalyticsEcommMeasurementData {
  actionField?: ActionFieldObject;
  products: Array<ProductFieldObject>;
}

const formatCartItemToUniversalAnalyticsProduct = (cartItem: CartItem): ProductFieldObject => ({
  id: cartItem.menuItem?.menu_item_id,
  name: cartItem.menuItem?.name_for_customer,
  brand: cartItem.menu?.customer?.customer_name,
  category: cartItem.menuItem?.heading?.heading_name,
  price: (cartItem.getTotal() / 100 ?? 0).toFixed(2),
  quantity: cartItem.qty,
  list_position: cartItem.menuItem?.display_position,
});

// View Item Details
interface ProductDetailsMeasurement {
  detail: UniversalAnalyticsEcommMeasurementData;
}
export const formatViewDetailsEvent = (cartItem: CartItem): ProductDetailsMeasurement => ({
  detail: {
    actionField: { list: cartItem.menuItem?.heading?.heading_name },
    products: [formatCartItemToUniversalAnalyticsProduct(cartItem)],
  },
});

// Add To Cart
interface AddToCartMeasurement {
  currencyCode: string;
  add: UniversalAnalyticsEcommMeasurementData;
}
export const formatAddToCartEvent = (cartItem: CartItem, locationCustomer: Customer): AddToCartMeasurement => ({
  currencyCode: locationCustomer.currency,
  add: {
    products: [formatCartItemToUniversalAnalyticsProduct(cartItem)],
  },
});

// Remove From Cart
interface RemoveFromCartMeasurement {
  currencyCode: string;
  remove: UniversalAnalyticsEcommMeasurementData;
}
export const formatRemoveFromCartEvent = (
  cartItem: CartItem,
  locationCustomer: Customer
): RemoveFromCartMeasurement => ({
  currencyCode: locationCustomer.currency,
  remove: {
    products: [formatCartItemToUniversalAnalyticsProduct(cartItem)],
  },
});

// Purchase
interface PurchaseMeasurement {
  purchase: UniversalAnalyticsEcommMeasurementData;
}
export const formatPurchaseEvent = (
  cart: Cart,
  check: Check,
  customer: Customer,
  coupon: string = ''
): PurchaseMeasurement => ({
  purchase: {
    actionField: {
      id: check.charge?.id ?? '', // Transaction ID. Required for purchases and refunds. TODO: Account for several checks
      affiliation: customer.customer_name,
      revenue: (cart.cartTotal / 100 ?? 0).toFixed(2),
      value: (cart.cartTotal / 100 ?? 0).toFixed(2),
      tax: (cart.getTax() / 100 ?? 0).toFixed(2),
      shipping: '0.00',
      coupon, // All the promo-codes used then joined into a large string
    },
    products: cart.items.map((item) => formatCartItemToUniversalAnalyticsProduct(item)),
  },
});
