import Order from 'models/Order';
import { TipChoice } from 'models/Cart';
import { DateTime } from 'luxon';

// Customer
export interface GoogleTagManagerIntegration {
  id: string;
}

export interface Checkout {
  checkout_id: string;
  checkoutTime: string;
  orders: Array<Order>;
}

export interface OrderStatusSection {
  id: string;
  checkouts?: Array<Checkout>;
  header?: React.ReactNode;
  defaultActiveKey?: string | number[];
}

export enum OrderSectionId {
  Orders = 'orders',
  OpenConsumerTab = 'open-consumer-tab',
  OpenPartyTab = 'open-party-tab',
  ClosedTabs = 'closed-tabs',
  OrderMoreButton = 'order-more-button-section',
}

export type ParsedGroupingId = Exclude<OrderSectionId, OrderSectionId.OrderMoreButton>;

export interface ParsedOrderSection {
  id: ParsedGroupingId;
  name: string;
  checkouts: Checkout[];
  lastOrderTime: DateTime;
}

export interface OrderMoreButtonSection {
  id: OrderSectionId.OrderMoreButton;
}

export type OrderSection = ParsedOrderSection | OrderMoreButtonSection;

// eslint-disable-next-line no-shadow
export enum TipType {
  Percentage = 'percentage',
  Amount = 'amount',
}

export type TipKey = `${TipChoice}-${number}`;

export interface Card {
  id: string;
  brand: string;
  last4: string;
  exp_month: string;
  exp_year: string;
  default: boolean;
}

export interface GiftCard {
  id: string;
  provider: string;
  last4: string;
  balance: number;
  selected: boolean;
}

export interface GiftCardCharge {
  id: string;
  giftCardDisplay: string;
  totalAmountForOrder: number;
}

export interface OrderItemFragment {
  orderitem_id: string;
  total_cents: number;
}

export interface OrderFeeFragment {
  orderfee_id: string;
  total_cents: number;
}

export interface BackendCharge {
  id: string;
  tender_type: string;
  item_charge_fragments: Array<OrderItemFragment>;
  fee_charge_fragments: Array<OrderFeeFragment>;
  gift_card_last4: string;
}

export interface ChargeDistribution {
  amount: number;
  chargeType: string;
  gift_card_id?: string; // optional
}
