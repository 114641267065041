import { AppProperties } from 'api/types';
import { GoogleTagManagerIntegration } from 'models/Types';

// Util
import { formatLocaleToValidSyntax } from 'utils/Locale';
import { TODO } from '../utils/Types';

export default class Customer {
  customer_id: string = '';
  app_properties: Partial<AppProperties> = {};
  banner_url: string = '';
  brand_parent: TODO = {};
  customer_name: string = '';
  desktop_banner_url: string = '';
  display_position: number = 0; // Matches minimum menu display_position for now
  tags: Partial<Record<TagType, CustomerTag>> = {};
  store_hours: string = '';
  integrations: TODO = {};
  enabled_integrations: Array<string> = [];
  has_gift_card_integration: Boolean = false;
  google_tag_manager: GoogleTagManagerIntegration = { id: 'false' };
  locale!: Intl.Locale;
  currency: string = '';
  tax_inclusive_pricing: boolean = false;
  physical_address: TODO;
  email_for_patrons: string = '';
  phone_for_patrons: string = '';
  enabled_features: string[] = [];
  upsells: string[] = [];

  constructor(obj: Customer & { locale: string | Intl.Locale }) {
    // TODO(types): fix this weird thing where we modify/parse locale before passing it to the update method
    obj.locale = formatLocaleToValidSyntax((obj?.locale ?? 'en-US') as string);
    this.update(obj);
  }

  public update(data: Customer): void {
    Object.entries(data).forEach(([key, value]) => {
      if (key !== 'logoUrl' && Object.prototype.hasOwnProperty.call(this, key)) {
        this[key as keyof typeof this] = value;
      }
    });
  }

  public getEnabledFeatures(featureName: string): boolean {
    return this?.enabled_features?.includes(featureName);
  }

  public get logoUrl(): string {
    return this.brand_parent?.logo_url || this.app_properties?.logo_url;
  }

  static isBbot(customer: Customer): boolean {
    return customer.customer_name?.toLowerCase() === 'bbot';
  }
}

export type TagType = 'gluten_free' | 'spicy' | 'vegetarian' | 'alcohol' | 'vegan';
export interface CustomerTag {
  display_name: string;
  img: string;
  visible: boolean;
  numeric: boolean;
}
