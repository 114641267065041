import combineURLs from 'axios/lib/helpers/combineURLs';
import { DateTime } from 'luxon';
import { makeAutoObservable, observable, runInAction } from 'mobx';

// Models
import Customer from 'models/Customer';
import Location from 'models/Location';

// Constants
import { TOPLoggedError } from 'constants/Errors';
import { FulfillmentMethod } from 'constants/FulfillmentMethods';

// Utils
import { deleteAllClientCookies } from 'utils/Cookie';
import { getCurrencySymbol } from 'utils/Currency';
import { getRootUrl } from 'utils/Host';
import { removeFromLocalStorage, retrieveFromLocalStorage } from 'utils/LocalStorage';

// Tracking
import { setLocationCustomer } from 'integrations/segment/instrumentation/CustomerService';

// Types
import RootStore from 'stores/RootStore';
import { CustomMenuProperties, RequiredCheckoutInfo } from 'stores/Types';
import { TODO } from 'utils/Types';
import { managedFeatures } from '../DynamicValues/DynamicValuesProvider';

interface KioskConfig {
  stripe_terminal_id: string | null;
  hardware_id: string | null;
  config: Partial<{
    stripe_terminal_config: TODO;
    screensaver_timeout: number;
    thank_you_timeout: number;
    thank_you_title: string;
    thank_you_description: string;
  }>;
}

export default class LocationStore {
  _currencySymbol: string | null = null;
  _loading: boolean = false;
  loaded: boolean = false;
  loadingLoc: string | null = null;

  id: string = '';
  allow_service_requests: boolean = false;
  service_request_options: Array<TODO> = [];
  locationName: string = '';
  locationShortId: string = '';
  customerLogo: string = '';
  customer: Customer = {
    customer_id: '',
  };

  enable_checkout: boolean = true;
  time_guests_seated: string = '';
  fulfillment_method?: FulfillmentMethod;
  possible_fulfillment_methods: Array<TODO> = [];
  seated_group_id: string = '';
  allow_order_ahead: string | null = null;
  order_allowed: string = '';
  forced_tip_fraction = null;
  forced_tip_name: string = '';
  exceeded_rate_limits: Array<TODO> = [];
  hours: Array<TODO> = [];
  merged_menus: Array<TODO> = [];
  custom_menu_properties: Partial<CustomMenuProperties> = {};
  required_checkout_info: RequiredCheckoutInfo = {};
  pre_checkout_instructions: Partial<{
    bottom_always?: string;
    bottom_food?: string;
    bottom_drink?: string;
    top_always?: string;
    tab_bottom_always?: string;
    tab_bottom_food?: string;
    tab_bottom_drink?: string;
  }> = {};

  checkout_pii_disclaimer_text: string = '';

  customers: Set<Customer> = new Set();
  uses_promo_codes: boolean = false; // Serializer method field
  message_when_off: string = '';

  // Kiosk Mode
  kiosk_enabled: boolean = false;
  kiosk: KioskConfig = {
    stripe_terminal_id: null,
    hardware_id: null,
    config: {},
  };

  show_screen_saver: boolean = false;
  debounceIdLocationRedirect?: NodeJS.Timeout;

  // Consumer Tabs
  allow_consumer_tabs: boolean = false;
  tabs_on_by_default: boolean = false;

  // Shared Carts
  shared_carts_allowed: boolean = false;
  sharedCartModalToShow: TODO = null;
  showLockSharedCartButton: boolean = true;

  rootStore: RootStore;

  _availableCustomers?: Array<Customer>;

  desktopBanner?: string;
  mobileBanner?: string;

  allow_tip_at_end_of_tab: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
      kiosk: observable.deep,
    });
  }

  reset = () => {
    runInAction(() => {
      this.customer = {};
      this.customers = new Set();
      this.merged_menus = [];
      this.fulfillment_method = '';
    });
    setLocationCustomer({});
    this.rootStore.menuDataStore.reset();
    this.rootStore.stripeStore.resetPaymentRequest();
  };

  setLoaded = (val: boolean) => {
    runInAction(() => {
      this.loaded = val;
    });
  };

  setSharedCartModalToShow(modalId: string | null) {
    runInAction(() => {
      this.sharedCartModalToShow = modalId;
    });
  }

  setShowLockSharedCartButton = (bool: boolean) => {
    runInAction(() => {
      this.showLockSharedCartButton = bool;
    });
  };

  get allowsConsumerTabs() {
    return !Location.supportsDelivery(this as unknown as Location) && this.allow_consumer_tabs;
  }

  get menuPageHref() {
    return `${getRootUrl()}/${this.locationShortId}`;
  }

  get currencySymbol() {
    // TODO: get the currency symbol from the owner panel since getting the currency symbol on browsers can run into polyfill issues
    if (this._currencySymbol && this.currencyCode) {
      return this._currencySymbol;
    }
    const symbol = getCurrencySymbol(this.currencyCode);
    runInAction(() => {
      this._currencySymbol = symbol;
    });
    return this._currencySymbol;
  }

  get currencyCode() {
    return this.customer?.currency;
  }

  get enabledIntegrations() {
    return this.customer.enabled_integrations;
  }

  get switchLocationText() {
    // When the location text is set to an empty string then it means it should not be shown
    const locationDontShowText = this.custom_menu_properties?.switch_text?.length === 0;

    // When the hostname text is set to an empty string then it means it should not be shown, however the location
    // code always takes precedence over this
    const hostnameDontShowText = this.rootStore.hostStore?.menu_page_config?.switch_text?.length === 0;

    if (locationDontShowText) {
      // The location config should always take precedence
      return null;
    } else if (this.custom_menu_properties?.switch_text?.length !== 0 && !this.custom_menu_properties?.switch_text) {
      // If the location code text is null then use the hostname config switch text if it isn't length 0
      return !hostnameDontShowText && this.rootStore.hostStore?.menu_page_config?.switch_text;
    } else {
      // Else use whatever the location code has saved
      return this.custom_menu_properties?.switch_text;
    }
  }

  getChildMenuPks = (menuId: string) => this.merged_menus.find((menu) => menu.menuId === menuId)?.children || [];

  setShowScreenSaver = (val: boolean) => {
    runInAction(() => {
      this.show_screen_saver = val;
    });
  };

  fetchLocationData = async (locationCode: string, forceRefetch: boolean = false) => {
    const normalizedLocationCode = locationCode.toLowerCase();
    // Check to see if you have started or have already loaded the location code you are requesting data for
    if (!forceRefetch && this.loadingLoc === normalizedLocationCode && (this.loaded || this._loading)) {
      return;
    }

    try {
      runInAction(() => {
        this.loaded = false;
        this._loading = true;
        this.loadingLoc = normalizedLocationCode;
      });

      this.reset();

      const response = await this.rootStore.api.getLocationData(normalizedLocationCode);
      setLocationCustomer(response.customer);

      if (normalizedLocationCode === 'random' && normalizedLocationCode !== response.locationShortId) {
        this.loadingLoc = response.locationShortId;
        this.rootStore.uiState.history.push(`/${response.locationShortId as string}`);
      }

      // Used to keep the menus for the location in sync with the location loaded
      this.rootStore.menuDataStore.setLocationId(response.id);

      await this.loadStripe(response.customer);

      runInAction(async () => {
        // For each key in  the response, set the corresponding property on our LocationStore:
        Object.keys(response).forEach((key: string) => {
          if (Object.prototype.hasOwnProperty.call(this, key)) {
            this[key] = response[key];
          }
        });

        // TODO: last_fetched is never used in the codebase
        this.last_fetched = DateTime.now();
        this.desktopBanner = response.customer?.desktop_banner_url;
        this.mobileBanner = response.customer?.banner_url;
        delete this.customer.desktopBanner; // remove desktop_banner_url from the customer object
        delete this.customer.mobileBanner; // remove banner_url from the customer object
        this.customer = new Customer(response.customer);
        this.pre_checkout_instructions = response.customer?.app_properties?.pre_checkout_instructions;

        this.rootStore.menuDataStore.loaded = false;
        this.shared_carts_allowed = response?.shared_carts_allowed;
        this.allow_tip_at_end_of_tab = response?.allow_tip_at_end_of_tab;

        if (this.allow_tip_at_end_of_tab) {
          // We support cloud tabs, so we need to fetch the list of POS only tabs available to claim for this location.
          await this.rootStore.tabStore.getUnclaimedPosOnlyTabsForLocation(this.id);
        }

        this.loaded = true;
        this._loading = false;
      });

      const sharedCartFromLocalStorage = retrieveFromLocalStorage(`shared_cart:${this.id}`);
      const { selectedCart } = this.rootStore.checkoutStore;
      const forceServerSideCarts = this.rootStore.locationStore.customer?.getEnabledFeatures(
        managedFeatures.serverSideCarts
      );
      const { activeCartIdByLocationId } = this.rootStore.userStore;
      const shouldSwitchToActiveCartAtLocation: boolean =
        forceServerSideCarts &&
        Boolean(activeCartIdByLocationId[this.id]) &&
        this.rootStore.checkoutStore.selectedCart.sharedCartReference?.id !== activeCartIdByLocationId[this.id];

      if (shouldSwitchToActiveCartAtLocation) {
        await this.rootStore.checkoutStore.rejoinSharedCart(activeCartIdByLocationId[this.id]);
      } else if (this.shared_carts_allowed && sharedCartFromLocalStorage) {
        await this.rootStore.checkoutStore.loadSharedCartFromLocalStorage(sharedCartFromLocalStorage);
      }
      // If a shared cart is loaded from viewing a previous menu page then remove it and start from a clean state
      else if (selectedCart?.sharedCartReference) {
        selectedCart.setSharedCartReference(null, false);
        selectedCart.resetCart();
      }

      if (this.kiosk_enabled && !this.rootStore.stripeTerminalStore.loaded) {
        if (window.location.pathname.includes('checkout')) {
          this.rootStore.uiState.history?.push(`/${this.locationShortId}`);
        }
        this.setShowScreenSaver(true);
        this.rootStore.stripeTerminalStore.init();
      }

      // put call in a setTimeout with no time param
      await this.rootStore.userStore.loadUserCards();

      this.rootStore.hostStore.updateIntegrations(response.customer);

      this.rootStore.checkoutStore.setLocationId(this.id);

      // Logic for setting the initial fulfillment method is on the LocationRoute because it is not dependent only on
      // the loading of a location. Ex: Load menuPage, navigate to LP, then navigate back to menuPage using the same
      // location. In this example the location data is only loaded once however on the LP you can change the selected
      // fulfillment method (Map Template)
    } catch (err) {
      if (!(err instanceof TOPLoggedError)) {
        console.error(err);
      }
      runInAction(() => {
        this.loaded = false;
        this._loading = false;
        this.loadingLoc = null;
      });

      throw err;
    }
  };

  addCustomer = (customer: Customer) => {
    if (!this.customers.has(customer)) {
      runInAction(() => {
        this.customers.add(customer);
      });
    }
  };

  // ------------------------------------------------------------
  //
  // Kiosk Config
  //
  // ------------------------------------------------------------

  updateKiosk = (data: KioskConfig) => {
    runInAction(() => {
      Object.assign(this.kiosk, data);
    });
  };

  /** Gets the milliseconds screensaver timeout value configured from the kiosk configuration.
   * @returns {number|milliseconds}
   */
  get kioskScreenSaverTimeout() {
    return this.kiosk?.config?.screensaver_timeout > 0 ? Number(this.kiosk.config.screensaver_timeout * 1000) : 30000;
  }

  /** Gets the milliseconds thank you page timeout value configured from the kiosk configuration.
   * @returns {number|milliseconds}
   */
  get kioskThankYouTimeout() {
    return this.kiosk?.config?.thank_you_timeout > 0 ? Number(this.kiosk.config.thank_you_timeout * 1000) : 30000;
  }

  get kioskThankYouTitle() {
    return this.kiosk?.config?.thank_you_title ?? 'Thank You';
  }

  get kioskThankYouDescription() {
    return (
      this.kiosk?.config?.thank_you_description ??
      'You will receive a text message when your order is ready to be picked up at the counter.'
    );
  }

  redirectToMenuPage = async (showScreenSaver = false) => {
    // The user is not placing another order and therefore you should not clear localstorage
    if (showScreenSaver) {
      localStorage.clear(); // Clears localStorage
    }
    deleteAllClientCookies(); // Always clear all client cookies
    await this.rootStore.api.clearSessionCookies(); // Rotates Session and CSRF cookies + gets new Anonymous User Id
    this.rootStore.checkoutStore.selectedCart?.resetCart(); // Clears all cart data form store
    removeFromLocalStorage(`shared_cart:${this.id}`);
    this.rootStore.userStore.clearUserData(); // Clears all user data from store
    await this.fetchLocationData(this.locationShortId, true); // Always fetch it again just to keep it up to date
    this.rootStore.uiState.history.push(`/${this.locationShortId}`); // Redirects user to menu page
    this.setShowScreenSaver(showScreenSaver); // Shows screensaver
  };

  cancelRedirectToMenuPage = () => {
    clearTimeout(this.debounceIdLocationRedirect);
    runInAction(() => {
      this.debounceIdLocationRedirect = undefined;
    });
  };

  debounceRedirectToMenuPage = (timeoutValue = 30000, showScreenSaver = true) => {
    if (this.debounceIdLocationRedirect) {
      clearTimeout(this.debounceIdLocationRedirect);
    }
    const handler = setTimeout(async () => await this.redirectToMenuPage(showScreenSaver), timeoutValue); // Fallback to 30 seconds as a fail safe
    runInAction(() => {
      this.debounceIdLocationRedirect = handler;
    });
  };

  get availableCustomers() {
    if (this._availableCustomers) {
      return this._availableCustomers;
    }
    const customerDisplayPositions = this.rootStore.menuDataStore.mergedMenus
      .sort((menu1, menu2) => menu1.display_position - menu2.display_position)
      .map((menu) => menu.customer_id);

    const sortedCustomers = Array.from(this.customers)
      .filter((c) => customerDisplayPositions.includes(c.customer_id))
      .sort(
        (c1, c2) => customerDisplayPositions.indexOf(c1.customer_id) - customerDisplayPositions.indexOf(c2.customer_id)
      );

    if (this.rootStore.menuDataStore.loaded) {
      this._availableCustomers = sortedCustomers;
    }

    return sortedCustomers;
  }

  get isMultiVendor() {
    return this.customers.size > 1;
  }

  get menuBannerContent() {
    return this.rootStore.menuDataStore.selectedMenu?.customer?.app_properties?.pre_checkout_instructions
      ?.below_menu_banner;
  }

  get countryCode() {
    return this.customer?.physical_address?.country_code || 'US';
  }

  // Priority of where we are pulling the logo from:
  // 1. Location Code
  // 2. BOS logo & hostname is not TOP
  // 3. Brand Parent logo
  // 4. Store logo (Presentation to Guests)
  // 5. Home
  getCustomerLogo(): string | undefined {
    const { hostStore } = this.rootStore;
    if (this.custom_menu_properties?.menu_logo_url) {
      return this.custom_menu_properties?.menu_logo_url;
    } else if (hostStore.assets?.menu_page_logo && hostStore.host_customer?.customer_name !== 'bbot') {
      return hostStore.assets?.menu_page_logo;
    } else if (this.customer?.brand_parent?.logo_url) {
      return combineURLs(process.env.REACT_APP_STATIC_ROOT, this.customer?.brand_parent?.logo_url);
    } else if (this.customer?.app_properties?.logo_url) {
      return combineURLs(process.env.REACT_APP_STATIC_ROOT, this.customer?.app_properties?.logo_url);
    } else if (hostStore.host_customer?.customer_name === 'bbot') {
      return hostStore.assets?.menu_page_logo;
    }
    return undefined;
  }

  get customerName(): string | undefined {
    return this.customer?.customer_name ?? this.rootStore.hostStore.host_customer?.customer_name ?? '';
  }

  get showServiceRequestOptions() {
    return (
      this.allow_service_requests &&
      this.service_request_options.length > 0 &&
      (this.rootStore.ordersStore.orders.length ||
        this.service_request_options.find((service) => !service.show_only_after_checkout))
    );
  }

  // -------------------------------------------------------------------------------------------------------------------
  //
  // User Desired Time / Patron Choice Toggle
  //
  // -------------------------------------------------------------------------------------------------------------------

  get showOrderDetailsCard() {
    const { showDateTimePicker } = this;

    return (
      this.rootStore.menuDataStore.loaded &&
      this.rootStore.checkoutStore.loaded &&
      (this.possible_fulfillment_methods.length > 1 || showDateTimePicker)
    );
  }

  get showDateTimePicker() {
    if (this.allow_order_ahead === 'on') {
      return true;
    }
    if (this.allow_order_ahead === 'auto' && this.rootStore.menuDataStore.loaded) {
      // If there are no menu items selected default to showing if at least one menu allows order ahead
      if (this.rootStore.checkoutStore.selectedCart?.itemsCount === 0) {
        return this.rootStore.menuDataStore.atLeastOneMenuAllowsFutureOrders;
      }

      const fulfillableNow = this.rootStore.checkoutStore.selectedCart?.isFulfillableNow();

      return (
        this.rootStore.checkoutStore.selectedCart?.usedMenus.every((menu) => menu.allow_order_ahead) ||
        (this.rootStore.checkoutStore.selectedCart?.usedMenus.some((menu) => menu.allow_order_ahead) && !fulfillableNow)
      );
    }
    return false;
  }

  getSortedMenus = () =>
    this.merged_menus.slice()?.sort((menu1, menu2) => menu1.display_position - menu2.display_position) || [];

  getLocationCheckoutData = () => ({
    location_id: this.id,
    enabled_integrations: this.enabledIntegrations,
    customer_id: this.customer.customer_id,
  });

  loadStripe = async (customer: Customer) => {
    this.rootStore.stripeStore.setCustomerData(customer);
  };

  // Used for the bottom instructions card on checkout
  getCheckoutInstructionsHtml(forTab: boolean = false): string | undefined {
    let bottomCheckoutInstructions = '';
    const arrayOfMenuItemClasses =
      this.rootStore.checkoutStore.selectedCart?.items.map((item) => item?.menuItem?.menu_item_class) ?? [];

    // Message Always
    const bottomAlways = forTab
      ? this.pre_checkout_instructions?.tab_bottom_always
      : this.pre_checkout_instructions?.bottom_always;
    bottomCheckoutInstructions += bottomAlways ?? '';

    // Message if Food in Cart
    if (arrayOfMenuItemClasses.includes('food')) {
      const bottomFood = forTab
        ? this.pre_checkout_instructions?.tab_bottom_food
        : this.pre_checkout_instructions?.bottom_food;
      bottomCheckoutInstructions += bottomFood ?? '';
    }

    // Message if Drink in Cart
    if (arrayOfMenuItemClasses.includes('drink')) {
      const bottomDrink = forTab
        ? this.pre_checkout_instructions?.tab_bottom_drink
        : this.pre_checkout_instructions?.bottom_drink;
      bottomCheckoutInstructions += bottomDrink ?? '';
    }

    return bottomCheckoutInstructions;
  }

  // Used for top checkout instructions card
  getPreCheckoutInstructionsHtml(forTab: boolean = false): string | undefined {
    return forTab ? undefined : this.pre_checkout_instructions?.top_always;
  }
}
