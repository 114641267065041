export const onPremiseUpsellsDictionary: { [key: string]: any } = {
  menus: [
    {
      id: '',
      cart_recommendations: ['', ''],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
    },
    {
      id: 'baaee2b6-35fc-46ef-bee8-ce026a858e0f', // Dinosoaur Cafe Staging Test --> (https://staging.dinosaurcafe.menu/dinocafetabletabsfixed?menu=baaee2b6-35fc-46ef-bee8-ce026a858e0f)
      cart_recommendations: [
        'cb647568-4619-4abd-ae85-b7be72ed22c6',
        'cb647340-2ff8-47b7-9de6-cdad82e0ae86',
        'cb646446-e62a-481a-b011-5e25cdfe639c',
        'cb646256-41f6-4b2c-8244-bf5982fc99b7',
        'cb645716-1b5a-4183-9278-336085a91b92',
      ],
      menu_item: [
        {
          id: 'cb647340-2ff8-47b7-9de6-cdad82e0ae86', // Guava Shiso Lemonade
          recommended_items: [
            'cb647568-4619-4abd-ae85-b7be72ed22c6',
            'cb647340-2ff8-47b7-9de6-cdad82e0ae86',
            'cb646446-e62a-481a-b011-5e25cdfe639c',
            'cb646256-41f6-4b2c-8244-bf5982fc99b7',
            'cb645716-1b5a-4183-9278-336085a91b92',
          ],
        },
      ],
      menu_heading: [
        {
          id: 'cb64441d-622a-44c2-ace8-d130564422a0', // menu_heading_id --> Cocktails
          recommended_items: [
            'cb647568-4619-4abd-ae85-b7be72ed22c6',
            'cb647340-2ff8-47b7-9de6-cdad82e0ae86',
            'cb646446-e62a-481a-b011-5e25cdfe639c',
            'cb646256-41f6-4b2c-8244-bf5982fc99b7',
            'cb645716-1b5a-4183-9278-336085a91b92',
          ],
        },
      ],
    },
    {
      id: '6d67220f-1d4c-4ca1-9737-0ec8733eda56',
      cart_recommendations: [
        'cb41eede-6374-4c06-9225-b420b56d2add',
        'cb41c6a6-f08a-44c5-8acf-8190c0f2b010',
        'cb41d429-4c64-4db5-9822-3cb0c7cd0ae9',
        'cb5f8e0d-413c-415c-af5b-a41defbeb45e',
        'cb41c608-3c56-48c3-94c5-d2a7247cc1e5',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'cb5fac0a-556c-4998-a0ed-1e5e4e84e1ed',
          recommended_items: [
            'cb41eede-6374-4c06-9225-b420b56d2add',
            'cb41c6a6-f08a-44c5-8acf-8190c0f2b010',
            'cb41d429-4c64-4db5-9822-3cb0c7cd0ae9',
          ],
        },
        {
          id: 'cb41c80f-68c6-4cdf-8e8c-1688eecc0b4c',
          recommended_items: [
            'cb41eede-6374-4c06-9225-b420b56d2add',
            'cb41c6a6-f08a-44c5-8acf-8190c0f2b010',
            'cb41d429-4c64-4db5-9822-3cb0c7cd0ae9',
          ],
        },
        {
          id: 'cb41d815-34d8-4717-bece-7783fa300c14',
          recommended_items: [
            'cb41eede-6374-4c06-9225-b420b56d2add',
            'cb41c6a6-f08a-44c5-8acf-8190c0f2b010',
            'cb41d429-4c64-4db5-9822-3cb0c7cd0ae9',
          ],
        },
        {
          id: 'cb41e371-94d8-4617-813a-12662f458d74',
          recommended_items: [
            'cb41eede-6374-4c06-9225-b420b56d2add',
            'cb41c6a6-f08a-44c5-8acf-8190c0f2b010',
            'cb41d429-4c64-4db5-9822-3cb0c7cd0ae9',
          ],
        },
        {
          id: 'cb41dc31-3cf4-458a-908f-89d70096e590',
          recommended_items: [
            'cb41eede-6374-4c06-9225-b420b56d2add',
            'cb41c6a6-f08a-44c5-8acf-8190c0f2b010',
            'cb41d429-4c64-4db5-9822-3cb0c7cd0ae9',
          ],
        },
        {
          id: 'cb5f9e27-fbda-443c-8e83-0cf3592964e6',
          recommended_items: [
            'cb41eede-6374-4c06-9225-b420b56d2add',
            'cb41c6a6-f08a-44c5-8acf-8190c0f2b010',
            'cb41d429-4c64-4db5-9822-3cb0c7cd0ae9',
          ],
        },
        {
          id: 'cb41ddb1-8759-434f-a2f9-9efcc5c89728',
          recommended_items: [
            'cb41eede-6374-4c06-9225-b420b56d2add',
            'cb41c6a6-f08a-44c5-8acf-8190c0f2b010',
            'cb41d429-4c64-4db5-9822-3cb0c7cd0ae9',
          ],
        },
      ],
    },
    {
      id: '4d2926ad-bbae-4071-9383-3b047ce08787',
      cart_recommendations: ['ca39ebb6-8057-4834-a0fa-c8346a5b246a'],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'ca39ec11-37e0-478a-a543-3133c20c3ba2',
          recommended_items: [
            'ca39ebb6-8057-4834-a0fa-c8346a5b246a',
            'ca39e5a9-42ea-4cba-918a-af8311e8a657',
            'ca39e7f8-6592-44cd-94c5-91c56844094c',
            'ca39e246-0eb4-4bec-881c-0d9b3af9633c',
          ],
        },
        {
          id: 'ca39ce40-31c0-48f9-89be-f124ff4a24c4',
          recommended_items: [
            'ca39ebb6-8057-4834-a0fa-c8346a5b246a',
            'ca39e5a9-42ea-4cba-918a-af8311e8a657',
            'ca39e7f8-6592-44cd-94c5-91c56844094c',
            'ca39e246-0eb4-4bec-881c-0d9b3af9633c',
          ],
        },
        {
          id: 'ca39cc1d-692a-4836-a67d-e3fc052eccf6',
          recommended_items: [
            'ca39ebb6-8057-4834-a0fa-c8346a5b246a',
            'ca39e5a9-42ea-4cba-918a-af8311e8a657',
            'ca39e7f8-6592-44cd-94c5-91c56844094c',
            'ca39e246-0eb4-4bec-881c-0d9b3af9633c',
          ],
        },
        {
          id: 'ca39dda3-b263-451a-bd42-a6e4daf54a3e',
          recommended_items: [
            'ca39ebb6-8057-4834-a0fa-c8346a5b246a',
            'ca39e5a9-42ea-4cba-918a-af8311e8a657',
            'ca39e7f8-6592-44cd-94c5-91c56844094c',
            'ca39e246-0eb4-4bec-881c-0d9b3af9633c',
          ],
        },
        {
          id: 'ca39d907-c1b2-476e-a754-6e625f72d00e',
          recommended_items: [
            'ca39ebb6-8057-4834-a0fa-c8346a5b246a',
            'ca39e5a9-42ea-4cba-918a-af8311e8a657',
            'ca39e7f8-6592-44cd-94c5-91c56844094c',
            'ca39e246-0eb4-4bec-881c-0d9b3af9633c',
          ],
        },
        {
          id: 'ca39fb2a-061c-415e-ba2a-ffcd586a81c8',
          recommended_items: [
            'ca39ebb6-8057-4834-a0fa-c8346a5b246a',
            'ca39e5a9-42ea-4cba-918a-af8311e8a657',
            'ca39e7f8-6592-44cd-94c5-91c56844094c',
            'ca39e246-0eb4-4bec-881c-0d9b3af9633c',
          ],
        },
        {
          id: 'ca39c844-1f19-4de3-94f5-374d7ed16033',
          recommended_items: [
            'ca39ebb6-8057-4834-a0fa-c8346a5b246a',
            'ca39e5a9-42ea-4cba-918a-af8311e8a657',
            'ca39e7f8-6592-44cd-94c5-91c56844094c',
            'ca39e246-0eb4-4bec-881c-0d9b3af9633c',
          ],
        },
        {
          id: 'caf4baf8-3618-48bd-83f2-0f0cea9bc844',
          recommended_items: [
            'ca39ebb6-8057-4834-a0fa-c8346a5b246a',
            'ca39e5a9-42ea-4cba-918a-af8311e8a657',
            'ca39e7f8-6592-44cd-94c5-91c56844094c',
            'ca39e246-0eb4-4bec-881c-0d9b3af9633c',
          ],
        },
      ],
    },
    {
      id: '0ee31cc3-623e-4738-9dfa-ef08cb43549d',
      cart_recommendations: ['cb0a2e99-29d9-47af-a693-2d790e6589d4', 'cb0a2201-dd4b-4b5e-aca6-27b168ea0495'],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'cb0a0a0b-c97b-46b9-a5c7-d7e215b10fdd',
          recommended_items: [
            'cb0a2e99-29d9-47af-a693-2d790e6589d4',
            'cb0a2201-dd4b-4b5e-aca6-27b168ea0495',
            'cb0a09f6-4dde-400f-a8e5-88f020fbe83d',
            'cb0a3166-ba0b-4782-a46d-29cdda89f786',
          ],
        },
        {
          id: 'cb0a12f1-e95a-4076-b841-d1d865024176',
          recommended_items: [
            'cb0a2e99-29d9-47af-a693-2d790e6589d4',
            'cb0a2201-dd4b-4b5e-aca6-27b168ea0495',
            'cb0a09f6-4dde-400f-a8e5-88f020fbe83d',
            'cb0a3166-ba0b-4782-a46d-29cdda89f786',
          ],
        },
        {
          id: 'cb0a38e1-a07f-45b1-bc1c-f751dccdb905',
          recommended_items: [
            'cb0a2e99-29d9-47af-a693-2d790e6589d4',
            'cb0a2201-dd4b-4b5e-aca6-27b168ea0495',
            'cb0a09f6-4dde-400f-a8e5-88f020fbe83d',
            'cb0a3166-ba0b-4782-a46d-29cdda89f786',
          ],
        },
        {
          id: 'cb0a1b10-2cb4-4bcf-b424-7b39f3f5e7f8',
          recommended_items: [
            'cb0a2e99-29d9-47af-a693-2d790e6589d4',
            'cb0a2201-dd4b-4b5e-aca6-27b168ea0495',
            'cb0a09f6-4dde-400f-a8e5-88f020fbe83d',
            'cb0a3166-ba0b-4782-a46d-29cdda89f786',
          ],
        },
        {
          id: 'cb0a330b-b1ba-4b1a-9653-fdeed921a3f4',
          recommended_items: [
            'cb0a2e99-29d9-47af-a693-2d790e6589d4',
            'cb0a2201-dd4b-4b5e-aca6-27b168ea0495',
            'cb0a09f6-4dde-400f-a8e5-88f020fbe83d',
            'cb0a3166-ba0b-4782-a46d-29cdda89f786',
          ],
        },
        {
          id: 'cb0a2b16-265c-4daf-9e7b-f9d307b1db08',
          recommended_items: [
            'cb0a2e99-29d9-47af-a693-2d790e6589d4',
            'cb0a2201-dd4b-4b5e-aca6-27b168ea0495',
            'cb0a09f6-4dde-400f-a8e5-88f020fbe83d',
            'cb0a3166-ba0b-4782-a46d-29cdda89f786',
          ],
        },
        {
          id: 'cb0a3eb0-849d-445a-b599-4586cf460b09',
          recommended_items: [
            'cb0a2e99-29d9-47af-a693-2d790e6589d4',
            'cb0a2201-dd4b-4b5e-aca6-27b168ea0495',
            'cb0a09f6-4dde-400f-a8e5-88f020fbe83d',
            'cb0a3166-ba0b-4782-a46d-29cdda89f786',
          ],
        },
      ],
    },
    {
      id: '8b0baaf1-a1f7-4f15-857d-e3e474071d30',
      cart_recommendations: [
        'cadfe3f0-0de4-46fa-8c29-c24060686345',
        'cac30df3-d4c2-4e69-a608-db475256d92a',
        'cadff5c6-31b9-4558-ab22-980cd00086f7',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'cac309f7-87f0-4ad4-9787-8c6fbf35109c',
          recommended_items: [
            'cadfe1a7-4752-4571-95c1-deb8153d590a',
            'cadfced3-4c23-4b15-ab44-3045269c954e',
            'cadfd18f-b581-4c7a-baea-b0ae82c004d5',
            'cadff5ae-95d9-4f69-9c86-f7961ba1a867',
          ],
        },
        {
          id: 'cac309f7-87f0-4ad4-9787-8c6fbf35109c',
          recommended_items: [
            'cac33c62-d360-41d7-816f-f71b98a9aa41',
            'cac30df3-d4c2-4e69-a608-db475256d92a',
            'cac30efd-c5a2-430f-8a0a-97f91683acd4',
            'cac31735-34b2-4b02-b86d-6e6f0c9f50b5',
          ],
        },
        {
          id: 'cadfeb1d-e480-4549-b35d-d6f5f24475bd',
          recommended_items: [
            'cac33c62-d360-41d7-816f-f71b98a9aa41',
            'cac30df3-d4c2-4e69-a608-db475256d92a',
            'cac30efd-c5a2-430f-8a0a-97f91683acd4',
            'cac31735-34b2-4b02-b86d-6e6f0c9f50b5',
          ],
        },
        {
          id: 'cadfd54c-6761-4427-b2e8-c2bd1f5fb96c',
          recommended_items: [
            'cac33c62-d360-41d7-816f-f71b98a9aa41',
            'cac30df3-d4c2-4e69-a608-db475256d92a',
            'cac30efd-c5a2-430f-8a0a-97f91683acd4',
            'cac31735-34b2-4b02-b86d-6e6f0c9f50b5',
          ],
        },
        {
          id: 'cadfd54f-8c60-4d28-839f-6dc65489446b',
          recommended_items: [
            'cac33c62-d360-41d7-816f-f71b98a9aa41',
            'cac30df3-d4c2-4e69-a608-db475256d92a',
            'cac30efd-c5a2-430f-8a0a-97f91683acd4',
            'cac31735-34b2-4b02-b86d-6e6f0c9f50b5',
          ],
        },
        {
          id: 'cadfc0ee-01b6-4e1f-a554-397b2d202bd7',
          recommended_items: [
            'cac33c62-d360-41d7-816f-f71b98a9aa41',
            'cac30df3-d4c2-4e69-a608-db475256d92a',
            'cac30efd-c5a2-430f-8a0a-97f91683acd4',
            'cac31735-34b2-4b02-b86d-6e6f0c9f50b5',
          ],
        },
      ],
    },
    {
      id: 'ceb24507-abfe-4347-bef0-7a1e7d0fabc0',
      cart_recommendations: [
        'cad50b8a-0cd7-413c-99dc-d6fd0aaca5b2',
        'cad52921-58bf-409c-9cd3-593e83d69313',
        'cad50a72-4fd9-45ab-af10-f8a8d9b7eb7c',
      ],
      menu_item: [
        {
          id: 'cad50452-f29e-40cc-9356-0b3d9e72fe05',
          recommended_items: [
            'cad524d3-89be-49fd-81b5-a964679aae74',
            'cad52093-5785-4f10-82cb-608c951f3c2f',
            'cb05f1a2-e698-4fe3-b21a-7240b8dc8949',
            'cad5010e-6ea2-4f84-ae51-302ff3fe9ae5',
          ],
        },
        {
          id: 'cad505eb-ec8c-4732-bad7-e86ae7c8cc40',
          recommended_items: [
            'cad524d3-89be-49fd-81b5-a964679aae74',
            'cad52093-5785-4f10-82cb-608c951f3c2f',
            'cb05f1a2-e698-4fe3-b21a-7240b8dc8949',
            'cad5010e-6ea2-4f84-ae51-302ff3fe9ae5',
          ],
        },
        {
          id: 'cad508fb-63e8-4e4f-8cb7-cdd6d7fc3b6b',
          recommended_items: [
            'cad524d3-89be-49fd-81b5-a964679aae74',
            'cad52093-5785-4f10-82cb-608c951f3c2f',
            'cb05f1a2-e698-4fe3-b21a-7240b8dc8949',
            'cad5010e-6ea2-4f84-ae51-302ff3fe9ae5',
          ],
        },
        {
          id: 'cad50b9a-8769-4693-a0d5-bf1b6dcc6d46',
          recommended_items: [
            'cad524d3-89be-49fd-81b5-a964679aae74',
            'cad52093-5785-4f10-82cb-608c951f3c2f',
            'cb05f1a2-e698-4fe3-b21a-7240b8dc8949',
            'cad5010e-6ea2-4f84-ae51-302ff3fe9ae5',
          ],
        },
        {
          id: 'cad50bb9-d276-47d1-bfaa-febe54990e71',
          recommended_items: [
            'cad524d3-89be-49fd-81b5-a964679aae74',
            'cad52093-5785-4f10-82cb-608c951f3c2f',
            'cb05f1a2-e698-4fe3-b21a-7240b8dc8949',
            'cad5010e-6ea2-4f84-ae51-302ff3fe9ae5',
          ],
        },
        {
          id: 'cad513be-ec13-4ca9-9370-aae75fd436b0',
          recommended_items: [
            'cad524d3-89be-49fd-81b5-a964679aae74',
            'cad52093-5785-4f10-82cb-608c951f3c2f',
            'cb05f1a2-e698-4fe3-b21a-7240b8dc8949',
            'cad5010e-6ea2-4f84-ae51-302ff3fe9ae5',
          ],
        },
        {
          id: 'cad518a1-361f-4644-bc94-355d818e31c4',
          recommended_items: [
            'cad524d3-89be-49fd-81b5-a964679aae74',
            'cad52093-5785-4f10-82cb-608c951f3c2f',
            'cb05f1a2-e698-4fe3-b21a-7240b8dc8949',
            'cad5010e-6ea2-4f84-ae51-302ff3fe9ae5',
          ],
        },
        {
          id: 'cad51984-7cf9-475f-827e-2c6e509eed2a',
          recommended_items: [
            'cad524d3-89be-49fd-81b5-a964679aae74',
            'cad52093-5785-4f10-82cb-608c951f3c2f',
            'cb05f1a2-e698-4fe3-b21a-7240b8dc8949',
            'cad5010e-6ea2-4f84-ae51-302ff3fe9ae5',
          ],
        },
        {
          id: 'cad51b1d-6fd3-48a3-ac02-c0c2bf05ebf4',
          recommended_items: [
            'cad524d3-89be-49fd-81b5-a964679aae74',
            'cad52093-5785-4f10-82cb-608c951f3c2f',
            'cb05f1a2-e698-4fe3-b21a-7240b8dc8949',
            'cad5010e-6ea2-4f84-ae51-302ff3fe9ae5',
          ],
        },
        {
          id: 'cad51c3d-f291-4fdd-a72e-06fec99fc482',
          recommended_items: [
            'cad524d3-89be-49fd-81b5-a964679aae74',
            'cad52093-5785-4f10-82cb-608c951f3c2f',
            'cb05f1a2-e698-4fe3-b21a-7240b8dc8949',
            'cad5010e-6ea2-4f84-ae51-302ff3fe9ae5',
          ],
        },
        {
          id: 'cb1777cb-5f07-4b36-a57f-824ecb86074e',
          recommended_items: [
            'cad524d3-89be-49fd-81b5-a964679aae74',
            'cad52093-5785-4f10-82cb-608c951f3c2f',
            'cb05f1a2-e698-4fe3-b21a-7240b8dc8949',
            'cad5010e-6ea2-4f84-ae51-302ff3fe9ae5',
          ],
        },
        {
          id: 'cad52d0a-f52d-4fb0-94fc-272b7a1b000e',
          recommended_items: [
            'cad524d3-89be-49fd-81b5-a964679aae74',
            'cad52093-5785-4f10-82cb-608c951f3c2f',
            'cb05f1a2-e698-4fe3-b21a-7240b8dc8949',
            'cad5010e-6ea2-4f84-ae51-302ff3fe9ae5',
          ],
        },
        {
          id: 'cad530a2-f970-43f0-8273-34b9a4e7e2eb',
          recommended_items: [
            'cad524d3-89be-49fd-81b5-a964679aae74',
            'cad52093-5785-4f10-82cb-608c951f3c2f',
            'cb05f1a2-e698-4fe3-b21a-7240b8dc8949',
            'cad5010e-6ea2-4f84-ae51-302ff3fe9ae5',
          ],
        },
        {
          id: 'cad531ca-06d8-4e0c-8b61-f53fd8fa454d',
          recommended_items: [
            'cad524d3-89be-49fd-81b5-a964679aae74',
            'cad52093-5785-4f10-82cb-608c951f3c2f',
            'cb05f1a2-e698-4fe3-b21a-7240b8dc8949',
            'cad5010e-6ea2-4f84-ae51-302ff3fe9ae5',
          ],
        },
        {
          id: 'cad5349f-aa28-498f-95a6-c5aea9cd0ed9',
          recommended_items: [
            'cad524d3-89be-49fd-81b5-a964679aae74',
            'cad52093-5785-4f10-82cb-608c951f3c2f',
            'cb05f1a2-e698-4fe3-b21a-7240b8dc8949',
            'cad5010e-6ea2-4f84-ae51-302ff3fe9ae5',
          ],
        },
        {
          id: 'cb177840-9f7e-469d-9b8a-1234939d97bf',
          recommended_items: [
            'cad524d3-89be-49fd-81b5-a964679aae74',
            'cad52093-5785-4f10-82cb-608c951f3c2f',
            'cb05f1a2-e698-4fe3-b21a-7240b8dc8949',
            'cad5010e-6ea2-4f84-ae51-302ff3fe9ae5',
          ],
        },
      ],
      menu_heading: [
        {
          id: 'cad536c3-4eee-4380-a015-048c3247b3a4',
          recommended_items: [
            'cad52baa-c4ce-475b-8aa4-8d73a2162a3a',
            'cad51eb5-11a8-42b1-8eff-f2ae3aad41ae',
            'cad52bb6-6aa1-4121-ad3f-2482312fefb7',
            'cad53d1f-90ea-4abc-94d0-1319e23f0b62',
          ],
        },
        {
          id: 'cad5107e-c699-4015-ae1f-93d1d43ce756',
          recommended_items: [
            'cad50c5c-de40-42f0-a285-4372152f8a05',
            'cad51eb5-11a8-42b1-8eff-f2ae3aad41ae',
            'cad52bb6-6aa1-4121-ad3f-2482312fefb7',
            'cad53d1f-90ea-4abc-94d0-1319e23f0b62',
          ],
        },
        {
          id: 'cad536a8-670b-4662-9774-55834f4ee58d',
          recommended_items: [
            'cad50b8a-0cd7-413c-99dc-d6fd0aaca5b2',
            'cad526b4-151d-4b83-92cc-593d2cb9a659',
            'cb05fd39-e60e-450a-806f-a83fcf50cd1b',
            'cad50b9a-8769-4693-a0d5-bf1b6dcc6d46',
          ],
        },
        {
          id: 'cad53fcc-4b44-4362-b21d-20bde99c1b4f',
          recommended_items: [
            'cad50b8a-0cd7-413c-99dc-d6fd0aaca5b2',
            'cad526b4-151d-4b83-92cc-593d2cb9a659',
            'cb05fd39-e60e-450a-806f-a83fcf50cd1b',
            'cad50b9a-8769-4693-a0d5-bf1b6dcc6d46',
          ],
        },
        {
          id: 'cad536a8-670b-4662-9774-55834f4ee58d',
          recommended_items: [
            'cad50b8a-0cd7-413c-99dc-d6fd0aaca5b2',
            'cad526b4-151d-4b83-92cc-593d2cb9a659',
            'cb05fd39-e60e-450a-806f-a83fcf50cd1b',
            'cad50b9a-8769-4693-a0d5-bf1b6dcc6d46',
          ],
        },
        {
          id: 'cad5295a-ca81-467d-9de6-220b017afde9',
          recommended_items: [
            'cad50b8a-0cd7-413c-99dc-d6fd0aaca5b2',
            'cad526b4-151d-4b83-92cc-593d2cb9a659',
            'cb05fd39-e60e-450a-806f-a83fcf50cd1b',
            'cad50b9a-8769-4693-a0d5-bf1b6dcc6d46',
          ],
        },
        {
          id: 'cad51d7e-70b6-49f4-a25f-a6ed3dbc664f',
          recommended_items: [
            'cad50b8a-0cd7-413c-99dc-d6fd0aaca5b2',
            'cad526b4-151d-4b83-92cc-593d2cb9a659',
            'cb05fd39-e60e-450a-806f-a83fcf50cd1b',
            'cad50b9a-8769-4693-a0d5-bf1b6dcc6d46',
          ],
        },
        {
          id: 'cad53e8b-ca29-4ef2-b13f-70c7f9b03872',
          recommended_items: [
            'cad50b8a-0cd7-413c-99dc-d6fd0aaca5b2',
            'cad526b4-151d-4b83-92cc-593d2cb9a659',
            'cb05fd39-e60e-450a-806f-a83fcf50cd1b',
            'cad50b9a-8769-4693-a0d5-bf1b6dcc6d46',
          ],
        },
        {
          id: 'cad503e1-9bc7-4f13-97d4-13509c7caacd',
          recommended_items: [
            'cad50b8a-0cd7-413c-99dc-d6fd0aaca5b2',
            'cad526b4-151d-4b83-92cc-593d2cb9a659',
            'cb05fd39-e60e-450a-806f-a83fcf50cd1b',
            'cad50b9a-8769-4693-a0d5-bf1b6dcc6d46',
          ],
        },
        {
          id: 'cad52112-77cd-47c2-ac63-dc5d1ad236d1',
          recommended_items: [
            'cad50b8a-0cd7-413c-99dc-d6fd0aaca5b2',
            'cad526b4-151d-4b83-92cc-593d2cb9a659',
            'cb05fd39-e60e-450a-806f-a83fcf50cd1b',
            'cad50b9a-8769-4693-a0d5-bf1b6dcc6d46',
          ],
        },
        {
          id: 'cad51175-57a0-4115-ada4-6df41dbdeafd',
          recommended_items: [
            'cad50b8a-0cd7-413c-99dc-d6fd0aaca5b2',
            'cad526b4-151d-4b83-92cc-593d2cb9a659',
            'cb05fd39-e60e-450a-806f-a83fcf50cd1b',
            'cad50b9a-8769-4693-a0d5-bf1b6dcc6d46',
          ],
        },
      ],
    },
    {
      id: '952dbedd-a052-4baf-ae08-21fa5216f528',
      cart_recommendations: [
        'c94b5cac-ba9d-4f8d-86fd-5079adba82be',
        'ca878eee-a947-4fe1-8321-52d90251be3c',
        'ca87a3b1-79d9-4010-90ca-b6e970fffda6',
        'cad46bcc-7dbe-4611-bd6e-2a17ed7a0965',
        'ca879f38-b170-4564-803a-c3ef426825ac',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c94b65eb-e710-47ed-86e3-135ab5be9b42',
          recommended_items: [
            'c94b5cac-ba9d-4f8d-86fd-5079adba82be',
            'ca878eee-a947-4fe1-8321-52d90251be3c',
            'ca87a3b1-79d9-4010-90ca-b6e970fffda6',
            'cad46bcc-7dbe-4611-bd6e-2a17ed7a0965',
            'ca879f38-b170-4564-803a-c3ef426825ac',
          ],
        },
        {
          id: 'c94b4ab0-86c2-4f34-9d47-1fbbf3fc14fd',
          recommended_items: [
            'c94b5cac-ba9d-4f8d-86fd-5079adba82be',
            'ca878eee-a947-4fe1-8321-52d90251be3c',
            'ca87a3b1-79d9-4010-90ca-b6e970fffda6',
            'cad46bcc-7dbe-4611-bd6e-2a17ed7a0965',
            'ca879f38-b170-4564-803a-c3ef426825ac',
          ],
        },
        {
          id: 'c94b4f36-959f-47bd-944d-5b6cb3ee142e',
          recommended_items: [
            'c94b5cac-ba9d-4f8d-86fd-5079adba82be',
            'ca878eee-a947-4fe1-8321-52d90251be3c',
            'ca87a3b1-79d9-4010-90ca-b6e970fffda6',
            'cad46bcc-7dbe-4611-bd6e-2a17ed7a0965',
            'ca879f38-b170-4564-803a-c3ef426825ac',
          ],
        },
        {
          id: 'c94b53eb-9ba2-47c5-ae6d-d5f40e5864c3',
          recommended_items: [
            'c94b5cac-ba9d-4f8d-86fd-5079adba82be',
            'ca878eee-a947-4fe1-8321-52d90251be3c',
            'ca87a3b1-79d9-4010-90ca-b6e970fffda6',
            'cad46bcc-7dbe-4611-bd6e-2a17ed7a0965',
            'ca879f38-b170-4564-803a-c3ef426825ac',
          ],
        },
        {
          id: 'c94b46a0-a1df-4938-ad7d-4a1ed91e58c6',
          recommended_items: [
            'c94b5cac-ba9d-4f8d-86fd-5079adba82be',
            'ca878eee-a947-4fe1-8321-52d90251be3c',
            'ca87a3b1-79d9-4010-90ca-b6e970fffda6',
            'cad46bcc-7dbe-4611-bd6e-2a17ed7a0965',
            'ca879f38-b170-4564-803a-c3ef426825ac',
          ],
        },
        {
          id: 'ca879e50-d12f-41af-8375-c1e9e5981eb0',
          recommended_items: [
            'c94b5cac-ba9d-4f8d-86fd-5079adba82be',
            'ca878eee-a947-4fe1-8321-52d90251be3c',
            'ca87a3b1-79d9-4010-90ca-b6e970fffda6',
            'cad46bcc-7dbe-4611-bd6e-2a17ed7a0965',
            'ca879f38-b170-4564-803a-c3ef426825ac',
          ],
        },
        {
          id: 'ca87a1a7-0e81-4811-a689-5bbb1a88eb75',
          recommended_items: [
            'c94b5cac-ba9d-4f8d-86fd-5079adba82be',
            'ca878eee-a947-4fe1-8321-52d90251be3c',
            'ca87a3b1-79d9-4010-90ca-b6e970fffda6',
            'cad46bcc-7dbe-4611-bd6e-2a17ed7a0965',
            'ca879f38-b170-4564-803a-c3ef426825ac',
          ],
        },
        {
          id: 'c94b6b6f-a0b0-4168-a569-40ccb8f2fb3b',
          recommended_items: [
            'c94b5cac-ba9d-4f8d-86fd-5079adba82be',
            'ca878eee-a947-4fe1-8321-52d90251be3c',
            'ca87a3b1-79d9-4010-90ca-b6e970fffda6',
            'cad46bcc-7dbe-4611-bd6e-2a17ed7a0965',
            'ca879f38-b170-4564-803a-c3ef426825ac',
          ],
        },
        {
          id: 'ca87bbb7-968d-4701-b379-0504822550ae',
          recommended_items: [
            'c94b5cac-ba9d-4f8d-86fd-5079adba82be',
            'ca878eee-a947-4fe1-8321-52d90251be3c',
            'ca87a3b1-79d9-4010-90ca-b6e970fffda6',
            'cad46bcc-7dbe-4611-bd6e-2a17ed7a0965',
            'ca879f38-b170-4564-803a-c3ef426825ac',
          ],
        },
        {
          id: 'c94b4bed-9415-4a5f-ac23-74404e4ac97c',
          recommended_items: [
            'c94b5cac-ba9d-4f8d-86fd-5079adba82be',
            'ca878eee-a947-4fe1-8321-52d90251be3c',
            'ca87a3b1-79d9-4010-90ca-b6e970fffda6',
            'cad46bcc-7dbe-4611-bd6e-2a17ed7a0965',
            'ca879f38-b170-4564-803a-c3ef426825ac',
          ],
        },
        {
          id: 'c94b46b3-13d2-4949-93aa-507e460854b7',
          recommended_items: [
            'c94b5cac-ba9d-4f8d-86fd-5079adba82be',
            'ca878eee-a947-4fe1-8321-52d90251be3c',
            'ca87a3b1-79d9-4010-90ca-b6e970fffda6',
            'cad46bcc-7dbe-4611-bd6e-2a17ed7a0965',
            'ca879f38-b170-4564-803a-c3ef426825ac',
          ],
        },
        {
          id: 'ca87a145-75da-4b96-8b5c-28d3c105e69d',
          recommended_items: [
            'c94b5cac-ba9d-4f8d-86fd-5079adba82be',
            'ca878eee-a947-4fe1-8321-52d90251be3c',
            'ca87a3b1-79d9-4010-90ca-b6e970fffda6',
            'cad46bcc-7dbe-4611-bd6e-2a17ed7a0965',
            'ca879f38-b170-4564-803a-c3ef426825ac',
          ],
        },
      ],
    },
    {
      id: '64831a00-00b6-441a-93fe-ce05c75fa146',
      cart_recommendations: [
        'ca2dbb18-6f0f-436f-98bb-3692184ec12e',
        'ca2da505-b992-4509-855d-903231afcbc2',
        'ca2d810b-412c-4d40-8456-44b82e52315d',
        'ca2d967b-5882-46a2-b211-e3caa346149a',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'ca2d9073-dae5-4951-8377-16f4b16fc3dc',
          recommended_items: [
            'ca2dbb18-6f0f-436f-98bb-3692184ec12e',
            'ca2da505-b992-4509-855d-903231afcbc2',
            'ca2d810b-412c-4d40-8456-44b82e52315d',
            'ca2d967b-5882-46a2-b211-e3caa346149a',
          ],
        },
        {
          id: 'ca2d8650-0106-49bf-808c-3d40a7250ef0',
          recommended_items: [
            'ca2dbb18-6f0f-436f-98bb-3692184ec12e',
            'ca2da505-b992-4509-855d-903231afcbc2',
            'ca2d810b-412c-4d40-8456-44b82e52315d',
            'ca2d967b-5882-46a2-b211-e3caa346149a',
          ],
        },
        {
          id: 'ca2d8a7d-3978-40a4-bb77-a58fab2f722b',
          recommended_items: [
            'ca2dbb18-6f0f-436f-98bb-3692184ec12e',
            'ca2da505-b992-4509-855d-903231afcbc2',
            'ca2d810b-412c-4d40-8456-44b82e52315d',
            'ca2d967b-5882-46a2-b211-e3caa346149a',
          ],
        },
        {
          id: 'ca45407b-e753-439e-861b-4c0e7d2cea13',
          recommended_items: [
            'ca2dbb18-6f0f-436f-98bb-3692184ec12e',
            'ca2da505-b992-4509-855d-903231afcbc2',
            'ca2d810b-412c-4d40-8456-44b82e52315d',
            'ca2d967b-5882-46a2-b211-e3caa346149a',
          ],
        },
        {
          id: 'ca2da3f7-c872-4ed1-bd98-3a3f44840d38',
          recommended_items: [
            'ca2dbb18-6f0f-436f-98bb-3692184ec12e',
            'ca2da505-b992-4509-855d-903231afcbc2',
            'ca2d810b-412c-4d40-8456-44b82e52315d',
            'ca2d967b-5882-46a2-b211-e3caa346149a',
          ],
        },
        {
          id: 'ca2db767-6404-4d5c-ba1e-99654205b666',
          recommended_items: [
            'ca2dbb18-6f0f-436f-98bb-3692184ec12e',
            'ca2da505-b992-4509-855d-903231afcbc2',
            'ca2d810b-412c-4d40-8456-44b82e52315d',
            'ca2d967b-5882-46a2-b211-e3caa346149a',
          ],
        },
        {
          id: 'ca2d8ab8-8665-400d-a80c-ecd81ef6fa25',
          recommended_items: [
            'ca2dbb18-6f0f-436f-98bb-3692184ec12e',
            'ca2da505-b992-4509-855d-903231afcbc2',
            'ca2d810b-412c-4d40-8456-44b82e52315d',
            'ca2d967b-5882-46a2-b211-e3caa346149a',
          ],
        },
        {
          id: 'ca2d8555-29d4-41f4-99c4-dbd50038a7cb',
          recommended_items: [
            'ca2dbb18-6f0f-436f-98bb-3692184ec12e',
            'ca2da505-b992-4509-855d-903231afcbc2',
            'ca2d810b-412c-4d40-8456-44b82e52315d',
            'ca2d967b-5882-46a2-b211-e3caa346149a',
          ],
        },
        {
          id: 'ca2dba0d-d968-49cc-8bae-1a96e0c53306',
          recommended_items: [
            'ca2dbb18-6f0f-436f-98bb-3692184ec12e',
            'ca2da505-b992-4509-855d-903231afcbc2',
            'ca2d810b-412c-4d40-8456-44b82e52315d',
            'ca2d967b-5882-46a2-b211-e3caa346149a',
          ],
        },
        {
          id: 'ca2da75c-8ecc-44ef-b31a-7672cff4af38',
          recommended_items: [
            'ca2dbb18-6f0f-436f-98bb-3692184ec12e',
            'ca2da505-b992-4509-855d-903231afcbc2',
            'ca2d810b-412c-4d40-8456-44b82e52315d',
            'ca2d967b-5882-46a2-b211-e3caa346149a',
          ],
        },
        {
          id: 'ca2db10c-011b-4b32-a9e2-e093510f97fa',
          recommended_items: [
            'ca2dbb18-6f0f-436f-98bb-3692184ec12e',
            'ca2da505-b992-4509-855d-903231afcbc2',
            'ca2d810b-412c-4d40-8456-44b82e52315d',
            'ca2d967b-5882-46a2-b211-e3caa346149a',
          ],
        },
        {
          id: 'ca2dabb1-0c0b-4a37-9fa3-eec290347e96',
          recommended_items: [
            'ca2dbb18-6f0f-436f-98bb-3692184ec12e',
            'ca2da505-b992-4509-855d-903231afcbc2',
            'ca2d810b-412c-4d40-8456-44b82e52315d',
            'ca2d967b-5882-46a2-b211-e3caa346149a',
          ],
        },
        {
          id: 'ca2da493-4735-44c2-ba39-906889998113',
          recommended_items: [
            'ca2dbb18-6f0f-436f-98bb-3692184ec12e',
            'ca3db048-3efd-4880-a737-9f03b0a7ca56',
            'ca2d810b-412c-4d40-8456-44b82e52315d',
            'ca3db957-d7a3-47a2-a58e-4ecd3771bf75',
          ],
        },
      ],
    },
    {
      id: '00bead04-ee35-441d-9721-79aa82228f8d',
      cart_recommendations: [
        'ca2d9964-8931-4ae9-a55e-b4bc695c1a83',
        'ca2d8a86-e845-4923-9c50-a92e8014a363',
        'ca3dc53d-6de0-4987-9d7f-1d23c32085c0',
        'ca2d8299-c953-41ec-9226-1efcfcf20169',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'ca2db206-be38-4c50-9be7-73b9076a908f',
          recommended_items: [
            'ca2d9964-8931-4ae9-a55e-b4bc695c1a83',
            'ca2d8a86-e845-4923-9c50-a92e8014a363',
            'ca3dc53d-6de0-4987-9d7f-1d23c32085c0',
            'ca2d8299-c953-41ec-9226-1efcfcf20169',
          ],
        },
        {
          id: 'ca2da86c-15ab-42f0-a65f-6a01ddeacc83',
          recommended_items: [
            'ca2d9964-8931-4ae9-a55e-b4bc695c1a83',
            'ca2d8a86-e845-4923-9c50-a92e8014a363',
            'ca3dc53d-6de0-4987-9d7f-1d23c32085c0',
            'ca2d8299-c953-41ec-9226-1efcfcf20169',
          ],
        },
        {
          id: 'ca2db8a6-f370-4abf-b982-84d584b5d8b0',
          recommended_items: [
            'ca2d9964-8931-4ae9-a55e-b4bc695c1a83',
            'ca2d8a86-e845-4923-9c50-a92e8014a363',
            'ca3dc53d-6de0-4987-9d7f-1d23c32085c0',
            'ca2d8299-c953-41ec-9226-1efcfcf20169',
          ],
        },
        {
          id: 'ca2d9c73-c21c-46b2-a26d-15c979a334cd',
          recommended_items: [
            'ca2d9964-8931-4ae9-a55e-b4bc695c1a83',
            'ca2d8a86-e845-4923-9c50-a92e8014a363',
            'ca3dc53d-6de0-4987-9d7f-1d23c32085c0',
            'ca2d8299-c953-41ec-9226-1efcfcf20169',
          ],
        },
        {
          id: 'ca2d87dc-4822-4b2c-9524-12b5b03f499f',
          recommended_items: [
            'ca2d9964-8931-4ae9-a55e-b4bc695c1a83',
            'ca2d8a86-e845-4923-9c50-a92e8014a363',
            'ca3dc53d-6de0-4987-9d7f-1d23c32085c0',
            'ca2d8299-c953-41ec-9226-1efcfcf20169',
          ],
        },
        {
          id: 'ca2d8788-7b5a-4e9b-8ab8-bfa38ebdc34d',
          recommended_items: [
            'ca2d9964-8931-4ae9-a55e-b4bc695c1a83',
            'ca2d8a86-e845-4923-9c50-a92e8014a363',
            'ca3dc53d-6de0-4987-9d7f-1d23c32085c0',
            'ca2d8299-c953-41ec-9226-1efcfcf20169',
          ],
        },
        {
          id: 'ca2d892b-d77c-4c25-97c2-cffe8675e6a5',
          recommended_items: [
            'ca2d9964-8931-4ae9-a55e-b4bc695c1a83',
            'ca2d8a86-e845-4923-9c50-a92e8014a363',
            'ca3dc53d-6de0-4987-9d7f-1d23c32085c0',
            'ca2d8299-c953-41ec-9226-1efcfcf20169',
          ],
        },
        {
          id: 'ca2da371-fe93-4be5-890a-a1a38051d28c',
          recommended_items: [
            'ca2d9964-8931-4ae9-a55e-b4bc695c1a83',
            'ca2d8a86-e845-4923-9c50-a92e8014a363',
            'ca3dc53d-6de0-4987-9d7f-1d23c32085c0',
            'ca2d8299-c953-41ec-9226-1efcfcf20169',
          ],
        },
        {
          id: 'ca2d8ade-f71e-412a-b9ff-869404756fa2',
          recommended_items: [
            'ca2d9964-8931-4ae9-a55e-b4bc695c1a83',
            'ca2d8a86-e845-4923-9c50-a92e8014a363',
            'ca3dc53d-6de0-4987-9d7f-1d23c32085c0',
            'ca2d8299-c953-41ec-9226-1efcfcf20169',
          ],
        },
        {
          id: 'ca2db03a-0b4d-4bb7-a709-4e023b91be72',
          recommended_items: [
            'ca2d9964-8931-4ae9-a55e-b4bc695c1a83',
            'ca2d8a86-e845-4923-9c50-a92e8014a363',
            'ca3dc53d-6de0-4987-9d7f-1d23c32085c0',
            'ca2d8299-c953-41ec-9226-1efcfcf20169',
          ],
        },
        {
          id: 'ca2d8d88-6af3-4f3d-b273-1ab61d75caba',
          recommended_items: [
            'ca3df551-d33a-4007-97e5-117186a77ae7',
            'ca2d8a86-e845-4923-9c50-a92e8014a363',
            'ca3dc53d-6de0-4987-9d7f-1d23c32085c0',
            'ca2d8299-c953-41ec-9226-1efcfcf20169',
          ],
        },
      ],
    },
    {
      id: 'f315a1ab-412a-4342-a204-7d4ae23ece58',
      cart_recommendations: [
        'ca2da2dc-73b2-4a8d-89d3-aea8cc0f6fae',
        'ca2d9c2a-78b9-4aca-a52c-317efe51890f',
        'ca3dd5be-aef7-477f-9b21-4668579ea23a',
        'ca2db00f-b39f-4676-8a93-463e680fd3f4',
        'ca2d9bb2-fedf-4117-b3ca-3b5ebedc4bef',
        'ca2da2dc-73b2-4a8d-89d3-aea8cc0f6fae',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'ca2db769-51f4-456c-a184-6d447a03bd34',
          recommended_items: [
            'ca2d9bb2-fedf-4117-b3ca-3b5ebedc4bef',
            'ca2db00f-b39f-4676-8a93-463e680fd3f4',
            'ca2dab85-7ff6-4b8a-aa7b-b4d53b73d2b0',
            'ca2d9c2a-78b9-4aca-a52c-317efe51890f',
          ],
        },
        {
          id: 'ca2d8491-0b5e-4e8f-a2fb-c4875725bc62',
          recommended_items: [
            'ca2d9bb2-fedf-4117-b3ca-3b5ebedc4bef',
            'ca2db00f-b39f-4676-8a93-463e680fd3f4',
            'ca2dab85-7ff6-4b8a-aa7b-b4d53b73d2b0',
            'ca2d9c2a-78b9-4aca-a52c-317efe51890f',
          ],
        },
        {
          id: 'ca2d8a3b-1c11-4b7e-844c-d35d4ddcbb67',
          recommended_items: [
            'ca2d9bb2-fedf-4117-b3ca-3b5ebedc4bef',
            'ca2db00f-b39f-4676-8a93-463e680fd3f4',
            'ca2dab85-7ff6-4b8a-aa7b-b4d53b73d2b0',
            'ca2d9c2a-78b9-4aca-a52c-317efe51890f',
          ],
        },
        {
          id: 'ca2daba1-f9da-40cb-a0b0-2ede7ab59d68',
          recommended_items: [
            'ca2d9bb2-fedf-4117-b3ca-3b5ebedc4bef',
            'ca2db00f-b39f-4676-8a93-463e680fd3f4',
            'ca2dab85-7ff6-4b8a-aa7b-b4d53b73d2b0',
            'ca2d9c2a-78b9-4aca-a52c-317efe51890f',
          ],
        },
        {
          id: 'ca2d8eba-af11-4ce6-8eeb-831fe0ee3668',
          recommended_items: [
            'ca2d9bb2-fedf-4117-b3ca-3b5ebedc4bef',
            'ca2db00f-b39f-4676-8a93-463e680fd3f4',
            'ca2dab85-7ff6-4b8a-aa7b-b4d53b73d2b0',
            'ca2d9c2a-78b9-4aca-a52c-317efe51890f',
          ],
        },
        {
          id: 'ca2d8eb9-acdc-479f-a0af-8481651b1663',
          recommended_items: [
            'ca2d9bb2-fedf-4117-b3ca-3b5ebedc4bef',
            'ca2db00f-b39f-4676-8a93-463e680fd3f4',
            'ca2dab85-7ff6-4b8a-aa7b-b4d53b73d2b0',
            'ca2d9c2a-78b9-4aca-a52c-317efe51890f',
          ],
        },
        {
          id: 'ca2dbbda-a10e-49c9-92f7-f1e568e0ac95',
          recommended_items: [
            'ca2d9bb2-fedf-4117-b3ca-3b5ebedc4bef',
            'ca2db00f-b39f-4676-8a93-463e680fd3f4',
            'ca2dab85-7ff6-4b8a-aa7b-b4d53b73d2b0',
            'ca2d9c2a-78b9-4aca-a52c-317efe51890f',
          ],
        },
        {
          id: 'ca2da329-b925-4d06-9712-8c2c07d3d441',
          recommended_items: [
            'ca2d9bb2-fedf-4117-b3ca-3b5ebedc4bef',
            'ca2db00f-b39f-4676-8a93-463e680fd3f4',
            'ca2dab85-7ff6-4b8a-aa7b-b4d53b73d2b0',
            'ca2d9c2a-78b9-4aca-a52c-317efe51890f',
          ],
        },
        {
          id: 'ca2db1c5-f82e-4683-9dcd-ae6b66ea05ab',
          recommended_items: [
            'ca3dee9c-98f2-41ae-8d8c-33ac70d5678c',
            'ca3dd5be-aef7-477f-9b21-4668579ea23a',
            'ca3dddec-3621-4927-9450-f53e1d346b09',
            'ca2d9c2a-78b9-4aca-a52c-317efe51890f',
          ],
        },
      ],
    },
    {
      id: '5a602f03-82c5-452d-9f47-4d506d76a34a',
      cart_recommendations: [
        'c8f1cf7f-87f2-495e-8f62-60ec61188b9c',
        'c987ffa8-ee1c-43ad-a32c-5093433e195e',
        'c8fdd5fe-9235-4589-8b7d-41e897eaf8c8',
        'c987f7e2-2178-4ed2-a31d-86ac13991b7a',
        'c987d9b3-9ade-4ee1-99ac-ac396677e5a6',
        'c8f1cf7f-87f2-495e-8f62-60ec61188b9c',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c906de49-f60b-4716-bee0-4488608a11ce',
          recommended_items: [
            'c987d9b3-9ade-4ee1-99ac-ac396677e5a6',
            'c987f7e2-2178-4ed2-a31d-86ac13991b7a',
            'c987f9a8-ba39-4473-ba2a-d2b9701ec5e8',
            'c987ffa8-ee1c-43ad-a32c-5093433e195e',
          ],
        },
        {
          id: 'c906cbb0-0e6f-4ce9-8b58-08fa015f6e49',
          recommended_items: [
            'c987d9b3-9ade-4ee1-99ac-ac396677e5a6',
            'c987f7e2-2178-4ed2-a31d-86ac13991b7a',
            'c987f9a8-ba39-4473-ba2a-d2b9701ec5e8',
            'c987ffa8-ee1c-43ad-a32c-5093433e195e',
          ],
        },
        {
          id: 'c881b147-71b5-4ef3-98bd-52d6e1a137db',
          recommended_items: [
            'c987d9b3-9ade-4ee1-99ac-ac396677e5a6',
            'c987f7e2-2178-4ed2-a31d-86ac13991b7a',
            'c987f9a8-ba39-4473-ba2a-d2b9701ec5e8',
            'c987ffa8-ee1c-43ad-a32c-5093433e195e',
          ],
        },
        {
          id: 'c8818c92-6eed-45c9-95c0-a96de791468c',
          recommended_items: [
            'c987d9b3-9ade-4ee1-99ac-ac396677e5a6',
            'c987f7e2-2178-4ed2-a31d-86ac13991b7a',
            'c987f9a8-ba39-4473-ba2a-d2b9701ec5e8',
            'c987ffa8-ee1c-43ad-a32c-5093433e195e',
          ],
        },
        {
          id: 'c881b2ec-c880-4e25-9d74-ae482b6a1fcb',
          recommended_items: [
            'c987d9b3-9ade-4ee1-99ac-ac396677e5a6',
            'c987f7e2-2178-4ed2-a31d-86ac13991b7a',
            'c987f9a8-ba39-4473-ba2a-d2b9701ec5e8',
            'c987ffa8-ee1c-43ad-a32c-5093433e195e',
          ],
        },
        {
          id: 'c8f1f471-2ac0-4989-ae60-3ab6a0fd0301',
          recommended_items: [
            'c987d9b3-9ade-4ee1-99ac-ac396677e5a6',
            'c987f7e2-2178-4ed2-a31d-86ac13991b7a',
            'c987f9a8-ba39-4473-ba2a-d2b9701ec5e8',
            'c987ffa8-ee1c-43ad-a32c-5093433e195e',
          ],
        },
      ],
    },
    {
      id: 'ef9c524b-9b42-492a-b677-e7be9d599de1',
      cart_recommendations: [
        'c9437eae-f9fd-4089-95e6-fa665585ba8b',
        'c9e9ff1c-dfa8-458e-808b-1b2a6ab98675',
        'c87e4d10-9903-445b-9a34-fa49a08fd190',
        'c87e796f-6228-4d58-9994-c3fd3ae96877',
        'c87e55ae-5d12-4a3c-accc-90dbfcfc3bc1',
        'c87e7fac-e060-41c1-8275-e7f5a75297ad',
        'c87e437c-cb68-45df-a758-b53382b53a54',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c906e747-81f5-4092-86e7-9d0943f201e3',
          recommended_items: [
            'c87e55ae-5d12-4a3c-accc-90dbfcfc3bc1',
            'c87e796f-6228-4d58-9994-c3fd3ae96877',
            'c9437eae-f9fd-4089-95e6-fa665585ba8b',
            'c9e9ff1c-dfa8-458e-808b-1b2a6ab98675',
          ],
        },
        {
          id: 'c906cd71-bc6d-4618-a7ed-3f2b64a2f2bf',
          recommended_items: [
            'c87e55ae-5d12-4a3c-accc-90dbfcfc3bc1',
            'c87e796f-6228-4d58-9994-c3fd3ae96877',
            'c9437eae-f9fd-4089-95e6-fa665585ba8b',
            'c9e9ff1c-dfa8-458e-808b-1b2a6ab98675',
          ],
        },
        {
          id: 'c87e6b8f-300d-4d56-9eff-8508e0b25773',
          recommended_items: [
            'c87e55ae-5d12-4a3c-accc-90dbfcfc3bc1',
            'c87e796f-6228-4d58-9994-c3fd3ae96877',
            'c9437eae-f9fd-4089-95e6-fa665585ba8b',
            'c9e9ff1c-dfa8-458e-808b-1b2a6ab98675',
          ],
        },
        {
          id: 'c87e73c7-1227-4558-a099-eb39957b779c',
          recommended_items: [
            'c87e55ae-5d12-4a3c-accc-90dbfcfc3bc1',
            'c87e796f-6228-4d58-9994-c3fd3ae96877',
            'c9437eae-f9fd-4089-95e6-fa665585ba8b',
            'c9e9ff1c-dfa8-458e-808b-1b2a6ab98675',
          ],
        },
        {
          id: 'c87e7b1a-06a0-4594-9cf0-b4bccd71e146',
          recommended_items: [
            'c87e55ae-5d12-4a3c-accc-90dbfcfc3bc1',
            'c87e796f-6228-4d58-9994-c3fd3ae96877',
            'c9437eae-f9fd-4089-95e6-fa665585ba8b',
            'c9e9ff1c-dfa8-458e-808b-1b2a6ab98675',
          ],
        },
        {
          id: 'c87e5069-f028-4381-b8ce-040b1751b8d0',
          recommended_items: [
            'c87e55ae-5d12-4a3c-accc-90dbfcfc3bc1',
            'c87e796f-6228-4d58-9994-c3fd3ae96877',
            'c9437eae-f9fd-4089-95e6-fa665585ba8b',
            'c9e9ff1c-dfa8-458e-808b-1b2a6ab98675',
          ],
        },
        {
          id: 'c87e4951-9cfe-487c-9278-61a060715725',
          recommended_items: [
            'c87e55ae-5d12-4a3c-accc-90dbfcfc3bc1',
            'c87e796f-6228-4d58-9994-c3fd3ae96877',
            'c9437eae-f9fd-4089-95e6-fa665585ba8b',
            'c9e9ff1c-dfa8-458e-808b-1b2a6ab98675',
          ],
        },
        {
          id: 'c9436641-5c2d-4a13-881c-a94ae3738457',
          recommended_items: [
            'c87e7fac-e060-41c1-8275-e7f5a75297ad',
            'c87e4d10-9903-445b-9a34-fa49a08fd190',
            'c87e6588-9baf-4381-b433-a8e4403439a5',
            'c9e9ff1c-dfa8-458e-808b-1b2a6ab98675',
          ],
        },
      ],
    },
    {
      id: '08f5ad0e-fb86-493d-8ec1-514eb3c17ad6',
      cart_recommendations: [
        'c981ea99-9438-47df-b8f5-ed472be722ff',
        'c8bbe809-47a3-4886-a932-d1750a373c7b',
        'c909ec46-fcc9-4c49-8d19-3d4e39eb0603',
        'c909e329-53a5-42cb-ac6a-be05d6ae516d',
        'c981c875-09af-46cc-b810-d797cfbd30d1',
        'c981fae1-1e1d-4d79-a3f1-c5045ab16bd0',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c906f921-9383-4282-9949-fe528764c87e',
          recommended_items: [
            'c981c875-09af-46cc-b810-d797cfbd30d1',
            'c909e329-53a5-42cb-ac6a-be05d6ae516d',
            'c981c264-75d7-4b35-b6a1-c72800def26d',
            'c8bbe809-47a3-4886-a932-d1750a373c7b',
          ],
        },
        {
          id: 'c909ce94-d4a5-4b80-91a7-366c2341dce1',
          recommended_items: [
            'c981c875-09af-46cc-b810-d797cfbd30d1',
            'c909e329-53a5-42cb-ac6a-be05d6ae516d',
            'c981c264-75d7-4b35-b6a1-c72800def26d',
            'c8bbe809-47a3-4886-a932-d1750a373c7b',
          ],
        },
        {
          id: 'c88bcbdd-4abf-4452-b3a0-8af6ebf65527',
          recommended_items: [
            'c981c875-09af-46cc-b810-d797cfbd30d1',
            'c909e329-53a5-42cb-ac6a-be05d6ae516d',
            'c981c264-75d7-4b35-b6a1-c72800def26d',
            'c8bbe809-47a3-4886-a932-d1750a373c7b',
          ],
        },
        {
          id: 'c88bfcb2-968b-4e48-88ef-5dc9ce3b7bba',
          recommended_items: [
            'c981c875-09af-46cc-b810-d797cfbd30d1',
            'c909e329-53a5-42cb-ac6a-be05d6ae516d',
            'c981c264-75d7-4b35-b6a1-c72800def26d',
            'c8bbe809-47a3-4886-a932-d1750a373c7b',
          ],
        },
        {
          id: 'c88be0fa-771e-4ee2-b376-2839bd2fe203',
          recommended_items: [
            'c981c875-09af-46cc-b810-d797cfbd30d1',
            'c909e329-53a5-42cb-ac6a-be05d6ae516d',
            'c981c264-75d7-4b35-b6a1-c72800def26d',
            'c8bbe809-47a3-4886-a932-d1750a373c7b',
          ],
        },
        {
          id: 'c88beb4a-de15-4a5b-96f8-49ed3ea3d29f',
          recommended_items: [
            'c981c875-09af-46cc-b810-d797cfbd30d1',
            'c909e329-53a5-42cb-ac6a-be05d6ae516d',
            'c981c264-75d7-4b35-b6a1-c72800def26d',
            'c8bbe809-47a3-4886-a932-d1750a373c7b',
          ],
        },
        {
          id: 'c909f5d6-276e-4934-909f-a2793ff5f5c7',
          recommended_items: [
            'c981c875-09af-46cc-b810-d797cfbd30d1',
            'c909e329-53a5-42cb-ac6a-be05d6ae516d',
            'c981c264-75d7-4b35-b6a1-c72800def26d',
            'c8bbe809-47a3-4886-a932-d1750a373c7b',
          ],
        },
        {
          id: 'c88bf67a-25e8-424c-b0bf-eb284e505089',
          recommended_items: [
            'c981c875-09af-46cc-b810-d797cfbd30d1',
            'c909e329-53a5-42cb-ac6a-be05d6ae516d',
            'c981c264-75d7-4b35-b6a1-c72800def26d',
            'c8bbe809-47a3-4886-a932-d1750a373c7b',
          ],
        },
        {
          id: 'c8bbf774-5515-4d12-8fe3-d3851b38ce95',
          recommended_items: [
            'c8ebd1d5-ff34-4fc0-9446-34beea47f788',
            'c909ec46-fcc9-4c49-8d19-3d4e39eb0603',
            'c909eb15-fd90-43a5-a4ee-4a9e88a94a4a',
            'c8bbe809-47a3-4886-a932-d1750a373c7b',
          ],
        },
      ],
    },
    {
      id: '7b448cbe-a0ec-4734-ab35-b0bfefd48257',
      cart_recommendations: [
        'c93c45fe-f075-4948-8c47-6b68f62a10b2',
        'c93c5731-c9dd-4eaa-9147-41b9f473459d',
        'c93c66cc-afac-4d22-9342-dd4b578702d4',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c93c7042-cd6f-4a5a-8bc7-18b75324ddc1',
          recommended_items: [
            'c93c5731-c9dd-4eaa-9147-41b9f473459d',
            'c93c45fe-f075-4948-8c47-6b68f62a10b2',
            'c93c66cc-afac-4d22-9342-dd4b578702d4',
            'c93c68d6-11bb-43a4-a530-24c5b82f9c0e',
          ],
        },
        {
          id: 'c93c7910-09ca-42d3-bd95-fc991f5e3e0c',
          recommended_items: [
            'c93c5731-c9dd-4eaa-9147-41b9f473459d',
            'c93c45fe-f075-4948-8c47-6b68f62a10b2',
            'c93c66cc-afac-4d22-9342-dd4b578702d4',
            'c93c68d6-11bb-43a4-a530-24c5b82f9c0e',
          ],
        },
        {
          id: 'c93c788a-a5a1-4b03-ae26-873c2beae6c9',
          recommended_items: [
            'c93c5731-c9dd-4eaa-9147-41b9f473459d',
            'c93c45fe-f075-4948-8c47-6b68f62a10b2',
            'c93c66cc-afac-4d22-9342-dd4b578702d4',
            'c93c68d6-11bb-43a4-a530-24c5b82f9c0e',
          ],
        },
        {
          id: 'c93c5409-9b15-443c-8d7a-4589fa463771',
          recommended_items: [
            'c93c5cd0-1269-4d35-845f-95e81882df4e',
            'c93c534a-90f2-4e1b-ad80-4bd2a16dcfa7',
            'c93c45fe-f075-4948-8c47-6b68f62a10b2',
            'c93c4cbc-8feb-42da-9e2a-3e03ae7083ca',
          ],
        },
        {
          id: 'c93c639e-90f1-489d-8ce2-aea7152cc982',
          recommended_items: [
            'c93c5cd0-1269-4d35-845f-95e81882df4e',
            'c93c534a-90f2-4e1b-ad80-4bd2a16dcfa7',
            'c93c45fe-f075-4948-8c47-6b68f62a10b2',
            'c93c4cbc-8feb-42da-9e2a-3e03ae7083ca',
          ],
        },
        {
          id: 'c93c6f3e-35b8-4c73-9b96-2fa97f4b00a8',
          recommended_items: [
            'c93c5cd0-1269-4d35-845f-95e81882df4e',
            'c93c534a-90f2-4e1b-ad80-4bd2a16dcfa7',
            'c93c45fe-f075-4948-8c47-6b68f62a10b2',
            'c93c4cbc-8feb-42da-9e2a-3e03ae7083ca',
          ],
        },
        {
          id: 'c93c567d-9e8c-4f9d-ace8-1a6c754d99cc',
          recommended_items: [
            'c93c5cd0-1269-4d35-845f-95e81882df4e',
            'c93c534a-90f2-4e1b-ad80-4bd2a16dcfa7',
            'c93c45fe-f075-4948-8c47-6b68f62a10b2',
            'c93c4cbc-8feb-42da-9e2a-3e03ae7083ca',
          ],
        },
        {
          id: '93c50c4-00ce-4ced-83c9-37e22b2ad1b3',
          recommended_items: [
            'c93c5731-c9dd-4eaa-9147-41b9f473459d',
            'c93c45fe-f075-4948-8c47-6b68f62a10b2',
            'c93c66cc-afac-4d22-9342-dd4b578702d4',
            'c93c68d6-11bb-43a4-a530-24c5b82f9c0e',
          ],
        },
      ],
    },
    {
      id: 'a0fec229-5ec7-4633-9343-b6e3bf039040',
      cart_recommendations: ['', ''],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c9c6f80c-d541-4ff9-a694-c14ba2384d26',
          recommended_items: [
            'c9c6ca3a-5941-4e7e-84cf-126ec5bd049e',
            'c9c6c571-c868-4ee8-b686-83fc230dfda9',
            'c9c6c97d-0b49-492b-9702-0f760df97f57',
            'c9c6e24d-928e-47c6-84e8-b0620f44326e',
            'c9c6dec2-2e3f-4796-a093-2fd1a1344eb8',
          ],
        },
        {
          id: 'c9c6df98-874f-4870-93e3-69022c099450',
          recommended_items: [
            'c9c6ca3a-5941-4e7e-84cf-126ec5bd049e',
            'c9c6c571-c868-4ee8-b686-83fc230dfda9',
            'c9c6c97d-0b49-492b-9702-0f760df97f57',
            'c9c6e24d-928e-47c6-84e8-b0620f44326e',
            'c9c6dec2-2e3f-4796-a093-2fd1a1344eb8',
          ],
        },
        {
          id: 'c9c6f8b8-ce0e-4bb9-909c-ecdea4d14bae',
          recommended_items: [
            'c9c6ca3a-5941-4e7e-84cf-126ec5bd049e',
            'c9c6c571-c868-4ee8-b686-83fc230dfda9',
            'c9c6c97d-0b49-492b-9702-0f760df97f57',
            'c9c6e24d-928e-47c6-84e8-b0620f44326e',
            'c9c6dec2-2e3f-4796-a093-2fd1a1344eb8',
          ],
        },
        {
          id: 'c9c6ce91-8803-4c54-a95a-67ad4ebd15be',
          recommended_items: [
            'c9c6ca3a-5941-4e7e-84cf-126ec5bd049e',
            'c9c6c571-c868-4ee8-b686-83fc230dfda9',
            'c9c6c97d-0b49-492b-9702-0f760df97f57',
            'c9c6e24d-928e-47c6-84e8-b0620f44326e',
            'c9c6dec2-2e3f-4796-a093-2fd1a1344eb8',
          ],
        },
        {
          id: 'c9c6fcec-bb61-409d-9f9e-3c8592266ebb',
          recommended_items: [
            'c9c6ca3a-5941-4e7e-84cf-126ec5bd049e',
            'c9c6c571-c868-4ee8-b686-83fc230dfda9',
            'c9c6c97d-0b49-492b-9702-0f760df97f57',
            'c9c6e24d-928e-47c6-84e8-b0620f44326e',
            'c9c6dec2-2e3f-4796-a093-2fd1a1344eb8',
          ],
        },
      ],
    },
    {
      id: '813ed990-f7f9-4972-915d-205531dacd5c',
      cart_recommendations: [
        'cac34253-a685-432f-a6e2-c2151731ae91',
        'cac358a5-514e-4a3c-a6f8-ec89cd853d3c',
        'cac34f8f-9bc0-493d-b599-1b9d37d9bccd',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c9c6e6a5-66bf-4e28-9d32-24fb74de55e2',
          recommended_items: [
            'cac34f8f-9bc0-493d-b599-1b9d37d9bccd',
            'cac36f41-f485-4cae-8299-874b2047ea3b',
            'cac34c69-cfda-43de-a188-f3863b8da632',
            'cac35fed-dbb2-4666-bcfe-8fcf1ec04c29',
            'cac37a3b-5b8d-4b60-bcec-d7174d6270a3',
          ],
        },
        {
          id: 'c9c6cece-fd28-449a-9d95-da457cf87e18',
          recommended_items: [
            'cac34f8f-9bc0-493d-b599-1b9d37d9bccd',
            'cac36f41-f485-4cae-8299-874b2047ea3b',
            'cac34c69-cfda-43de-a188-f3863b8da632',
            'cac35fed-dbb2-4666-bcfe-8fcf1ec04c29',
            'cac37a3b-5b8d-4b60-bcec-d7174d6270a3',
          ],
        },
        {
          id: 'c9c6c7cf-1398-4cc0-8611-14192cffc3fc',
          recommended_items: [
            'c9c6cd9d-d25e-4ef9-9a08-140cc89948e2',
            'c9c6dd7a-d88d-49a3-8201-b77cc2840316',
            'c9c6f3bf-183a-4a50-a9a6-3c6bb978a9ee',
            'cac34f8f-9bc0-493d-b599-1b9d37d9bccd',
            'cac36f41-f485-4cae-8299-874b2047ea3b',
          ],
        },
        {
          id: 'c9c6c263-8d63-4fab-a752-020725767eef',
          recommended_items: [
            'c9c6cd9d-d25e-4ef9-9a08-140cc89948e2',
            'c9c6dd7a-d88d-49a3-8201-b77cc2840316',
            'c9c6f3bf-183a-4a50-a9a6-3c6bb978a9ee',
            'cac34f8f-9bc0-493d-b599-1b9d37d9bccd',
            'cac36f41-f485-4cae-8299-874b2047ea3b',
          ],
        },
        {
          id: 'c9c6ed99-dde0-46a4-a91a-9c6a426cbb29',
          recommended_items: [
            'c9c6cd9d-d25e-4ef9-9a08-140cc89948e2',
            'c9c6dd7a-d88d-49a3-8201-b77cc2840316',
            'c9c6f3bf-183a-4a50-a9a6-3c6bb978a9ee',
            'cac34f8f-9bc0-493d-b599-1b9d37d9bccd',
            'cac36f41-f485-4cae-8299-874b2047ea3b',
          ],
        },
        {
          id: 'c9c6d77f-4aa7-460c-8204-92e4c219ca7b',
          recommended_items: [
            'cac34c69-cfda-43de-a188-f3863b8da632',
            'cac36f41-f485-4cae-8299-874b2047ea3b',
            'c9c6f3bf-183a-4a50-a9a6-3c6bb978a9ee',
            'cac34f8f-9bc0-493d-b599-1b9d37d9bccd',
            'cac36f41-f485-4cae-8299-874b2047ea3b',
          ],
        },
        {
          id: 'cac3446e-52f1-4750-ad31-9297b5e646b7',
          recommended_items: [
            'c9c6e810-412b-469a-b0ab-22d72f63a6a8',
            'c9c6f2b1-6e3c-4bda-8867-0919bc6ba7e1',
            'c9c6e8c2-14a4-46cc-b8a5-7e52b5da5d8c',
            'c9c6cd88-8285-40cd-b1ba-03535d51630e',
            'c9c6d954-25f2-418c-85e9-d6132ca9bfa0',
          ],
        },
      ],
    },
    {
      id: '3b9dfec2-c50c-4b43-a939-e81e2b337d5c',
      cart_recommendations: [
        'caafcd4c-5060-4b35-bb08-148bf63baecb',
        'caafd15d-012e-40a2-ad5c-ffac44cd1c5b',
        'caafed62-a0ee-45d0-a974-249792073ce6',
      ],
      menu_item: [
        {
          id: 'caafcbce-9cb8-412a-8eb3-b6ab4877e43c',
          recommended_items: [
            'caafd7da-ce1a-439e-9601-59d6442ed7ad',
            'caafd15d-012e-40a2-ad5c-ffac44cd1c5b',
            'caafcd4c-5060-4b35-bb08-148bf63baecb',
            'caafc7af-4097-4fff-ae61-9db47c2d18ec',
            'caaff73b-2796-4274-96a2-a29af264f8fa',
          ],
        },
      ],
      menu_heading: [
        {
          id: 'caafce04-2eeb-4b8e-a8ef-69a301c37000',
          recommended_items: [
            'caafed23-9eee-4587-8593-122658bb35d4',
            'caafd15d-012e-40a2-ad5c-ffac44cd1c5b',
            'caafcd4c-5060-4b35-bb08-148bf63baecb',
            'caafc7af-4097-4fff-ae61-9db47c2d18ec',
            'caaff73b-2796-4274-96a2-a29af264f8fa',
          ],
        },
        {
          id: 'caafefa5-f3a4-46fc-8c06-798122b1f8a0',
          recommended_items: [
            'caafed23-9eee-4587-8593-122658bb35d4',
            'caafd15d-012e-40a2-ad5c-ffac44cd1c5b',
            'caafcd4c-5060-4b35-bb08-148bf63baecb',
            'caafc7af-4097-4fff-ae61-9db47c2d18ec',
            'caaff73b-2796-4274-96a2-a29af264f8fa',
          ],
        },
        {
          id: 'caafd6e7-6321-4815-81d5-833155e5536b',
          recommended_items: [
            'caafed23-9eee-4587-8593-122658bb35d4',
            'caafd15d-012e-40a2-ad5c-ffac44cd1c5b',
            'caafcd4c-5060-4b35-bb08-148bf63baecb',
            'caafc7af-4097-4fff-ae61-9db47c2d18ec',
            'caaff73b-2796-4274-96a2-a29af264f8fa',
          ],
        },
        {
          id: 'caafeb24-a073-4702-b273-e2e1c7f75204',
          recommended_items: [
            'caafed23-9eee-4587-8593-122658bb35d4',
            'caafd15d-012e-40a2-ad5c-ffac44cd1c5b',
            'caafcd4c-5060-4b35-bb08-148bf63baecb',
            'caafc7af-4097-4fff-ae61-9db47c2d18ec',
            'caaff73b-2796-4274-96a2-a29af264f8fa',
          ],
        },
        {
          id: 'caafc359-7a34-4827-b3d2-ab70f9e5a9a3',
          recommended_items: [
            'caafed23-9eee-4587-8593-122658bb35d4',
            'caafd15d-012e-40a2-ad5c-ffac44cd1c5b',
            'caafcd4c-5060-4b35-bb08-148bf63baecb',
            'caafc7af-4097-4fff-ae61-9db47c2d18ec',
            'caaff73b-2796-4274-96a2-a29af264f8fa',
          ],
        },
        {
          id: 'caafea5d-ef58-4798-8562-8677584e1dac',
          recommended_items: [
            'caafed23-9eee-4587-8593-122658bb35d4',
            'caafd15d-012e-40a2-ad5c-ffac44cd1c5b',
            'caafcd4c-5060-4b35-bb08-148bf63baecb',
            'caafc7af-4097-4fff-ae61-9db47c2d18ec',
            'caaff73b-2796-4274-96a2-a29af264f8fa',
          ],
        },
        {
          id: 'caaff14e-a82c-48a8-9e66-b2939ea6fc30',
          recommended_items: [
            'caafed23-9eee-4587-8593-122658bb35d4',
            'caafd15d-012e-40a2-ad5c-ffac44cd1c5b',
            'caafcd4c-5060-4b35-bb08-148bf63baecb',
            'caafc7af-4097-4fff-ae61-9db47c2d18ec',
            'caaff73b-2796-4274-96a2-a29af264f8fa',
          ],
        },
        {
          id: 'caafeab6-55f5-4beb-8474-2d202117bc25',
          recommended_items: [
            'caafed23-9eee-4587-8593-122658bb35d4',
            'caafd15d-012e-40a2-ad5c-ffac44cd1c5b',
            'caafcd4c-5060-4b35-bb08-148bf63baecb',
            'caafc7af-4097-4fff-ae61-9db47c2d18ec',
            'caaff73b-2796-4274-96a2-a29af264f8fa',
          ],
        },
      ],
    },
    {
      id: '5f185b7a-87f9-40f3-bb0f-273c2e553e58',
      cart_recommendations: ['cac39c68-8b04-4f61-8fec-a814903123cc', 'cac3a497-4bbc-4dfa-abb7-ace15c29a1db'],
      menu_item: [
        {
          id: 'cac3b936-720e-4633-9ead-11c814941e83',
          recommended_items: [
            'cac3983e-544e-4f6b-adb8-3058e71f44e2',
            'cac3a2bb-f3cc-443a-93f7-c8603e1af1b6',
            'cac3831c-13fb-4089-9cfb-bf3f5932761a',
          ],
        },
        {
          id: 'cac3b457-19ca-4f75-8675-646dae6506ef',
          recommended_items: [
            'cac3b936-720e-4633-9ead-11c814941e83',
            'cac3983e-544e-4f6b-adb8-3058e71f44e2',
            'cac3b487-638b-445e-bb64-6397760150de',
          ],
        },
        {
          id: 'cac3b7e1-bd4e-4590-8f4f-1c3ffb5c778f',
          recommended_items: [
            'cac3a8dd-a3db-435d-8889-e2fad25307b3',
            'cac385d1-9f5b-4248-a8bb-b159423d25c8',
            'cac390ac-df58-4897-9fb4-5190db01f5ae',
          ],
        },
        {
          id: 'cac3983e-544e-4f6b-adb8-3058e71f44e2',
          recommended_items: [
            'cac3b936-720e-4633-9ead-11c814941e83',
            'cac3b85c-4bca-4d64-88f9-d3a43308d5b7',
            'cac3b771-22cd-4551-86f6-05fd63369eb1',
          ],
        },
        {
          id: 'cac3928f-6524-47e8-a443-75bab8c6a501',
          recommended_items: [
            'cac3b457-19ca-4f75-8675-646dae6506ef',
            'cac3983e-544e-4f6b-adb8-3058e71f44e2',
            'cac3b936-720e-4633-9ead-11c814941e83',
          ],
        },
        {
          id: 'cac39869-37a2-435e-8202-a086856bd937',
          recommended_items: [
            'cac3b457-19ca-4f75-8675-646dae6506ef',
            'cac3983e-544e-4f6b-adb8-3058e71f44e2',
            'cac3b936-720e-4633-9ead-11c814941e83',
          ],
        },
        {
          id: 'cac3831c-13fb-4089-9cfb-bf3f5932761a',
          recommended_items: [
            'cac39462-fb24-4d23-a46d-e22524e45080',
            'cac3b771-22cd-4551-86f6-05fd63369eb1',
            'cac3b85c-4bca-4d64-88f9-d3a43308d5b7',
          ],
        },
        {
          id: 'cac3ab0b-295a-45af-b7d3-2c4822d66341',
          recommended_items: [
            'cac39462-fb24-4d23-a46d-e22524e45080',
            'cac3b771-22cd-4551-86f6-05fd63369eb1',
            'cac3b85c-4bca-4d64-88f9-d3a43308d5b7',
          ],
        },
        {
          id: 'cac39c9e-023b-4015-b694-1a41b152f333',
          recommended_items: [
            'cac3836b-e35b-4c4f-bb90-b80a86862010',
            'cac3b85c-4bca-4d64-88f9-d3a43308d5b7',
            'cac3b457-19ca-4f75-8675-646dae6506ef',
          ],
        },
        {
          id: 'cac3805a-5445-40a4-8611-7ea020d94f99',
          recommended_items: [
            'cac385d1-9f5b-4248-a8bb-b159423d25c8',
            'cac3b771-22cd-4551-86f6-05fd63369eb1',
            'cac3b85c-4bca-4d64-88f9-d3a43308d5b7',
          ],
        },
        {
          id: 'cac3b074-592e-4a22-9c3e-464c811ea60d',
          recommended_items: [
            'cac39c9e-023b-4015-b694-1a41b152f333',
            'cac3ab0b-295a-45af-b7d3-2c4822d66341',
            'cac3831c-13fb-4089-9cfb-bf3f5932761a',
          ],
        },
        {
          id: 'cac3b85c-4bca-4d64-88f9-d3a43308d5b7',
          recommended_items: [
            'cac3b457-19ca-4f75-8675-646dae6506ef',
            'cac3b771-22cd-4551-86f6-05fd63369eb1',
            'cac3831c-13fb-4089-9cfb-bf3f5932761a',
          ],
        },
        {
          id: 'cac3836b-e35b-4c4f-bb90-b80a86862010',
          recommended_items: [
            'cac3b457-19ca-4f75-8675-646dae6506ef',
            'cac3b771-22cd-4551-86f6-05fd63369eb1',
            'cac3b457-19ca-4f75-8675-646dae6506ef',
          ],
        },
        {
          id: 'cac3b487-638b-445e-bb64-6397760150de',
          recommended_items: [
            'cac3b936-720e-4633-9ead-11c814941e83',
            'cac3983e-544e-4f6b-adb8-3058e71f44e2',
            'cac3b771-22cd-4551-86f6-05fd63369eb1',
          ],
        },
      ],
      menu_heading: [
        {
          id: 'cac38a9b-fe5a-4328-9e46-32df0dc60104',
          recommended_items: [
            'cac390ac-df58-4897-9fb4-5190db01f5ae',
            'cac3983e-544e-4f6b-adb8-3058e71f44e2',
            'cac3b771-22cd-4551-86f6-05fd63369eb1',
          ],
        },
        {
          id: 'cac39772-e6cb-45cb-aa00-87b3602b3ef9',
          recommended_items: [
            'cac3b85c-4bca-4d64-88f9-d3a43308d5b7',
            'cac3983e-544e-4f6b-adb8-3058e71f44e2',
            'cac3b457-19ca-4f75-8675-646dae6506ef',
          ],
        },
        {
          id: 'cac39f8d-dbf7-4ace-9949-e8aeed2138c7',
          recommended_items: [
            'cac3b457-19ca-4f75-8675-646dae6506ef',
            'cac396af-55f8-4444-8c20-41232b90d2cf',
            'cac385d1-9f5b-4248-a8bb-b159423d25c8',
          ],
        },
        {
          id: 'cac3bd5b-5cfb-48d3-a202-72f370eab3b2',
          recommended_items: [
            'cac3b457-19ca-4f75-8675-646dae6506ef',
            'cac396af-55f8-4444-8c20-41232b90d2cf',
            'cac385d1-9f5b-4248-a8bb-b159423d25c8',
          ],
        },
        {
          id: 'cac3b093-a088-4911-882e-7fd8abadb11d',
          recommended_items: [
            'cac3836b-e35b-4c4f-bb90-b80a86862010',
            'cac39f65-5b2e-4c91-acbe-8aeded765dc2',
            'cac39462-fb24-4d23-a46d-e22524e45080',
          ],
        },
      ],
    },
    {
      id: '12402fd7-9af3-453b-8faa-2723803503e0',
      cart_recommendations: [
        'cab75a1a-7b0c-4d29-a556-4e8ad97d46c4',
        'cab765a7-f9c2-43cd-b0b4-062b40e027a4',
        'cab7528b-3daa-4244-a634-5caec6709d05',
        'cab76bd6-76fc-44ea-aae6-46666a7937fe',
        'cab77660-b5aa-45ba-a061-bde28dc6b348',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'cab774b2-c139-4be8-9059-7b85a61c4d87',
          recommended_items: [
            'cab75a1a-7b0c-4d29-a556-4e8ad97d46c4',
            'cab765a7-f9c2-43cd-b0b4-062b40e027a4',
            'cab7528b-3daa-4244-a634-5caec6709d05',
            'cab76100-7da0-42b1-a785-4388a3b91f24',
            'cab777c9-4ba8-4838-a3ba-9d84da753f7b',
            'cab77e37-699f-45eb-8445-91a4f7b10cb5',
            'cab77624-fab3-4939-b61e-01d993dccd53',
          ],
        },
        {
          id: 'cab77400-5ba6-4d7f-8a5a-871044eee9b3',
          recommended_items: [
            'cab75a1a-7b0c-4d29-a556-4e8ad97d46c4',
            'cab765a7-f9c2-43cd-b0b4-062b40e027a4',
            'cab7528b-3daa-4244-a634-5caec6709d05',
            'cab76100-7da0-42b1-a785-4388a3b91f24',
            'cab777c9-4ba8-4838-a3ba-9d84da753f7b',
            'cab77e37-699f-45eb-8445-91a4f7b10cb5',
            'cab77624-fab3-4939-b61e-01d993dccd53',
          ],
        },
        {
          id: 'cab7500d-64e3-4a6f-9433-1713b35a19a0',
          recommended_items: [
            'cab75a1a-7b0c-4d29-a556-4e8ad97d46c4',
            'cab765a7-f9c2-43cd-b0b4-062b40e027a4',
            'cab7528b-3daa-4244-a634-5caec6709d05',
            'cab76100-7da0-42b1-a785-4388a3b91f24',
            'cab777c9-4ba8-4838-a3ba-9d84da753f7b',
            'cab77e37-699f-45eb-8445-91a4f7b10cb5',
            'cab77624-fab3-4939-b61e-01d993dccd53',
          ],
        },
        {
          id: 'cab75eaf-1ab4-4a8f-af13-0129493bde15',
          recommended_items: [
            'cab75a1a-7b0c-4d29-a556-4e8ad97d46c4',
            'cab765a7-f9c2-43cd-b0b4-062b40e027a4',
            'cab7528b-3daa-4244-a634-5caec6709d05',
            'cab76100-7da0-42b1-a785-4388a3b91f24',
            'cab777c9-4ba8-4838-a3ba-9d84da753f7b',
            'cab77e37-699f-45eb-8445-91a4f7b10cb5',
            'cab77624-fab3-4939-b61e-01d993dccd53',
          ],
        },
        {
          id: 'cab76ff6-77d1-4182-87e9-33a8e5ce334f',
          recommended_items: [
            'cab75a1a-7b0c-4d29-a556-4e8ad97d46c4',
            'cab765a7-f9c2-43cd-b0b4-062b40e027a4',
            'cab7528b-3daa-4244-a634-5caec6709d05',
            'cab76100-7da0-42b1-a785-4388a3b91f24',
            'cab777c9-4ba8-4838-a3ba-9d84da753f7b',
            'cab77e37-699f-45eb-8445-91a4f7b10cb5',
            'cab77624-fab3-4939-b61e-01d993dccd53',
          ],
        },
      ],
    },
    {
      id: 'aa029003-b18b-42e8-8c82-da2cfe9191e1',
      cart_recommendations: [
        'cab7528b-3daa-4244-a634-5caec6709d05',
        'cab765df-47de-48f7-97c1-4eb50e98fa04',
        'cab76bd6-76fc-44ea-aae6-46666a7937fe',
        'cab77660-b5aa-45ba-a061-bde28dc6b348',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'cab764ad-1d49-417f-a8ed-4de08c8c21a8',
          recommended_items: [
            'cab765a7-f9c2-43cd-b0b4-062b40e027a4',
            'cab74502-4d25-49f5-8970-d5f1f9f9acb3',
            'cab76fe3-e514-4e50-a3f9-254f5ac4f9c6',
            'cab765ec-73b7-4bee-b2ca-8e59c7454b53',
            'cab773ed-19c9-41c7-bc98-10172c3aa1d3',
          ],
        },
        {
          id: 'cab774b2-c139-4be8-9059-7b85a61c4d87',
          recommended_items: [
            'cab765a7-f9c2-43cd-b0b4-062b40e027a4',
            'cab74502-4d25-49f5-8970-d5f1f9f9acb3',
            'cab76fe3-e514-4e50-a3f9-254f5ac4f9c6',
            'cab765ec-73b7-4bee-b2ca-8e59c7454b53',
            'cab773ed-19c9-41c7-bc98-10172c3aa1d3',
          ],
        },
        {
          id: 'cab76f33-0ea3-42e3-81c4-7167bd595f37',
          recommended_items: [
            'cab765a7-f9c2-43cd-b0b4-062b40e027a4',
            'cab74502-4d25-49f5-8970-d5f1f9f9acb3',
            'cab76fe3-e514-4e50-a3f9-254f5ac4f9c6',
            'cab765ec-73b7-4bee-b2ca-8e59c7454b53',
            'cab773ed-19c9-41c7-bc98-10172c3aa1d3',
          ],
        },
        {
          id: 'cab774b2-c139-4be8-9059-7b85a61c4d87',
          recommended_items: [
            'cab765a7-f9c2-43cd-b0b4-062b40e027a4',
            'cab74502-4d25-49f5-8970-d5f1f9f9acb3',
            'cab76fe3-e514-4e50-a3f9-254f5ac4f9c6',
            'cab765ec-73b7-4bee-b2ca-8e59c7454b53',
            'cab773ed-19c9-41c7-bc98-10172c3aa1d3',
          ],
        },
      ],
    },
    {
      id: '4b8f1758-ef56-4daf-b866-46e9e6dbdf99',
      cart_recommendations: [
        'c74a170b-c91c-4915-988a-f1d7104d474c',
        'c74a76e2-a086-4464-b102-d6813f4bb85a',
        'c74a5d94-7eb3-483d-9000-fc1df7bb4c38',
        'c74599bb-17ee-4685-84c4-62c9fad28c94',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c743f450-dcb7-44c2-b081-f9fff1f0dbb5',
          recommended_items: [
            'c7456fa2-b12e-4a57-9974-aaca696b81e5',
            'c74588f9-9d10-409f-bf7d-021523b58f9c',
            'c74a3cb1-5a00-467f-97f6-fca446b4fd5b',
            'c74a1b7b-851c-4561-9e85-a369177eeac7',
            'c744eb25-ccc9-4f0d-ac96-4b84e7e24395',
          ],
        },
        {
          id: 'c743f918-d71f-4d56-9a0b-cea94b52d4e1',
          recommended_items: [
            'c7456fa2-b12e-4a57-9974-aaca696b81e5',
            'c74588f9-9d10-409f-bf7d-021523b58f9c',
            'c74a3cb1-5a00-467f-97f6-fca446b4fd5b',
            'c74a1b7b-851c-4561-9e85-a369177eeac7',
            'c744eb25-ccc9-4f0d-ac96-4b84e7e24395',
          ],
        },
        {
          id: 'c743d04d-131c-4ddd-98fa-135a389841d7',
          recommended_items: [
            'c7456fa2-b12e-4a57-9974-aaca696b81e5',
            'c74588f9-9d10-409f-bf7d-021523b58f9c',
            'c74a3cb1-5a00-467f-97f6-fca446b4fd5b',
            'c74a1b7b-851c-4561-9e85-a369177eeac7',
            'c744eb25-ccc9-4f0d-ac96-4b84e7e24395',
          ],
        },
        {
          id: 'c743de21-c10b-46a4-bbf4-731f5d709bcf',
          recommended_items: [
            'c7456fa2-b12e-4a57-9974-aaca696b81e5',
            'c74588f9-9d10-409f-bf7d-021523b58f9c',
            'c74a3cb1-5a00-467f-97f6-fca446b4fd5b',
            'c74a1b7b-851c-4561-9e85-a369177eeac7',
            'c744eb25-ccc9-4f0d-ac96-4b84e7e24395',
          ],
        },
        {
          id: 'c743e6f4-6b1f-40e9-a74f-7ae8429a074d',
          recommended_items: [
            'c7456fa2-b12e-4a57-9974-aaca696b81e5',
            'c74588f9-9d10-409f-bf7d-021523b58f9c',
            'c74a3cb1-5a00-467f-97f6-fca446b4fd5b',
            'c74a1b7b-851c-4561-9e85-a369177eeac7',
            'c744eb25-ccc9-4f0d-ac96-4b84e7e24395',
          ],
        },
      ],
    },
    {
      id: '00bead04-ee35-441d-9721-79aa82228f8d',
      cart_recommendations: [
        'ca2dad03-1f43-4e33-a71a-705b21e001ca',
        'ca2d9964-8931-4ae9-a55e-b4bc695c1a83',
        'ca2da4f6-3e08-4ade-b5a7-b7c18b348ff0',
        'ca2db34c-7ae5-4f1d-9882-a74e9ddbf886',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'ca2d9c73-c21c-46b2-a26d-15c979a334cd',
          recommended_items: [
            'ca2d9a3a-c317-446f-ab2e-e2e3b434b788',
            'ca2da4c3-a7a7-468d-a5b0-89ae40654b55',
            'ca2d8bd9-9cd4-4bec-a63c-72b314423e5c',
            'ca2d9dc1-af72-4163-898e-7db4c6ee0ddc',
            'ca2d8dfa-1cdf-4217-adc7-37f66fee1b24',
          ],
        },
        {
          id: 'ca2d8cef-8acc-4dde-bdd4-d1306f2fa925',
          recommended_items: [
            'ca2d9a3a-c317-446f-ab2e-e2e3b434b788',
            'ca2da4c3-a7a7-468d-a5b0-89ae40654b55',
            'ca2d8bd9-9cd4-4bec-a63c-72b314423e5c',
            'ca2d9dc1-af72-4163-898e-7db4c6ee0ddc',
            'ca2d8dfa-1cdf-4217-adc7-37f66fee1b24',
          ],
        },
        {
          id: 'ca2d892b-d77c-4c25-97c2-cffe8675e6a5',
          recommended_items: [
            'ca2d9a3a-c317-446f-ab2e-e2e3b434b788',
            'ca2da4c3-a7a7-468d-a5b0-89ae40654b55',
            'ca2d8bd9-9cd4-4bec-a63c-72b314423e5c',
            'ca2d9dc1-af72-4163-898e-7db4c6ee0ddc',
            'ca2d8dfa-1cdf-4217-adc7-37f66fee1b24',
          ],
        },
        {
          id: 'ca2d8ade-f71e-412a-b9ff-869404756fa2',
          recommended_items: [
            'ca2d9a3a-c317-446f-ab2e-e2e3b434b788',
            'ca2da4c3-a7a7-468d-a5b0-89ae40654b55',
            'ca2d8bd9-9cd4-4bec-a63c-72b314423e5c',
            'ca2d9dc1-af72-4163-898e-7db4c6ee0ddc',
            'ca2d8dfa-1cdf-4217-adc7-37f66fee1b24',
          ],
        },
        {
          id: 'ca2db03a-0b4d-4bb7-a709-4e023b91be72',
          recommended_items: [
            'ca2d9a3a-c317-446f-ab2e-e2e3b434b788',
            'ca2da4c3-a7a7-468d-a5b0-89ae40654b55',
            'ca2d8bd9-9cd4-4bec-a63c-72b314423e5c',
            'ca2d9dc1-af72-4163-898e-7db4c6ee0ddc',
            'ca2d8dfa-1cdf-4217-adc7-37f66fee1b24',
          ],
        },
      ],
    },
    {
      id: '64831a00-00b6-441a-93fe-ce05c75fa146',
      cart_recommendations: [
        'ca2d967b-5882-46a2-b211-e3caa346149a',
        'ca2db0ae-be54-468c-a61a-33ae7cfa18cd',
        'ca2da54b-71a1-45fb-8747-bab3fd1e650f',
        'ca2d9ed7-1061-44a3-a354-3fcf2f1f49ef',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'ca2db767-6404-4d5c-ba1e-99654205b666',
          recommended_items: [
            'ca4561ae-acb0-4903-bc73-ccbd5591885d',
            'ca45519b-e3b7-412b-90c0-ea3d29c16b8d',
            'ca2da9e1-1b00-441b-81b2-e11c1ae97454',
            'ca2d8480-1f3a-4b57-93ec-01ff9195e8df',
          ],
        },
        {
          id: 'ca2db0a3-0e93-4b42-a515-3911f89dfc3c',
          recommended_items: [
            'ca4561ae-acb0-4903-bc73-ccbd5591885d',
            'ca45519b-e3b7-412b-90c0-ea3d29c16b8d',
            'ca2da9e1-1b00-441b-81b2-e11c1ae97454',
            'ca2d8480-1f3a-4b57-93ec-01ff9195e8df',
          ],
        },
        {
          id: 'ca2d8ab8-8665-400d-a80c-ecd81ef6fa25',
          recommended_items: [
            'ca4561ae-acb0-4903-bc73-ccbd5591885d',
            'ca45519b-e3b7-412b-90c0-ea3d29c16b8d',
            'ca2da9e1-1b00-441b-81b2-e11c1ae97454',
            'ca2d8480-1f3a-4b57-93ec-01ff9195e8df',
          ],
        },
        {
          id: 'ca2dba0d-d968-49cc-8bae-1a96e0c53306',
          recommended_items: [
            'ca4561ae-acb0-4903-bc73-ccbd5591885d',
            'ca45519b-e3b7-412b-90c0-ea3d29c16b8d',
            'ca2da9e1-1b00-441b-81b2-e11c1ae97454',
            'ca2d8480-1f3a-4b57-93ec-01ff9195e8df',
          ],
        },
        {
          id: 'ca2da75c-8ecc-44ef-b31a-7672cff4af38',
          recommended_items: [
            'ca4561ae-acb0-4903-bc73-ccbd5591885d',
            'ca45519b-e3b7-412b-90c0-ea3d29c16b8d',
            'ca2da9e1-1b00-441b-81b2-e11c1ae97454',
            'ca2d8480-1f3a-4b57-93ec-01ff9195e8df',
          ],
        },
      ],
    },
    {
      id: 'f315a1ab-412a-4342-a204-7d4ae23ece58',
      cart_recommendations: [
        'ca2dbcab-3f10-4b8a-ad2a-7513b347b014',
        'ca2d926f-ba35-4fc3-a163-544fb55864cc',
        'ca2da2dc-73b2-4a8d-89d3-aea8cc0f6fae',
        'ca2db41b-5266-4ced-80f2-240bec1a85e1',
        'ca2da280-da4f-4e01-bade-6b9ac6bc9cb4',
        'ca2da280-da4f-4e01-bade-6b9ac6bc9cb4',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'ca2d8eba-af11-4ce6-8eeb-831fe0ee3668',
          recommended_items: [
            'ca2d9bea-d05b-4c5c-a87b-57401a22fabc',
            'ca2d83fb-42aa-4d3d-8021-3874093b4bfc',
            'ca2da513-6cda-40f8-b382-1b94e5ed49a9',
            'ca2db50c-0b1f-492e-aeaa-372c3f248522',
          ],
        },
        {
          id: 'ca2da619-df11-4780-8fa4-7869fa885c53',
          recommended_items: [
            'ca2d9bea-d05b-4c5c-a87b-57401a22fabc',
            'ca2d83fb-42aa-4d3d-8021-3874093b4bfc',
            'ca2da513-6cda-40f8-b382-1b94e5ed49a9',
            'ca2db50c-0b1f-492e-aeaa-372c3f248522',
          ],
        },
        {
          id: 'ca2d8eba-af11-4ce6-8eeb-831fe0ee3668',
          recommended_items: [
            'ca2d9bea-d05b-4c5c-a87b-57401a22fabc',
            'ca2d83fb-42aa-4d3d-8021-3874093b4bfc',
            'ca2da513-6cda-40f8-b382-1b94e5ed49a9',
            'ca2db50c-0b1f-492e-aeaa-372c3f248522',
          ],
        },
        {
          id: 'ca2d8eb9-acdc-479f-a0af-8481651b1663',
          recommended_items: [
            'ca2d9bea-d05b-4c5c-a87b-57401a22fabc',
            'ca2d83fb-42aa-4d3d-8021-3874093b4bfc',
            'ca2da513-6cda-40f8-b382-1b94e5ed49a9',
            'ca2db50c-0b1f-492e-aeaa-372c3f248522',
          ],
        },
      ],
    },
    {
      id: '39cdc4fb-1415-4a5b-a218-7c87b28a0beb',
      cart_recommendations: [
        'ca8e6744-83d3-4a80-a7c3-feba3a3e32a9',
        'ca8e5b75-8335-4afa-a480-15d7e46aac33',
        'ca8e6d43-e79a-4f64-a4fc-32123412ef58',
        'ca8e52be-1606-4467-8b38-c20f780987c1',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: [''],
        },
      ],
    },
    {
      id: '',
      cart_recommendations: [
        'ca5bf3cf-ecb5-4811-b1e8-61cd03e046c7',
        'ca5bc17b-842f-474f-babb-4a669ba0950b',
        'ca5bea4d-c6dd-4b99-b7a2-1394ea6d640c',
        'ca5bd683-b68d-462b-b39f-a2dcd74140dc',
        'ca5beb2d-d086-44ba-ae48-588468c22e35',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: [''],
        },
      ],
    },
    {
      id: '4870aa24-1bb0-498b-a741-d3fb765f298c',
      cart_recommendations: [
        'ca3ef385-8260-4f87-8ef6-940ab0e1839d',
        'ca3ed07b-b4c4-4c29-a18f-151b084ad362',
        'ca3edb05-b1a3-447d-8451-d86d2d556e67',
        'ca3ec6f3-6ff9-4fd7-95a8-8406b8399adc',
        'ca3ef4c9-3f7a-4450-b228-cef1fb6c2aeb',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c74b749a-29cc-4f9f-8c90-d29327066317',
          recommended_items: [
            'ca3ef389-8774-4d88-9d41-ef5e45677c6c',
            'ca3ee7eb-3674-4f15-a6ae-a0c4a8edd045',
            'ca3eeb99-4c61-48e0-a48c-bc18ea0629c3',
            'ca3ece91-db59-449d-bf79-e227c2e13144',
            'ca3ed07b-b4c4-4c29-a18f-151b084ad362',
          ],
        },
        {
          id: 'c74b5627-fcff-4634-a5f5-8ee18ce249ce',
          recommended_items: [
            'ca3ef389-8774-4d88-9d41-ef5e45677c6c',
            'ca3ee7eb-3674-4f15-a6ae-a0c4a8edd045',
            'ca3eeb99-4c61-48e0-a48c-bc18ea0629c3',
            'ca3ece91-db59-449d-bf79-e227c2e13144',
            'ca3ed07b-b4c4-4c29-a18f-151b084ad362',
          ],
        },
        {
          id: 'c74b719e-b362-4304-a036-67005666c66c',
          recommended_items: [
            'ca3ef389-8774-4d88-9d41-ef5e45677c6c',
            'ca3ee7eb-3674-4f15-a6ae-a0c4a8edd045',
            'ca3eeb99-4c61-48e0-a48c-bc18ea0629c3',
            'ca3ece91-db59-449d-bf79-e227c2e13144',
            'ca3ed07b-b4c4-4c29-a18f-151b084ad362',
          ],
        },
        {
          id: 'c74ba139-63e2-4c92-bc62-db0763b4290e',
          recommended_items: [
            'ca3ef389-8774-4d88-9d41-ef5e45677c6c',
            'ca3ee7eb-3674-4f15-a6ae-a0c4a8edd045',
            'ca3eeb99-4c61-48e0-a48c-bc18ea0629c3',
            'ca3ece91-db59-449d-bf79-e227c2e13144',
            'ca3ed07b-b4c4-4c29-a18f-151b084ad362',
          ],
        },
        {
          id: 'c74b8f27-0f11-4414-8acd-a159aafdb94b',
          recommended_items: [
            'ca3ef389-8774-4d88-9d41-ef5e45677c6c',
            'ca3ee7eb-3674-4f15-a6ae-a0c4a8edd045',
            'ca3eeb99-4c61-48e0-a48c-bc18ea0629c3',
            'ca3ece91-db59-449d-bf79-e227c2e13144',
            'ca3ed07b-b4c4-4c29-a18f-151b084ad362',
          ],
        },
      ],
    },
    {
      id: '0e0f6b8d-0724-487d-ae45-30e1365580a7',
      cart_recommendations: [
        'c83ab4bd-9e09-44b0-bfbe-e582b8fd8b08',
        'c83ab7e9-bd60-4abb-9f9a-e69de72e5d96',
        'c83aca18-03e6-4232-bc47-e27063a53f91',
        'c83aebc5-e651-44e5-becf-7f5099b14579',
        'c83ada10-b82d-4701-a26b-ca12d657a6ae',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c836f2e2-33f1-40c2-ac43-31095808e5dd',
          recommended_items: [
            'c83aca18-03e6-4232-bc47-e27063a53f91',
            'c83aebc5-e651-44e5-becf-7f5099b14579',
            'c83ada10-b82d-4701-a26b-ca12d657a6ae',
          ],
        },
        {
          id: 'c836ff4c-b370-46ac-bb81-476e50e94d25',
          recommended_items: [
            'c83aca18-03e6-4232-bc47-e27063a53f91',
            'c83aebc5-e651-44e5-becf-7f5099b14579',
            'c83ada10-b82d-4701-a26b-ca12d657a6ae',
          ],
        },
      ],
    },
    {
      id: '54174b76-5d0f-4e6a-a97e-8de786410b19',
      cart_recommendations: [
        'c9b42439-9599-4110-999e-141af9ae545d',
        'c9b409a6-fc8a-426f-822f-a94c7666ebbc',
        'c9b4117e-fc5a-405b-80c0-d9ab0e92efa4',
      ],
      menu_item: [
        {
          id: 'c9b41b40-c744-4fd0-8135-2a3bb19b476a',
          recommended_items: ['c9b42439-9599-4110-999e-141af9ae545d'],
        },
        {
          id: 'c9b42955-46c5-45e4-90b6-d6b26d586681',
          recommended_items: ['c9b409a6-fc8a-426f-822f-a94c7666ebbc'],
        },
        {
          id: 'c9b42897-a21c-4f3e-b81c-1f7fdf70d444',
          recommended_items: ['c9b427f8-bc17-4277-9579-805c5282eb23'],
        },
        {
          id: 'c9b43171-5430-4f55-961d-591ce87f2281',
          recommended_items: ['c9b42a48-7417-4c89-9362-f24e069e0649'],
        },
        {
          id: 'c9b43ee5-2030-41ad-9129-9ee1b50963f9',
          recommended_items: ['c9b42a48-7417-4c89-9362-f24e069e0649'],
        },
        {
          id: 'c9b417d3-5477-43f5-86b4-fccb3ce095af',
          recommended_items: ['c9b4117e-fc5a-405b-80c0-d9ab0e92efa4'],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: [''],
        },
      ],
    },
    {
      id: 'cfd03d05-d4d3-412b-b6ab-85e3f911f078',
      cart_recommendations: [
        'c869f3f5-529d-49c7-937f-34b0aa4dca79',
        'c24d4ec0-5acd-4aeb-ab41-c3540bfc2976',
        'c24d78e9-8989-4ad8-a62e-ad4270657255',
        'c24d53e1-c5a2-437f-9ea5-c7605747591f',
      ],
      menu_item: [
        {
          id: 'c82f075c-d581-443a-8a6c-cbb2809b2074',
          recommended_items: [
            'c869f3f5-529d-49c7-937f-34b0aa4dca79',
            'c24d4ec0-5acd-4aeb-ab41-c3540bfc2976',
            'c24d78e9-8989-4ad8-a62e-ad4270657255',
            'c24d53e1-c5a2-437f-9ea5-c7605747591f',
          ],
        },
        {
          id: 'c82f0c53-14a3-4ee8-8673-548fad624d2e',
          recommended_items: [
            'c869f3f5-529d-49c7-937f-34b0aa4dca79',
            'c24d4ec0-5acd-4aeb-ab41-c3540bfc2976',
            'c24d78e9-8989-4ad8-a62e-ad4270657255',
            'c24d53e1-c5a2-437f-9ea5-c7605747591f',
          ],
        },
        {
          id: 'c24d4160-f25a-4ac7-b67b-ab91aac96b66',
          recommended_items: [
            'c869f3f5-529d-49c7-937f-34b0aa4dca79',
            'c24d4ec0-5acd-4aeb-ab41-c3540bfc2976',
            'c24d78e9-8989-4ad8-a62e-ad4270657255',
            'c24d53e1-c5a2-437f-9ea5-c7605747591f',
          ],
        },
        {
          id: 'c24d481e-8450-487e-92a6-cf7bde86ea19',
          recommended_items: [
            'c869f3f5-529d-49c7-937f-34b0aa4dca79',
            'c24d4ec0-5acd-4aeb-ab41-c3540bfc2976',
            'c24d78e9-8989-4ad8-a62e-ad4270657255',
            'c24d53e1-c5a2-437f-9ea5-c7605747591f',
          ],
        },
        {
          id: 'c24d62a8-f38f-41de-b97d-ecae2e07aa41',
          recommended_items: [
            'c869f3f5-529d-49c7-937f-34b0aa4dca79',
            'c24d4ec0-5acd-4aeb-ab41-c3540bfc2976',
            'c24d78e9-8989-4ad8-a62e-ad4270657255',
            'c24d53e1-c5a2-437f-9ea5-c7605747591f',
          ],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: [''],
        },
      ],
    },
    {
      id: 'a32ea882-2eea-4ee3-8628-0f726904b2f7',
      cart_recommendations: [
        'c6fd1846-75d6-4f47-b48b-8ca20cb3bba9',
        'c6fd37ba-ea3b-494d-bcf9-2428e8f614fa',
        'c6fd33d5-0f56-498e-9f05-5905b70f5da0',
        'c6fd266a-2b10-4433-a9cb-98f38a74e0e9',
      ],
      menu_item: [
        {
          id: 'c6fd1935-3d5e-48dc-bdfd-4e8b5ff30b71',
          recommended_items: [
            'c6fd3e73-3b3e-47f2-b18a-6f1e63c6c1e9',
            'c6fd37ba-ea3b-494d-bcf9-2428e8f614fa',
            'c6fd33d5-0f56-498e-9f05-5905b70f5da0',
            'c6fd266a-2b10-4433-a9cb-98f38a74e0e9',
          ],
        },
        {
          id: 'c6fd2932-5ea3-423f-b975-1fff65d8722d',
          recommended_items: [
            'c6fd3e73-3b3e-47f2-b18a-6f1e63c6c1e9',
            'c6fd37ba-ea3b-494d-bcf9-2428e8f614fa',
            'c6fd33d5-0f56-498e-9f05-5905b70f5da0',
            'c6fd266a-2b10-4433-a9cb-98f38a74e0e9',
          ],
        },
        {
          id: 'c6fd3c81-f34b-4624-a6ab-936091d8f1dd',
          recommended_items: [
            'c6fd3e73-3b3e-47f2-b18a-6f1e63c6c1e9',
            'c6fd37ba-ea3b-494d-bcf9-2428e8f614fa',
            'c6fd33d5-0f56-498e-9f05-5905b70f5da0',
            'c6fd266a-2b10-4433-a9cb-98f38a74e0e9',
          ],
        },
      ],
      menu_heading: [
        {
          id: 'bddf2499-82e3-4388-8e4b-62c789973625',
          recommended_items: [
            'c6fd1846-75d6-4f47-b48b-8ca20cb3bba9',
            'c6fd37ba-ea3b-494d-bcf9-2428e8f614fa',
            'c6fd33d5-0f56-498e-9f05-5905b70f5da0',
            'c6fd266a-2b10-4433-a9cb-98f38a74e0e9',
          ],
        },
        {
          id: 'baa2f97d-daaf-4968-bf2e-45410e3f2c4a',
          recommended_items: [
            'c6fd1846-75d6-4f47-b48b-8ca20cb3bba9',
            'c6fd37ba-ea3b-494d-bcf9-2428e8f614fa',
            'c6fd33d5-0f56-498e-9f05-5905b70f5da0',
            'c6fd266a-2b10-4433-a9cb-98f38a74e0e9',
          ],
        },
      ],
    },
    {
      id: 'f3a2aedf-a63e-4abb-a571-ccae5c43219e',
      cart_recommendations: [
        'c5d67902-6199-45a1-8193-978464b0291b',
        'c7043e1e-d3c2-4542-a04f-9848a0788e5d',
        'c70f85b2-f3f2-480f-b2a1-ed0b62a166c1',
        'c71c4858-847a-43ac-ab92-65838e520fab',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: [''],
        },
      ],
    },
    {
      id: '33cd095b-7d96-4bc6-b39a-da40044c28d7',
      cart_recommendations: [
        'c804b260-73fd-4173-8ef3-972d7436a394',
        'c91e4085-8ac5-4512-b9f9-5baa01094ba2',
        'c7042837-fe6f-4675-9fe1-a863893b80d2',
        'c7041e96-0185-4230-9fb1-2227d30e5a81',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: [''],
        },
      ],
    },
    {
      id: 'ca7082a4-1566-4af3-8f6f-c73cd7fe1d77',
      cart_recommendations: [
        'c7c2d17e-fbc6-4933-be7f-2c2205dc9b57',
        'c7c60ded-902e-4f6d-968c-2978e666b19f',
        'c7c2ca8e-d49f-490c-a5ae-894fde09392c',
        'c7c2fa5e-4eac-4c28-890a-fbb057fc0d22',
        'c7c2c645-7f11-4d3e-bf4c-86a05ffe6a78',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c7c63d05-0340-4433-9630-f7f786cb7471',
          recommended_items: [
            'c7c64f1e-d9a9-4c56-9aa6-e789a3875cf7',
            'c7c2f29e-3ff5-46db-a599-0e1885bb1242',
            'c7c2fa5e-4eac-4c28-890a-fbb057fc0d22',
            'c7c2fd55-62cb-41ab-af06-787672412088',
          ],
        },
        {
          id: 'c7c634fc-cf7b-407f-8e30-1943f3ced285',
          recommended_items: [
            'c7c64f1e-d9a9-4c56-9aa6-e789a3875cf7',
            'c7c2f29e-3ff5-46db-a599-0e1885bb1242',
            'c7c2fa5e-4eac-4c28-890a-fbb057fc0d22',
            'c7c2fd55-62cb-41ab-af06-787672412088',
          ],
        },
        {
          id: 'c7c6278e-4990-4b97-b46d-5c055e06ff8d',
          recommended_items: [
            'c7c64f1e-d9a9-4c56-9aa6-e789a3875cf7',
            'c7c2f29e-3ff5-46db-a599-0e1885bb1242',
            'c7c2fa5e-4eac-4c28-890a-fbb057fc0d22',
            'c7c2fd55-62cb-41ab-af06-787672412088',
          ],
        },
        {
          id: 'c7c60ded-902e-4f6d-968c-2978e666b19f',
          recommended_items: [
            'c7c64f1e-d9a9-4c56-9aa6-e789a3875cf7',
            'c7c2f29e-3ff5-46db-a599-0e1885bb1242',
            'c7c2fa5e-4eac-4c28-890a-fbb057fc0d22',
            'c7c2fd55-62cb-41ab-af06-787672412088',
          ],
        },
        {
          id: 'c7c622ff-1058-44b6-82ee-0758c8b80788',
          recommended_items: [
            'c7c64f1e-d9a9-4c56-9aa6-e789a3875cf7',
            'c7c2f29e-3ff5-46db-a599-0e1885bb1242',
            'c7c2fa5e-4eac-4c28-890a-fbb057fc0d22',
            'c7c2fd55-62cb-41ab-af06-787672412088',
          ],
        },
        {
          id: 'c7c62d7c-298b-4818-a860-0f11be6ee2fc',
          recommended_items: [
            'c7c64f1e-d9a9-4c56-9aa6-e789a3875cf7',
            'c7c2f29e-3ff5-46db-a599-0e1885bb1242',
            'c7c2fa5e-4eac-4c28-890a-fbb057fc0d22',
            'c7c2fd55-62cb-41ab-af06-787672412088',
          ],
        },
        {
          id: 'c875d851-22ce-4018-b12d-e1c97fe62957',
          recommended_items: [
            'c7c64f1e-d9a9-4c56-9aa6-e789a3875cf7',
            'c7c2f29e-3ff5-46db-a599-0e1885bb1242',
            'c7c2fa5e-4eac-4c28-890a-fbb057fc0d22',
            'c7c2fd55-62cb-41ab-af06-787672412088',
          ],
        },
        {
          id: 'c875d5e3-0dca-413e-994b-efb9f82993d7',
          recommended_items: [
            'c7c64f1e-d9a9-4c56-9aa6-e789a3875cf7',
            'c7c2f29e-3ff5-46db-a599-0e1885bb1242',
            'c7c2fa5e-4eac-4c28-890a-fbb057fc0d22',
            'c7c2fd55-62cb-41ab-af06-787672412088',
          ],
        },
        {
          id: 'c875eced-e75d-48a5-88c7-16778f1eb1ac',
          recommended_items: [
            'c7c64f1e-d9a9-4c56-9aa6-e789a3875cf7',
            'c7c2f29e-3ff5-46db-a599-0e1885bb1242',
            'c7c2fa5e-4eac-4c28-890a-fbb057fc0d22',
            'c7c2fd55-62cb-41ab-af06-787672412088',
          ],
        },
        {
          id: 'c7c2e672-ef1e-40f7-a4a2-947ef7f79d40',
          recommended_items: [
            'c7c64f1e-d9a9-4c56-9aa6-e789a3875cf7',
            'c7c2f29e-3ff5-46db-a599-0e1885bb1242',
            'c7c2fa5e-4eac-4c28-890a-fbb057fc0d22',
            'c7c2fd55-62cb-41ab-af06-787672412088',
          ],
        },
        {
          id: 'c7c2d87e-3694-4af8-9fbe-0e043d8481c1',
          recommended_items: [
            'c7c64f1e-d9a9-4c56-9aa6-e789a3875cf7',
            'c7c2f29e-3ff5-46db-a599-0e1885bb1242',
            'c7c2fa5e-4eac-4c28-890a-fbb057fc0d22',
            'c7c2fd55-62cb-41ab-af06-787672412088',
          ],
        },
      ],
    },
    {
      id: '3167671d-c315-4748-bbc8-4c2243999dec',
      cart_recommendations: [
        'c73dde70-bbe0-4505-86dc-3566bdf32fd7',
        'c73dfa35-34ab-476f-a318-b07c4189302d',
        'c73dd17d-4e16-4384-abb5-bc270121ffe7',
        'c73dddfe-23f8-47ae-8126-048871ec7b75',
        'c73dfa30-224d-4665-be55-9d705c626016',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: [''],
        },
      ],
    },
    {
      id: '80b4e64b-e44a-4c42-baa9-5bcfd87823fd',
      cart_recommendations: [
        'c875ca82-5199-46e7-aca3-9867d25aeef8',
        'c74b7eba-50e6-4e6b-90c3-8580d08324bc',
        'c875c1ae-90a8-4300-a6c6-4bfe95915e4e',
        'c875d69a-ae52-4e8a-8cb4-d2b40c1d5cea',
        'c875cf76-2d34-457e-bb61-939944ff25ee',
        'c875e3d6-9968-4cc3-bf85-497fc72165b9',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c74ba942-6b7e-4d29-9390-faac47d83638',
          recommended_items: [
            'c875ca82-5199-46e7-aca3-9867d25aeef8',
            'c875d69a-ae52-4e8a-8cb4-d2b40c1d5cea',
            'c875cf76-2d34-457e-bb61-939944ff25ee',
            'c875e3d6-9968-4cc3-bf85-497fc72165b9',
            'c875e7e5-b751-4bda-a05f-9784c1223f9d',
            'c875f7a1-8e79-4bec-b706-8b4c60323905',
          ],
        },
        {
          id: 'c74b719e-b362-4304-a036-67005666c66c',
          recommended_items: [
            'c875ca82-5199-46e7-aca3-9867d25aeef8',
            'c875d69a-ae52-4e8a-8cb4-d2b40c1d5cea',
            'c875cf76-2d34-457e-bb61-939944ff25ee',
            'c875e3d6-9968-4cc3-bf85-497fc72165b9',
            'c875e7e5-b751-4bda-a05f-9784c1223f9d',
            'c875f7a1-8e79-4bec-b706-8b4c60323905',
          ],
        },
        {
          id: 'c74ba139-63e2-4c92-bc62-db0763b4290e',
          recommended_items: [
            'c875d69a-ae52-4e8a-8cb4-d2b40c1d5cea',
            'c875cf76-2d34-457e-bb61-939944ff25ee',
            'c875e3d6-9968-4cc3-bf85-497fc72165b9',
            'c875e7e5-b751-4bda-a05f-9784c1223f9d',
            'c875f7a1-8e79-4bec-b706-8b4c60323905',
          ],
        },
      ],
    },
    {
      id: 'fdbdb65e-00d3-4bab-87c4-ba103a78dd31',
      cart_recommendations: [
        '6628f1ad-c998-4894-80d8-dca99b4cd9f6',
        'e0ed441e-aa3c-4553-a859-029b0bb6fa8c',
        '75e6e60f-c845-4ad4-8302-de4fdf5179c3',
        'f3de2442-6fd2-4fcf-a067-4d7625ebbcf9',
        'f4636d17-d35d-4d41-a498-30b84dd2044b',
      ],
      menu_item: [
        {
          id: '8aa5cc52-36e9-443d-8155-26446fe6d29c',
          recommended_items: ['f3de2442-6fd2-4fcf-a067-4d7625ebbcf9'],
        },
        {
          id: 'd09974ad-75ee-4620-aec5-d5939d69cf4f',
          recommended_items: ['f3de2442-6fd2-4fcf-a067-4d7625ebbcf9'],
        },
        {
          id: '8d6a9cfb-6420-4b59-a6f5-fbc988e4aa95',
          recommended_items: [
            '6628f1ad-c998-4894-80d8-dca99b4cd9f6',
            'e0ed441e-aa3c-4553-a859-029b0bb6fa8c',
            '75e6e60f-c845-4ad4-8302-de4fdf5179c3',
          ],
        },
      ],
      menu_heading: [
        {
          id: '64ee3bb9-f3ff-4a85-9919-ec0a4be17b83',
          recommended_items: [
            '6628f1ad-c998-4894-80d8-dca99b4cd9f6',
            'e0ed441e-aa3c-4553-a859-029b0bb6fa8c',
            '75e6e60f-c845-4ad4-8302-de4fdf5179c3',
            'f3de2442-6fd2-4fcf-a067-4d7625ebbcf9',
            'f4636d17-d35d-4d41-a498-30b84dd2044b',
          ],
        },
        {
          id: '64645f06-5d44-4091-801c-969cca622aff',
          recommended_items: [
            '6628f1ad-c998-4894-80d8-dca99b4cd9f6',
            'e0ed441e-aa3c-4553-a859-029b0bb6fa8c',
            '75e6e60f-c845-4ad4-8302-de4fdf5179c3',
          ],
        },
      ],
    },
    {
      id: '2905c8ab-a895-401a-adcd-8f1f89279da2',
      cart_recommendations: [
        'bcbfe025-f7e0-4184-b520-e82af1ef1d5e',
        'bcbffa5c-97aa-4e27-8bd7-739869918f57',
        'c7b8519f-1914-4566-a2aa-5383efe6d85e',
        'c7b86d32-1704-4078-8553-e0a2c2b16934',
      ],
      menu_item: [
        {
          id: 'c3d24858-b019-422e-9c8c-165df3a79b45',
          recommended_items: [
            'bcbfe025-f7e0-4184-b520-e82af1ef1d5e',
            'bcbffa5c-97aa-4e27-8bd7-739869918f57',
            'c7b8519f-1914-4566-a2aa-5383efe6d85e',
            'c7b86d32-1704-4078-8553-e0a2c2b16934',
          ],
        },
        {
          id: 'bcbffbf7-495c-4111-84ad-9685d8174a87',
          recommended_items: [
            'bcbfe025-f7e0-4184-b520-e82af1ef1d5e',
            'bcbffa5c-97aa-4e27-8bd7-739869918f57',
            'c7b8519f-1914-4566-a2aa-5383efe6d85e',
            'c7b86d32-1704-4078-8553-e0a2c2b16934',
          ],
        },
        {
          id: 'bcbff634-5655-4032-a82a-33eb3d82db15',
          recommended_items: [
            'bcbfe025-f7e0-4184-b520-e82af1ef1d5e',
            'bcbffa5c-97aa-4e27-8bd7-739869918f57',
            'c7b8519f-1914-4566-a2aa-5383efe6d85e',
            'c7b86d32-1704-4078-8553-e0a2c2b16934',
          ],
        },
        {
          id: 'bcbfceec-1bd5-4938-8d57-133937563b92',
          recommended_items: [
            'bcbfe025-f7e0-4184-b520-e82af1ef1d5e',
            'bcbffa5c-97aa-4e27-8bd7-739869918f57',
            'c7b8519f-1914-4566-a2aa-5383efe6d85e',
            'c7b86d32-1704-4078-8553-e0a2c2b16934',
          ],
        },
        {
          id: 'bcbfc2d8-70e0-4b0a-a5c5-b5daf9da4720',
          recommended_items: [
            'bcbfceec-1bd5-4938-8d57-133937563b92',
            'bcbffc92-d0a4-4eb3-9537-f25358bcea5c',
            'bcbfd79c-fd3c-400d-9b0e-fbb3cc776a40',
            'bcbfd7e7-e7d6-4330-9de0-fc0313e29012',
          ],
        },
        {
          id: 'c3d2622e-d13d-4bea-8765-7d003b53a168',
          recommended_items: [
            'bcbfceec-1bd5-4938-8d57-133937563b92',
            'bcbffc92-d0a4-4eb3-9537-f25358bcea5c',
            'bcbfd79c-fd3c-400d-9b0e-fbb3cc776a40',
            'bcbfd7e7-e7d6-4330-9de0-fc0313e29012',
          ],
        },
        {
          id: 'bcbff40f-8264-4779-802e-02a4224ee6de',
          recommended_items: [
            'bcbfceec-1bd5-4938-8d57-133937563b92',
            'bcbffc92-d0a4-4eb3-9537-f25358bcea5c',
            'bcbfd79c-fd3c-400d-9b0e-fbb3cc776a40',
            'bcbfd7e7-e7d6-4330-9de0-fc0313e29012',
          ],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: [''],
        },
      ],
    },
    {
      id: '037460a7-b287-4a2e-95d3-869b64c8db02',
      cart_recommendations: [
        'c5f644aa-4f0e-4bb2-8ff5-a12eb2cbde5a',
        'c618c5c4-c3f8-4d58-8167-1d8bf15cf29e',
        'c88b0d33-0ea7-4f9a-a8d2-39d675f8577c',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'bacdf97b-122b-41ca-ad31-dcd47f60ca7c',
          recommended_items: [
            'c5f644aa-4f0e-4bb2-8ff5-a12eb2cbde5a',
            'c618c5c4-c3f8-4d58-8167-1d8bf15cf29e',
            'c88b0d33-0ea7-4f9a-a8d2-39d675f8577c',
          ],
        },
        {
          id: 'c89b2774-adbb-4793-827a-699dd98de138',
          recommended_items: [
            'c5f644aa-4f0e-4bb2-8ff5-a12eb2cbde5a',
            'c618c5c4-c3f8-4d58-8167-1d8bf15cf29e',
            'c88b0d33-0ea7-4f9a-a8d2-39d675f8577c',
          ],
        },
      ],
    },
    {
      id: 'b4386b3c-3656-42bd-825a-c286badcd690',
      cart_recommendations: ['', ''],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'b4386b3c-3656-42bd-825a-c286badcd690',
          recommended_items: [
            'c846f06a-82c6-4742-a2c8-120d272e3cf5',
            'c7ecb840-5ff8-4d96-bf5d-ec77dceae5ba',
            'c8b8ab90-cea4-419f-ab02-fca4e0835858',
            'c7b06863-e1e8-4807-8648-b17c94c07d7b',
          ],
        },
      ],
    },
    {
      id: 'd7516b92-dc2b-44e6-9769-66a3c93724d7',
      cart_recommendations: [
        'c8452f76-3099-4a09-aea9-7b4e36faf73f',
        'c8451c0e-b42d-4fe3-aa53-91e5c574d6b6',
        'c8453ba7-284e-4177-879a-5c2bd96e49b6',
        'c84507b6-333b-4c06-bc40-731c469039a6',
        'c8451191-dd3f-447d-bfaf-b84a8cb0b5a8',
      ],
      menu_item: [
        {
          id: 'c8451369-289b-4475-a45c-1709f981d353',
          recommended_items: [
            'c8450a65-4c39-401c-8827-9b45b91c97f8',
            'c8453ba7-284e-4177-879a-5c2bd96e49b6',
            'c84507b6-333b-4c06-bc40-731c469039a6',
            'c8451191-dd3f-447d-bfaf-b84a8cb0b5a8',
          ],
        },
      ],
      menu_heading: [
        {
          id: '845328b-8a47-4454-b04a-4a606cf47c45',
          recommended_items: [
            'c8450a65-4c39-401c-8827-9b45b91c97f8',
            'c8453ba7-284e-4177-879a-5c2bd96e49b6',
            'c84507b6-333b-4c06-bc40-731c469039a6',
            'c8451191-dd3f-447d-bfaf-b84a8cb0b5a8',
            'c8453065-7d9f-41e9-8a39-194bd871a205',
          ],
        },
        {
          id: 'c8453738-0bac-4763-914f-ebda4488ab84',
          recommended_items: [
            'c8450a65-4c39-401c-8827-9b45b91c97f8',
            'c8453ba7-284e-4177-879a-5c2bd96e49b6',
            'c84507b6-333b-4c06-bc40-731c469039a6',
            'c8451191-dd3f-447d-bfaf-b84a8cb0b5a8',
            'c8453065-7d9f-41e9-8a39-194bd871a205',
          ],
        },
        {
          id: 'cb0adb60-6695-450f-8411-6044e96d2be6',
          recommended_items: [
            'c8450a65-4c39-401c-8827-9b45b91c97f8',
            'c8453ba7-284e-4177-879a-5c2bd96e49b6',
            'c84507b6-333b-4c06-bc40-731c469039a6',
            'c8451191-dd3f-447d-bfaf-b84a8cb0b5a8',
            'c8453065-7d9f-41e9-8a39-194bd871a205',
          ],
        },
        {
          id: 'c845100f-1d08-46c6-8227-a1e1941f0987',
          recommended_items: [
            'c845267c-c150-4625-9182-2c7cbde94a45',
            'c8452da4-d02e-40bd-8f4a-514641a271d6',
            'c914cc6b-fb0e-40fa-9e65-ae27471c63d9',
            'c84507b6-333b-4c06-bc40-731c469039a6',
            'c8453f88-7369-4fa6-9d8b-d1b5d30d4539',
            'c84502fe-4194-4754-9d0a-b3283c8c33b0',
          ],
        },
        {
          id: 'c8450f93-ffa4-4549-ad67-fd0c1b8d0321',
          recommended_items: [
            'c845267c-c150-4625-9182-2c7cbde94a45',
            'c8452da4-d02e-40bd-8f4a-514641a271d6',
            'c914cc6b-fb0e-40fa-9e65-ae27471c63d9',
            'c84507b6-333b-4c06-bc40-731c469039a6',
            'c8453f88-7369-4fa6-9d8b-d1b5d30d4539',
            'c84502fe-4194-4754-9d0a-b3283c8c33b0',
          ],
        },
      ],
    },
    {
      id: 'fa7ef35a-875a-47a4-a2dc-647ee1859109',
      cart_recommendations: ['be437366-da1d-4156-9d0f-e87cc509700d'],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: [''],
        },
      ],
    },
    {
      id: '86925212-d6ae-49ea-a0f8-4a9728d4ce5a',
      cart_recommendations: ['2d2e1250-71fe-49b0-a9dd-cc7697f8380b', '41dfce6f-6af0-4926-91e2-c631ee46dc7e'],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: '857900a9-e4e9-4c13-8235-ef1bcdce80ce',
          recommended_items: [
            'd7bdaccb-851a-4b17-9560-93d6ea0c9a47',
            '7c5f184e-3308-4522-85a0-9aa5d845979c',
            '3e086cbf-03cd-48d9-97de-3b55e5837afb',
            '28d0c141-848f-47cc-b25e-11125e80b5c2',
          ],
        },
        {
          id: 'ea6cfcf0-0772-4dab-bc83-4346ec902479',
          recommended_items: [
            'd7bdaccb-851a-4b17-9560-93d6ea0c9a47',
            '7c5f184e-3308-4522-85a0-9aa5d845979c',
            '3e086cbf-03cd-48d9-97de-3b55e5837afb',
            '28d0c141-848f-47cc-b25e-11125e80b5c2',
          ],
        },
        {
          id: '930dcfb5-acd2-454b-aa4f-fcb13fcb26b7',
          recommended_items: [
            'd7bdaccb-851a-4b17-9560-93d6ea0c9a47',
            '7c5f184e-3308-4522-85a0-9aa5d845979c',
            '3e086cbf-03cd-48d9-97de-3b55e5837afb',
            '28d0c141-848f-47cc-b25e-11125e80b5c2',
          ],
        },
        {
          id: 'e0e13110-2856-4b22-b368-934183de81b2',
          recommended_items: [
            'd7bdaccb-851a-4b17-9560-93d6ea0c9a47',
            '7c5f184e-3308-4522-85a0-9aa5d845979c',
            '3e086cbf-03cd-48d9-97de-3b55e5837afb',
            '28d0c141-848f-47cc-b25e-11125e80b5c2',
          ],
        },
        {
          id: '182cde5f-2d6f-4ec4-aa75-ea0e247c95d6',
          recommended_items: [
            'd7bdaccb-851a-4b17-9560-93d6ea0c9a47',
            '7c5f184e-3308-4522-85a0-9aa5d845979c',
            '3e086cbf-03cd-48d9-97de-3b55e5837afb',
            '28d0c141-848f-47cc-b25e-11125e80b5c2',
          ],
        },
      ],
    },
    {
      id: '',
      cart_recommendations: ['', ''],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c8163422-8de2-4e89-8f53-8f4eaf5b404d',
          recommended_items: [
            'c7c2ec3b-028a-40ab-ae6f-72fac5171962',
            'c7c2fbb1-4010-4bb3-a688-d399500cfa58',
            'c7c2e4a9-0f51-4eb2-b38b-09eda614551f',
            'c7c2c2fb-0bd2-46c9-938e-a3691cfa681d',
          ],
        },
        {
          id: 'c8299b4b-8820-4ee2-b531-a9751fddf91e',
          recommended_items: [
            'c7c2ec3b-028a-40ab-ae6f-72fac5171962',
            'c7c2fbb1-4010-4bb3-a688-d399500cfa58',
            'c7c2e4a9-0f51-4eb2-b38b-09eda614551f',
            'c7c2c2fb-0bd2-46c9-938e-a3691cfa681d',
          ],
        },
        {
          id: 'c8162f59-4c68-4148-88b4-e8a5524a81b6',
          recommended_items: [
            'c7c2ec3b-028a-40ab-ae6f-72fac5171962',
            'c7c2fbb1-4010-4bb3-a688-d399500cfa58',
            'c7c2e4a9-0f51-4eb2-b38b-09eda614551f',
            'c7c2c2fb-0bd2-46c9-938e-a3691cfa681d',
          ],
        },
        {
          id: 'c816260d-2ed8-4f11-a4c0-3374cb5f1a01',
          recommended_items: [
            'c7c2ec3b-028a-40ab-ae6f-72fac5171962',
            'c7c2fbb1-4010-4bb3-a688-d399500cfa58',
            'c7c2e4a9-0f51-4eb2-b38b-09eda614551f',
            'c7c2c2fb-0bd2-46c9-938e-a3691cfa681d',
          ],
        },
        {
          id: 'c8169a19-2339-4b47-af5a-55ca266bfea1',
          recommended_items: [
            'c7c2ec3b-028a-40ab-ae6f-72fac5171962',
            'c7c2fbb1-4010-4bb3-a688-d399500cfa58',
            'c7c2e4a9-0f51-4eb2-b38b-09eda614551f',
            'c7c2c2fb-0bd2-46c9-938e-a3691cfa681d',
          ],
        },
      ],
    },
    {
      id: 'c347d35c-a2ea-4409-8c4c-2f2d31f91af5',
      cart_recommendations: [
        'cabbcfe1-1c8a-41ed-a93c-d3dbeebdf0c3',
        'cabbe2d6-aea7-4b11-9f63-78abca11130d',
        'cabbfb18-dc05-4e54-a29a-6f2f06bb4390',
        'cabbf01d-7ba8-4762-b0e1-86e5509bce52',
        'cabbc615-4ae5-48e6-a6b7-5d9640bb79b0',
        'cabbc051-76b4-4646-8963-479fb80bb583',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c9ba0e4c-cc6d-44f8-a4f5-fd38cdeb7d0b',
          recommended_items: [
            'cabbcfe1-1c8a-41ed-a93c-d3dbeebdf0c3',
            'cabbe2d6-aea7-4b11-9f63-78abca11130d',
            'cabbc615-4ae5-48e6-a6b7-5d9640bb79b0',
            'cabbfb18-dc05-4e54-a29a-6f2f06bb4390',
          ],
        },
        {
          id: 'c9ba0c02-d492-48e6-96a7-243e97d5a1a1',
          recommended_items: [
            'cabbcfe1-1c8a-41ed-a93c-d3dbeebdf0c3',
            'cabbe2d6-aea7-4b11-9f63-78abca11130d',
            'cabbc615-4ae5-48e6-a6b7-5d9640bb79b0',
            'cabbfb18-dc05-4e54-a29a-6f2f06bb4390',
          ],
        },
        {
          id: 'c9ba3378-573e-4d3f-8695-92bd935abcc6',
          recommended_items: [
            'cabbcfe1-1c8a-41ed-a93c-d3dbeebdf0c3',
            'cabbe2d6-aea7-4b11-9f63-78abca11130d',
            'cabbc615-4ae5-48e6-a6b7-5d9640bb79b0',
            'cabbfb18-dc05-4e54-a29a-6f2f06bb4390',
          ],
        },
        {
          id: 'c9ba0c47-8dae-4b77-b7ca-160c55710217',
          recommended_items: [
            'cabbcfe1-1c8a-41ed-a93c-d3dbeebdf0c3',
            'cabbe2d6-aea7-4b11-9f63-78abca11130d',
            'cabbc615-4ae5-48e6-a6b7-5d9640bb79b0',
            'cabbfb18-dc05-4e54-a29a-6f2f06bb4390',
          ],
        },
        {
          id: 'c9ba08b3-4334-43ff-b883-d3f0d8858d8d',
          recommended_items: [
            'cabbcfe1-1c8a-41ed-a93c-d3dbeebdf0c3',
            'cabbe2d6-aea7-4b11-9f63-78abca11130d',
            'cabbc615-4ae5-48e6-a6b7-5d9640bb79b0',
            'cabbfb18-dc05-4e54-a29a-6f2f06bb4390',
          ],
        },
        {
          id: 'c9ba27dc-d714-415e-9711-d23997c20c63',
          recommended_items: [
            'cabbcfe1-1c8a-41ed-a93c-d3dbeebdf0c3',
            'cabbe2d6-aea7-4b11-9f63-78abca11130d',
            'cabbc615-4ae5-48e6-a6b7-5d9640bb79b0',
            'cabbfb18-dc05-4e54-a29a-6f2f06bb4390',
          ],
        },
        {
          id: 'c9ba3d65-b8d4-4050-a8c9-5c51c7fe4e90',
          recommended_items: [
            'cabbcfe1-1c8a-41ed-a93c-d3dbeebdf0c3',
            'cabbe2d6-aea7-4b11-9f63-78abca11130d',
            'cabbc615-4ae5-48e6-a6b7-5d9640bb79b0',
            'cabbfb18-dc05-4e54-a29a-6f2f06bb4390',
          ],
        },
        {
          id: 'c9ba0062-2722-41f7-82f2-2eda9b38a327',
          recommended_items: [
            'cabbcfe1-1c8a-41ed-a93c-d3dbeebdf0c3',
            'cabbe2d6-aea7-4b11-9f63-78abca11130d',
            'cabbc615-4ae5-48e6-a6b7-5d9640bb79b0',
            'cabbfb18-dc05-4e54-a29a-6f2f06bb4390',
          ],
        },
      ],
    },
    {
      id: '8ac66318-9ae1-405e-b39e-f307cd9bd5a0',
      cart_recommendations: [
        'c7c27d92-20e4-40e3-8a41-9e937f91f77f',
        'c7c25d63-b557-4cf3-b6c1-42358d1f2e52',
        'c7c25306-946f-494f-bc58-f8e01ecd982e',
        'c7c245a9-d524-424c-bfc0-c3612ac32c61',
        'c7c94f7d-6f3c-49e0-9ecd-4af97b1adf08',
        'c7c951ca-93f9-47ff-a34f-3d640ab78c33',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c7c27e35-bf96-4477-8f19-6313a5fa540a',
          recommended_items: [
            'c7c24da7-3201-48a9-adad-9352ec19d7d9',
            'c7c25d63-b557-4cf3-b6c1-42358d1f2e52',
            'c7c94f7d-6f3c-49e0-9ecd-4af97b1adf08',
            'c7c25306-946f-494f-bc58-f8e01ecd982e',
          ],
        },
        {
          id: 'c86e1f96-5f9e-49ae-a3d8-888949a63cc1',
          recommended_items: [
            'c7c24da7-3201-48a9-adad-9352ec19d7d9',
            'c7c25d63-b557-4cf3-b6c1-42358d1f2e52',
            'c7c94f7d-6f3c-49e0-9ecd-4af97b1adf08',
            'c7c25306-946f-494f-bc58-f8e01ecd982e',
          ],
        },
        {
          id: 'c7c25d79-5b24-4c58-8b5c-eca236002952',
          recommended_items: [
            'c7c24da7-3201-48a9-adad-9352ec19d7d9',
            'c7c25d63-b557-4cf3-b6c1-42358d1f2e52',
            'c7c94f7d-6f3c-49e0-9ecd-4af97b1adf08',
            'c7c25306-946f-494f-bc58-f8e01ecd982e',
          ],
        },
        {
          id: 'c7c24bcb-1ed3-4d9e-9e3d-d02a1d37a93f',
          recommended_items: [
            'c7c24da7-3201-48a9-adad-9352ec19d7d9',
            'c7c25d63-b557-4cf3-b6c1-42358d1f2e52',
            'c7c94f7d-6f3c-49e0-9ecd-4af97b1adf08',
            'c7c25306-946f-494f-bc58-f8e01ecd982e',
          ],
        },
        {
          id: 'c7c2428e-3e41-4537-862a-4b76427aab1a',
          recommended_items: [
            'c7c24da7-3201-48a9-adad-9352ec19d7d9',
            'c7c25d63-b557-4cf3-b6c1-42358d1f2e52',
            'c7c94f7d-6f3c-49e0-9ecd-4af97b1adf08',
            'c7c25306-946f-494f-bc58-f8e01ecd982e',
          ],
        },
        {
          id: 'c7c2425c-0193-40c7-9e61-ae32bba56ce5',
          recommended_items: [
            'c7c24da7-3201-48a9-adad-9352ec19d7d9',
            'c7c25d63-b557-4cf3-b6c1-42358d1f2e52',
            'c7c94f7d-6f3c-49e0-9ecd-4af97b1adf08',
            'c7c25306-946f-494f-bc58-f8e01ecd982e',
          ],
        },
        {
          id: 'c7c2442d-93fd-4a26-a197-d69844e84edd',
          recommended_items: [
            'c7c24da7-3201-48a9-adad-9352ec19d7d9',
            'c7c25d63-b557-4cf3-b6c1-42358d1f2e52',
            'c7c94f7d-6f3c-49e0-9ecd-4af97b1adf08',
            'c7c25306-946f-494f-bc58-f8e01ecd982e',
          ],
        },
        {
          id: 'c7c2742c-28a8-4a14-99ab-7fd7b8676362',
          recommended_items: [
            'c7c24da7-3201-48a9-adad-9352ec19d7d9',
            'c7c25d63-b557-4cf3-b6c1-42358d1f2e52',
            'c7c94f7d-6f3c-49e0-9ecd-4af97b1adf08',
            'c7c25306-946f-494f-bc58-f8e01ecd982e',
          ],
        },
        {
          id: 'c7c25080-e829-4aa0-b4e0-41e211872969',
          recommended_items: [
            'c7c24da7-3201-48a9-adad-9352ec19d7d9',
            'c7c25d63-b557-4cf3-b6c1-42358d1f2e52',
            'c7c94f7d-6f3c-49e0-9ecd-4af97b1adf08',
            'c7c25306-946f-494f-bc58-f8e01ecd982e',
          ],
        },
        {
          id: 'c7c2625d-e583-4a3b-a2aa-20b0ed67e8ca',
          recommended_items: [
            'c7c24da7-3201-48a9-adad-9352ec19d7d9',
            'c7c25d63-b557-4cf3-b6c1-42358d1f2e52',
            'c7c94f7d-6f3c-49e0-9ecd-4af97b1adf08',
            'c7c25306-946f-494f-bc58-f8e01ecd982e',
          ],
        },
        {
          id: 'c7c258e1-671e-441c-be12-b5e99f971915',
          recommended_items: [
            'c7c24da7-3201-48a9-adad-9352ec19d7d9',
            'c7c25d63-b557-4cf3-b6c1-42358d1f2e52',
            'c7c94f7d-6f3c-49e0-9ecd-4af97b1adf08',
            'c7c25306-946f-494f-bc58-f8e01ecd982e',
          ],
        },
        {
          id: 'c7c27e35-bf96-4477-8f19-6313a5fa540a',
          recommended_items: [
            'c7c24da7-3201-48a9-adad-9352ec19d7d9',
            'c7c25d63-b557-4cf3-b6c1-42358d1f2e52',
            'c7c94f7d-6f3c-49e0-9ecd-4af97b1adf08',
            'c7c25306-946f-494f-bc58-f8e01ecd982e',
          ],
        },
      ],
    },
    {
      id: '7dfdf329-80eb-4a1d-bc68-6e536e6fe398',
      cart_recommendations: [
        'c685f638-b9a1-4934-a2f5-3f3f45cd0b82',
        'c685d843-cae9-4c15-8aa8-b415dea799f7',
        'c6c830c1-c31a-4931-9fde-8e9e3faa6973',
      ],
      menu_item: [
        {
          id: 'c4c3a411-81f5-431c-99ab-1b7937b1eaf9',
          recommended_items: [
            'c82340f0-354c-411d-88a2-0ee99638bb44',
            'c5047f59-61cf-4dc8-b726-d7fc69798108',
            'c4c3aef8-a166-44ec-bcb3-3bfc93a4be9d',
          ],
        },
        {
          id: 'c82340f0-354c-411d-88a2-0ee99638bb44',
          recommended_items: [
            'c39d6297-f292-4a04-8023-3e968aa49b86',
            'c4c3aef8-a166-44ec-bcb3-3bfc93a4be9d',
            'c39d60f4-c4a4-4901-8c5d-3543e7a361cb',
          ],
        },
        {
          id: 'c4c3aef8-a166-44ec-bcb3-3bfc93a4be9d',
          recommended_items: [
            'c82340f0-354c-411d-88a2-0ee99638bb44',
            'c39d6297-f292-4a04-8023-3e968aa49b86',
            'c4c3aef8-a166-44ec-bcb3-3bfc93a4be9d',
          ],
        },
        {
          id: 'c39d7b40-ae69-48b5-a162-8ec6dad8a329',
          recommended_items: [
            'c5047f59-61cf-4dc8-b726-d7fc69798108',
            'c4c3aef8-a166-44ec-bcb3-3bfc93a4be9d',
            'c4c3a411-81f5-431c-99ab-1b7937b1eaf9',
          ],
        },
        {
          id: 'c4c38c6c-3ff2-459a-97fd-937e927e7126',
          recommended_items: [
            'c5047f59-61cf-4dc8-b726-d7fc69798108',
            'c4c3aef8-a166-44ec-bcb3-3bfc93a4be9d',
            'c39d6297-f292-4a04-8023-3e968aa49b86',
          ],
        },
        {
          id: 'c79f235d-5dba-4bbd-9eb2-5fa2bb85c118',
          recommended_items: [
            'c5047f59-61cf-4dc8-b726-d7fc69798108',
            'c4c3aef8-a166-44ec-bcb3-3bfc93a4be9d',
            'c4c3a411-81f5-431c-99ab-1b7937b1eaf9',
          ],
        },
        {
          id: 'c7936e3f-0ac6-4ac1-ac66-cc1965d3d1de',
          recommended_items: [
            'c5047f59-61cf-4dc8-b726-d7fc69798108',
            'c4c3aef8-a166-44ec-bcb3-3bfc93a4be9d',
            'c4c3a411-81f5-431c-99ab-1b7937b1eaf9',
          ],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: ['', '', ''],
        },
      ],
    },
    {
      id: 'b8cf0c41-f7cd-4295-ba81-9d8df4b5530c',
      cart_recommendations: [
        'c34f65aa-f8ef-45aa-bc72-b854db0d5d19',
        'c34f54ab-9509-4ea7-b8cf-5a6ee4b4df38',
        'c34f7c41-031d-446c-9f33-71a6a02c304d',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c34f50f5-b9b1-4475-a95b-b929e01f0e9d',
          recommended_items: ['c34f65aa-f8ef-45aa-bc72-b854db0d5d19', 'c34f54ab-9509-4ea7-b8cf-5a6ee4b4df38'],
        },
        {
          id: 'c34f57ac-ba4f-4adf-bcdf-44c8f529d604',
          recommended_items: ['c34f65aa-f8ef-45aa-bc72-b854db0d5d19', 'c34f54ab-9509-4ea7-b8cf-5a6ee4b4df38'],
        },
      ],
    },
    {
      id: 'a3c8e9df-a1b8-455c-90dd-3935f17f6116',
      cart_recommendations: [
        'c4507863-5610-4352-9254-cddd27b41e2b',
        'c4504427-c55a-4100-9289-13fa4410714e',
        'c450505a-081b-4455-a38c-79e5a520fd46',
        'c45054e9-01fc-4314-ab7a-4fe27223a6b3',
        'c4504001-c327-4ac1-a4ea-b8bb3acb3578',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c4505ac9-f697-472e-aeae-898dfba4ddea',
          recommended_items: [
            'c450505a-081b-4455-a38c-79e5a520fd46',
            'c45051a7-9b51-4b36-8ab5-990f9684d30b',
            'c4504e77-c01f-46e7-be7b-559bb0e0d5b3',
            'c45073d2-dcf6-4562-85b6-a3b8252556af',
            'c4506af4-cca2-4701-93b6-552c163d9bba',
          ],
        },
        {
          id: 'c450597d-e6ca-4f63-9f7a-fb8543a07031',
          recommended_items: [
            'c450505a-081b-4455-a38c-79e5a520fd46',
            'c45051a7-9b51-4b36-8ab5-990f9684d30b',
            'c4504e77-c01f-46e7-be7b-559bb0e0d5b3',
            'c45073d2-dcf6-4562-85b6-a3b8252556af',
            'c4506af4-cca2-4701-93b6-552c163d9bba',
          ],
        },
      ],
    },
    {
      id: 'd9bc10ae-4883-49ab-b643-2d4a282ef256',
      cart_recommendations: [
        'c5cbecf1-1593-4fd8-9ad2-99334f759b4a',
        'c5cbcf21-6e83-4704-91ca-3fb9de5f157c',
        'c5cbd73d-1273-414e-a9b4-f54b000ba9c5',
        'c5cbf1ab-aaea-4f5f-ae98-823b641d3423',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c5cbd828-8e60-4b1b-a4d4-78ceebb704ed',
          recommended_items: [
            'c5cbecf1-1593-4fd8-9ad2-99334f759b4a',
            'c5cbcf21-6e83-4704-91ca-3fb9de5f157c',
            'c5cbd73d-1273-414e-a9b4-f54b000ba9c5',
            'c5cbf1ab-aaea-4f5f-ae98-823b641d3423',
          ],
        },
        {
          id: 'c5cbff72-8b71-4652-afc9-cf2426678b3d',
          recommended_items: [
            'c5cbecf1-1593-4fd8-9ad2-99334f759b4a',
            'c5cbcf21-6e83-4704-91ca-3fb9de5f157c',
            'c5cbd73d-1273-414e-a9b4-f54b000ba9c5',
            'c5cbf1ab-aaea-4f5f-ae98-823b641d3423',
          ],
        },
        {
          id: 'c5cbd359-9c69-406b-b63a-7c8847ee50bf',
          recommended_items: [
            'c5cbecf1-1593-4fd8-9ad2-99334f759b4a',
            'c5cbcf21-6e83-4704-91ca-3fb9de5f157c',
            'c5cbd73d-1273-414e-a9b4-f54b000ba9c5',
            'c5cbf1ab-aaea-4f5f-ae98-823b641d3423',
          ],
        },
      ],
    },
    {
      id: 'f755a2be-893b-4b2b-b6ba-552228c1cceb',
      cart_recommendations: [
        'c70f6235-e6a4-4fe8-a3e8-1c05ba6417a5',
        'c70f4dec-df1b-4dae-9d0e-b11b93b1e35f',
        'c70f5797-e712-4620-8978-56e476f3c546',
        'c70f7765-5240-410a-a566-9656dedcebc1',
        'c70f4853-f5f5-4401-a20f-aad413f1980c',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c70f5454-58df-4576-b212-ed1aef20e0e4',
          recommended_items: [
            'c70f6235-e6a4-4fe8-a3e8-1c05ba6417a5',
            'c70f4dec-df1b-4dae-9d0e-b11b93b1e35f',
            'c70f5797-e712-4620-8978-56e476f3c546',
            'c70f7765-5240-410a-a566-9656dedcebc1',
            'c70f4853-f5f5-4401-a20f-aad413f1980c',
          ],
        },
        {
          id: 'c70f5442-03ae-4336-94b9-6c7c095a0b84',
          recommended_items: [
            'c70f6235-e6a4-4fe8-a3e8-1c05ba6417a5',
            'c70f4dec-df1b-4dae-9d0e-b11b93b1e35f',
            'c70f5797-e712-4620-8978-56e476f3c546',
            'c70f7765-5240-410a-a566-9656dedcebc1',
            'c70f4853-f5f5-4401-a20f-aad413f1980c',
          ],
        },
        {
          id: 'c70f67ed-f1e1-483b-b880-bc54954d79e6',
          recommended_items: [
            'c70f6235-e6a4-4fe8-a3e8-1c05ba6417a5',
            'c70f4dec-df1b-4dae-9d0e-b11b93b1e35f',
            'c70f5797-e712-4620-8978-56e476f3c546',
            'c70f7765-5240-410a-a566-9656dedcebc1',
            'c70f4853-f5f5-4401-a20f-aad413f1980c',
          ],
        },
      ],
    },
    {
      id: 'bba1c325-b3d3-4825-a8eb-c07c7372500a',
      cart_recommendations: [
        'c37e3be5-81f4-4329-bda3-4d39b94196b9',
        'c37e1d85-e17f-4338-a4f4-df888215960f',
        'c37e3c78-988f-4f62-8e9e-f7b46366b9e1',
        'c37e327c-ea4b-4201-906d-868c04425a28',
        'c37e0964-f21c-4def-a313-b153575e51ab',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c37e087e-c9e9-4589-b39c-60ddd2d29256',
          recommended_items: [
            'c37e3be5-81f4-4329-bda3-4d39b94196b9',
            'c37e1d85-e17f-4338-a4f4-df888215960f',
            'c37e3c78-988f-4f62-8e9e-f7b46366b9e1',
            'c37e327c-ea4b-4201-906d-868c04425a28',
            'c37e0964-f21c-4def-a313-b153575e51ab',
          ],
        },
        {
          id: 'c42be325-01e8-4fca-ad8e-1743b7a993d1',
          recommended_items: [
            'c37e3be5-81f4-4329-bda3-4d39b94196b9',
            'c37e1d85-e17f-4338-a4f4-df888215960f',
            'c37e3c78-988f-4f62-8e9e-f7b46366b9e1',
            'c37e327c-ea4b-4201-906d-868c04425a28',
            'c37e0964-f21c-4def-a313-b153575e51ab',
          ],
        },
        {
          id: 'c37e3fa5-31da-4bcc-8ebf-b488d2b54dcd',
          recommended_items: [
            'c37e3be5-81f4-4329-bda3-4d39b94196b9',
            'c37e1d85-e17f-4338-a4f4-df888215960f',
            'c37e3c78-988f-4f62-8e9e-f7b46366b9e1',
            'c37e327c-ea4b-4201-906d-868c04425a28',
            'c37e0964-f21c-4def-a313-b153575e51ab',
          ],
        },
        {
          id: 'c37e0cf9-2fd9-45cd-a033-21ce7eb978a5',
          recommended_items: [
            'c37e3be5-81f4-4329-bda3-4d39b94196b9',
            'c37e1d85-e17f-4338-a4f4-df888215960f',
            'c37e3c78-988f-4f62-8e9e-f7b46366b9e1',
            'c37e327c-ea4b-4201-906d-868c04425a28',
            'c37e0964-f21c-4def-a313-b153575e51ab',
          ],
        },
        {
          id: 'c37e1f20-2d93-4372-a57a-481bbe77abd6',
          recommended_items: [
            'c37e3be5-81f4-4329-bda3-4d39b94196b9',
            'c37e1d85-e17f-4338-a4f4-df888215960f',
            'c37e3c78-988f-4f62-8e9e-f7b46366b9e1',
            'c37e327c-ea4b-4201-906d-868c04425a28',
            'c37e0964-f21c-4def-a313-b153575e51ab',
          ],
        },
      ],
    },
    {
      id: '70dff0fc-5bba-4b68-b587-a5fca0b4fa20',
      cart_recommendations: [
        'c37e454c-2257-4081-beca-54641c8c8b6a',
        'c37e4754-1f11-4c88-9178-3b9bf729b981',
        'c37e784e-9596-44e3-9bf8-85bd06a5d6e2',
        'c37e5765-5d8b-4a79-ac9c-10618a2f5b0f',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c37e7302-1a53-405c-9088-543cde01ca47',
          recommended_items: [
            'c37e454c-2257-4081-beca-54641c8c8b6a',
            'c37e71cc-52b7-43d9-9bdb-da9406b1bfe8',
            'c37e604d-e1ac-4d5c-9a8d-4f2fa2d13ad0',
          ],
        },
      ],
    },
    {
      id: '486e2f10-58f9-4b82-9ee7-e006d559276f',
      cart_recommendations: [
        'c37ee9ff-c4e5-4464-b515-aaa172213b43',
        'c5c45ad0-a653-47ec-a43d-979057c2686f',
        'c37ed438-2b69-4b88-9927-1db40cd4dd76',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c37ef705-d631-4652-9638-aff5004af1e4',
          recommended_items: [
            'c37ee9ff-c4e5-4464-b515-aaa172213b43',
            'c5c45ad0-a653-47ec-a43d-979057c2686f',
            'c37ed438-2b69-4b88-9927-1db40cd4dd76',
          ],
        },
        {
          id: 'c37ec8ce-b75a-4c7b-aaf7-52dc32a4a767',
          recommended_items: [
            'c37ee9ff-c4e5-4464-b515-aaa172213b43',
            'c5c45ad0-a653-47ec-a43d-979057c2686f',
            'c37ed438-2b69-4b88-9927-1db40cd4dd76',
          ],
        },
        {
          id: 'c37eda4f-2f96-4b02-bdd3-0680d76bee10',
          recommended_items: [
            'c37ee9ff-c4e5-4464-b515-aaa172213b43',
            'c5c45ad0-a653-47ec-a43d-979057c2686f',
            'c37ed438-2b69-4b88-9927-1db40cd4dd76',
          ],
        },
      ],
    },
    {
      id: 'e66ccc54-e5c5-4c34-bb7e-9d6a81039f02',
      cart_recommendations: ['', ''],
      menu_item: [
        {
          id: 'c2e3254b-5b5c-4d4b-bed9-13e3f28bed1e',
          recommended_items: ['c2e30dc1-e58e-480e-b370-faa744ceb9f5', 'c3d843d5-210a-48be-b473-d44318d3dd10'],
        },
        {
          id: 'c2e3266e-f94c-4386-b1ce-07cec420ff6f',
          recommended_items: ['c2e30dc1-e58e-480e-b370-faa744ceb9f5', 'c3d843d5-210a-48be-b473-d44318d3dd10'],
        },
        {
          id: 'c2e30e32-7a1c-45f3-a9ec-e3cccbcad477',
          recommended_items: ['c2e30dc1-e58e-480e-b370-faa744ceb9f5', 'c3d843d5-210a-48be-b473-d44318d3dd10'],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: ['', '', ''],
        },
      ],
    },
    {
      id: '3023d624-6aa6-4549-9b23-41487a109dba',
      cart_recommendations: ['', ''],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c794331f-01e7-417f-adda-663799af5861',
          recommended_items: [
            'b8568a19-f0fd-4231-a79e-dfcbbbe67d68',
            'b856812b-0947-4c49-940f-9e77e9667d2d',
            'b8569599-0f9a-49f8-8126-263dad90a3ef',
            'c79402fe-2312-4f49-b7c8-0f8ee1607926',
            'c7942441-7960-477b-92d3-32ef5e3c408c',
          ],
        },
        {
          id: 'c79416cf-aa72-4f31-bf57-3ff26faadb36',
          recommended_items: [
            'c79406ba-f0ec-4c2f-ad67-643a0c281f1f',
            'c7dfb6e2-8059-4998-84e2-e85367f8702e',
            'c7943af8-16cb-4f5e-b36c-7bed6c33109d',
            'c7941682-f6c9-4e54-9188-83e266baca76',
            'c7943f06-e93d-425a-ba52-ac203672ff3e',
          ],
        },
        {
          id: 'c794024c-bca3-4171-ad05-beb41ea38542',
          recommended_items: [
            'c794393b-e75c-4346-aee9-4fb1970afdbb',
            'c7940d6d-7563-45d6-9859-d50ba591ce85',
            'c7943af8-16cb-4f5e-b36c-7bed6c33109d',
            'c7941682-f6c9-4e54-9188-83e266baca76',
            'c7943f06-e93d-425a-ba52-ac203672ff3e',
          ],
        },
      ],
    },
    {
      id: 'e5e76efb-6ef3-4dc5-b2d9-f3b77501384d',
      cart_recommendations: [
        'c00c2265-9993-42e0-b610-8c8c11aef8d1',
        'c885ca6e-0371-4509-b3c6-c881ac010530',
        'c03fe126-94a5-4ddf-943d-a52fcd1ffb4f',
        'c027f074-0821-4ba7-8237-718153fa3f51',
        'c03fd6d3-1756-4c52-982c-19add9ba7ab5',
        'c03ff0b8-0575-44ab-a91a-77a37bd373a6',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c906e19b-7d45-4beb-b42e-e4aac9a6fbc0',
          recommended_items: [
            'c03fd6d3-1756-4c52-982c-19add9ba7ab5',
            'c027f074-0821-4ba7-8237-718153fa3f51',
            'c885d569-b5cf-4929-9c91-04b63306f6cc',
            'c03fe126-94a5-4ddf-943d-a52fcd1ffb4f',
          ],
        },
        {
          id: 'c906edfd-9345-4d33-abb8-a865ed92b2da',
          recommended_items: [
            'c03fd6d3-1756-4c52-982c-19add9ba7ab5',
            'c027f074-0821-4ba7-8237-718153fa3f51',
            'c885d569-b5cf-4929-9c91-04b63306f6cc',
            'c03fe126-94a5-4ddf-943d-a52fcd1ffb4f',
          ],
        },
        {
          id: 'c906dffe-0170-48fe-a16f-a7fb597f4247',
          recommended_items: [
            'c03fd6d3-1756-4c52-982c-19add9ba7ab5',
            'c027f074-0821-4ba7-8237-718153fa3f51',
            'c885d569-b5cf-4929-9c91-04b63306f6cc',
            'c03fe126-94a5-4ddf-943d-a52fcd1ffb4f',
          ],
        },
        {
          id: 'c027cfdc-40b7-4aca-9f44-c7c7c46fda7b',
          recommended_items: [
            'c03fd6d3-1756-4c52-982c-19add9ba7ab5',
            'c027f074-0821-4ba7-8237-718153fa3f51',
            'c885d569-b5cf-4929-9c91-04b63306f6cc',
            'c03fe126-94a5-4ddf-943d-a52fcd1ffb4f',
          ],
        },
        {
          id: 'c027c851-7bcb-433e-a14b-67261b64c9d9',
          recommended_items: [
            'c03fd6d3-1756-4c52-982c-19add9ba7ab5',
            'c027f074-0821-4ba7-8237-718153fa3f51',
            'c885d569-b5cf-4929-9c91-04b63306f6cc',
            'c03fe126-94a5-4ddf-943d-a52fcd1ffb4f',
          ],
        },
        {
          id: 'c027f1a0-4caa-4c30-952a-5e7779867253',
          recommended_items: [
            'c03fd6d3-1756-4c52-982c-19add9ba7ab5',
            'c027f074-0821-4ba7-8237-718153fa3f51',
            'c885d569-b5cf-4929-9c91-04b63306f6cc',
            'c03fe126-94a5-4ddf-943d-a52fcd1ffb4f',
          ],
        },
        {
          id: 'c027ed9c-d28f-4f8d-9be3-23a56da7ac29',
          recommended_items: [
            'c03fd6d3-1756-4c52-982c-19add9ba7ab5',
            'c027f074-0821-4ba7-8237-718153fa3f51',
            'c885d569-b5cf-4929-9c91-04b63306f6cc',
            'c03fe126-94a5-4ddf-943d-a52fcd1ffb4f',
          ],
        },
        {
          id: 'c027dc56-a108-48af-908b-3026ca2d654d',
          recommended_items: [
            'c03fd6d3-1756-4c52-982c-19add9ba7ab5',
            'c027f074-0821-4ba7-8237-718153fa3f51',
            'c885d569-b5cf-4929-9c91-04b63306f6cc',
            'c03fe126-94a5-4ddf-943d-a52fcd1ffb4f',
          ],
        },
        {
          id: 'c027c03a-bde3-4848-8df4-92072a237883',
          recommended_items: [
            'c00c24a1-c766-47bb-8265-9d2acda8427f',
            'c00c2265-9993-42e0-b610-8c8c11aef8d1',
            'c027dd9c-d392-4bc8-b6c8-0a6701e08e92',
            'c03fe126-94a5-4ddf-943d-a52fcd1ffb4f',
          ],
        },
      ],
    },
    {
      id: '14388366-3b98-439b-8286-474af69ff544',
      cart_recommendations: [
        'be4481cb-92cd-4a4a-b711-3ad52ba5fb80',
        'be44acbe-bd21-425e-8624-ea8d1f0b938d',
        'bee86ecd-b613-44bd-8497-c6e71c7ddb56',
        'c5a14339-3b4f-466f-8c53-be68c1d2d70f',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'be44bf0a-86cf-41ec-8f22-addc1f787a4d',
          recommended_items: [
            'be44a065-ceb1-41b8-9703-2ac81646cacc',
            'be44b01e-6340-4f6a-b649-fa6d1fd5fda9',
            'be44881d-b615-4f2d-a88b-7f1a4265da0e',
            'be4489e8-2b46-4e7f-8a1c-9e6fe9665ed4',
            'be44a67a-5f56-40e3-a55f-5dddfe65d1bc',
          ],
        },
        {
          id: 'be44ad82-266f-4a13-aa0b-311bdd7e5cc4',
          recommended_items: [
            'be44a065-ceb1-41b8-9703-2ac81646cacc',
            'be44b01e-6340-4f6a-b649-fa6d1fd5fda9',
            'be44881d-b615-4f2d-a88b-7f1a4265da0e',
            'be4489e8-2b46-4e7f-8a1c-9e6fe9665ed4',
            'be44a67a-5f56-40e3-a55f-5dddfe65d1bc',
          ],
        },
      ],
    },
    {
      id: '18080ea5-114b-42e2-a3d8-f935886270a0',
      cart_recommendations: ['', ''],
      menu_item: [
        {
          id: 'c3efbde7-63ee-4578-8c02-290e7afbd82f',
          recommended_items: ['c3ef8abd-90c9-4e3f-a075-60fc26c5f07c', 'c3efbc38-601b-4f0a-bd76-45670ee61f2f'],
        },
        {
          id: 'c3ef9bfa-eace-4498-a6a9-e220120f7921',
          recommended_items: ['c3ef8abd-90c9-4e3f-a075-60fc26c5f07c', 'c3efbc38-601b-4f0a-bd76-45670ee61f2f'],
        },
        {
          id: 'c3efa7fc-e782-42aa-bff6-7143784abb25',
          recommended_items: [
            'c3ef8abd-90c9-4e3f-a075-60fc26c5f07c',
            'c3efbc38-601b-4f0a-bd76-45670ee61f2f',
            'c3efbe45-4a81-4a0d-9ab6-06b1cfbcc6e8',
          ],
        },
      ],
      menu_heading: [
        {
          id: 'c3efba36-0e3c-4d5b-9aed-401b41647fa1',
          recommended_items: [
            'c3ef9adc-e288-4a91-a074-af0e7063b812',
            'c3ef9f0d-2cf2-4ba0-9ba2-3bcea10b88cb',
            'c3ef98e1-d0de-4358-95ae-18ed3a37f45b',
            'c3ef8abd-90c9-4e3f-a075-60fc26c5f07c',
            'c3efbc38-601b-4f0a-bd76-45670ee61f2f',
            'c3efbe45-4a81-4a0d-9ab6-06b1cfbcc6e8',
          ],
        },
        {
          id: 'c3ef97ad-e9b1-458a-aa9c-22a512229f5c',
          recommended_items: [
            'c3ef9adc-e288-4a91-a074-af0e7063b812',
            'c3ef9f0d-2cf2-4ba0-9ba2-3bcea10b88cb',
            'c3ef98e1-d0de-4358-95ae-18ed3a37f45b',
          ],
        },
      ],
    },
    {
      id: '628839e5-589d-48f3-928a-a6885a4735f5',
      cart_recommendations: [
        'c3aa23a9-22b8-4169-beac-bd386d22e0e1',
        'c3b4eb98-2078-48e4-a0a6-57a14384045a',
        'c3aa242a-ad8c-4c9b-a8f3-f24b2bb4f572',
        'c3b4d5d3-51c4-4e84-962c-1e76e6824617',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: ['', '', ''],
        },
      ],
    },
    {
      id: 'c45c4c30-7da7-4660-aa84-01c3a450b873',
      cart_recommendations: [
        'c0afc02a-73f3-4434-a46f-cb6cf101f4ad',
        'c0afdc2a-0692-4f0f-bb04-1fe1c3a53c7e',
        'c0f7a7dc-de19-4358-a40d-da58d1c44a7f',
        'c0afc8e2-5a41-42e9-8953-63094e1f26bd',
        'c0afed5e-cd1a-4caa-9712-c019faee164d',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: ['', '', ''],
        },
      ],
    },
    {
      id: '47deb3c4-2385-4049-a404-b79b1c9130bf',
      cart_recommendations: [
        'bec967cd-bd4f-478b-832c-7b6d2b091a8e',
        'bec947c4-a303-42a7-b28a-0fdd5b18dfaf',
        'bec96b7b-8eb9-442b-84a8-3ca3016a5e96',
        'bec971ff-9f3f-4b66-ada8-3b471718a176',
        'bec975bf-81fa-4f6c-9b12-9c8289a029c5',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'bec96f74-011c-4bea-a68d-379998963cb0',
          recommended_items: [
            'bec975bf-81fa-4f6c-9b12-9c8289a029c5',
            'bec9524d-762c-49d2-870c-0e8aa5549900',
            'bec9459f-35a0-4651-9b20-843a3a3c1b69',
            'bec9782c-3886-480b-b882-276c169657be',
            'bec96eed-f37a-427d-b253-d3e4c34d2ce9',
            'bec94f51-c7e9-4b87-98d1-67e2ea3b6221',
          ],
        },
      ],
    },
    {
      id: '72f2171c-8f9f-4655-a0ab-545723e4307e',
      cart_recommendations: [
        'c2a84a73-35ea-4cb4-ae71-18190ba389d2',
        'bcd6d797-b90a-41c0-b143-1a5704f77118',
        'c2a87525-dd9a-4ed1-b5e7-f590528cfb22',
        'bcd6d2f2-415d-44bd-8d3a-11e3488b32aa',
        'bcd6fdc4-ab3d-48c4-b124-171b4ff34a26',
        'c2a8198b-0ea1-4ebf-ac0f-c2b689bd3ff3',
        'c2a80cb0-90c3-41bc-8811-b1182874f81f',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c2a80302-2dbd-4824-925c-9d631c2ea3b2',
          recommended_items: [
            'c2a87525-dd9a-4ed1-b5e7-f590528cfb22',
            'bcd6d2f2-415d-44bd-8d3a-11e3488b32aa',
            'bcd6d797-b90a-41c0-b143-1a5704f77118',
          ],
        },
        {
          id: 'c2a80d46-3a2b-4d6c-b707-fca3f019f5b6',
          recommended_items: [
            'c2a87525-dd9a-4ed1-b5e7-f590528cfb22',
            'bcd6d2f2-415d-44bd-8d3a-11e3488b32aa',
            'bcd6d797-b90a-41c0-b143-1a5704f77118',
          ],
        },
        {
          id: 'c2a81e21-fe6a-4e03-bd84-00ffd8677560',
          recommended_items: [
            'c2a87525-dd9a-4ed1-b5e7-f590528cfb22',
            'bcd6d2f2-415d-44bd-8d3a-11e3488b32aa',
            'bcd6d797-b90a-41c0-b143-1a5704f77118',
          ],
        },
        {
          id: 'c2a83aa9-865d-4237-9f14-102f72f3ce68',
          recommended_items: [
            'c2a87525-dd9a-4ed1-b5e7-f590528cfb22',
            'bcd6d2f2-415d-44bd-8d3a-11e3488b32aa',
            'bcd6d797-b90a-41c0-b143-1a5704f77118',
          ],
        },
      ],
    },
    {
      id: '',
      cart_recommendations: [
        'c57da438-e932-436b-b269-fae78a73ddd0',
        'c57d876b-3bcc-4cdb-a3e6-e247251cc150',
        'c57d8f27-a56d-4360-8ce8-3eee20c9a5cf',
        'c57daeff-e96d-47fd-8273-9c682ed7898f',
        'c57d8db4-668c-4815-912f-c43435246337',
        'c57d876b-3bcc-4cdb-a3e6-e247251cc150',
        'c7740908-94c3-42d4-9029-302a77102116',
      ],
      menu_item: [
        {
          id: 'c57dac1c-429f-490d-8aa5-fea8120ab5dd',
          recommended_items: [
            'c57daeff-e96d-47fd-8273-9c682ed7898f',
            'c57d8db4-668c-4815-912f-c43435246337',
            'c57db63c-ebc6-41c7-8e47-9f72c020a57c',
            'c7740908-94c3-42d4-9029-302a77102116',
          ],
        },
      ],
      menu_heading: [
        {
          id: 'c57d9216-d05c-477c-90e6-7ea951533677',
          recommended_items: [
            'c57daeff-e96d-47fd-8273-9c682ed7898f',
            'c57d8db4-668c-4815-912f-c43435246337',
            'c57d876b-3bcc-4cdb-a3e6-e247251cc150',
            'c7740908-94c3-42d4-9029-302a77102116',
          ],
        },
      ],
    },
    {
      id: '1d1151a3-17a5-4040-9006-96ddd79b5389',
      cart_recommendations: [
        'c76ef4c7-8429-46e2-8339-0460bae0be25',
        'c76ee9ee-37b8-43d2-8da7-ba6c15de67c5',
        'c76ec992-08da-40d7-af2f-b3b1a933a62d',
        'c768e729-b689-4ede-9969-16caf12b7919',
        'c768ee8f-426a-4d0a-8674-04b88932664f',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: ['', '', ''],
        },
      ],
    },
    {
      id: 'd7afb4c2-5b36-4b04-bae7-1f322a520083',
      cart_recommendations: [
        'b366cb1f-6541-40c0-a202-3eca5e906cec',
        'b366c456-8691-4f8e-8976-735c2c898afa',
        'b366dba2-23b2-47b8-878f-ac950b52bf71',
        'b369e4c1-5dbe-4e44-95dc-b993bf5ab06d',
        'b369f8af-eba5-4813-b95b-04a4f158ad0e',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'b1fb7c11-a8fb-4d9f-9183-8919876a9dcf',
          recommended_items: [
            'b377076a-1afc-4ffc-9051-b081097dd418',
            'b37734d6-ca32-4479-9295-26e828959939',
            'b37738bd-87c2-4e20-93a7-cfb5e00e87b8',
            'b366cb1f-6541-40c0-a202-3eca5e906cec',
            'b366ec23-73ef-48ad-963d-23a585f4e9c2',
          ],
        },
      ],
    },
    {
      id: '0ba87dbb-5706-4ff5-bc88-946709cfcc63',
      cart_recommendations: [
        'c5a10d1c-9d12-4d4f-bd66-5f409471b500',
        'c5a10318-04f7-4303-aedd-801b9594fd9b',
        'c5a1291a-ccec-4728-997b-227d68a75dff',
        'c5ace35f-c4d5-4842-ae9a-9ec7684585f1',
      ],
      menu_item: [
        {
          id: 'c5a10446-bc58-40ad-8c10-56262dc162a8',
          recommended_items: [
            'c5a13302-0a36-4313-a96f-c8306649a4e6',
            'c5a106f1-9155-4ab8-b072-87073d95b836',
            'c5a10318-04f7-4303-aedd-801b9594fd9b',
            'c5a1291a-ccec-4728-997b-227d68a75dff',
          ],
        },
        {
          id: 'c5a13302-0a36-4313-a96f-c8306649a4e6',
          recommended_items: [
            'c5a10d1c-9d12-4d4f-bd66-5f409471b500',
            'c5a106f1-9155-4ab8-b072-87073d95b836',
            'c5a10318-04f7-4303-aedd-801b9594fd9b',
            'c5a1291a-ccec-4728-997b-227d68a75dff',
          ],
        },
        {
          id: 'c5a10446-bc58-40ad-8c10-56262dc162a8',
          recommended_items: [
            'c5a13302-0a36-4313-a96f-c8306649a4e6',
            'c5a106f1-9155-4ab8-b072-87073d95b836',
            'c5a10318-04f7-4303-aedd-801b9594fd9b',
            'c5a1291a-ccec-4728-997b-227d68a75dff',
          ],
        },
      ],
      menu_heading: [
        {
          id: 'c5a13baa-0dfa-447d-a6ac-bbf5627a2f1d',
          recommended_items: [
            'c5a10d1c-9d12-4d4f-bd66-5f409471b500',
            'c5a10318-04f7-4303-aedd-801b9594fd9b',
            'c5a1291a-ccec-4728-997b-227d68a75dff',
            'c5ace35f-c4d5-4842-ae9a-9ec7684585f1',
          ],
        },
      ],
    },
    {
      id: '6f3fd7b8-3db3-4666-a58e-646154cc7ab9',
      cart_recommendations: [
        'c86dcaf5-4611-44f0-b426-aaeebe175a63',
        'c86dda3f-dfad-4e69-b3f7-dcac898c2745',
        'c885dc1b-9f2a-4a6b-b45f-b8d9a931cead',
        'c8d3c2c3-692e-4be2-b83c-30991b12eef5',
        'c981e9f4-8fd6-4185-bf14-de996ded4b52',
        'c981d427-d589-435f-be55-3c97696dba3f',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'bb272fd5-13f7-4eb0-9feb-fd2488326c70',
          recommended_items: [
            'c981e9f4-8fd6-4185-bf14-de996ded4b52',
            'c8d3c2c3-692e-4be2-b83c-30991b12eef5',
            'c86defa6-849b-4688-802c-2639bb97e0be',
            'c885dc1b-9f2a-4a6b-b45f-b8d9a931cead',
          ],
        },
        {
          id: 'bb273f51-4562-4c27-b218-5528e12e3d90',
          recommended_items: [
            'c981e9f4-8fd6-4185-bf14-de996ded4b52',
            'c8d3c2c3-692e-4be2-b83c-30991b12eef5',
            'c86defa6-849b-4688-802c-2639bb97e0be',
            'c885dc1b-9f2a-4a6b-b45f-b8d9a931cead',
          ],
        },
        {
          id: 'bb27090c-cc64-43e9-a6e0-cf978b561026',
          recommended_items: [
            'c981e9f4-8fd6-4185-bf14-de996ded4b52',
            'c8d3c2c3-692e-4be2-b83c-30991b12eef5',
            'c86defa6-849b-4688-802c-2639bb97e0be',
            'c885dc1b-9f2a-4a6b-b45f-b8d9a931cead',
          ],
        },
        {
          id: 'c86df2d8-3b3b-4933-8214-bd8d47d8f955',
          recommended_items: [
            'c981e9f4-8fd6-4185-bf14-de996ded4b52',
            'c8d3c2c3-692e-4be2-b83c-30991b12eef5',
            'c86defa6-849b-4688-802c-2639bb97e0be',
            'c885dc1b-9f2a-4a6b-b45f-b8d9a931cead',
          ],
        },
        {
          id: 'c86dfc6a-3a6b-482c-bdf2-914a7c727b73',
          recommended_items: [
            'c981e9f4-8fd6-4185-bf14-de996ded4b52',
            'c8d3c2c3-692e-4be2-b83c-30991b12eef5',
            'c86defa6-849b-4688-802c-2639bb97e0be',
            'c885dc1b-9f2a-4a6b-b45f-b8d9a931cead',
          ],
        },
        {
          id: 'bb27310e-9a31-4307-a153-b1548cc30e41',
          recommended_items: [
            'c981e9f4-8fd6-4185-bf14-de996ded4b52',
            'c8d3c2c3-692e-4be2-b83c-30991b12eef5',
            'c86defa6-849b-4688-802c-2639bb97e0be',
            'c885dc1b-9f2a-4a6b-b45f-b8d9a931cead',
          ],
        },
        {
          id: 'bb273b7d-e825-4f7b-9820-34a3347d3c89',
          recommended_items: [
            'c981e9f4-8fd6-4185-bf14-de996ded4b52',
            'c8d3c2c3-692e-4be2-b83c-30991b12eef5',
            'c86defa6-849b-4688-802c-2639bb97e0be',
            'c885dc1b-9f2a-4a6b-b45f-b8d9a931cead',
          ],
        },
        {
          id: 'c909e520-42d6-4dad-bc71-7db44eebb129',
          recommended_items: [
            'c981e9f4-8fd6-4185-bf14-de996ded4b52',
            'c8d3c2c3-692e-4be2-b83c-30991b12eef5',
            'c86defa6-849b-4688-802c-2639bb97e0be',
            'c885dc1b-9f2a-4a6b-b45f-b8d9a931cead',
          ],
        },
        {
          id: 'bb27238e-f1a6-43c5-b7f4-130e66f2cce5',
          recommended_items: [
            'c86dd0f3-bacb-4aa8-9fd7-3924f1b0a190',
            'c86dcaf5-4611-44f0-b426-aaeebe175a63',
            '86dea30-0ef8-421f-8270-b86c5ab8d621',
            'c885dc1b-9f2a-4a6b-b45f-b8d9a931cead',
          ],
        },
      ],
    },
    {
      id: 'ce6846e7-d43a-4b55-b006-c919964bff43',
      cart_recommendations: [
        'c2b81f8c-aad1-42af-8454-eb3c9422a48b',
        'b177d571-9ff7-4710-b07c-d4c2087281e1',
        'c2883c92-a0e6-4570-ba13-edbf05ef40df',
        'c4db060b-d7d4-4979-a268-bcb804a52396',
      ],
      menu_item: [
        {
          id: 'c4db08be-308b-4111-a789-d052fea3a775',
          recommended_items: ['b177d571-9ff7-4710-b07c-d4c2087281e1', 'c4db060b-d7d4-4979-a268-bcb804a52396'],
        },
        {
          id: 'c25f2cf0-c6ba-4fe6-8747-b40d852c8d15',
          recommended_items: ['b177d571-9ff7-4710-b07c-d4c2087281e1', 'c4db060b-d7d4-4979-a268-bcb804a52396'],
        },
        {
          id: 'b177c494-009c-4489-9c9e-769f9079f2b4',
          recommended_items: ['b177d571-9ff7-4710-b07c-d4c2087281e1', 'c4db060b-d7d4-4979-a268-bcb804a52396'],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: ['', '', ''],
        },
      ],
    },
    {
      id: 'baa6e124-961f-4718-a476-da1d407885bb',
      cart_recommendations: [
        'c4a94a19-5e05-4c6f-9258-3b3a9d7c7db6',
        'c4a963c4-74ce-439d-a79d-a0999224689b',
        'c4a94b8e-441f-4b94-9da6-64209dfdddad',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: ['', '', ''],
        },
      ],
    },
    {
      id: '9c85d78c-748f-4a01-b7d8-afa61d52ec9d',
      cart_recommendations: [
        'ba790966-d5f8-4d0e-ae40-570fa037daf2',
        'ba7900b0-9b89-4c05-9ec4-e1a39554b04b',
        'ba790606-af12-4005-9a3a-705d3b8a5128',
        'ba792336-2fbf-4f21-95d9-3b8c5d7529a8',
        'ba792c73-424d-47c0-9c52-6ec21ce1fb82',
        'c47dad9a-8606-4297-a3e6-406e72e39e39',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'ba790a77-b5b7-4ea6-b40c-df46e925e24a',
          recommended_items: [
            'ba790966-d5f8-4d0e-ae40-570fa037daf2',
            'ba79303e-b490-4407-aa76-bb156d8ec57a',
            'ba793753-223d-4bc9-b7fd-437db83b9c01',
            'bb09fef5-31a5-4bac-bf10-e1b60b4fc7f3',
          ],
        },
        {
          id: 'ba790def-5da0-4b6b-944b-446ab4c9bc16',
          recommended_items: [
            'ba7917f6-645d-4d11-bdc7-d221d1cb372e',
            'ba791a2d-8af5-406b-b325-8734a1eefd87',
            'ba7934bb-4b79-42f1-97dc-12df3eead840',
            'ba791609-52c8-4f1d-8f93-baa0763a7815',
          ],
        },
        {
          id: 'ba793a0e-74b5-4508-93a3-87a645cb974b',
          recommended_items: [
            'ba790966-d5f8-4d0e-ae40-570fa037daf2',
            'ba79303e-b490-4407-aa76-bb156d8ec57a',
            'ba793753-223d-4bc9-b7fd-437db83b9c01',
            'bb09fef5-31a5-4bac-bf10-e1b60b4fc7f3',
          ],
        },
      ],
    },
    {
      id: '29983226-cce5-4f46-8fee-e3ddbf14cd26',
      cart_recommendations: [
        'c6f239ab-5a1a-47c1-8a81-e64008c5bdbe',
        'c7e6ca57-7d3b-450d-865c-adfeae002cdb',
        'c6f2048d-9306-42b2-b92e-690e0e5b8e65',
        'c6f23c8c-c23c-4559-ba7b-44c23d66b5e7',
        'c7e6c110-d19c-4f94-8178-b6c67e83fe53',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c73f8070-7ea7-4671-9c2a-0f8cc3887a63',
          recommended_items: [
            'c6f239ab-5a1a-47c1-8a81-e64008c5bdbe',
            'c7e6ca57-7d3b-450d-865c-adfeae002cdb',
            'c6f2048d-9306-42b2-b92e-690e0e5b8e65',
            'c6f23c8c-c23c-4559-ba7b-44c23d66b5e7',
            'c7e6c110-d19c-4f94-8178-b6c67e83fe53',
          ],
        },
      ],
    },
    {
      id: '0903dad1-a00d-4106-820d-535eb70111c1',
      cart_recommendations: [
        'c81101c5-c639-4914-8b5d-3ce57b3de2ca',
        'c8111dc5-3063-41a4-aab9-f1a0955b517c',
        'c8110131-cd6c-4071-a54c-7e6310696560',
        'c8113374-7911-4d81-8417-9808e27764bd',
        'c8113a0a-1a1d-420b-b3b8-6d19b6d17334',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c8113ba7-e5de-4078-9a07-3658db482ab7',
          recommended_items: [
            'c8111041-5169-4144-9bcf-12e537b28467',
            'c8111fef-04f6-4ce9-9d9a-51bb821d1647',
            'c811035d-a6d1-4075-a744-c7c9f94a58b0',
            'c8111dc5-3063-41a4-aab9-f1a0955b517c',
          ],
        },
      ],
    },
    {
      id: 'eff054da-1852-481d-952c-3c0abf198a30',
      cart_recommendations: [
        'c337ab4f-57ae-4e79-9d60-0cc64e5b4641',
        'c07e472f-f9c2-4fd6-94b0-9c5acbcc2a70',
        'c3384238-4114-4a0d-8cd3-46ab5a3cc963',
        'c3389543-359e-4448-93f7-c68d89735f27',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: ['', '', ''],
        },
      ],
    },
    {
      id: '51c7cc91-cc61-4a66-81da-c46c6271ea5d',
      cart_recommendations: [
        'c6cffd2b-5b85-41fe-b632-fcd897541307',
        'c48090b0-3149-499c-9ae3-e696959c8823',
        'c480a710-fe2a-473c-8367-367269004a6e',
        'c4808206-ba32-4b1c-843a-c282cefcacda',
        'c4808206-ba32-4b1c-843a-c282cefcacda',
      ],
      menu_item: [
        {
          id: '',
          recommended_items: ['', ''],
        },
      ],
      menu_heading: [
        {
          id: 'c4809df8-ca48-4710-bb95-ffe82695eff5',
          recommended_items: [
            'c4809fbe-ee85-4d16-9485-9e9809a2fe9e',
            'c6cffd2b-5b85-41fe-b632-fcd897541307',
            'c48090b0-3149-499c-9ae3-e696959c8823',
            'c480b155-3e5f-469c-8ac2-6e8df2ee20c8',
          ],
        },
      ],
    },
    {
      id: '9885e0da-b3a2-4029-8529-f5292790e86c',
      cart_recommendations: [
        'c6a363c0-ed1e-4be4-99a8-7e1639b7c829',
        'c722ce02-e283-4d00-9454-a79eea1e48b7',
        'c69f7bcb-ec1c-4241-bb9f-38a42fd6cd81',
        'c6a33465-2764-4c64-a38f-594017d0a15f',
      ],
      menu_item: [
        {
          id: 'c69faf51-c770-4c9d-9065-215450f30cde',
          recommended_items: [
            'c6a363c0-ed1e-4be4-99a8-7e1639b7c829',
            'c722ce02-e283-4d00-9454-a79eea1e48b7',
            'c69f7bcb-ec1c-4241-bb9f-38a42fd6cd81',
            'c6a33465-2764-4c64-a38f-594017d0a15f',
          ],
        },
        {
          id: 'c69d5433-f3e3-4998-bdc6-5ce218dfe965',
          recommended_items: [
            'c6a363c0-ed1e-4be4-99a8-7e1639b7c829',
            'c722ce02-e283-4d00-9454-a79eea1e48b7',
            'c69f7bcb-ec1c-4241-bb9f-38a42fd6cd81',
            'c6a33465-2764-4c64-a38f-594017d0a15f',
          ],
        },
        {
          id: 'c69f7bcb-ec1c-4241-bb9f-38a42fd6cd81',
          recommended_items: [
            'c6a363c0-ed1e-4be4-99a8-7e1639b7c829',
            'c722ce02-e283-4d00-9454-a79eea1e48b7',
            'c69f7bcb-ec1c-4241-bb9f-38a42fd6cd81',
            'c6a33465-2764-4c64-a38f-594017d0a15f',
          ],
        },
        {
          id: 'c69fbc00-517c-4dbf-b9a6-9edc9e0a49ba',
          recommended_items: [
            'c6a363c0-ed1e-4be4-99a8-7e1639b7c829',
            'c722ce02-e283-4d00-9454-a79eea1e48b7',
            'c69f7bcb-ec1c-4241-bb9f-38a42fd6cd81',
            'c6a33465-2764-4c64-a38f-594017d0a15f',
          ],
        },
        {
          id: 'c6cde233-ca59-4b76-b201-5bc093aa20d0',
          recommended_items: [
            'c6a363c0-ed1e-4be4-99a8-7e1639b7c829',
            'c722ce02-e283-4d00-9454-a79eea1e48b7',
            'c69f7bcb-ec1c-4241-bb9f-38a42fd6cd81',
            'c6a33465-2764-4c64-a38f-594017d0a15f',
          ],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: ['', '', ''],
        },
      ],
    },
    {
      id: '08b3aa8b-7683-4ce5-a7f5-54ceb9514c80',
      cart_recommendations: [
        'c88bccaa-e2b8-4d14-9fcd-d3248aee5457',
        'be6c3cfb-04c8-4515-9773-72e35b003596',
        'be460356-2640-4fad-bf71-bbfff7b23112',
        'be780300-4f4f-4bbf-91f0-6a6dcda0a483',
        'c945f998-1e18-4f4e-b18f-476d3e03c1dd',
        'be3c2dc3-c50d-4323-b83b-5d276dfc3282',
      ],
      menu_item: [
        {
          id: 'be3c0281-f1ee-4046-9843-6237dc7c0c3a',
          recommended_items: ['be74029e-2eba-4ca9-a7af-dc9e22317e79', 'be6c0ad4-5856-4283-a0cc-3e45a355c0be'],
        },
      ],
      menu_heading: [
        {
          id: 'c906e2fe-2eb4-4f83-a919-5909aa0feffc',
          recommended_items: [
            'c945f998-1e18-4f4e-b18f-476d3e03c1dd',
            'be780300-4f4f-4bbf-91f0-6a6dcda0a483',
            'c987db0f-38ec-4c0e-9837-9024818724bc',
            'be6c3cfb-04c8-4515-9773-72e35b003596',
          ],
        },
        {
          id: 'c906c4bf-1eff-44f8-ad15-30ccc845d96c',
          recommended_items: [
            'c945f998-1e18-4f4e-b18f-476d3e03c1dd',
            'be780300-4f4f-4bbf-91f0-6a6dcda0a483',
            'c987db0f-38ec-4c0e-9837-9024818724bc',
            'be6c3cfb-04c8-4515-9773-72e35b003596',
          ],
        },
        {
          id: 'c88bec45-7d35-46c5-8614-6414e6fbce96',
          recommended_items: [
            'c945f998-1e18-4f4e-b18f-476d3e03c1dd',
            'be780300-4f4f-4bbf-91f0-6a6dcda0a483',
            'c987db0f-38ec-4c0e-9837-9024818724bc',
            'be6c3cfb-04c8-4515-9773-72e35b003596',
          ],
        },
        {
          id: 'be3c227f-f0e5-4f5b-8619-d1d6f12f47ac',
          recommended_items: [
            'c945f998-1e18-4f4e-b18f-476d3e03c1dd',
            'be780300-4f4f-4bbf-91f0-6a6dcda0a483',
            'c987db0f-38ec-4c0e-9837-9024818724bc',
            'be6c3cfb-04c8-4515-9773-72e35b003596',
          ],
        },
        {
          id: 'be3c21c1-4b43-4996-af19-1fcc6854b68f',
          recommended_items: [
            'c945f998-1e18-4f4e-b18f-476d3e03c1dd',
            'be780300-4f4f-4bbf-91f0-6a6dcda0a483',
            'c987db0f-38ec-4c0e-9837-9024818724bc',
            'be6c3cfb-04c8-4515-9773-72e35b003596',
          ],
        },
        {
          id: 'be3c0fae-a8e5-44b8-8035-917ecf65f8d6',
          recommended_items: [
            'c945f998-1e18-4f4e-b18f-476d3e03c1dd',
            'be780300-4f4f-4bbf-91f0-6a6dcda0a483',
            'c987db0f-38ec-4c0e-9837-9024818724bc',
            'be6c3cfb-04c8-4515-9773-72e35b003596',
          ],
        },
        {
          id: 'be541072-92e6-4cda-83d7-c3cf10ce8a07',
          recommended_items: [
            'c945f998-1e18-4f4e-b18f-476d3e03c1dd',
            'be780300-4f4f-4bbf-91f0-6a6dcda0a483',
            'c987db0f-38ec-4c0e-9837-9024818724bc',
            'be6c3cfb-04c8-4515-9773-72e35b003596',
          ],
        },
        {
          id: 'be542561-40c2-43e8-8d7e-c3b9c40df69d',
          recommended_items: [
            'c945f998-1e18-4f4e-b18f-476d3e03c1dd',
            'be780300-4f4f-4bbf-91f0-6a6dcda0a483',
            'c987db0f-38ec-4c0e-9837-9024818724bc',
            'be6c3cfb-04c8-4515-9773-72e35b003596',
          ],
        },
        {
          id: 'be3c0fcd-1eb8-40f0-833e-54fd3f1a6efc',
          recommended_items: [
            'be461535-567a-414e-b1e0-b8625d589afa',
            'be460356-2640-4fad-bf71-bbfff7b23112',
            'be3c232e-723f-45d0-8a8d-ef4ecc7cd484',
            'be6c3cfb-04c8-4515-9773-72e35b003596',
          ],
        },
      ],
    },
    {
      id: 'fc184bc9-6690-4c0d-99ee-a2695ae30aa6',
      cart_recommendations: [
        'c3a2dd75-1d1d-4533-ae0a-968114e99e3b',
        'c3a2e153-6ab9-4fb9-b373-65d3a9e3ed12',
        'c3a2e452-0dca-4a55-be0b-55472ec4c5b9',
      ],
      menu_item: [
        {
          id: 'c3a2defe-e4d8-4581-8016-a2eca5f5ff7d',
          recommended_items: [
            'c3a2e452-0dca-4a55-be0b-55472ec4c5b9',
            'c3a2e911-4337-41d5-a11a-1a8b123ccb5d',
            'c3a2ecce-bd63-4204-af4a-b534ad175bb1',
            'c3a2f1e1-935b-40a9-acb0-82f22fa451cc',
          ],
        },
      ],
      menu_heading: [
        {
          id: 'c3a2d118-3427-441a-b2c6-a04921874e70',
          recommended_items: [
            'c3a2c8eb-6585-48ec-9a73-d60bd5ccf54f',
            'c3a2cae3-3156-469c-af7c-fd2e7f56d660',
            'c3a2caff-c7b2-49f9-a7de-9264b10a21dc',
          ],
        },
        {
          id: 'c3a2ed12-a8c3-4b02-960c-56aea185f36e',
          recommended_items: [
            'c3a2cb36-f2b2-4d09-8bab-bae1632f52cd',
            'c3a2cbdc-a02d-45b9-b06f-4b9f18098494',
            'c3a2ce28-af62-4507-9cd7-fce054c0d44d',
            'c3a2dac0-d7fa-4a8c-bf79-bb3e5b8ccda3',
          ],
        },
      ],
    },
    {
      id: 'e5c5e2c4-9135-4d4c-93d4-8752f7e2d80a',
      cart_recommendations: ['', ''],
      menu_item: [
        {
          id: 'f7fffa93-63ba-49c9-8219-08cef61f1ade',
          recommended_items: [
            '33921c83-a140-4215-ac33-ced17be27b71',
            '0800ffce-a708-43a2-a3a1-acdcc41455bd',
            '584ad273-eae7-4e92-865c-3322d66d39f9',
            'dda26f17-5314-4806-87a7-854c24ed59df',
          ],
        },
        {
          id: '83d612fe-622d-4376-a6b0-6be5ef52016c',
          recommended_items: [
            '33921c83-a140-4215-ac33-ced17be27b71',
            '0800ffce-a708-43a2-a3a1-acdcc41455bd',
            '584ad273-eae7-4e92-865c-3322d66d39f9',
            'dda26f17-5314-4806-87a7-854c24ed59df',
          ],
        },
        {
          id: '9b95e42c-b936-4831-a05d-824bb5e4f329',
          recommended_items: [
            '33921c83-a140-4215-ac33-ced17be27b71',
            '0800ffce-a708-43a2-a3a1-acdcc41455bd',
            '584ad273-eae7-4e92-865c-3322d66d39f9',
            'dda26f17-5314-4806-87a7-854c24ed59df',
          ],
        },
        {
          id: 'b426a9a5-4403-40bd-9559-64672a7fe742',
          recommended_items: [
            '33921c83-a140-4215-ac33-ced17be27b71',
            '0800ffce-a708-43a2-a3a1-acdcc41455bd',
            '584ad273-eae7-4e92-865c-3322d66d39f9',
            'dda26f17-5314-4806-87a7-854c24ed59df',
          ],
        },
        {
          id: '1faabfa6-1fac-487f-806d-343795cac1f0',
          recommended_items: [
            '33921c83-a140-4215-ac33-ced17be27b71',
            '0800ffce-a708-43a2-a3a1-acdcc41455bd',
            '584ad273-eae7-4e92-865c-3322d66d39f9',
            'dda26f17-5314-4806-87a7-854c24ed59df',
          ],
        },
      ],
      menu_heading: [
        {
          id: '',
          recommended_items: ['', '', ''],
        },
      ],
    },
    {
      id: '4f1ce1d5-b9c0-4af4-ac10-114a2968cb06',
      cart_recommendations: [
        'c0e98dac-5913-4b68-9533-bc101ac56140',
        'c0e9895a-a134-4a9e-9ca3-b45c2bafdf17',
        'c0e9b4e8-3daa-4752-9c14-2043156762a9',
        'c7ec293e-3c33-400f-ae31-5f18ed86621b',
      ],
      menu_item: [
        {
          id: 'c7ec3095-0105-4bdb-9345-e27e553c8e48',
          recommended_items: [
            'bfb85169-6839-4a23-9d5c-87d3ddc2e8dd',
            'c282e2b7-8c3e-486b-8903-77c3e4110362',
            'c5ad1dd7-90eb-4748-9bb3-08fdad226100',
          ],
        },
        {
          id: 'c0e9b4e8-3daa-4752-9c14-2043156762a9',
          recommended_items: [
            'c5ad1dd7-90eb-4748-9bb3-08fdad226100',
            'c282e2b7-8c3e-486b-8903-77c3e4110362',
            'bfb85169-6839-4a23-9d5c-87d3ddc2e8dd',
          ],
        },
        {
          id: 'c7ec034b-e475-43bc-9c47-785650e75c39',
          recommended_items: [
            'c7ec034b-e475-43bc-9c47-785650e75c39',
            'c282e2b7-8c3e-486b-8903-77c3e4110362',
            'bfb85169-6839-4a23-9d5c-87d3ddc2e8dd',
          ],
        },
        {
          id: 'c0e98dac-5913-4b68-9533-bc101ac56140',
          recommended_items: [
            'c5ad1dd7-90eb-4748-9bb3-08fdad226100',
            'c282e2b7-8c3e-486b-8903-77c3e4110362',
            'bfb85169-6839-4a23-9d5c-87d3ddc2e8dd',
          ],
        },
        {
          id: 'c5d142c3-5933-408e-a3e7-4604baee1ae0',
          recommended_items: [
            'c282e2b7-8c3e-486b-8903-77c3e4110362',
            'c282e2b7-8c3e-486b-8903-77c3e4110362',
            'bfb85169-6839-4a23-9d5c-87d3ddc2e8dd',
          ],
        },
        {
          id: 'c7ec293e-3c33-400f-ae31-5f18ed86621b',
          recommended_items: [
            'c6f78070-99b3-464b-84c0-383ca2d9ed97',
            'c282e2b7-8c3e-486b-8903-77c3e4110362',
            'bfb85169-6839-4a23-9d5c-87d3ddc2e8dd',
          ],
        },
        {
          id: 'c7ec1284-9a3b-4d63-a5b6-142bedad5473',
          recommended_items: [
            'c282cf37-99c1-4cd8-ade5-adc07468bcdd',
            'c282e2b7-8c3e-486b-8903-77c3e4110362',
            'bfb85169-6839-4a23-9d5c-87d3ddc2e8dd',
            'c89458ab-6b3d-4de3-bffc-d42574b0e27d',
          ],
        },
        {
          id: 'c7ec1e61-84d9-4f5e-a868-83dd8fe643c7',
          recommended_items: [
            'bfb84503-bde8-4437-815f-03526e225571',
            'c282e2b7-8c3e-486b-8903-77c3e4110362',
            'bfb85169-6839-4a23-9d5c-87d3ddc2e8dd',
          ],
        },
      ],
      menu_heading: [
        {
          id: 'bfb84818-017b-43a0-96d3-3a23b8516a2c',
          recommended_items: [
            'c0e9b4e8-3daa-4752-9c14-2043156762a9',
            'c0e98dac-5913-4b68-9533-bc101ac56140',
            'c0e9895a-a134-4a9e-9ca3-b45c2bafdf17',
            'c7ec293e-3c33-400f-ae31-5f18ed86621b',
            'c89458ab-6b3d-4de3-bffc-d42574b0e27d',
          ],
        },
      ],
    },
    {
      id: '662d76bb-a5f8-4d87-b1c1-e131fd21846c',
      cart_recommendations: [
        'c06eb387-2c39-4b4a-8559-fad2d475336b',
        'c06e99fa-fa26-4ad1-9a65-d5532097f8b5',
        'c06eb2eb-b134-46d6-ac6d-daf07bcfa18f',
        'c06eb6ec-c2c2-4d66-9f2b-d0b1a6b107a0',
      ],
      menu_heading: [
        {
          id: 'c06e8dbc-f5d0-41f0-b7ce-8ded7d14e6cf',
          recommended_items: [
            'c06eb3bb-1cf6-40e1-acfa-efc0514af7eb',
            'c06ea021-0211-4341-8774-3d63e234d59e',
            'c06e9cf5-15c9-4621-b88a-9162370bf3c3',
          ],
        },
        {
          id: 'c69c88f8-d2c7-4f8f-98bf-48b90d7733d0',
          recommended_items: [
            'c06e8f87-7beb-4e93-95b4-5dc58f23b677',
            'c06e8670-889d-4004-8803-86575a14c7d4',
            'c06e8652-d76a-47e7-88f8-c8287e037b86',
          ],
        },
      ],
    },
    {
      id: '222c1343-7c4e-494d-b15c-f20953e36222',
      cart_recommendations: ['c4a95fba-402e-4b38-8a83-5355fb6be28e', 'c4a9658b-00bd-44aa-b80b-2f4be15cf7d8'],
      menu_item: [
        {
          id: 'c4a97027-519a-45e2-a340-091f04e286bf',
          recommended_items: ['c4a9658b-00bd-44aa-b80b-2f4be15cf7d8', 'c4a96ac1-3f40-44f7-aa7f-13ebee6a2e56'],
        },
      ],
      menu_heading: [
        {
          id: 'c4a95b03-65c5-4b20-b197-3d0b415105b6',
          recommended_items: [
            'c4a97027-519a-45e2-a340-091f04e286bf',
            'c4a9503c-5162-478c-820b-b08bb0f577f0',
            'c4a95746-b6ce-4108-8e86-432f3cd5c230',
            'c4a95b4e-faa1-4640-9bc2-1b148d6a1331',
            'c4a9727f-6545-47bb-8234-f38efb10f4d1',
          ],
        },
        {
          id: 'c4a97e1f-c662-4e3f-89d2-392264bf7a40',
          recommended_items: [
            'c4a9727f-6545-47bb-8234-f38efb10f4d1',
            'c4a95fba-402e-4b38-8a83-5355fb6be28e',
            'c4a9503c-5162-478c-820b-b08bb0f577f0',
            'c4a95b4e-faa1-4640-9bc2-1b148d6a1331',
          ],
        },
        {
          id: 'c4a9637d-88a9-45d4-8091-8ee5ce9b0952',
          recommended_items: [
            'c4a95746-b6ce-4108-8e86-432f3cd5c230',
            'c4a97027-519a-45e2-a340-091f04e286bf',
            'c4a95b4e-faa1-4640-9bc2-1b148d6a1331',
          ],
        },
      ],
    },
    {
      id: 'ddf55cae-5331-4156-a656-c4bbafa10e51',
      cart_recommendations: [
        'c24d0028-fea2-4efe-b0ba-8c81d555e769',
        'c24d00a3-aaa9-4882-904a-aaea74be6eb2',
        'c24d01be-0420-4e82-9833-46900499e965',
        'c24d0276-96e0-4bfe-96b9-f9431f4593c7',
        'c24d05fb-73d6-4919-b637-691cafd7cb17',
        'c24d0d35-2c88-4cae-846c-c8280791671d',
        'c24d1292-8640-4410-a674-f32354d73203',
      ],
      menu_item: [
        {
          id: 'c24d1e5b-a4bc-4215-b6ec-eafd27695d03',
          recommended_items: ['c24d39d2-9bd1-400c-9c33-afdb2050d2d0', 'c24d1dbf-6289-424d-b0b7-aac111d3d88b'],
        },
      ],
      menu_heading: [
        {
          id: 'c24d3ef6-7e05-4f6a-812b-8f802ebb2705',
          recommended_items: [
            'c24d0028-fea2-4efe-b0ba-8c81d555e769',
            'c24d00a3-aaa9-4882-904a-aaea74be6eb2',
            'c24d01be-0420-4e82-9833-46900499e965',
            'c24d0276-96e0-4bfe-96b9-f9431f4593c7',
            'c24d05fb-73d6-4919-b637-691cafd7cb17',
            'c24d0d35-2c88-4cae-846c-c8280791671d',
            'c24d1292-8640-4410-a674-f32354d73203',
          ],
        },
        {
          id: 'c24d2bfc-b0bc-4493-a68c-92485e7ee6d6',
          recommended_items: [
            'c24d0028-fea2-4efe-b0ba-8c81d555e769',
            'c24d00a3-aaa9-4882-904a-aaea74be6eb2',
            'c24d01be-0420-4e82-9833-46900499e965',
            'c24d0276-96e0-4bfe-96b9-f9431f4593c7',
            'c24d05fb-73d6-4919-b637-691cafd7cb17',
            'c24d0d35-2c88-4cae-846c-c8280791671d',
            'c24d1292-8640-4410-a674-f32354d73203',
          ],
        },
        {
          id: 'c24d39b1-1d62-40cf-8f23-c4ed8555e197',
          recommended_items: [
            'c24d0028-fea2-4efe-b0ba-8c81d555e769',
            'c24d00a3-aaa9-4882-904a-aaea74be6eb2',
            'c24d01be-0420-4e82-9833-46900499e965',
            'c24d0276-96e0-4bfe-96b9-f9431f4593c7',
            'c24d05fb-73d6-4919-b637-691cafd7cb17',
            'c24d0d35-2c88-4cae-846c-c8280791671d',
            'c24d1292-8640-4410-a674-f32354d73203',
          ],
        },
        {
          id: 'c24d1f7f-5624-4673-b8ee-85884264553a',
          recommended_items: [
            'c24d0028-fea2-4efe-b0ba-8c81d555e769',
            'c24d00a3-aaa9-4882-904a-aaea74be6eb2',
            'c24d01be-0420-4e82-9833-46900499e965',
            'c24d0276-96e0-4bfe-96b9-f9431f4593c7',
            'c24d05fb-73d6-4919-b637-691cafd7cb17',
            'c24d0d35-2c88-4cae-846c-c8280791671d',
            'c24d1292-8640-4410-a674-f32354d73203',
          ],
        },
      ],
    },
    {
      id: '07e6989f-61fa-4884-b4ea-b61ab752f1a3',
      cart_recommendations: [],
      menu_item: [
        {
          id: 'cbd17b57-6c34-4e84-8f78-9ff3b311ea1b',
          recommended_items: [
            'cbd15c5d-81a4-4424-a881-9366ea52be52',
            'cbd14a99-813e-491d-9339-c0328223e82e',
            'cbd14b0f-5d93-44d3-856c-ae6de5e3694d',
          ],
        },
        {
          id: 'cbd140a1-a8e9-4a64-bc85-02fe742daf86',
          recommended_items: [
            'cbd17b57-6c34-4e84-8f78-9ff3b311ea1b',
            'cbd15c5d-81a4-4424-a881-9366ea52be52',
            'cbd1463e-0b44-4ff6-8899-0a908c908246',
          ],
        },
        {
          id: 'cbd14bc4-959f-48d6-950f-4be6ac086952',
          recommended_items: [
            'cbd14ee2-b375-47ea-a0a8-5dac401a7bb3',
            'cbd15fb0-00e3-49fe-9e01-09d95a629b45',
            'cbd158ac-8de4-494c-a89c-80ee8dc42a89',
          ],
        },
        {
          id: 'cbd15c5d-81a4-4424-a881-9366ea52be52',
          recommended_items: [
            'cbd17b57-6c34-4e84-8f78-9ff3b311ea1b',
            'cbd1417f-bcb5-42c6-aa5a-90097c9a9751',
            'cbd1587e-2d76-4070-b5c4-a7d652235a70',
          ],
        },
        {
          id: 'cbd1518f-32f5-43a4-bc99-a53d7b5965fc',
          recommended_items: [
            'cbd140a1-a8e9-4a64-bc85-02fe742daf86',
            'cbd15c5d-81a4-4424-a881-9366ea52be52',
            'cbd17b57-6c34-4e84-8f78-9ff3b311ea1b',
          ],
        },
        {
          id: 'cbd15ede-d6f1-4e19-8b21-52a118dd8846',
          recommended_items: [
            'cbd140a1-a8e9-4a64-bc85-02fe742daf86',
            'cbd15c5d-81a4-4424-a881-9366ea52be52',
            'cbd17b57-6c34-4e84-8f78-9ff3b311ea1b',
          ],
        },
        {
          id: 'cbd14b0f-5d93-44d3-856c-ae6de5e3694d',
          recommended_items: [
            'cbd17770-3a54-48e0-8734-d31c5f0706db',
            'cbd1587e-2d76-4070-b5c4-a7d652235a70',
            'cbd1417f-bcb5-42c6-aa5a-90097c9a9751',
          ],
        },
        {
          id: 'cbd16fa1-1136-4996-9a4e-4575424f849f',
          recommended_items: [
            'cbd17770-3a54-48e0-8734-d31c5f0706db',
            'cbd1587e-2d76-4070-b5c4-a7d652235a70',
            'cbd1417f-bcb5-42c6-aa5a-90097c9a9751',
          ],
        },
        {
          id: 'cbd1473b-119f-4ab4-8772-ab4c01ec481b',
          recommended_items: [
            'cbd15fb0-00e3-49fe-9e01-09d95a629b45',
            'cbd1587e-2d76-4070-b5c4-a7d652235a70',
            'cbd1417f-bcb5-42c6-aa5a-90097c9a9751',
          ],
        },
        {
          id: 'cbd148f0-c1a8-4812-bd52-cab577203f05',
          recommended_items: [
            'cbd162d7-1278-4910-8fbf-31df2288a650',
            'cbd1417f-bcb5-42c6-aa5a-90097c9a9751',
            'cbd140a1-a8e9-4a64-bc85-02fe742daf86',
          ],
        },
        {
          id: 'cbd142bc-7cac-4224-877a-2812c99c29cd',
          recommended_items: [
            'cbd15fb0-00e3-49fe-9e01-09d95a629b45',
            'cbd1587e-2d76-4070-b5c4-a7d652235a70',
            'cbd1417f-bcb5-42c6-aa5a-90097c9a9751',
          ],
        },
        {
          id: 'cbd14919-7334-4de1-aaef-24ebc81cbbb0',
          recommended_items: [
            'cbd148f0-c1a8-4812-bd52-cab577203f05',
            'cbd1473b-119f-4ab4-8772-ab4c01ec481b',
            'cbd14b0f-5d93-44d3-856c-ae6de5e3694d',
          ],
        },
        {
          id: 'cbd1417f-bcb5-42c6-aa5a-90097c9a9751',
          recommended_items: [
            'cbd140a1-a8e9-4a64-bc85-02fe742daf86',
            'cbd1587e-2d76-4070-b5c4-a7d652235a70',
            'cbd14b0f-5d93-44d3-856c-ae6de5e3694d',
          ],
        },
        {
          id: 'cbd162d7-1278-4910-8fbf-31df2288a650',
          recommended_items: [
            'cbd158ac-8de4-494c-a89c-80ee8dc42a89',
            'cbd15c5d-81a4-4424-a881-9366ea52be52',
            'cbd140a1-a8e9-4a64-bc85-02fe742daf86',
          ],
        },
        {
          id: 'cbd1463e-0b44-4ff6-8899-0a908c908246',
          recommended_items: [
            'cbd17b57-6c34-4e84-8f78-9ff3b311ea1b',
            'cbd15c5d-81a4-4424-a881-9366ea52be52',
            'cbd1587e-2d76-4070-b5c4-a7d652235a70',
          ],
        },
        {
          id: 'cbd160ea-3948-4e4f-aafc-df50526d5253',
          recommended_items: [
            'cbd140a1-a8e9-4a64-bc85-02fe742daf86',
            'cbd16d7c-43b8-499c-90ea-556cd0a42ee3',
            'cbd15fb0-00e3-49fe-9e01-09d95a629b45',
          ],
        },
        {
          id: 'cbd14ee2-b375-47ea-a0a8-5dac401a7bb3',
          recommended_items: [
            'cbd1417f-bcb5-42c6-aa5a-90097c9a9751',
            'cbd1587e-2d76-4070-b5c4-a7d652235a70',
            'cbd15c5d-81a4-4424-a881-9366ea52be52',
          ],
        },
        {
          id: 'cbd16de5-1a15-4dea-bbac-a6c4e7b3e58b',
          recommended_items: [
            'cbd140a1-a8e9-4a64-bc85-02fe742daf86',
            'cbd17770-3a54-48e0-8734-d31c5f0706db',
            'cbd15fb0-00e3-49fe-9e01-09d95a629b45',
          ],
        },
        {
          id: 'cbd169b3-112a-44b4-bab7-d99cb4bba8f2',
          recommended_items: [
            'cbd140a1-a8e9-4a64-bc85-02fe742daf86',
            'cbd17770-3a54-48e0-8734-d31c5f0706db',
            'cbd15fb0-00e3-49fe-9e01-09d95a629b45',
          ],
        },
        {
          id: 'cbd14db3-7ec3-4140-ae01-d278f5b71772',
          recommended_items: [
            'cbd140a1-a8e9-4a64-bc85-02fe742daf86',
            'cbd17770-3a54-48e0-8734-d31c5f0706db',
            'cbd15fb0-00e3-49fe-9e01-09d95a629b45',
          ],
        },
        {
          id: 'cbd14728-56ac-4768-aa01-e9b1412cb7c5',
          recommended_items: [
            'cbd1417f-bcb5-42c6-aa5a-90097c9a9751',
            'cbd1587e-2d76-4070-b5c4-a7d652235a70',
            'cbd15c5d-81a4-4424-a881-9366ea52be52',
          ],
        },
        {
          id: 'cbd16d7c-43b8-499c-90ea-556cd0a42ee3',
          recommended_items: [
            'cbd140a1-a8e9-4a64-bc85-02fe742daf86',
            'cbd17b57-6c34-4e84-8f78-9ff3b311ea1b',
            'cbd1587e-2d76-4070-b5c4-a7d652235a70',
          ],
        },
        {
          id: 'cbd17770-3a54-48e0-8734-d31c5f0706db',
          recommended_items: [
            'cbd140a1-a8e9-4a64-bc85-02fe742daf86',
            'cbd1417f-bcb5-42c6-aa5a-90097c9a9751',
            'cbd1587e-2d76-4070-b5c4-a7d652235a70',
          ],
        },
        {
          id: 'cbd15fb0-00e3-49fe-9e01-09d95a629b45',
          recommended_items: [
            'cbd140a1-a8e9-4a64-bc85-02fe742daf86',
            'cbd17b57-6c34-4e84-8f78-9ff3b311ea1b',
            'cbd1587e-2d76-4070-b5c4-a7d652235a70',
          ],
        },
        {
          id: 'cbd15a66-ab43-4889-97a1-4f350979be5a',
          recommended_items: [
            'cbd162d7-1278-4910-8fbf-31df2288a650',
            'cbd15ede-d6f1-4e19-8b21-52a118dd8846',
            'cbd17770-3a54-48e0-8734-d31c5f0706db',
          ],
        },
        {
          id: 'cbd158ac-8de4-494c-a89c-80ee8dc42a89',
          recommended_items: [
            'cbd162d7-1278-4910-8fbf-31df2288a650',
            'cbd15ede-d6f1-4e19-8b21-52a118dd8846',
            'cbd17770-3a54-48e0-8734-d31c5f0706db',
          ],
        },
        {
          id: 'cbd17deb-1d88-470c-833a-a7d640f85e7a',
          recommended_items: [
            'cbd162d7-1278-4910-8fbf-31df2288a650',
            'cbd15ede-d6f1-4e19-8b21-52a118dd8846',
            'cbd17770-3a54-48e0-8734-d31c5f0706db',
          ],
        },
        {
          id: 'cbd14be6-6e99-4b70-9867-2ec441660563',
          recommended_items: [
            'cbd162d7-1278-4910-8fbf-31df2288a650',
            'cbd15ede-d6f1-4e19-8b21-52a118dd8846',
            'cbd17770-3a54-48e0-8734-d31c5f0706db',
          ],
        },
        {
          id: 'cbd14a99-813e-491d-9339-c0328223e82e',
          recommended_items: [
            'cbd162d7-1278-4910-8fbf-31df2288a650',
            'cbd15ede-d6f1-4e19-8b21-52a118dd8846',
            'cbd17770-3a54-48e0-8734-d31c5f0706db',
          ],
        },
      ],
      menu_heading: [
        {
          id: 'c9b3c2b6-efc7-45cf-89e1-764b9b48468d',
          recommended_items: [
            'cbd140a1-a8e9-4a64-bc85-02fe742daf86',
            'cbd15c5d-81a4-4424-a881-9366ea52be52',
            'cbd1587e-2d76-4070-b5c4-a7d652235a70',
          ],
        },
        {
          id: 'c9b3fc5e-b456-4c3a-a6b2-e1916f944cc3',
          recommended_items: [
            'cbd1417f-bcb5-42c6-aa5a-90097c9a9751',
            'cbd15c5d-81a4-4424-a881-9366ea52be52',
            'cbd140a1-a8e9-4a64-bc85-02fe742daf86',
          ],
        },
      ],
    },
    {
      id: '56b97847-1fa1-49a1-9ebd-8b80f18550e1',
      cart_recommendations: [],
      menu_item: [
        {
          id: 'cc72b05d-4617-4d51-a70f-b9bd74327a7b',
          recommended_items: ['cc72bc8f-b05d-4cb0-aab0-7c53a09fbf2f'],
        },
        {
          id: 'cc72b859-ea95-4778-b778-63eab7340777',
          recommended_items: ['cc72bc8f-b05d-4cb0-aab0-7c53a09fbf2f'],
        },
        {
          id: 'cc72b69d-0cda-4d8f-a92a-0a044aa6c7bf',
          recommended_items: ['cc72bc8f-b05d-4cb0-aab0-7c53a09fbf2f', 'cc72bc8f-b05d-4cb0-aab0-7c53a09fbf2f'],
        },
        {
          id: 'cc72a8bc-2f3c-4edc-ad07-99ac0ec43026',
          recommended_items: ['cc72bc8f-b05d-4cb0-aab0-7c53a09fbf2f'],
        },
        {
          id: 'cc72bdaf-e284-44f3-94ef-a9eff4e51160',
          recommended_items: ['cc72bc8f-b05d-4cb0-aab0-7c53a09fbf2f'],
        },
        {
          id: 'cc72b9ea-9952-4a3b-b024-a99ef5fd908b',
          recommended_items: ['cc72bc8f-b05d-4cb0-aab0-7c53a09fbf2f'],
        },
        {
          id: 'cc728d22-ec6d-4488-8815-ab95ecd0c851',
          recommended_items: ['cc72bc8f-b05d-4cb0-aab0-7c53a09fbf2f'],
        },
      ],
      menu_heading: [],
    },
    {
      id: '8f45c21e-7a3c-4484-bb14-59c6ceaa01c2',
      cart_recommendations: [],
      menu_item: [
        {
          id: 'cc72a6db-6e8a-41f7-8013-93d141a7a1b8',
          recommended_items: [
            'cc72adce-827d-4e67-b90b-1eee17b9cd3d',
            'cc72a5de-1ade-431e-ab02-a214f6557d56',
            'cc72b2e4-7aba-4b09-92c4-b22207fc22cb',
            'cc72ba90-5be6-4652-aea6-dcdd2d61e0ec',
          ],
        },
        {
          id: 'cc72b79d-0195-4ac7-84a6-001314cde7a8',
          recommended_items: [
            'cc72adce-827d-4e67-b90b-1eee17b9cd3d',
            'cc72a5de-1ade-431e-ab02-a214f6557d56',
            'cc729913-2523-4f86-9c3d-0b1e15380e56',
          ],
        },
        {
          id: 'cc7292a6-032b-4b84-b7f8-99d8a049729b',
          recommended_items: [
            'cc72adce-827d-4e67-b90b-1eee17b9cd3d',
            'cc72a5de-1ade-431e-ab02-a214f6557d56',
            'cc72a98c-8e93-401f-b9fc-83c9e941ad46',
          ],
        },
        {
          id: 'cc72a9d1-80b7-4f47-9db8-b2e11a2a7ef1',
          recommended_items: [
            'cc72adce-827d-4e67-b90b-1eee17b9cd3d',
            'cc72a5de-1ade-431e-ab02-a214f6557d56',
            'cc72b0dc-1965-415f-b9e4-48bce652fcd7',
          ],
        },
        {
          id: 'cc72a98c-8e93-401f-b9fc-83c9e941ad46',
          recommended_items: [
            'cc72adce-827d-4e67-b90b-1eee17b9cd3d',
            'cc72a5de-1ade-431e-ab02-a214f6557d56',
            'cc729913-2523-4f86-9c3d-0b1e15380e56',
          ],
        },
        {
          id: 'cc728af4-927c-4d1a-943a-b833646d074b',
          recommended_items: [
            'cc72adce-827d-4e67-b90b-1eee17b9cd3d',
            'cc72a5de-1ade-431e-ab02-a214f6557d56',
            'cc72b0dc-1965-415f-b9e4-48bce652fcd7',
          ],
        },
        {
          id: 'cc72b42f-305e-4ff2-9a1d-34d64a14c97c',
          recommended_items: [
            'cc72adce-827d-4e67-b90b-1eee17b9cd3d',
            'cc72a5de-1ade-431e-ab02-a214f6557d56',
            'cc729356-cef8-4827-ac82-21dc11d0ece6',
          ],
        },
        {
          id: 'cc72a111-76a9-460e-bb37-3c4cec158325',
          recommended_items: [
            'cc72a6db-6e8a-41f7-8013-93d141a7a1b8',
            'cc72a5de-1ade-431e-ab02-a214f6557d56',
            'cc72b79d-0195-4ac7-84a6-001314cde7a8',
          ],
        },
        {
          id: 'cc729913-2523-4f86-9c3d-0b1e15380e56',
          recommended_items: [
            'cc72adce-827d-4e67-b90b-1eee17b9cd3d',
            'cc72b79d-0195-4ac7-84a6-001314cde7a8',
            'cc72a6db-6e8a-41f7-8013-93d141a7a1b8',
          ],
        },
        {
          id: 'cc773ab8-b7d7-4d6e-9f7a-4ee846abed7c',
          recommended_items: [
            'cc72a5de-1ade-431e-ab02-a214f6557d56',
            'cc72b79d-0195-4ac7-84a6-001314cde7a8',
            'cc72a6db-6e8a-41f7-8013-93d141a7a1b8',
          ],
        },
        {
          id: 'cc72b0dc-1965-415f-b9e4-48bce652fcd7',
          recommended_items: [
            'cc72a5de-1ade-431e-ab02-a214f6557d56',
            'cc72b79d-0195-4ac7-84a6-001314cde7a8',
            'cc72a6db-6e8a-41f7-8013-93d141a7a1b8',
          ],
        },
        {
          id: 'cc72b2e4-7aba-4b09-92c4-b22207fc22cb',
          recommended_items: [
            'cc72a6db-6e8a-41f7-8013-93d141a7a1b8',
            'cc72b79d-0195-4ac7-84a6-001314cde7a8',
            'cc72a52a-c16d-4b6d-962c-1e53d592025f',
          ],
        },
        {
          id: 'cc72ba90-5be6-4652-aea6-dcdd2d61e0ec',
          recommended_items: [
            'cc72a6db-6e8a-41f7-8013-93d141a7a1b8',
            'cc72b79d-0195-4ac7-84a6-001314cde7a8',
            'cc72a52a-c16d-4b6d-962c-1e53d592025f',
          ],
        },
        {
          id: 'cc72a5de-1ade-431e-ab02-a214f6557d56',
          recommended_items: [
            'cc72a98c-8e93-401f-b9fc-83c9e941ad46',
            'cc72b0dc-1965-415f-b9e4-48bce652fcd7',
            'cc72be08-bf10-43b7-ab30-4f08b668f68c',
          ],
        },
        {
          id: 'cc72adce-827d-4e67-b90b-1eee17b9cd3d',
          recommended_items: [
            'cc729913-2523-4f86-9c3d-0b1e15380e56',
            'cc72b0dc-1965-415f-b9e4-48bce652fcd7',
            'cc72a111-76a9-460e-bb37-3c4cec158325',
          ],
        },
        {
          id: 'cc729356-cef8-4827-ac82-21dc11d0ece6',
          recommended_items: [
            'cc729913-2523-4f86-9c3d-0b1e15380e56',
            'cc72b0dc-1965-415f-b9e4-48bce652fcd7',
            'cc72a111-76a9-460e-bb37-3c4cec158325',
          ],
        },
        {
          id: 'cc728155-a6ce-472d-9da1-ef3c96742d87',
          recommended_items: [
            'cc729913-2523-4f86-9c3d-0b1e15380e56',
            'cc72b0dc-1965-415f-b9e4-48bce652fcd7',
            'cc72a111-76a9-460e-bb37-3c4cec158325',
          ],
        },
        {
          id: 'cc72be08-bf10-43b7-ab30-4f08b668f68c',
          recommended_items: [
            'cc72b42f-305e-4ff2-9a1d-34d64a14c97c',
            'cc72b0dc-1965-415f-b9e4-48bce652fcd7',
            'cc728af4-927c-4d1a-943a-b833646d074b',
          ],
        },
        {
          id: 'cc771350-7f19-4872-b16b-c040d5924940',
          recommended_items: [
            'cc72b79d-0195-4ac7-84a6-001314cde7a8',
            'cc72b0dc-1965-415f-b9e4-48bce652fcd7',
            'cc729356-cef8-4827-ac82-21dc11d0ece6',
          ],
        },
        {
          id: 'cc770069-a0b8-4be1-bbb9-0c55c3bbe3fa',
          recommended_items: [
            'cc72b2e4-7aba-4b09-92c4-b22207fc22cb',
            'cc72ba90-5be6-4652-aea6-dcdd2d61e0ec',
            'cc72b0dc-1965-415f-b9e4-48bce652fcd7',
            'cc728155-a6ce-472d-9da1-ef3c96742d87',
          ],
        },
        {
          id: 'cc770287-c51e-42a5-90ec-852fb7adeed4',
          recommended_items: [
            'cc72b2e4-7aba-4b09-92c4-b22207fc22cb',
            'cc72ba90-5be6-4652-aea6-dcdd2d61e0ec',
            'cc72be08-bf10-43b7-ab30-4f08b668f68c',
            'cc728155-a6ce-472d-9da1-ef3c96742d87',
          ],
        },
        {
          id: 'cc771ed5-d235-4793-9829-0dedf158c950',
          recommended_items: [
            'cc72b2e4-7aba-4b09-92c4-b22207fc22cb',
            'cc72ba90-5be6-4652-aea6-dcdd2d61e0ec',
            'cc72be08-bf10-43b7-ab30-4f08b668f68c',
            'cc728155-a6ce-472d-9da1-ef3c96742d87',
          ],
        },
        {
          id: 'cc770131-049d-43c9-9f5d-2132278b4601',
          recommended_items: [
            'cc72b2e4-7aba-4b09-92c4-b22207fc22cb',
            'cc72ba90-5be6-4652-aea6-dcdd2d61e0ec',
            'cc72be08-bf10-43b7-ab30-4f08b668f68c',
            'cc728155-a6ce-472d-9da1-ef3c96742d87',
          ],
        },
        {
          id: 'cc7717c5-6c1a-446a-a769-dd51e1513525',
          recommended_items: [
            'cc72b2e4-7aba-4b09-92c4-b22207fc22cb',
            'cc72ba90-5be6-4652-aea6-dcdd2d61e0ec',
            'cc72be08-bf10-43b7-ab30-4f08b668f68c',
            'cc728155-a6ce-472d-9da1-ef3c96742d87',
          ],
        },
      ],
      menu_heading: [],
    },
    {
      id: '59677ef5-c324-46a6-92c3-8a6b73446660',
      cart_recommendations: [],
      menu_item: [
        {
          id: 'cbd17ca7-63e9-482a-a7ad-f730a022add8',
          recommended_items: [
            'cbd17c8e-b10b-4f5f-9448-ed2d4fbf4b16',
            'cbd1482f-0216-46a9-8411-22fc6de60ae9',
            'cbd14abe-3f61-4742-8e45-0274d4c1cf21',
          ],
        },
        {
          id: 'cbd17c8e-b10b-4f5f-9448-ed2d4fbf4b16',
          recommended_items: [
            'cbd1482f-0216-46a9-8411-22fc6de60ae9',
            'cbd15b09-a764-4521-8881-1999274f737a',
            'cbd17362-2f5a-454d-a57d-641136b2a700',
          ],
        },
        {
          id: 'cbd1482f-0216-46a9-8411-22fc6de60ae9',
          recommended_items: [
            'cbd15b09-a764-4521-8881-1999274f737a',
            'cbd17362-2f5a-454d-a57d-641136b2a700',
            'cbd17c8e-b10b-4f5f-9448-ed2d4fbf4b16',
          ],
        },
        {
          id: 'cbd14abe-3f61-4742-8e45-0274d4c1cf21',
          recommended_items: [
            'cbd15b09-a764-4521-8881-1999274f737a',
            'cbd17362-2f5a-454d-a57d-641136b2a700',
            'cbd17c8e-b10b-4f5f-9448-ed2d4fbf4b16',
          ],
        },
        {
          id: 'cbd15b09-a764-4521-8881-1999274f737a',
          recommended_items: [
            'cbd17c8e-b10b-4f5f-9448-ed2d4fbf4b16',
            'cbd1482f-0216-46a9-8411-22fc6de60ae9',
            'cbd14abe-3f61-4742-8e45-0274d4c1cf21',
          ],
        },
        {
          id: 'cbd17362-2f5a-454d-a57d-641136b2a700',
          recommended_items: [
            'cbd15b09-a764-4521-8881-1999274f737a',
            'cbd17362-2f5a-454d-a57d-641136b2a700',
            'cbd17c8e-b10b-4f5f-9448-ed2d4fbf4b16',
          ],
        },
        {
          id: 'cbd16c92-b6a0-4866-a7ea-32848046667a',
          recommended_items: [
            'cbd15b09-a764-4521-8881-1999274f737a',
            'cbd17362-2f5a-454d-a57d-641136b2a700',
            'cbd17c8e-b10b-4f5f-9448-ed2d4fbf4b16',
          ],
        },
        {
          id: 'cbd17d06-1bbe-4343-820c-c4f11ff1e40b',
          recommended_items: [
            'cbd15b09-a764-4521-8881-1999274f737a',
            'cbd17362-2f5a-454d-a57d-641136b2a700',
            'cbd17c8e-b10b-4f5f-9448-ed2d4fbf4b16',
          ],
        },
        {
          id: 'cbd15d0f-4923-44f8-92ba-dfa67c216a9b',
          recommended_items: [
            'cbd15b09-a764-4521-8881-1999274f737a',
            'cbd17362-2f5a-454d-a57d-641136b2a700',
            'cbd17c8e-b10b-4f5f-9448-ed2d4fbf4b16',
          ],
        },
        {
          id: 'cbd15e6f-7fdd-4502-b467-964144f9bf2b',
          recommended_items: [
            'cbd15b09-a764-4521-8881-1999274f737a',
            'cbd17362-2f5a-454d-a57d-641136b2a700',
            'cbd17c8e-b10b-4f5f-9448-ed2d4fbf4b16',
          ],
        },
        {
          id: 'cbd15748-46b5-44ad-9710-0b01be1c5c8e',
          recommended_items: [
            'cbd15b09-a764-4521-8881-1999274f737a',
            'cbd17362-2f5a-454d-a57d-641136b2a700',
            'cbd17c8e-b10b-4f5f-9448-ed2d4fbf4b16',
          ],
        },
        {
          id: 'cbd1105b-7359-42c7-a891-9d0ed5962172',
          recommended_items: [
            'cbd15b09-a764-4521-8881-1999274f737a',
            'cbd17362-2f5a-454d-a57d-641136b2a700',
            'cbd17c8e-b10b-4f5f-9448-ed2d4fbf4b16',
          ],
        },
        {
          id: 'cbd11246-24eb-45d3-8c6b-7b4d490de0fc',
          recommended_items: [
            'cbd15b09-a764-4521-8881-1999274f737a',
            'cbd1482f-0216-46a9-8411-22fc6de60ae9',
            'cbd17c8e-b10b-4f5f-9448-ed2d4fbf4b16',
          ],
        },
        {
          id: 'cbd10f5b-8779-4c67-838f-661efe9240ef',
          recommended_items: [
            'cbd15b09-a764-4521-8881-1999274f737a',
            'cbd17362-2f5a-454d-a57d-641136b2a700',
            'cbd17c8e-b10b-4f5f-9448-ed2d4fbf4b16',
          ],
        },
        {
          id: 'cbd10536-b6c0-4814-ae97-de9ed13f7288',
          recommended_items: [
            'cbd15b09-a764-4521-8881-1999274f737a',
            'cbd17362-2f5a-454d-a57d-641136b2a700',
            'cbd17c8e-b10b-4f5f-9448-ed2d4fbf4b16',
          ],
        },
        {
          id: 'cbd11f80-911a-45ca-97a4-25508488f97f',
          recommended_items: [
            'cbd15b09-a764-4521-8881-1999274f737a',
            'cbd17362-2f5a-454d-a57d-641136b2a700',
            'cbd17c8e-b10b-4f5f-9448-ed2d4fbf4b16',
          ],
        },
        {
          id: 'cbd12f81-cb70-4d46-8082-efd85834eb08',
          recommended_items: [
            'cbd15b09-a764-4521-8881-1999274f737a',
            'cbd17362-2f5a-454d-a57d-641136b2a700',
            'cbd17c8e-b10b-4f5f-9448-ed2d4fbf4b16',
          ],
        },
      ],
      menu_heading: [],
    },
    {
      id: '750033c8-cfb2-4646-9946-6c9d5b58110f',
      cart_recommendations: [],
      menu_item: [],
      menu_heading: [
        {
          id: 'cc0d45b1-dc87-4e2c-b729-1c3db2051305',
          recommended_items: [
            'cbfa6788-7d4f-44ad-9b27-b66f07725393',
            'cbe2f03f-3bd2-43aa-8fba-74ae794a01f9',
            'cbe2cd5e-e8fd-4b01-a019-ed6d96bcc4be',
            'cbe2d362-960e-4de6-b39a-6cec010a4978',
          ],
        },
        {
          id: 'cbe2fe82-44d6-4bce-b61b-f13e25cea1f4',
          recommended_items: [
            'cc0d5968-30d1-450b-8750-17d04e79b52e',
            'cbe2cde9-9996-43b4-95c2-2ff5ab94ea1d',
            'cbe2c1e3-708a-49f5-80fe-726523d9c2c5',
          ],
        },
        {
          id: 'cbe2c0af-6210-4e98-b169-c21bd089f9bd',
          recommended_items: [
            'cbfa6788-7d4f-44ad-9b27-b66f07725393',
            'cbe2f03f-3bd2-43aa-8fba-74ae794a01f9',
            'cbe2cd5e-e8fd-4b01-a019-ed6d96bcc4be',
            'cbe2d362-960e-4de6-b39a-6cec010a4978',
          ],
        },
        {
          id: 'cbe2c183-d432-4735-bc08-e77227829990',
          recommended_items: [
            'cbfa6788-7d4f-44ad-9b27-b66f07725393',
            'cbe2f03f-3bd2-43aa-8fba-74ae794a01f9',
            'cbe2cd5e-e8fd-4b01-a019-ed6d96bcc4be',
            'cbe2d362-960e-4de6-b39a-6cec010a4978',
          ],
        },
        {
          id: 'cbe2f17e-8432-4303-923d-05f8fbddc58e',
          recommended_items: [
            'cbfa6788-7d4f-44ad-9b27-b66f07725393',
            'cbe2f03f-3bd2-43aa-8fba-74ae794a01f9',
            'cbe2cd5e-e8fd-4b01-a019-ed6d96bcc4be',
            'cbe2d362-960e-4de6-b39a-6cec010a4978',
          ],
        },
        {
          id: 'cbe2f1ae-8cbe-4210-8f45-21e430423af2',
          recommended_items: [
            'cbfa6788-7d4f-44ad-9b27-b66f07725393',
            'cbe2f03f-3bd2-43aa-8fba-74ae794a01f9',
            'cbe2cd5e-e8fd-4b01-a019-ed6d96bcc4be',
            'cbe2d362-960e-4de6-b39a-6cec010a4978',
          ],
        },
        {
          id: 'cbe2c2b6-100c-4d07-b13c-a9819b0ecdcb',
          recommended_items: [
            'cbfa6788-7d4f-44ad-9b27-b66f07725393',
            'cbe2f03f-3bd2-43aa-8fba-74ae794a01f9',
            'cbe2cd5e-e8fd-4b01-a019-ed6d96bcc4be',
            'cbe2d362-960e-4de6-b39a-6cec010a4978',
          ],
        },
        {
          id: 'cbe2c095-2097-473e-a07b-62159f155a0f',
          recommended_items: [
            'cbfa6788-7d4f-44ad-9b27-b66f07725393',
            'cbe2f03f-3bd2-43aa-8fba-74ae794a01f9',
            'cbe2cd5e-e8fd-4b01-a019-ed6d96bcc4be',
            'cbe2d362-960e-4de6-b39a-6cec010a4978',
          ],
        },
        {
          id: 'cbe2e9b7-3a19-41b3-b18b-433405e495ea',
          recommended_items: [
            'cbfa6788-7d4f-44ad-9b27-b66f07725393',
            'cbe2f03f-3bd2-43aa-8fba-74ae794a01f9',
            'cbe2cd5e-e8fd-4b01-a019-ed6d96bcc4be',
            'cbe2d362-960e-4de6-b39a-6cec010a4978',
          ],
        },
        {
          id: 'cbe2faa4-738d-490b-b843-3ab05d3e47f8',
          recommended_items: [
            'cbfa6788-7d4f-44ad-9b27-b66f07725393',
            'cbe2f03f-3bd2-43aa-8fba-74ae794a01f9',
            'cbe2cd5e-e8fd-4b01-a019-ed6d96bcc4be',
            'cbe2d362-960e-4de6-b39a-6cec010a4978',
          ],
        },
        {
          id: 'cc0d5593-cb66-4dbe-a2e1-fa65467cb224',
          recommended_items: [
            'cbfa6788-7d4f-44ad-9b27-b66f07725393',
            'cbe2f03f-3bd2-43aa-8fba-74ae794a01f9',
            'cbe2cd5e-e8fd-4b01-a019-ed6d96bcc4be',
            'cbe2d362-960e-4de6-b39a-6cec010a4978',
          ],
        },
        {
          id: 'cc0d4b90-f3d6-4de5-b55e-ff2bb8f51810',
          recommended_items: [
            'cbfa6788-7d4f-44ad-9b27-b66f07725393',
            'cbe2f03f-3bd2-43aa-8fba-74ae794a01f9',
            'cbe2cd5e-e8fd-4b01-a019-ed6d96bcc4be',
            'cbe2d362-960e-4de6-b39a-6cec010a4978',
          ],
        },
      ],
    },
    {
      id: '908d5b31-8513-490e-a741-fe98a545b524',
      cart_recommendations: [],
      menu_item: [],
      menu_heading: [
        {
          id: 'cbfa68aa-25e8-4c16-afa2-38c0a3269e57',
          recommended_items: [
            'cbfa62d4-2996-4f59-b05f-7dddccf8342e',
            'cbfa51b6-876b-4cf9-80de-9158ce1c96be',
            'cbfa44ad-0148-4d3e-a917-32d480549e0b',
            'cbfa4bdd-d583-40c8-990a-27c31e49af34',
          ],
        },
        {
          id: 'cbfa4b4f-ae03-436b-b19f-ff89f6d22bf9',
          recommended_items: [
            'cbfa5c4a-d7cc-4724-8348-1f9e5cacaf3f',
            'cbfa5286-2f97-4438-9d65-fb248064da90',
            'cbfa69fd-3abb-44eb-b243-05405dc401aa',
          ],
        },
        {
          id: 'cbfa7733-b957-4ef4-8b8d-7f507ae397e7',
          recommended_items: [
            'cbfa62d4-2996-4f59-b05f-7dddccf8342e',
            'cbfa51b6-876b-4cf9-80de-9158ce1c96be',
            'cbfa44ad-0148-4d3e-a917-32d480549e0b',
            'cbfa4bdd-d583-40c8-990a-27c31e49af34',
          ],
        },
        {
          id: 'cbfa44ab-630c-4d51-86c2-152f9e36cb35',
          recommended_items: [
            'cbfa62d4-2996-4f59-b05f-7dddccf8342e',
            'cbfa51b6-876b-4cf9-80de-9158ce1c96be',
            'cbfa44ad-0148-4d3e-a917-32d480549e0b',
            'cbfa4bdd-d583-40c8-990a-27c31e49af34',
          ],
        },
        {
          id: 'cbfa527d-5b84-4464-b94c-6724775908f8',
          recommended_items: [
            'cbfa62d4-2996-4f59-b05f-7dddccf8342e',
            'cbfa51b6-876b-4cf9-80de-9158ce1c96be',
            'cbfa44ad-0148-4d3e-a917-32d480549e0b',
            'cbfa4bdd-d583-40c8-990a-27c31e49af34',
          ],
        },
        {
          id: 'cbfa6a17-b279-4160-8600-5123e526aa67',
          recommended_items: [
            'cbfa62d4-2996-4f59-b05f-7dddccf8342e',
            'cbfa51b6-876b-4cf9-80de-9158ce1c96be',
            'cbfa44ad-0148-4d3e-a917-32d480549e0b',
            'cbfa4bdd-d583-40c8-990a-27c31e49af34',
          ],
        },
        {
          id: 'cbfa6cf8-906c-4f0c-90a7-490ee002fe0c',
          recommended_items: [
            'cbfa62d4-2996-4f59-b05f-7dddccf8342e',
            'cbfa51b6-876b-4cf9-80de-9158ce1c96be',
            'cbfa44ad-0148-4d3e-a917-32d480549e0b',
            'cbfa4bdd-d583-40c8-990a-27c31e49af34',
          ],
        },
        {
          id: 'cbfa40bc-a9d6-472f-8e96-15bc1095e151',
          recommended_items: [
            'cbfa62d4-2996-4f59-b05f-7dddccf8342e',
            'cbfa51b6-876b-4cf9-80de-9158ce1c96be',
            'cbfa44ad-0148-4d3e-a917-32d480549e0b',
            'cbfa4bdd-d583-40c8-990a-27c31e49af34',
          ],
        },
        {
          id: 'cbfa7365-560c-4240-8fb7-0d952f5fddb5',
          recommended_items: [
            'cbfa62d4-2996-4f59-b05f-7dddccf8342e',
            'cbfa51b6-876b-4cf9-80de-9158ce1c96be',
            'cbfa44ad-0148-4d3e-a917-32d480549e0b',
            'cbfa4bdd-d583-40c8-990a-27c31e49af34',
          ],
        },
        {
          id: 'cbfa7586-d38c-4fe7-ac77-3ace99744d01',
          recommended_items: [
            'cbfa62d4-2996-4f59-b05f-7dddccf8342e',
            'cbfa51b6-876b-4cf9-80de-9158ce1c96be',
            'cbfa44ad-0148-4d3e-a917-32d480549e0b',
            'cbfa4bdd-d583-40c8-990a-27c31e49af34',
          ],
        },
        {
          id: 'cbfa4c8c-94e2-4df8-ae2a-04f5dc48582a',
          recommended_items: [
            'cbfa62d4-2996-4f59-b05f-7dddccf8342e',
            'cbfa51b6-876b-4cf9-80de-9158ce1c96be',
            'cba44ad-0148-4d3e-a917-32d480549e0b',
            'cbfa4bdd-d583-40c8-990a-27c31e49af34',
          ],
        },
        {
          id: '',
          recommended_items: [
            'cbfa62d4-2996-4f59-b05f-7dddccf8342e',
            'cbfa51b6-876b-4cf9-80de-9158ce1c96be',
            'cbfa44ad-0148-4d3e-a917-32d480549e0b',
            'cbfa4bdd-d583-40c8-990a-27c31e49af34',
          ],
        },
      ],
    },
    {
      id: '19df1f1a-5a0c-4ba0-b3e1-9e70f4cc7a03',
      cart_recommendations: [],
      menu_item: [
        {
          id: 'cbfa4f82-5911-4cd3-a6a2-b05b097f9929',
          recommended_items: [
            'cbfa4650-091d-46c6-ac23-cea35c13f431',
            'cbfa4671-acd6-49b8-b7c4-406a12256c44',
            'cbfa6cde-74a1-4ebb-8661-387247aec9d4',
            'cbfa75d9-b4bb-4f7f-9efb-5a0630262157',
          ],
        },
        {
          id: 'cbfa47f1-179b-4152-8b05-4f836708b6d8',
          recommended_items: [
            'cbfa4650-091d-46c6-ac23-cea35c13f431',
            'cbfa4671-acd6-49b8-b7c4-406a12256c44',
            'cbfa717e-2ac0-47bb-a6b9-3bd3e13aec73',
            'cbfa75d9-b4bb-4f7f-9efb-5a0630262157',
          ],
        },
        {
          id: 'cbfa717e-2ac0-47bb-a6b9-3bd3e13aec73',
          recommended_items: [
            'cbfa4650-091d-46c6-ac23-cea35c13f431',
            'cbfa4671-acd6-49b8-b7c4-406a12256c44',
            'cbfa4ee4-2471-4c7d-921e-9bf726fd5833',
            'cbfa4f82-5911-4cd3-a6a2-b05b097f9929',
          ],
        },
        {
          id: 'cbfa4ee4-2471-4c7d-921e-9bf726fd5833',
          recommended_items: [
            'cbfa4650-091d-46c6-ac23-cea35c13f431',
            'cbfa4447-8def-4544-a8c5-9f73d8cff6b9',
            'cbfa47f1-179b-4152-8b05-4f836708b6d8',
            'cbfa4f82-5911-4cd3-a6a2-b05b097f9929',
          ],
        },
        {
          id: 'cbfa4447-8def-4544-a8c5-9f73d8cff6b9',
          recommended_items: [
            'cbfa4650-091d-46c6-ac23-cea35c13f431',
            'cbfa4ee4-2471-4c7d-921e-9bf726fd5833',
            'cbfa6cde-74a1-4ebb-8661-387247aec9d4',
            'cbfa4f82-5911-4cd3-a6a2-b05b097f9929',
          ],
        },
        {
          id: 'cbfa6cde-74a1-4ebb-8661-387247aec9d4',
          recommended_items: [
            'cbfa4650-091d-46c6-ac23-cea35c13f431',
            'cbfa4671-acd6-49b8-b7c4-406a12256c44',
            'cbfa4447-8def-4544-a8c5-9f73d8cff6b9',
            'cbfa4f82-5911-4cd3-a6a2-b05b097f9929',
          ],
        },
        {
          id: 'cbfa4671-acd6-49b8-b7c4-406a12256c44',
          recommended_items: [
            'cbfa4ee4-2471-4c7d-921e-9bf726fd5833',
            'cbfa4447-8def-4544-a8c5-9f73d8cff6b9',
            'cbfa6cde-74a1-4ebb-8661-387247aec9d4',
            'cbfa717e-2ac0-47bb-a6b9-3bd3e13aec73',
          ],
        },
        {
          id: 'cbfa5350-b2ff-48d5-861b-ef33ce1148b5',
          recommended_items: [
            'cbfa4ee4-2471-4c7d-921e-9bf726fd5833',
            'cbfa4447-8def-4544-a8c5-9f73d8cff6b9',
            'cbfa6cde-74a1-4ebb-8661-387247aec9d4',
            'cbfa717e-2ac0-47bb-a6b9-3bd3e13aec73',
          ],
        },
        {
          id: 'cbfa79ce-e3bb-4fac-9d0c-d517a79c125e',
          recommended_items: [
            'cbfa4ee4-2471-4c7d-921e-9bf726fd5833',
            'cbfa4447-8def-4544-a8c5-9f73d8cff6b9',
            'cbfa6cde-74a1-4ebb-8661-387247aec9d4',
            'cbfa717e-2ac0-47bb-a6b9-3bd3e13aec73',
          ],
        },
        {
          id: 'cbfa798a-ae25-41b6-8a0d-06d906ffd8fa',
          recommended_items: [
            'cbfa4ee4-2471-4c7d-921e-9bf726fd5833',
            'cbfa4447-8def-4544-a8c5-9f73d8cff6b9',
            'cbfa6cde-74a1-4ebb-8661-387247aec9d4',
            'cbfa717e-2ac0-47bb-a6b9-3bd3e13aec73',
          ],
        },
        {
          id: 'cbfa63ba-ef9f-4f0e-8c19-cd07e4a7fe54',
          recommended_items: [
            'cbfa5c28-227d-449d-96ca-4e27523c0e3e',
            'cbfa7922-da44-4f1c-922d-fd31c98bd781',
            'cbfa4879-b80a-4440-b3a4-7b241c3b494a',
            'cbfa6759-ffa0-44a6-84cb-47c3a84963be',
          ],
        },
        {
          id: 'cbfa75d9-b4bb-4f7f-9efb-5a0630262157',
          recommended_items: [
            'cbfa5c28-227d-449d-96ca-4e27523c0e3e',
            'cbfa7922-da44-4f1c-922d-fd31c98bd781',
            'cbfa4879-b80a-4440-b3a4-7b241c3b494a',
            'cbfa6759-ffa0-44a6-84cb-47c3a84963be',
          ],
        },
        {
          id: 'cbfa6950-2ddb-4080-84af-a2ed4579aeb0',
          recommended_items: [
            'cbfa5c28-227d-449d-96ca-4e27523c0e3e',
            'cbfa7922-da44-4f1c-922d-fd31c98bd781',
            'cbfa4879-b80a-4440-b3a4-7b241c3b494a',
            'cbfa6759-ffa0-44a6-84cb-47c3a84963be',
          ],
        },
        {
          id: 'cbfa7872-e639-46ff-8432-06f64281f0f4',
          recommended_items: [
            'cbfa5c28-227d-449d-96ca-4e27523c0e3e',
            'cbfa7922-da44-4f1c-922d-fd31c98bd781',
            'cbfa4879-b80a-4440-b3a4-7b241c3b494a',
            'cbfa6759-ffa0-44a6-84cb-47c3a84963be',
          ],
        },
        {
          id: 'cbfa6979-7dff-49e7-aefe-68269f261209',
          recommended_items: [
            'cbfa5c28-227d-449d-96ca-4e27523c0e3e',
            'cbfa7922-da44-4f1c-922d-fd31c98bd781',
            'cbfa4879-b80a-4440-b3a4-7b241c3b494a',
            'cbfa6759-ffa0-44a6-84cb-47c3a84963be',
          ],
        },
        {
          id: 'cbfa50e3-3509-4a21-a373-f232af229bd4',
          recommended_items: [
            'cbfa5c28-227d-449d-96ca-4e27523c0e3e',
            'cbfa7922-da44-4f1c-922d-fd31c98bd781',
            'cbfa4879-b80a-4440-b3a4-7b241c3b494a',
            'cbfa6759-ffa0-44a6-84cb-47c3a84963be',
          ],
        },
        {
          id: 'cbfa7bf8-f5a5-4ac8-a2f6-f234ba80b955',
          recommended_items: [
            'cbfa5c28-227d-449d-96ca-4e27523c0e3e',
            'cbfa7922-da44-4f1c-922d-fd31c98bd781',
            'cbfa4879-b80a-4440-b3a4-7b241c3b494a',
            'cbfa6759-ffa0-44a6-84cb-47c3a84963be',
          ],
        },
        {
          id: 'cbfa4ffc-7c18-40c1-8f34-a6c960561097',
          recommended_items: [
            'cbfa5c28-227d-449d-96ca-4e27523c0e3e',
            'cbfa7922-da44-4f1c-922d-fd31c98bd781',
            'cbfa4879-b80a-4440-b3a4-7b241c3b494a',
            'cbfa6759-ffa0-44a6-84cb-47c3a84963be',
          ],
        },
        {
          id: 'cbfa58a8-1745-434d-806d-c96069f01510',
          recommended_items: [
            'cbfa5c28-227d-449d-96ca-4e27523c0e3e',
            'cbfa7922-da44-4f1c-922d-fd31c98bd781',
            'cbfa4879-b80a-4440-b3a4-7b241c3b494a',
            'cbfa6759-ffa0-44a6-84cb-47c3a84963be',
          ],
        },
        {
          id: 'cbfa57ed-da86-423d-bc36-40ace432ae13',
          recommended_items: [
            'cbfa5c28-227d-449d-96ca-4e27523c0e3e',
            'cbfa7922-da44-4f1c-922d-fd31c98bd781',
            'cbfa4879-b80a-4440-b3a4-7b241c3b494a',
            'cbfa6759-ffa0-44a6-84cb-47c3a84963be',
          ],
        },
        {
          id: 'cbfa42db-982a-4f89-918c-088d2f7f7cf1',
          recommended_items: [
            'cbfa5c28-227d-449d-96ca-4e27523c0e3e',
            'cbfa7922-da44-4f1c-922d-fd31c98bd781',
            'cbfa4879-b80a-4440-b3a4-7b241c3b494a',
            'cbfa6759-ffa0-44a6-84cb-47c3a84963be',
          ],
        },
        {
          id: 'cbfa50e4-1a98-43bf-b957-beb337459f95',
          recommended_items: [
            'cbfa5c28-227d-449d-96ca-4e27523c0e3e',
            'cbfa7922-da44-4f1c-922d-fd31c98bd781',
            'cbfa4879-b80a-4440-b3a4-7b241c3b494a',
            'cbfa6759-ffa0-44a6-84cb-47c3a84963be',
          ],
        },
        {
          id: 'cbfa6ad8-f428-4b99-a822-11f72995e10a',
          recommended_items: [
            'cbfa5c28-227d-449d-96ca-4e27523c0e3e',
            'cbfa7922-da44-4f1c-922d-fd31c98bd781',
            'cbfa4879-b80a-4440-b3a4-7b241c3b494a',
            'cbfa6759-ffa0-44a6-84cb-47c3a84963be',
          ],
        },
        {
          id: 'cbfa5302-f626-4337-90a2-59eb2fccd6d9',
          recommended_items: [
            'cbfa79ce-e3bb-4fac-9d0c-d517a79c125e',
            'cbfa798a-ae25-41b6-8a0d-06d906ffd8fa',
            'cbfa5350-b2ff-48d5-861b-ef33ce1148b5',
            'cbfa6759-ffa0-44a6-84cb-47c3a84963be',
          ],
        },
        {
          id: 'cbfa5ab3-460a-496e-b474-f6cc95a712b7',
          recommended_items: [
            'cbfa79ce-e3bb-4fac-9d0c-d517a79c125e',
            'cbfa798a-ae25-41b6-8a0d-06d906ffd8fa',
            'cbfa5350-b2ff-48d5-861b-ef33ce1148b5',
            'cbfa6759-ffa0-44a6-84cb-47c3a84963be',
          ],
        },
        {
          id: 'cbfa41b5-dcad-42f6-97d8-2b28bb1ca380',
          recommended_items: [
            'cbfa79ce-e3bb-4fac-9d0c-d517a79c125e',
            'cbfa798a-ae25-41b6-8a0d-06d906ffd8fa',
            'cbfa5350-b2ff-48d5-861b-ef33ce1148b5',
            'cbfa6759-ffa0-44a6-84cb-47c3a84963be',
          ],
        },
        {
          id: 'cbfa7784-d7e6-4f33-a3e5-4741d64eb59b',
          recommended_items: ['cbfa656d-6680-47aa-81bb-cbd434e42cda', 'cbfa4ae3-d834-4494-9200-5a329c705989'],
        },
        {
          id: 'cbfa656d-6680-47aa-81bb-cbd434e42cda',
          recommended_items: ['cbfa7784-d7e6-4f33-a3e5-4741d64eb59b', 'cbfa4ae3-d834-4494-9200-5a329c705989'],
        },
        {
          id: 'cbfa4ae3-d834-4494-9200-5a329c705989',
          recommended_items: ['cbfa7784-d7e6-4f33-a3e5-4741d64eb59b', 'cbfa656d-6680-47aa-81bb-cbd434e42cda'],
        },
        {
          id: 'cbfa4879-b80a-4440-b3a4-7b241c3b494a',
          recommended_items: [
            'cbfa63ba-ef9f-4f0e-8c19-cd07e4a7fe54',
            'cbfa75d9-b4bb-4f7f-9efb-5a0630262157',
            'cbfa6950-2ddb-4080-84af-a2ed4579aeb0',
            'cbfa7872-e639-46ff-8432-06f64281f0f4',
          ],
        },
        {
          id: 'cbfa5ab6-0e90-40bb-b3be-efba01f751e7',
          recommended_items: [
            'cbfa63ba-ef9f-4f0e-8c19-cd07e4a7fe54',
            'cbfa75d9-b4bb-4f7f-9efb-5a0630262157',
            'cbfa6950-2ddb-4080-84af-a2ed4579aeb0',
            'cbfa7872-e639-46ff-8432-06f64281f0f4',
          ],
        },
        {
          id: 'cbfa5c28-227d-449d-96ca-4e27523c0e3e',
          recommended_items: [
            'cbfa63ba-ef9f-4f0e-8c19-cd07e4a7fe54',
            'cbfa75d9-b4bb-4f7f-9efb-5a0630262157',
            'cbfa6950-2ddb-4080-84af-a2ed4579aeb0',
            'cbfa7872-e639-46ff-8432-06f64281f0f4',
          ],
        },
        {
          id: 'cbfa6759-ffa0-44a6-84cb-47c3a84963be',
          recommended_items: [
            'cbfa63ba-ef9f-4f0e-8c19-cd07e4a7fe54',
            'cbfa75d9-b4bb-4f7f-9efb-5a0630262157',
            'cbfa6950-2ddb-4080-84af-a2ed4579aeb0',
            'cbfa7872-e639-46ff-8432-06f64281f0f4',
          ],
        },
        {
          id: 'cbfa7547-8919-4358-9713-464a9ae4ed30',
          recommended_items: [
            'cbfa63ba-ef9f-4f0e-8c19-cd07e4a7fe54',
            'cbfa75d9-b4bb-4f7f-9efb-5a0630262157',
            'cbfa6950-2ddb-4080-84af-a2ed4579aeb0',
            'cbfa7872-e639-46ff-8432-06f64281f0f4',
          ],
        },
        {
          id: 'cbfa7922-da44-4f1c-922d-fd31c98bd781',
          recommended_items: [
            'cbfa63ba-ef9f-4f0e-8c19-cd07e4a7fe54',
            'cbfa75d9-b4bb-4f7f-9efb-5a0630262157',
            'cbfa6950-2ddb-4080-84af-a2ed4579aeb0',
            'cbfa7872-e639-46ff-8432-06f64281f0f4',
          ],
        },
        {
          id: 'cbfa7bc5-7aa3-4ef4-b8fa-eb9970152b90',
          recommended_items: [
            'cbfa63ba-ef9f-4f0e-8c19-cd07e4a7fe54',
            'cbfa75d9-b4bb-4f7f-9efb-5a0630262157',
            'cbfa6950-2ddb-4080-84af-a2ed4579aeb0',
            'cbfa7872-e639-46ff-8432-06f64281f0f4',
          ],
        },
      ],
      menu_heading: [],
    },
    {
      id: '414d94c4-a666-428d-8022-d99c40bc00f9',
      cart_recommendations: [],
      menu_item: [
        {
          id: 'cd2c7e49-273c-4905-94ce-f247fe77694f',
          recommended_items: [
            'cd2c56fc-ebcc-4bed-964c-7f414dcf9c53',
            'cd2c6392-81ad-41a3-ab35-564c7c94c500',
            'cd2c6b38-0c68-409e-8429-9eaa34952b99',
            'cd2c59bf-3749-44fe-b459-7d71e6e6e8e7',
            'cd2c50a7-88bb-4373-8f7c-0c119bb49d92',
            'cd2c6741-6b24-47d3-8473-f5c4722d4624',
          ],
        },
        {
          id: 'cd2c7965-5cd8-4756-97f2-c4db6e2a745c',
          recommended_items: [
            'cd2c7dc0-7c76-42a7-af81-6b642e7d49b0',
            'cd2c56fc-ebcc-4bed-964c-7f414dcf9c53',
            'cd2c5a13-88f4-464d-b4e5-bee22f4cabbe',
            'cd2c59bf-3749-44fe-b459-7d71e6e6e8e7',
            'cd2c50a7-88bb-4373-8f7c-0c119bb49d92',
            'cd2c6741-6b24-47d3-8473-f5c4722d4624',
            'cd2c72d2-8ae3-4bd7-9024-f3417763e03f',
          ],
        },
        {
          id: 'cd2c6649-e3c5-4e5f-9d7b-ec02d55c3c22',
          recommended_items: [
            'cd2c56fc-ebcc-4bed-964c-7f414dcf9c53',
            'cd2c7dc0-7c76-42a7-af81-6b642e7d49b0',
            'cd2c6b38-0c68-409e-8429-9eaa34952b99',
            'cd2c6392-81ad-41a3-ab35-564c7c94c500',
          ],
        },
        {
          id: 'cd2c5a13-88f4-464d-b4e5-bee22f4cabbe',
          recommended_items: ['cd2c7dc0-7c76-42a7-af81-6b642e7d49b0', 'cd2c56fc-ebcc-4bed-964c-7f414dcf9c53'],
        },
        {
          id: 'cd2c5ec8-639e-4ddf-8e74-914f5dd125bf',
          recommended_items: ['cd2c4536-a8c9-460d-a17c-10f87016830c', 'cd2c6392-81ad-41a3-ab35-564c7c94c500'],
        },
        {
          id: 'cd2c5c93-7c1b-4fa7-91d1-0587ba1f9505',
          recommended_items: [
            'cd2c4536-a8c9-460d-a17c-10f87016830c',
            'cd2c6392-81ad-41a3-ab35-564c7c94c500',
            'cd2c56fc-ebcc-4bed-964c-7f414dcf9c53',
          ],
        },
        {
          id: 'cd2c6a05-eaf4-499b-994d-d86f088450e9',
          recommended_items: [
            'cd2c4536-a8c9-460d-a17c-10f87016830c',
            'cd2c56fc-ebcc-4bed-964c-7f414dcf9c53',
            'cd2c437e-0443-4426-be37-d40d8c15c778',
            'cd2c7e49-273c-4905-94ce-f247fe77694f',
          ],
        },
        {
          id: 'cd2c72d2-8ae3-4bd7-9024-f3417763e03f',
          recommended_items: [
            'cd2c7c09-a504-4d56-9ff2-495e93b0ad82',
            'cd2c6392-81ad-41a3-ab35-564c7c94c500',
            'cd2c7e49-273c-4905-94ce-f247fe77694f',
          ],
        },
        {
          id: 'cd2c7c09-a504-4d56-9ff2-495e93b0ad82',
          recommended_items: [
            'cd2c4536-a8c9-460d-a17c-10f87016830c',
            'cd2c49c6-860b-4343-a9e8-3548d1130144',
            'cd2c6392-81ad-41a3-ab35-564c7c94c500',
          ],
        },
        {
          id: 'cd2c61cf-61f5-44d3-b84a-5209d7d93adb',
          recommended_items: ['cd2c6392-81ad-41a3-ab35-564c7c94c500', 'cd2c56fc-ebcc-4bed-964c-7f414dcf9c53'],
        },
        {
          id: 'cd2c7dc0-7c76-42a7-af81-6b642e7d49b0',
          recommended_items: ['cd2c4fd7-0a2a-4b76-9dde-a46e27163e28', 'cd2c5a13-88f4-464d-b4e5-bee22f4cabbe'],
        },
        {
          id: 'cd2c4fd7-0a2a-4b76-9dde-a46e27163e28',
          recommended_items: [
            'cd2c56fc-ebcc-4bed-964c-7f414dcf9c53',
            'cd2c4536-a8c9-460d-a17c-10f87016830c',
            'cd2c7965-5cd8-4756-97f2-c4db6e2a745c',
          ],
        },
        {
          id: 'cd2c56fc-ebcc-4bed-964c-7f414dcf9c53',
          recommended_items: [
            'cd2c5c93-7c1b-4fa7-91d1-0587ba1f9505',
            'cd2c6a05-eaf4-499b-994d-d86f088450e9',
            'cd2c7e49-273c-4905-94ce-f247fe77694f',
          ],
        },
        {
          id: 'cd2c6392-81ad-41a3-ab35-564c7c94c500',
          recommended_items: [
            'cd2c7dc0-7c76-42a7-af81-6b642e7d49b0',
            'cd2c7c09-a504-4d56-9ff2-495e93b0ad82',
            'cd2c4fd7-0a2a-4b76-9dde-a46e27163e28',
            'cd2c5ec8-639e-4ddf-8e74-914f5dd125bf',
          ],
        },
        {
          id: 'cd2c4536-a8c9-460d-a17c-10f87016830c',
          recommended_items: [
            'cd2c5ec8-639e-4ddf-8e74-914f5dd125bf',
            'cd2c72d2-8ae3-4bd7-9024-f3417763e03f',
            'cd2c49c6-860b-4343-a9e8-3548d1130144',
            'cd2c59bf-3749-44fe-b459-7d71e6e6e8e7',
            'cd2c50a7-88bb-4373-8f7c-0c119bb49d92',
            'cd2c6741-6b24-47d3-8473-f5c4722d4624',
          ],
        },
      ],
      menu_heading: [],
    },
    {
      id: 'd0e4e241-1f66-4951-83a7-1dc610ff5d72',
      cart_recommendations: [],
      menu_item: [
        {
          id: 'cc609213-4791-4c6b-b9c2-d41383afd3f9',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc60ad6c-96be-47e1-9ed2-565b8ecca38c',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc60b2fe-91fb-460a-91bb-4715486a3f86',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc6090ef-d2e6-42c0-ac3b-fde856de1260',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc609966-4fab-49a4-8942-61333fe74109',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc60a41a-cc52-4e40-8ea6-309ddc18ecc8',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc6080b0-3ea6-43cc-a549-2fe3d791af87',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc609bd2-fe7c-4e1b-aa95-988c59b52e40',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc60a0af-adc5-48ee-addc-1c6406d3b1ca',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc60b91d-14a6-482b-bfd4-2df2e0a1fee0',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc60b92e-1be8-4680-9ff7-63eaa60d17f9',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc60b894-5b15-4772-9aa6-c2af5f69aab7',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc60ac7b-9630-4b3f-92ee-98e477db36d2',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc6094a1-281c-4084-84b0-73f158b9ac50',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc6087ac-0c2c-48f1-b4cf-be0dbe969645',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc609bd3-c7e2-4ee8-9ac9-3df63a6d8bd5',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc60a52b-2235-4ea8-a1b8-b2d6531eb6a3',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc60bbcd-96c6-4bbd-89d5-346e71589dd5',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc609c38-d017-4b7e-9b24-2e33ceb18f6c',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc60b206-7a35-470d-975b-26d2f1c9cb0e',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc60be28-e9fa-41d1-a3a9-107aeee378c9',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc609c3e-c68d-42a5-822c-964ae148542f',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc60965e-11ff-4dcd-bb34-7ae692d8bccb',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc60a803-8ea3-45c9-89f9-218383047d8b',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc60b800-9b00-4faf-b26b-ece4b2b96241',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc60923f-c56f-4ac5-94da-91eb9b1ae709',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc60a799-5423-4543-bf46-f25eaef53973',
          recommended_items: [
            'cc60be41-f868-4a7b-8b92-c43407f2d4ab',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
        {
          id: 'cc60aefc-c092-428e-b168-db8a240dbfe2',
          recommended_items: ['cc6094c3-2fe1-404b-bf1d-f801ea3b34bd', 'cc60a58d-f0f3-45a1-81b0-4f6dba38d830'],
        },
        {
          id: 'cc60bb1d-2976-4ee2-99d0-8b44a8f96031',
          recommended_items: ['cc6094c3-2fe1-404b-bf1d-f801ea3b34bd', 'cc60a58d-f0f3-45a1-81b0-4f6dba38d830'],
        },
        {
          id: 'cc60a4b9-edd9-4e88-8f6c-ea43c2fe9a7a',
          recommended_items: ['cc6094c3-2fe1-404b-bf1d-f801ea3b34bd', 'cc60a58d-f0f3-45a1-81b0-4f6dba38d830'],
        },
        {
          id: 'cc60ad11-c8bf-4b23-a973-04707ed76eea',
          recommended_items: ['cc6094c3-2fe1-404b-bf1d-f801ea3b34bd', 'cc60a58d-f0f3-45a1-81b0-4f6dba38d830'],
        },
        {
          id: 'cc60a245-797e-49c3-958b-1307416718e6',
          recommended_items: ['cc6094c3-2fe1-404b-bf1d-f801ea3b34bd', 'cc60a58d-f0f3-45a1-81b0-4f6dba38d830'],
        },
        {
          id: 'cc608310-55fc-4107-857d-49732de278c2',
          recommended_items: ['cc6094c3-2fe1-404b-bf1d-f801ea3b34bd', 'cc60a58d-f0f3-45a1-81b0-4f6dba38d830'],
        },
        {
          id: 'cc60a0a5-c1af-4e9d-b032-5a9e655483b0',
          recommended_items: [
            'cc608672-0d32-4ad7-98d1-fe6eddcc853f',
            'cc60b8c5-7add-40a5-8fe7-90bf05bec225',
            'cc60ab5a-5ed0-4952-88cb-93e9e821bb65',
            'cc608c49-ae3e-44e4-9591-934f9d2de93b',
            'cc6094c3-2fe1-404b-bf1d-f801ea3b34bd',
            'cc60a58d-f0f3-45a1-81b0-4f6dba38d830',
          ],
        },
      ],
      menu_heading: [],
    },
    {
      id: '0d4119b6-1da6-4907-8153-47dbea80fa99',
      cart_recommendations: [],
      menu_item: [
        {
          id: 'cc5b4171-0c9a-4cfb-8273-365106355517',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b4d34-af5b-4e86-ab78-2e9ebcae6b97',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b5e6d-ad98-423d-917a-6bc30373e53b',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b5cc5-e6b1-4dee-8040-b4baccf4cb51',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b5a06-e77b-4657-9b69-401af86587ea',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b41ab-f08e-4477-9cb5-16fd7858158f',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b6785-8d2f-4dd6-a3f8-b560ff993b25',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b671d-9c50-4ca3-a74e-ddffbbe83ccf',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b6f41-2533-402d-993b-d63120b5c6eb',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b4f4b-b8ab-4b95-aea9-4398589b9db8',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b6d7b-2d56-4140-8efb-6c6489f2e19d',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b50ca-cc50-46d4-bca6-347e09778d28',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b73d6-780d-4529-826b-8088566049cf',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b7959-dd19-432c-b9e7-48f17aa425f4',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b46e5-3e0f-4f72-ac4b-de562703b918',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b514e-e2d4-4bc8-b405-b49d28bea0eb',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b424e-026b-4cba-987f-b774a0e28af8',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b7173-af54-43b8-9efa-d93c7127324e',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b5b3f-ab12-449d-b7c5-0e1cc502f23a',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b7d61-b4bd-46c3-8255-02b7df77f04f',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b4925-48f1-4e1b-a246-e9b37e67f3b0',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b4c58-d0a4-40f4-9e5c-e80eb8a84de7',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b6d3d-dd87-46d9-a4a7-42100c2e4022',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b7ef2-9dae-4faf-b593-dae2e40e148b',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b4d45-8dc4-4814-9f7c-db562e7e7efe',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b5e88-3850-40fc-a693-0904fb91a102',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b4745-a200-4dfe-ad8f-c346924e6d70',
          recommended_items: [
            'cc5b7a87-a587-4faa-a83f-6d3ee2581cd9',
            'cc5b6ad9-cac9-4e9b-9f99-9f6fb32fb1cb',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b4371-6587-447c-b505-b1dba5b8d3a8',
          recommended_items: [
            'cc5b7ddf-2273-4c8b-b2e6-4803e0e436ef',
            'cc5b540e-1275-43bc-99e6-f7798fe56eff',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b7928-f747-45f2-b777-ec41a00c5921',
          recommended_items: [
            'cc5b7ddf-2273-4c8b-b2e6-4803e0e436ef',
            'cc5b540e-1275-43bc-99e6-f7798fe56eff',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b6ee3-bc09-49fe-9f4c-eaa665c03cc7',
          recommended_items: [
            'cc5b7ddf-2273-4c8b-b2e6-4803e0e436ef',
            'cc5b540e-1275-43bc-99e6-f7798fe56eff',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b475f-5488-4c30-bf2c-e4789733244d',
          recommended_items: [
            'cc5b7ddf-2273-4c8b-b2e6-4803e0e436ef',
            'cc5b540e-1275-43bc-99e6-f7798fe56eff',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
        {
          id: 'cc5b5ead-1d79-4624-a8e7-20853c98a244',
          recommended_items: [
            'cc5b6d39-20b2-4aed-9032-2f690d6b7236',
            'cc5b5f0a-f875-4381-974f-fac9edb20d4e',
            'cc5b607f-9e62-49b9-94f5-90387a52187b',
            'cc5b73bd-c87b-49a0-b83c-9aad9d5ab4c8',
            'cc5b58af-b148-419b-9f62-23d8cc11433e',
            'cc5b4a3b-4bd7-4ce0-99cc-a863343ca467',
          ],
        },
      ],
      menu_heading: [],
    },
  ],
};
