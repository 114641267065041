export function formatCentsToPrice(cents: number, currency: string = 'USD', locale: string = 'en-US'): string {
  if (Number.isNaN(cents / 100)) {
    return '';
  } else {
    return Intl.NumberFormat(locale, { style: 'currency', currency }).format(cents / 100);
  }
}

export function getCurrencySymbol(currency: string = 'USD', locale: string = 'en-US'): string {
  return (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
}

export function roundToNDecimalPoints(num: number, numDecimalPlaces: number): number {
  // eslint-disable-next-line no-restricted-properties
  const temp = Math.pow(10, numDecimalPlaces);
  return Math.round((num + Number.EPSILON) * temp) / temp;
}

export function formatCurrencyCode(code: string = 'usd'): string {
  return code.toLocaleLowerCase();
}

// NOTE: Do not use this unless for formatting input values for display.
// TODO: Account for international currencies that are formatted with the symbol after the value.
export function currencyAmountFormatter(number: number, currencySymbol: string | null, decimal: number): string {
  return `${currencySymbol ?? ''} ${number.toFixed(decimal)}`;
}
