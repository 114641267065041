import Icon from '@ant-design/icons';
import { TODO } from 'utils/Types';

const VeganSVG = ({ fill }: { fill: string }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="9" r="9" fill={fill || '#292929'} />
    <path
      d="M9.97656 6.28906H11.1992L9.25781 12H7.9375L6 6.28906H7.22266L8.29688 9.6875C8.35677 9.88802 8.41797 10.1224 8.48047 10.3906C8.54557 10.6562 8.58594 10.8411 8.60156 10.9453C8.63021 10.7057 8.72786 10.2865 8.89453 9.6875L9.97656 6.28906Z"
      fill="white"
    />
  </svg>
);

const Vegan = (props: TODO) => (
  <Icon data-testid="vegan-tag" title="Vegan" aria-label="Vegan" component={() => VeganSVG(props)} {...props} />
);

export default Vegan;
