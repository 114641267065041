/* eslint-disable @typescript-eslint/no-for-in-array */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { TODO } from './Types';

export const jsonToCSS = (cssObj: TODO): string => {
  let cssText = '';
  // eslint-disable-next-line prefer-const
  let cssVars = [];
  if (Array.isArray(cssObj)) {
    for (const i in cssObj) {
      cssText += jsonToCSS(cssObj[i]);
    }
  } else {
    for (const k in cssObj) {
      // k: { ... }
      const v = cssObj[k];
      if (typeof v === 'string' || typeof v === 'number') {
        cssVars.push(`${k}: ${v}`);
      } else if (Array.isArray(v)) {
        for (const j in v) {
          cssText += `${k}{\r\n`;
          cssText += jsonToCSS(v[j]);
          cssText += '}\r\n';
        }
      } else {
        cssText += `${k}{\r\n`;
        cssText += jsonToCSS(v);
        cssText += '}\r\n';
      }
    }
  }
  cssText += cssVars.join(';\r\n');
  return cssText;
};

export const addVariablesToRoot = (cssVariableDict: Object): Promise<any> | undefined => {
  if (!cssVariableDict) {
    return;
  }
  // eslint-disable-next-line consistent-return
  return new Promise<void>((resolve, reject) => {
    const root = document.documentElement;

    const { length } = Object.keys(cssVariableDict);
    let index = 0;
    for (const [key, value] of Object.entries(cssVariableDict)) {
      root.style.setProperty(key, value);
      index += 1;
      if (index === length - 1) {
        resolve();
      }
    }
  });
};

export const injectFonts = (fonts: Array<{ [key: string]: string }>): void => {
  if (!fonts) {
    return;
  }
  let fontFaceCss = '';

  fonts.forEach((font) => {
    // create the font face string
    const fontFace = `@font-face {
      font-family: "${font['font-family']}";
      src: ${font.src};
      font-weight: ${font['font-weight']};
      font-style: ${font['font-style']};
    }\n`;

    fontFaceCss = fontFaceCss.concat(fontFace);
  });

  // add style tag to the head
  const style = document.createElement('style');
  style.innerHTML = fontFaceCss;
  document.head.appendChild(style);
};
