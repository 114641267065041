import { track } from 'integrations/segment/instrumentation/Track';
import { FieldsType } from './types/types';

// Menu Page
export const trackClickOpenCartSideCard = (fields: FieldsType = {}): void =>
  track('track_click_open_cart_side_card', fields);

export const trackLocationCodeNotFound = (fields: FieldsType = {}): void => {
  track('track_location_code_not_found', fields);
};

export const trackClickMenuSelectDropDown = (fields: FieldsType = {}): void => {
  track('track_click_menu_select_dropdown', fields);
};

export const trackClickMenuSelectHorizontalScroll = (fields: FieldsType = {}): void => {
  track('track_click_menu_select_horizontal_scroll', fields);
};

export const trackClickMenuHeading = (fields: FieldsType = {}): void => {
  track('track_click_menu_heading_menu_page', fields);
};

export const trackClickMenuPageToggleFulfillmentType = (fields: FieldsType = {}): void =>
  track('track_click_menu_page_toggle_fulfillment_type', fields);

// Menus
export const trackMenuPageLoad = (fields: FieldsType = {}): void => track('track_menu_page_load', fields);

export const trackClickSwitchLocation = (fields: FieldsType = {}): void => track('track_click_switch_location', fields);

export const trackViewMenu = (fields: FieldsType = {}): void => track('track_view_menu', fields);

// Menu Items
export const trackClickMenuItem = (fields: FieldsType = {}): void => track('track_click_menu_item', fields);
