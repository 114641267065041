import Icon from '@ant-design/icons';

const DropdownArrowSVG = () => (
  <svg
    width="8"
    height="4"
    viewBox="0 0 8 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ display: 'flex' }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.27477 0C0.918405 0 0.739939 0.430857 0.991925 0.682843L4.02624 3.71716C4.18245 3.87337 4.43572 3.87337 4.59192 3.71716L7.62624 0.682842C7.87823 0.430856 7.69976 0 7.3434 0L1.27477 0Z"
      fill="white"
    />
  </svg>
);

const DropdownArrow = (props) => (
  <Icon
    title="dropdown-arrow"
    aria-label="dropdown-arrow"
    component={DropdownArrowSVG}
    style={{ verticalAlign: 'middle' }}
    {...props}
  />
);

export default DropdownArrow;
