/* eslint-disable no-nested-ternary */
import { captureErrorInSentry } from 'integrations/Sentry';

export type DefaultParam = string | string[] | null;

export const base64EncodeJSON = (value: any): string => {
  let result = '';
  try {
    result = btoa(JSON.stringify(value));
  } catch (e) {
    captureErrorInSentry(e);
  }
  return result;
};

export const base64DecodeJSON = (value: any): Record<string, any> => {
  let result = {};

  if (typeof value !== 'string' || !value) {
    return result;
  }

  try {
    result = JSON.parse(atob(value));
  } catch (e) {
    captureErrorInSentry(e);
  }
  return result;
};

export const stringifyParam = (param: DefaultParam) =>
  param === null ? '' : Array.isArray(param) ? param.join(',') : param;
