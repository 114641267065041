import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet-async';

// Hooks
import { useStores } from 'hooks/use-stores';

function ThemeLoader() {
  const { hostStore } = useStores();

  if (!hostStore.settings) {
    return null;
  }

  return (
    <>
      <StyleSheet url={hostStore.theme.css_stylesheet} />
    </>
  );
}

const StyleSheet = ({ url }) => {
  if (!url) {
    return null;
  }
  if (url.indexOf('http') !== 0) {
    url = new URL(url, process.env.REACT_APP_API_URL).href;
  }
  return (
    <Helmet>
      <link rel="preload" as="style" type="text/css" href={url} />
    </Helmet>
  );
};

export default observer(ThemeLoader);
