import { Input as AntdInput } from 'antd';
import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &.error {
    .ant-input {
      border-color: var(--color-input-border__error);
      box-shadow: var(--box-shadow-input__error);
      color: var(--color-input-text__error);

      &.focus-ring {
        &:focus-within {
          outline: 0;
          border-color: var(--color-input-border__selected);
          box-shadow: var(--box-shadow-input__selected);
        }
      }
      background-color: transparent;
    }
  }
`;

export const Input = styled(AntdInput)`
  border-radius: var(--input__border-radius);
  padding: var(--padding__input);
  height: var(--height__input);
  font-size: var(--font-size__input);

  &:focus,
  &.ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
    outline: 0;
    border-color: var(--color-input-border__selected);
    box-shadow: var(--box-shadow-input__selected);
  }

  input {
    &.ant-input {
      background: transparent;
      line-height: var(--line-height__input);
      font-size: var(--font-size__input);
      font-weight: var(--font-weight__input);
    }
  }
`;

export const Label = styled.label`
  font-weight: var(--font-weight__label);
  font-size: var(--font-size__label);
  color: var(--color-label-primary__regular);
`;

export const InputError = styled.div`
  color: var(--color-input-text__error);
`;
