import { useCallback, useState } from 'react';

interface UseInputFieldOptions {
  validator?: (value: string) => string;
  defaultValue?: string;
}

export interface InputField {
  value: string;
  error: string;
  validate: () => boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: () => void;
}

export const useInputField = ({ validator = () => '', defaultValue = '' }: UseInputFieldOptions = {}): InputField => {
  const [value, setValue] = useState(defaultValue);
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState('');

  const validateInput = useCallback(
    (newValue: string) => {
      const validationError = validator(newValue);
      setError(validationError);
      return !validationError;
    },
    [validator]
  );

  const validate = useCallback(() => {
    setTouched(true);
    return validateInput(value);
  }, [validateInput, value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value: newValue } = e.target;
      if (touched) {
        validateInput(newValue);
      }
      setValue(newValue);
    },
    [validate, touched]
  );

  const handleBlur = useCallback(() => validate(), [validate]);

  return { value, error, validate, handleChange, handleBlur };
};
