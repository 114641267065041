export const eventBus = {
  on(event: string, callback: EventListener): void {
    document.addEventListener(event, callback);
  },
  dispatch(event: string, data: CustomEventInit): void {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event: string, callback: EventListener): void {
    document.removeEventListener(event, callback);
  },
};

export const removePrefix = (text: string, prefix: string): string => {
  if (text.startsWith(prefix)) {
    return text.slice(prefix.length);
  }
  return text;
};

export const appendHashToUrl = (hashValue: string, existingUrl?: URL): URL => {
  const url = existingUrl ?? new URL(window.location.href);
  url.hash = hashValue;
  return url;
};

export const appendQueryParamToUrl = (name: string, value: string, existingUrl?: URL): URL => {
  const url = existingUrl ?? new URL(window.location.href);
  url.searchParams.set(name, value);
  return url;
};

export const deleteQueryParamsFromUrl = (...queryParams: string[]): URL => {
  const url = new URL(window.location.href);
  queryParams.forEach((param) => url.searchParams.delete(param));
  return url;
};

/**
 *
 * Prevents an async function from being executed multiple times while
 * still processing.
 *
 * Returns the promise from the first call and ignores additional
 * calls.
 *
 */
export const takeFirst = <Fn extends (...args: any[]) => Promise<any>>(fn: Fn) => {
  let promise: Promise<Awaited<ReturnType<Fn>>> | null = null;

  return async (...args: Parameters<Fn>) => {
    if (promise) {
      const result = await promise;
      promise = null;
      return result;
    }

    promise = fn(...args);
    const result = await promise;
    promise = null;
    return result;
  };
};

export const sleep = async (ms: number) => await new Promise((resolve) => setTimeout(resolve, ms));
