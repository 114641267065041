// Prism
import { IconType } from '@doordash/design-language';

export enum TYPE_TO_CHARGE_KEY_MAP {
  apple_pay = 'apple_pay_token',
  saved_stripe = 'card_id',
  tab = 'tab_id',
  free = 'id',
  party_tab = 'tab_id',
  payment_intent_to_verify = 'payment_intent_id_to_verify',
  terminal = 'tab_id',
  gift_card = 'id',
}

export enum CHARGE_TYPE {
  APPLE_PAY = 'apple_pay',
  SAVED_STRIPE = 'saved_stripe',
  TAB = 'tab',
  FREE = 'free',
  PARTY_TAB = 'party_tab',
  PAYMENT_INTENT = 'payment_intent_to_verify',
  TERMINAL = 'terminal',
  GIFT_CARD = 'gift_card',
}

export enum SHARED_CART_STATE {
  OPEN = 'open',
  LOCKED = 'locked',
  CHECKED_OUT = 'checked_out',
  ABANDONED = 'abandoned',
}

export const CHARGE_TYPES_THAT_REQUIRE_CHARGE_SOURCE_ID: Array<CHARGE_TYPE> = [
  CHARGE_TYPE.APPLE_PAY,
  CHARGE_TYPE.SAVED_STRIPE,
  CHARGE_TYPE.TAB,
  CHARGE_TYPE.GIFT_CARD,
];

export const CHARGE_TYPES_THAT_REQUIRE_CARD: Array<CHARGE_TYPE> = [CHARGE_TYPE.SAVED_STRIPE, CHARGE_TYPE.TAB];

export enum COUNTRY_CODE_APARTMENT_LABELS_MAP {
  US = 'Apt/Unit',
  GB = 'Flat Number (optional)',
  AU = 'Unit/Level/Building Name',
}

export const PrismCardIconTypes: Record<string, string> = {
  amex: IconType.CardAmexColor,
  applepay: IconType.CardApplePayColor,
  apple: IconType.CardApplePayColor,
  caviar: IconType.CardCaviarColor,
  chase: IconType.CardChaseColor,
  dinersclub: IconType.CardDinersClubColor,
  diners: IconType.CardDinersClubColor,
  discover: IconType.CardDiscoverColor,
  doordash: IconType.CardDoordashColor,
  doordashrewards: IconType.CardDoordashRewardsColor,
  googlepay: IconType.CardGooglePayColor,
  google: IconType.CardGooglePayColor,
  mastercard: IconType.CardMastercardColor,
  master: IconType.CardMastercardColor,
  paypal: IconType.CardPaypalColor,
  venmo: IconType.CardVenmoColor,
  visa: IconType.CardVisaColor,
};
