import { TODO } from 'utils/Types';
import { CHARGE_TYPE } from 'constants/Checkout';
import { useStores } from 'hooks/use-stores';
import { CART_VIEW_MODES } from 'constants/UIState';
import { POSInitiatedTab } from 'api/types';

export interface CalculationLineItem {
  key: string;
  type: SummaryLineItemType;
  label: string;
  total: number;
}

export enum SummaryLineItemType {
  Subtotal = 'subtotal',
  Tax = 'tax',
  Fees = 'fees',
  Promos = 'promos',
  Tip = 'tip',
  PaidAmount = 'paid_amount',
  GiftCards = 'gift_card',
  Total = 'total',
  Divider = 'divider',
}

const getItemOrder = <T extends string>(items: T[]) =>
  Object.fromEntries(items.map((item, position) => [item, position])) as Record<T, number>;

const LineItemOrder = getItemOrder([
  SummaryLineItemType.Subtotal,
  SummaryLineItemType.Promos,
  SummaryLineItemType.Tip,
  SummaryLineItemType.Fees,
  SummaryLineItemType.Tax,
  SummaryLineItemType.PaidAmount,
  SummaryLineItemType.GiftCards,
  SummaryLineItemType.Divider,
  SummaryLineItemType.Total,
]);

const getValidLineItemParts = (parts: (SummaryLineItemType | false | null | undefined)[]) =>
  parts
    .filter((partType): partType is SummaryLineItemType => !!partType)
    .sort((a, b) => LineItemOrder[a] - LineItemOrder[b]);

export function useTabSummaryLineItems(
  parts: (SummaryLineItemType | false | null | undefined)[],
  dontShowZeroTip: boolean = false
): CalculationLineItem[] {
  const { locationStore, tabStore } = useStores();
  const { forced_tip_name: forcedTipName } = locationStore;
  const {
    activeConsumerTabSummary,
    activeConsumerTabTipSettings,
    subtotalBeforeFeesAndPromos,
    activeConsumerTabChargeDistributions,
  } = tabStore;

  if (!activeConsumerTabSummary) {
    return [];
  }

  const { tab } = activeConsumerTabSummary;
  const { user_has_chosen_tip: userHasChosenTip = false, new_tip_cents: newTipCents = 0 } =
    activeConsumerTabTipSettings;
  const existingTip = activeConsumerTabSummary?.total_tip_cents ?? 0;
  const newOrExistingTip = userHasChosenTip ? newTipCents : existingTip;
  const tipCentsToShow = tab?.isClosed ? activeConsumerTabSummary?.total_tip_cents : newOrExistingTip;

  const giftCardsOnly = activeConsumerTabChargeDistributions.filter(
    ({ chargeType }) => chargeType === CHARGE_TYPE.GIFT_CARD
  );

  const totalWithoutGiftCards =
    activeConsumerTabSummary.total_pretax_cents +
    activeConsumerTabSummary.total_tax_cents +
    tipCentsToShow -
    activeConsumerTabSummary.paid_amount_cents;

  const giftCardTotal = parts.some((type) => type === SummaryLineItemType.GiftCards)
    ? giftCardsOnly.reduce((total, { amount }) => total + amount, 0)
    : 0;

  let validParts = getValidLineItemParts(parts);

  // if tab is not closed don't show field
  validParts =
    dontShowZeroTip && tipCentsToShow === 0
      ? validParts.filter((type) => type !== SummaryLineItemType.Tip)
      : validParts;

  return validParts
    .map((partType) => {
      switch (partType) {
        case SummaryLineItemType.Subtotal:
          return {
            key: 'subtotal',
            type: partType,
            label: 'Subtotal',
            total: subtotalBeforeFeesAndPromos ?? 0,
          };
        case SummaryLineItemType.Tax:
          return {
            key: 'tax',
            type: partType,
            label: 'Tax',
            total: activeConsumerTabSummary.total_tax_cents ?? 0,
          };
        case SummaryLineItemType.Tip:
          // TODO: don't show the tip percentage, it's incorrect. It won't change anyways until we finish toast open checks.
          // const tipPercentage = tabStore.calculateTipPercentage(tipCentsToShow) * 100;
          return {
            key: 'tip',
            type: partType,
            // label: `Tip (${tipPercentage.toFixed(0)}%)`,
            label: forcedTipName || 'Tip',
            total: tipCentsToShow,
          };
        case SummaryLineItemType.PaidAmount:
          return activeConsumerTabSummary?.paid_amount_cents > 0
            ? {
                key: 'paid_balance',
                type: partType,
                label: 'Paid Balance',
                total: -activeConsumerTabSummary?.paid_amount_cents,
              }
            : [];
        case SummaryLineItemType.Promos:
          return Object.values(activeConsumerTabSummary?.promotions_summary ?? {}).map(
            ({ name_for_customer: nameForCustomer, pretax_cents_added: pretaxCentsAdded }: TODO, index) => ({
              key: `${nameForCustomer as string}_${pretaxCentsAdded as number}_${index}`,
              type: partType,
              label: nameForCustomer,
              total: pretaxCentsAdded,
            })
          );
        case SummaryLineItemType.Fees:
          return Object.values(activeConsumerTabSummary?.order_fees_summary ?? {}).map(
            ({ name_for_customer: nameForCustomer, pretax_cents: pretaxCents }: TODO, index) => ({
              key: `${nameForCustomer as string}_${pretaxCents as number}_${index}`,
              type: partType,
              label: nameForCustomer,
              total: pretaxCents,
            })
          );
        case SummaryLineItemType.GiftCards:
          return giftCardsOnly.map(({ last4, amount }, index) => ({
            key: `gc_${last4 ?? ''}_${amount}_${index}`,
            type: partType,
            label: `Gift Card${last4 ? ` ...${last4}` : ''}`,
            total: -amount,
          }));
        case SummaryLineItemType.Total:
          return {
            key: 'total',
            type: partType,
            label: 'Total',
            total: totalWithoutGiftCards - giftCardTotal,
          };
        case SummaryLineItemType.Divider:
          return {
            key: '',
            type: partType,
            label: '',
            total: 0,
          };
        default:
          return [];
      }
    })
    .flat();
}

export function usePOSInitiatedTabSummaryLineItems(
  posInitiatedTab: POSInitiatedTab,
  parts: SummaryLineItemType[]
): CalculationLineItem[] {
  const { locationStore, tabStore } = useStores();
  const { forced_tip_name: forcedTipName } = locationStore;
  const {
    amount_cents: amountCents = 0,
    tax_amount_cents: taxAmountCents = 0,
    tax_inclusive_pricing: taxInclusivePricing = 0,
  } = posInitiatedTab;
  const tipAmountCents = tabStore.getSelectedPOSTabTipAmount(amountCents);

  const totalWithoutGiftCards = amountCents + tipAmountCents + taxAmountCents * (taxInclusivePricing ? 0 : 1);

  const chargeDistributions = tabStore.getTabChargeDistributions(totalWithoutGiftCards);

  const giftCardsOnly = chargeDistributions.filter(({ chargeType }) => chargeType === CHARGE_TYPE.GIFT_CARD);

  const giftCardTotal = parts.some((type) => type === SummaryLineItemType.GiftCards)
    ? giftCardsOnly.reduce((total, { amount }) => total + amount, 0)
    : 0;

  const validParts = getValidLineItemParts(parts);

  return validParts
    .map((partType) => {
      switch (partType) {
        case SummaryLineItemType.Subtotal:
          return {
            key: 'subtotal',
            type: partType,
            label: 'Subtotal',
            total: amountCents,
          };
        case SummaryLineItemType.Tax:
          return {
            key: 'tax',
            type: partType,
            label: 'Tax',
            total: taxAmountCents,
          };
        case SummaryLineItemType.Tip:
          // TODO: don't show the tip percentage, it's incorrect. It won't change anyways until we finish toast open checks.
          // const tipPercentage = tabStore.calculateTipPercentage(tipCentsToShow) * 100;
          return {
            key: 'tip',
            type: partType,
            // label: `Tip (${tipPercentage.toFixed(0)}%)`,
            label: forcedTipName || 'Tip',
            total: tipAmountCents,
          };
        case SummaryLineItemType.GiftCards:
          return giftCardsOnly.map(({ last4, amount }, index) => ({
            key: `gc_${last4 ?? ''}_${amount}_${index}`,
            type: partType,
            label: `Gift Card${last4 ? ` ...${last4}` : ''}`,
            total: -amount,
          }));
        case SummaryLineItemType.Total:
          return {
            key: 'total',
            type: partType,
            label: 'Total',
            total: totalWithoutGiftCards - giftCardTotal,
          };
        case SummaryLineItemType.Divider:
          return {
            key: '',
            type: partType,
            label: '',
            total: 0,
          };
        default:
          return [];
      }
    })
    .flat();
}

export function useCartSummaryLineItems(
  parts: (SummaryLineItemType | false | null | undefined)[]
): CalculationLineItem[] {
  const { checkoutStore, uiState, locationStore, tabStore } = useStores();
  const { selectedCart } = checkoutStore;
  const { forced_tip_name: forcedTipName, forced_tip_fraction: forcedTipFraction } = locationStore;
  const { activeConsumerTab } = tabStore;
  const { isMobile } = uiState;

  const hasForcedTipFraction = forcedTipFraction !== null && !Number.isNaN(+forcedTipFraction);

  // Don't show the tip in the cart if we tip at the end unless the discounted subtotal is $0 (in those cases
  // we let them tip up front and place the order as a single order)
  const showTipCalculationsCloudTabs =
    hasForcedTipFraction ||
    !tabStore.allowTipAtEndOfTab() ||
    selectedCart.getSubtotal() + selectedCart.getPromoDiscountsCentsAdded() === 0;
  const showTipCalculationsViewMode =
    uiState.cartViewMode === CART_VIEW_MODES.CART_VIEW_CARD ||
    (uiState.cartViewMode === CART_VIEW_MODES.CART_VIEW_DRAWER && !!activeConsumerTab) ||
    isMobile;
  const showTipCalculationsTipAmount = selectedCart.tip_amount > 0;
  const showTipCalculations =
    showTipCalculationsCloudTabs && showTipCalculationsViewMode && showTipCalculationsTipAmount;

  const validParts = getValidLineItemParts(parts);

  return validParts
    .map((partType) => {
      switch (partType) {
        case SummaryLineItemType.Subtotal:
          return {
            key: 'subtotal',
            type: partType,
            label: 'Subtotal',
            total: selectedCart.getSubtotal(),
          };
        case SummaryLineItemType.Tax:
          return {
            key: 'tax',
            type: partType,
            label: 'Est. Tax',
            total: selectedCart.getTax(),
          };
        case SummaryLineItemType.Tip:
          // TODO: don't show the tip percentage, it's incorrect. It won't change anyways until we finish toast open checks.
          // const tipPercentage = tabStore.calculateTipPercentage(tipCentsToShow) * 100;
          return showTipCalculations
            ? {
                key: 'tip',
                type: partType,
                label: forcedTipName || 'Tip',
                total: selectedCart.tip_amount,
              }
            : [];
        case SummaryLineItemType.Promos:
          return (selectedCart?.promoDiscounts ?? []).map(({ name, cents_added: centsAdded }) => ({
            key: name,
            type: partType,
            label: `Promo: ${name}`,
            total: centsAdded,
          }));
        case SummaryLineItemType.Fees:
          return selectedCart.groupedFees.map(
            ({ name_for_customer: nameForCustomer, pretax_cents_subtotal: pretaxCentsSubtotal }, index) => ({
              key: `${nameForCustomer as string}_${pretaxCentsSubtotal as number}_${index}`,
              type: partType,
              label: nameForCustomer as string,
              total: pretaxCentsSubtotal as number,
            })
          );
        case SummaryLineItemType.Total:
          return {
            key: 'total',
            type: partType,
            label: 'Total',
            total: selectedCart.calcCartTotalPartial(validParts),
          };
        default:
          return [];
      }
    })
    .flat();
}
