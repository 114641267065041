import _ from 'lodash';
import { makeObservable, observable, runInAction } from 'mobx';

// Models
import CartItem, { MinimumDataToConstructCartItem } from 'models/CartItem';
import { DateTime } from 'luxon';
import RootStore from '../stores/RootStore';
import SharedCart from './SharedCart';
import { TODO } from '../utils/Types';
import Cart from './Cart';
import CartModifier from './CartModifier';

type MininimumDataToConstructSharedCartItem = MinimumDataToConstructCartItem & {
  owner_id: string;
  source_menu_for_item?: string;
  ready_for_checkout?: boolean;
};

export default class SharedCartItem extends CartItem {
  id: string = '';
  cart_item: string = '';
  owner_id: string = '';
  menu_item_id: string = '';
  source_menu_for_item: string = '';
  shared_cart_id: string = '';
  for_patron: boolean = false;
  for_anonymous_user: boolean = false;
  time_created: DateTime = DateTime.fromSeconds(0);
  sharedCartReference?: SharedCart;
  cart?: Cart;
  ready_for_checkout: boolean = false;
  syncedWithServer: boolean = true;

  constructor(rootStore: RootStore, sharedCart: SharedCart, item: MininimumDataToConstructSharedCartItem) {
    super(rootStore.menuDataStore, item);
    this.rootStore = rootStore;

    runInAction(() => {
      this.id = item.id ?? '';
      this.sharedCartReference = sharedCart;
      this.cart = sharedCart.cart;
      // TODO(types): is this a mistyping???
      this.shared_cart_id = item.shared_cart;
      this.owner_id = item.owner_id;
      this.menu_item_id = item.menu_item_id;
      this.source_menu_for_item = item.source_menu_for_item ?? '';
      this.menu_id = item.source_menu_for_item ?? '';
      this.ready_for_checkout = item.ready_for_checkout ?? false;
    });
    this.update(item, false);

    makeObservable(this, {
      owner_id: observable,
      id: observable,
      source_menu_for_item: observable,
      shared_cart_id: observable,
      for_patron: observable,
      for_anonymous_user: observable,
      time_created: observable,
      ready_for_checkout: observable,
    });
  }

  syncIdFromServer(serverId: string) {
    runInAction(() => {
      this.id = serverId;
    });
  }

  get ownerId(): string | null {
    return this.owner_id ?? this.for_patron ?? this.for_anonymous_user;
  }

  get itemOwner() {
    // @ts-expect-error
    return this.sharedCartReference?.membersById[this.ownerId];
  }

  get cartItem() {
    return this.cart?.items?.find((item: CartItem) => item.hash() === CartItem.calculateItemHash(this));
  }

  get lineitem_id() {
    return this.cartItem?.line_item_id;
  }

  setQty(qty: number) {
    // NOTE: allow setting qty to 0 because this will inform the backend to remove the item
    runInAction(() => {
      this.qty = qty;
    });

    if (qty === 0) {
      this.sharedCartReference?.localRemoveSharedCartItemById(this);
    } else if (this.id) {
      // if it has an id, it's an existing sharedCartItem so we need
      // to refresh the cart
      this.sharedCartReference?.handleUpdateCartItemInSharedCart(this);
    }
  }

  markSyncedWithServer(val: boolean) {
    runInAction(() => {
      this.syncedWithServer = val;
    });
  }

  update(data: MininimumDataToConstructSharedCartItem, syncedWithServer = false) {
    runInAction(() => {
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in data) {
        const blackListKeys = ['mods']; // Blacklist updating mods because CartModifier converts all mods to CartModifier instances
        // eslint-disable-next-line no-prototype-builtins
        if (this.hasOwnProperty(key) && !blackListKeys.includes(key)) {
          // @ts-expect-error
          this[key] = data[key];
        }
      }

      // Update Mods
      // TODO: To make more performant we should not recreate the list of mods everytime
      if (data.mods) {
        this._setMods(data.mods);
      }

      // Be sure to set both these when source_menu_for_item is set
      if (data.source_menu_for_item) {
        this.menu_id = data.source_menu_for_item;
        this.source_menu_for_item = data.source_menu_for_item;
      }

      runInAction(() => {
        this.syncedWithServer = syncedWithServer;
      });
    });
  }

  hash(): number {
    let string = this.menu_item_id;
    string += this.owner_id;

    const hashCode = (s: string): number =>
      s.split('').reduce((a, b) => {
        // eslint-disable-next-line no-bitwise, no-param-reassign
        a = (a << 5) - a + b.charCodeAt(0);
        // eslint-disable-next-line no-bitwise
        return a & a;
      }, 0);

    function modsHash(mods: Array<CartModifier>) {
      _.sortBy(mods, 'menu_item_id').forEach((i) => {
        string += `_${i.menu_item_id}`;
        if (i.hasMods()) {
          // @ts-expect-error
          modsHash([].concat(...Object.values(i.mods)));
        }
      });
    }
    modsHash([].concat(...Object.values(this.mods)));
    string += this.special_instructions;

    return hashCode(string);
  }

  updateFromPriceCheck(priceCheck: TODO) {
    runInAction(() => {
      this.discounts = priceCheck?.discounts?.length > 0 ? priceCheck.discounts : [];
      this.lineitem_pretax_cents = priceCheck.lineitem_pretax_cents ?? null;
      this.lineitem_tax_cents = priceCheck.lineitem_tax_cents ?? null;
    });
  }

  toJSON() {
    const res = super.toJSON();
    // get mods and menu_item_id

    return Object.assign(res, {
      id: this.id,
      menu_id: this.menu_id,
      menu_item_id: this.menu_item_id,
      source_menu_for_item: this.source_menu_for_item,
      owner_id: this.owner_id,
      shared_cart_id: this.shared_cart_id,
      for_patron: this.for_patron,
      for_anonymous_user: this.for_anonymous_user,
      ready_for_checkout: this.ready_for_checkout,
    });
  }
}
