/* eslint-disable max-classes-per-file */
import User from 'models/User';
import { TOPErrorOptions, TOPLoggedError } from './Errors';

interface SharedCartErrorDetails {
  cartOwner: User;
  currentMember: User;
}

export const getSharedCartError = (
  serverError: Error & { error_id: Exclude<SharedCartErrorId, SharedCartErrorId.general_shared_cart_error> },
  options: TOPErrorOptions,
  errorDetails?: SharedCartErrorDetails
): SharedCartErrorType =>
  serverError.error_id in ERROR_ID_TO_TYPE
    ? new ERROR_ID_TO_TYPE[serverError.error_id](serverError.message, options, errorDetails)
    : new GeneralSharedCartError(serverError.message, options);

export enum SharedCartErrorId {
  cart_must_be_checked_out = 'cart_must_be_checked_out',
  invalid_shared_cart_id = 'invalid_shared_cart_id',
  disallow_joining_closed_carts = 'disallow_joining_closed_carts',
  disallow_editing_closed_carts = 'disallow_editing_closed_carts',
  insufficient_secrets_provided = 'insufficient_secrets_provided',
  secret_link_not_found = 'secret_link_not_found',
  incorrect_cart_password = 'incorrect_cart_password',
  no_items_to_edit = 'no_items_to_edit',
  one_open_cart_per_cart_master = 'one_open_cart_per_cart_master',
  user_is_not_cart_master = 'user_is_not_cart_master',
  user_not_member_of_cart = 'user_not_member_of_cart',
  no_negative_quantity = 'no_negative_quantity',
  cannot_edit_locked_cart = 'cannot_edit_locked_cart',
  require_lock_before_checkout = 'require_lock_before_checkout',
  cannot_edit_abandoned_cart = 'cannot_edit_abandoned_cart',
  wait_for_others_before_editing = 'wait_for_others_before_editing',
  cart_name_invalid = 'cart_name_invalid',
  previous_cart_abandoned = 'previous_cart_abandoned',
  user_can_only_edit_owned_items = 'user_can_only_edit_owned_items',
  general_shared_cart_error = 'general_shared_cart_error',
}

export class CartMustBeCheckedOutError extends TOPLoggedError {
  constructor(_: string, options: TOPErrorOptions, __?: SharedCartErrorDetails) {
    // TODO: This message is only universally relevant because the only way this error is thrown is when you
    //  try to reclaim a group order that has no checkouts. Change this default when we expand the ways this exception
    //  can be thrown.
    const message =
      'We were unable to find a phone number associated with this tab. Please create a new tab to continue ordering.';
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.cart_must_be_checked_out;
  }
}
export class CannotJoinClosedCartError extends TOPLoggedError {
  constructor(_: string, options: TOPErrorOptions, __?: SharedCartErrorDetails) {
    const message =
      'Oops! The group order you tried joining has expired. Please try joining another group order or starting your own.';
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.disallow_joining_closed_carts;
  }
}

export class CannotEditClosedCartError extends TOPLoggedError {
  constructor(_: string, options: TOPErrorOptions, errorDetails: SharedCartErrorDetails) {
    const message = `${errorDetails?.cartOwner?.name} has already checked out the items in this order, so no more edits can be made. Redirecting you to the order status page...`;
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.disallow_editing_closed_carts;
  }
}

export class InsufficientSecretsProvidedError extends TOPLoggedError {
  constructor(_: string, options: TOPErrorOptions, errorDetails: SharedCartErrorDetails) {
    const message = `We had trouble adding you to ${errorDetails?.cartOwner?.name}'s group order. Please refresh and try again.`;
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.insufficient_secrets_provided;
  }
}

export class InvalidSecretForLinkError extends TOPLoggedError {
  constructor(_: string, options: TOPErrorOptions, errorDetails: SharedCartErrorDetails) {
    const message = `Seems like this QR code or link has expired. Please ask a member of ${errorDetails?.cartOwner?.name}'s group order to send a new invite.`;
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.secret_link_not_found;
  }
}

export class InvalidSecretPasswordError extends TOPLoggedError {
  constructor(_: string, options: TOPErrorOptions, errorDetails: SharedCartErrorDetails) {
    const message = `Hmm, we didn't recognize the pin you entered for that group order. Please try again or ask a member of ${errorDetails.cart_master_name}'s group order for the pin.`;
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.incorrect_cart_password;
  }
}

export class CartMemberHasNoItemsToEditError extends TOPLoggedError {
  constructor(_: string, options: TOPErrorOptions, __?: SharedCartErrorDetails) {
    const message = 'Your order looks very empty. Please add an item to join the party!';
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.no_items_to_edit;
  }
}

export class OneOpenCartPerCartMasterError extends TOPLoggedError {
  constructor(_: string, options: TOPErrorOptions, errorDetails: SharedCartErrorDetails) {
    const message = `Oops! Something went wrong. Please refresh and try again.`;
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.one_open_cart_per_cart_master;
  }
}

export class UserIsNotCartMasterError extends TOPLoggedError {
  constructor(_: string, options: TOPErrorOptions, errorDetails: SharedCartErrorDetails) {
    const message = `Only the owner of the group order can make that happen. Please ask ${errorDetails?.cartOwner?.name} to try instead.`;
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.user_is_not_cart_master;
  }
}

export class UserCannotEditOtherMembersItemsError extends TOPLoggedError {
  constructor(_: string, options: TOPErrorOptions, __?: SharedCartErrorDetails) {
    const message = `Only the owner of those items can edit them. Please ask them to change their items if you want to make a change.`;
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.user_can_only_edit_owned_items;
  }
}

export class UserIsNotMemberOfCartError extends TOPLoggedError {
  constructor(_: string, options: TOPErrorOptions, __?: SharedCartErrorDetails) {
    const message =
      "For some reason we don't have you listed as a member of the current group order. Please refresh and try joining again.";
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.user_not_member_of_cart;
  }
}

export class InvalidQuantityError extends TOPLoggedError {
  constructor(_: string, options: TOPErrorOptions, __?: SharedCartErrorDetails) {
    const message = "We weren't able to update your item's quantity. Please refresh and try again.";
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.no_negative_quantity;
  }
}

export class CannotEditLockedCartError extends TOPLoggedError {
  constructor(_: string, options: TOPErrorOptions, errorDetails: SharedCartErrorDetails) {
    const message = `${errorDetails?.cartOwner?.name} is checking out your group's order, so no more edits can be made. Check in with ${errorDetails.cartOwner.name} for an update.`;
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.cannot_edit_locked_cart;
  }
}

export class CannotCloseOpenCartWithoutLockError extends TOPLoggedError {
  constructor(_: string, options: TOPErrorOptions, __?: SharedCartErrorDetails) {
    const message =
      "We weren't able to check out your group's order, but we made sure not to charge your card. Please refresh and try again.";
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.require_lock_before_checkout;
  }
}

export class InvalidSharedCartIdError extends TOPLoggedError {
  constructor(message: string, options: TOPErrorOptions, __?: SharedCartErrorDetails) {
    message =
      'Oops! Looks like the group order you have stored is either invalid or does not exist. Please join another group order or start a new one.';
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.invalid_shared_cart_id;
  }
}

export class CannotEditAbandonedCartError extends TOPLoggedError {
  constructor(_: string, options: TOPErrorOptions, errorDetails: SharedCartErrorDetails) {
    const message = `${
      errorDetails?.cartOwner?.name ?? 'The owner'
    } has ended this group order for all members. Please join another group or start a new one.`;
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.cannot_edit_abandoned_cart;
  }
}

export class WaitForOtherMemberToFinishEditingError extends TOPLoggedError {
  constructor(_: string, options: TOPErrorOptions, errorDetails: SharedCartErrorDetails) {
    const personToBlame =
      // TODO(types): this field doesn't exist on SharedCart?
      errorDetails.cartOwner.id !== errorDetails.currentMember.id ? errorDetails.cartOwner.name : 'The item owner';
    const message = `${personToBlame} is still editing one of the items you tried to edit. Please wait a few seconds for them to finish and try again.`;
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.wait_for_others_before_editing;
  }
}

export class GeneralSharedCartError extends TOPLoggedError {
  constructor(_: string, options: TOPErrorOptions, __?: SharedCartErrorDetails) {
    const message = 'We hit a snag. Please refresh and try again.';
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.general_shared_cart_error;
  }
}

export class CartNameInvalidError extends TOPLoggedError {
  constructor(_: string, options: TOPErrorOptions, __?: SharedCartErrorDetails) {
    const message =
      "Someone's already using your name for their group order! Add a twist so your friends can find yours.";
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.cart_name_invalid;
  }
}

export class PreviousCartAbandonedError extends TOPLoggedError {
  constructor(_: string, options: TOPErrorOptions, __?: SharedCartErrorDetails) {
    const message = 'Whoops! The group you are starting another round for left. Please create a new group order';
    super(message, options);
    this.message = message;
    this.error_id = SharedCartErrorId.previous_cart_abandoned;
  }
}

// PartialRecord since we don't get back general errors from the backend, it's a failsafe
export const ERROR_ID_TO_TYPE: Record<
  Exclude<SharedCartErrorId, SharedCartErrorId.general_shared_cart_error>,
  typeof GeneralSharedCartError
> = {
  [SharedCartErrorId.cart_must_be_checked_out]: CartMustBeCheckedOutError,
  [SharedCartErrorId.invalid_shared_cart_id]: InvalidSharedCartIdError,
  [SharedCartErrorId.disallow_joining_closed_carts]: CannotJoinClosedCartError,
  [SharedCartErrorId.disallow_editing_closed_carts]: CannotEditClosedCartError,
  [SharedCartErrorId.insufficient_secrets_provided]: InsufficientSecretsProvidedError,
  [SharedCartErrorId.secret_link_not_found]: InvalidSecretForLinkError,
  [SharedCartErrorId.incorrect_cart_password]: InvalidSecretPasswordError,
  [SharedCartErrorId.no_items_to_edit]: CartMemberHasNoItemsToEditError,
  [SharedCartErrorId.one_open_cart_per_cart_master]: OneOpenCartPerCartMasterError,
  [SharedCartErrorId.user_is_not_cart_master]: UserIsNotCartMasterError,
  [SharedCartErrorId.user_not_member_of_cart]: UserIsNotMemberOfCartError,
  [SharedCartErrorId.no_negative_quantity]: InvalidQuantityError,
  [SharedCartErrorId.cannot_edit_locked_cart]: CannotEditLockedCartError,
  [SharedCartErrorId.require_lock_before_checkout]: CannotCloseOpenCartWithoutLockError,
  [SharedCartErrorId.cannot_edit_abandoned_cart]: CannotEditAbandonedCartError,
  [SharedCartErrorId.wait_for_others_before_editing]: WaitForOtherMemberToFinishEditingError,
  [SharedCartErrorId.cart_name_invalid]: CartNameInvalidError,
  [SharedCartErrorId.previous_cart_abandoned]: PreviousCartAbandonedError,
  [SharedCartErrorId.user_can_only_edit_owned_items]: UserCannotEditOtherMembersItemsError,
};

export type SharedCartErrorType =
  | CartMustBeCheckedOutError
  | GeneralSharedCartError
  | InvalidSharedCartIdError
  | CannotJoinClosedCartError
  | CannotEditClosedCartError
  | InsufficientSecretsProvidedError
  | InvalidSecretForLinkError
  | InvalidSecretPasswordError
  | CartMemberHasNoItemsToEditError
  | OneOpenCartPerCartMasterError
  | UserIsNotCartMasterError
  | UserIsNotMemberOfCartError
  | InvalidQuantityError
  | CannotEditLockedCartError
  | CannotCloseOpenCartWithoutLockError
  | CannotEditAbandonedCartError
  | WaitForOtherMemberToFinishEditingError
  | CartNameInvalidError
  | PreviousCartAbandonedError
  | UserCannotEditOtherMembersItemsError;
