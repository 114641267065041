import { action, computed, makeObservable, observable, runInAction } from 'mobx';

// Constants
import { TOPLoggedError } from 'constants/Errors';

// Types
import Modifier from 'models/Modifier';
import ModifierGroup from 'models/ModifierGroup';
import MenuDataStore from 'stores/MenuDataStore';
import { MinimumRequiredFields } from 'utils/Types';

export type MinimumDataToConstructCartModifier = MinimumRequiredFields<Omit<CartModifier, 'mods'>, 'menu_item_id'> & {
  mods?: Record<string, MinimumDataToConstructCartModifier[]>;
};

export default class CartModifier {
  menu_item_id: string = '';
  mods: Record<string, Array<CartModifier>> = {};
  parent!: CartModifier;
  selected: boolean = true;
  qty!: number;

  menuDataStore!: MenuDataStore;
  modifier_group_id!: string;

  constructor(
    menuDataStore: MenuDataStore,
    parent: CartModifier,
    modifierGroupId: string,
    data: MinimumDataToConstructCartModifier
  ) {
    makeObservable(this, {
      menu_item_id: observable, // this should never change
      menuDataStore: false,
      mods: observable.deep,
      numMissingSelections: computed,
      parent: false,
      selected: observable,
      selectedMods: computed,
      toggleModifier: action,
      qty: observable,
    });

    runInAction(() => {
      this.menu_item_id = data.menu_item_id;
      this.menuDataStore = menuDataStore;
      this.modifier_group_id = modifierGroupId;
      this.parent = parent;
      this.qty = data.qty ?? 1;
    });

    if (data.mods && Object.keys(data.mods ?? {}).length > 0) {
      this._setMods(data.mods);
    } else {
      this._setPreselectedMods();
    }
  }

  get name() {
    return this.menuItem?.name_for_customer;
  }

  /**
   * Used only to increment the quantity and not toggle the modifier.
   * NOTE: Do not use this function if you are selecting a modifier or going from qty 0 to qty 1.
   * @param group
   * @param modifierId
   * @param incrementAmount
   * @returns {{success: boolean, errorType: string, errorTitle: string, errorMessage: string}}
   */
  incrementModifierQty(group: ModifierGroup, modifierId: string, incrementAmount = 1) {
    try {
      const referencedModifier = this.menuDataStore.modifiersById[modifierId];
      const currentMod = this.parent.mods[group.id].find((mod) => mod.menu_item_id === referencedModifier.id);

      const selectedMods = this.parent.mods[group.id].filter((mod) => mod.selected);
      const selectedModsQty = selectedMods.reduce((total, mod) => total + mod.qty, 0);

      // Check set of conditions that allow for incrementing qty
      if (group.max_selected && selectedModsQty >= group.max_selected) {
        return;
      } else {
        this.setQty((currentMod?.qty ?? 1) + incrementAmount);
      }
    } catch (error) {
      if (!(error instanceof TOPLoggedError)) {
        console.error(error);
      } // Log the error if it's not a TOPLoggedError
    }
  }

  /**
   * Used only to decrement the quantity of a modifier.
   * @param group
   * @param modifierId
   * @param decrementAmount
   * @returns {{success: boolean, errorType: string, errorTitle: string, errorMessage: string}}
   */
  decrementModifierQty(group: ModifierGroup, modifierId: string, decrementAmount = 1) {
    try {
      const referencedModifier = this.menuDataStore.modifiersById[modifierId];
      if (!this.parent.mods[group.id]) {
        this.parent.mods[group.id] = [];
      }
      const currentMod = this.parent.mods[group.id].find((mod) => mod.menu_item_id === referencedModifier.id);
      this.setQty((currentMod?.qty ?? 1) - decrementAmount);
    } catch (error) {
      if (!(error instanceof TOPLoggedError)) {
        console.error(error);
      } // Log the error if it's not a TOPLoggedError
    }
  }

  setQty(num: number): void {
    if (num > 0) {
      runInAction(() => {
        this.qty = num;
      });
    } else if (num === 0) {
      runInAction(() => {
        this.selected = false;
      });
    }

    // Clone the mods making the mods behave as if they were immutable
    if (this.parent) {
      this.parent.cloneMods();
    }
  }

  cloneMods(): void {
    runInAction(() => {
      this.mods = { ...this.mods };
    });
  }

  /**
   * Convert cartItem mods in localStorage from JSON to CartModifier objects.
   */
  _setMods(mods: Record<string, MinimumDataToConstructCartModifier[]>) {
    runInAction(() => {
      Object.entries(mods).forEach(([modifierGroupId, modifiers]) => {
        this.mods[modifierGroupId] = modifiers.map(
          (modifier) => new CartModifier(this.menuDataStore, this, modifierGroupId, modifier)
        );
      });
    });
  }

  /**
   * Automatically select mods which have been configured to be "pre-selected" in the Owner Portal.
   */
  _setPreselectedMods() {
    this.menuItem?.modifierGroups.forEach((modGroup) => {
      const group = this.menuDataStore.modifierGroupsById[modGroup.modifierGroupId];

      Object.entries(group.modifiers).forEach(([id, mod]) => {
        if (
          modGroup.modifierIds.includes(id) &&
          mod.isFulfillable &&
          (mod.pre_selected || mod.default_modifier_quantity > 1)
        ) {
          this.toggleModifier(group, id);
        }
      });
    });
  }

  getPrice(modGroupId: string, modifier: Modifier) {
    const mods = { ...this.mods };
    const group = this.menuDataStore.modifierGroupsById[modGroupId];
    if (!mods[group.id]) {
      mods[group.id] = [];
    }

    const selectedMods = mods[modGroupId].filter((mod) => mod.selected);
    const numSelectedMods = selectedMods.reduce((total: number, mod: CartModifier) => total + mod.qty, 0);
    // TODO: Make price smarter when showing total
    if (group.num_free_mods > numSelectedMods) {
      return null;
    }

    // The cheapest mods will be the ones that get marked as free first
    selectedMods.sort(
      (mod1, mod2) =>
        this.menuDataStore.modifiersById[mod1.menu_item_id].pretax_cents -
        this.menuDataStore.modifiersById[mod2.menu_item_id].pretax_cents
    );

    let unappliedFreeMods = group.num_free_mods;

    selectedMods.forEach((mod) => {
      if (
        mod.menu_item_id === modifier.menu_item_id &&
        unappliedFreeMods > 0 &&
        (!modifier.modifier_allow_quantities || numSelectedMods === group.max_selected)
      ) {
        return null;
      }
      unappliedFreeMods -= mod.qty;
      return null;
    });

    return modifier.displayCents !== 0 ? modifier.displayCents : null;
  }

  toggleModifier(group: ModifierGroup, modifierId: string) {
    const mods = { ...this.mods };
    const toggledMod = this.menuDataStore.modifiersById[modifierId];

    if (!mods[group.id]) {
      mods[group.id] = [];
    }

    const currentMod = mods[group.id].find((mod) => mod.menu_item_id === toggledMod.id);

    const selectedMods = mods[group.id].filter((mod) => mod.selected);
    const selectedModsQty = selectedMods.reduce((total, mod) => total + mod.qty, 0);

    if (toggledMod.default_modifier_quantity > 1 && group.max_selected > selectedModsQty) {
      toggledMod.qty = Math.min(toggledMod.default_modifier_quantity, group.max_selected - selectedModsQty);
    }

    // Deselect all group mods before selecting `currentMod` or adding `toggledMod` to `this.mods`.
    if (group.is_radio) {
      mods[group.id].forEach((mod) => {
        const groupMod = mod;
        groupMod.selected = false;
      });
    } else if (group.max_selected && (!currentMod || !currentMod.selected)) {
      if (selectedModsQty >= group.max_selected) {
        if (group.max_selected === 1) {
          mods[group.id].forEach((mod) => {
            const groupMod = mod;
            groupMod.selected = false;
          });
        } else {
          return;
        }
      }
    }

    if (currentMod) {
      // If the selected modifier qty plus the selection about to be made is greater than the allow max selected
      // modifiers by more than 1, then we can reset the qty of the mod that is being toggled to qty 1 so that it
      // is still toggled and still is under the max quantity
      if (!currentMod.selected && selectedModsQty + currentMod.qty - group.max_selected >= 1) {
        currentMod.qty = 1;
      }

      currentMod.selected = !currentMod.selected;
    } else {
      mods[group.id].push(new CartModifier(this.menuDataStore, this, group.id, toggledMod));
    }

    runInAction(() => {
      this.mods = mods;
    });
  }

  get menuItem() {
    return this.menuDataStore.modifiersById[this.menu_item_id];
  }

  get modifier() {
    return this.menuDataStore.modifiersById[this.menu_item_id];
  }

  getMenuItemModifierGroup(groupId: string) {
    return this.modifier?.modifierGroups?.find((group) => group.modifierGroupId === groupId);
  }

  get selectedMods() {
    return Object.values(this.mods ?? {})
      .map((modList) => modList.filter((mod) => mod.selected).map((mod) => mod.menu_item_id))
      .flat();
  }

  hasRequiredModifiers(): boolean {
    const hasRequiredModifiers = Object.values(this.mods ?? {}).some((modList) =>
      modList.some((mod) => {
        const modifierGroup = mod.menuDataStore.modifierGroupsById[mod.modifier_group_id];
        return modifierGroup.necessity === 'Required';
      })
    );
    return hasRequiredModifiers;
  }

  hasMods() {
    return this.selectedMods.length > 0;
  }

  isSelected(childModifier: Modifier): boolean {
    if (!this.mods[childModifier.menu_heading_id]) {
      return false;
    }
    const foundMod = this.mods[childModifier.menu_heading_id].find((mod) => mod.menu_item_id === childModifier.id);
    return foundMod?.selected ?? false;
  }

  toJSON(): Partial<Modifier> {
    const mods = {};
    Object.keys(this.mods).forEach((id) => {
      mods[id] = this.mods[id].map((mod) => mod.toJSON());
    });
    return {
      menu_item_id: this.menu_item_id,
      qty: this.qty,
      mods,
    };
  }

  getButtonText(): string {
    const numMissing = this.numMissingSelections;

    if (numMissing === 0) {
      return 'Customize This Item';
    } else {
      return numMissing === 1 ? '1 Selection Required' : 'Additional Selections Required';
    }
  }

  /**
   * Gets the number of missing required selections for the current modifier and all child modifiers.
   * @returns {number}
   */
  get numMissingSelections(): number {
    let numMissing = 0;

    this.modifier.modifierGroups.forEach((group) => {
      const parentGroup = this.menuDataStore.modifierGroupsById[group.modifierGroupId];
      if (!this.mods[parentGroup.id]) {
        this.mods[parentGroup.id] = [];
      }

      const selectedMods = this.mods[parentGroup.id].filter((mod) => mod.selected);
      const selectedModsQty = selectedMods.reduce((total, selectedModifier) => total + selectedModifier.qty, 0);

      if (selectedModsQty < parentGroup.min_selected) {
        numMissing += parentGroup.min_selected - selectedModsQty;
      }
      if (selectedMods.length) {
        selectedMods.forEach((mod) => {
          numMissing += mod.getNumMissingChildSelections();
        });
      }
    });

    return numMissing;
  }

  /**
   * Recursively inspects child modifiers and returns the total number of missing required selections.
   * @returns {number}
   */
  getNumMissingChildSelections(): number {
    return this.modifier.modifierGroups.reduce((totalMissing, group) => {
      const modifierGroup = this.menuDataStore.modifierGroupsById[group.modifierGroupId];
      const cartMods = this.mods[group.modifierGroupId] || [];
      const selectedMods = cartMods.filter((mod) => mod.selected);
      const selectedModsQty = selectedMods.reduce((total, mod) => total + mod.qty, 0);

      const missingFromModifierGroup = Math.max(0, modifierGroup.min_selected - selectedModsQty);
      const missingFromSelectedMods = selectedMods.reduce(
        (total, mod) => total + mod.getNumMissingChildSelections(),
        0
      );

      return totalMissing + missingFromModifierGroup + missingFromSelectedMods;
    }, 0);
  }

  getCartModifier(groupId: string, id: string) {
    return this.mods[groupId]?.find((mod) => mod.menu_item_id === id);
  }

  isValid(group: ModifierGroup | null = null): boolean {
    // Used by Parent
    // Check validation for a specific group:
    if (group) {
      // Check to see if the minimum amount of fullilable mods is less than the minimum required selection
      const currentModModifierGroupModsList =
        this.getMenuItemModifierGroup(group.id)?.modifierIds?.map((modId) => this.menuDataStore.modifiersById[modId]) ??
        [];
      const fulfillableGroupMods = currentModModifierGroupModsList.filter((mod) => mod.isFulfillable);
      if (fulfillableGroupMods.length < group.min_selected) {
        new TOPLoggedError(
          `Modifier Group Error: There are fewer fulfillable modifiers available for ${group.heading_name} than the required minimum selection of ${group.min_selected}. Only ${fulfillableGroupMods.length} are fulfillable of the total modifier(s) for ${group.heading_name}.`
        );
      }

      const selectedMods = this.mods[group.id] ? this.mods[group.id].filter((mod) => mod.selected) : [];
      const selectedModsQty = selectedMods.reduce((total, selectedModifier) => total + selectedModifier.qty, 0);
      return !(selectedModsQty < group.min_selected || selectedMods.find((mod) => !mod.isValid()));
    }

    // Used by Nested Groups
    // Validate all groups:
    return this.menuItem.modifierGroups.every((groupDef) => {
      const nestedGroup = this.menuDataStore.modifierGroupsById[groupDef.modifierGroupId];
      const { id, min_selected: minSelected, heading_name: headingName } = nestedGroup;

      // Check to see if the minimum amount of fullilable mods is less than the minimum required selection
      const currentModModifierGroupModsList = (this.getMenuItemModifierGroup(id)?.modifierIds ?? []).map(
        (modId) => this.menuDataStore.modifiersById[modId]
      );
      const fulfillableGroupMods = currentModModifierGroupModsList.filter((mod) => mod.isFulfillable);

      if (fulfillableGroupMods.length < minSelected) {
        new TOPLoggedError(
          `Modifier Group Error: There are fewer fulfillable modifiers available for ${headingName} than the required minimum selection of ${minSelected}. Only ${fulfillableGroupMods.length} are fulfillable out of the ${groupDef?.modifierIds?.length} total modifier(s) for ${headingName}.`
        );
      }

      const selectedMods = (this.mods[id] ?? []).filter((mod) => mod.selected);
      const selectedModsQty = selectedMods.reduce((total, mod) => total + mod.qty, 0);

      return selectedModsQty >= minSelected && selectedMods.every((mod) => mod.isValid());
    });
  }

  /**
   * Cleans up the nested modifiers (removes instances of mods where selected=false).
   */
  clean(): void {
    runInAction(() => {
      this.mods = Object.fromEntries(
        Object.entries(this.mods)
          .map(([groupId, modList]) => {
            const selectedMods = modList.filter((mod) => mod.selected);
            selectedMods.forEach((mod) => mod.clean());
            return [groupId, selectedMods];
          })
          .filter(([_, modList]) => modList.length)
      );
    });
  }

  /**
   * Get the total price of a menu item and all of its modifiers.
   * @param cents {string} - Choice of 'pretax_cents' or 'price_in_cents'.
   * @returns {number}
   */
  getTotal(cents: 'price_in_cents' | 'pretax_cents' = 'price_in_cents', quantityForPricing: number | null = null) {
    const totalPrice: number = Object.entries(this.mods).reduce(
      (total, [groupId, mods]) => {
        const group = this.menuDataStore.modifierGroupsById[groupId];

        // The cheapest mods will be the ones that get marked as free first if the modifier group
        // has N free mods.
        const selectedMods = (mods ?? [])
          .filter((mod) => mod.selected)
          .sort(
            (mod1, mod2) =>
              (this.menuDataStore.modifiersById[mod1.menu_item_id]?.pretax_cents ?? 0) -
              (this.menuDataStore.modifiersById[mod2.menu_item_id]?.pretax_cents ?? 0)
          );

        let unappliedFreeMods = group?.num_free_mods ?? 0;

        return (
          total +
          selectedMods.reduce((subTotal, mod) => {
            const minQuantityToApplyFreePriceTo = Math.min(unappliedFreeMods, mod.qty);
            unappliedFreeMods -= minQuantityToApplyFreePriceTo;

            return subTotal + mod.getTotal(cents, mod.qty - minQuantityToApplyFreePriceTo);
          }, 0)
        );
      },
      this.menuItem ? this.menuItem[cents] : 0
    );

    return totalPrice * (quantityForPricing ?? this.qty);
  }

  /**
   * Get the total tax of item and its modifiers
   * @returns {number}
   */
  getTaxTotal(): number {
    let total = this.menuItem?.tax_cents || 0;
    Object.keys(this.mods).forEach((groupId) => {
      this.mods[groupId].forEach((modifier) => {
        if (modifier.selected) {
          total += modifier.getTaxTotal();
        }
      });
    });
    return total * this.qty;
  }

  getErrors(errors: Array<CartModifier | string> = []): Array<CartModifier | string> {
    this.menuItem.modifierGroups.forEach((group: ModifierGroup) => {
      const modifierGroup = this.menuDataStore.modifierGroupsById[group.modifierGroupId];
      const cartMods = this.mods[modifierGroup.id] || [];
      const selectedMods = cartMods.filter((mod) => mod.selected);
      const selectedModsQty = selectedMods.reduce((total, selectedModifier) => total + selectedModifier.qty, 0);
      if (modifierGroup.min_selected > selectedModsQty || modifierGroup.max_selected < selectedModsQty) {
        errors.push(this);
      }
      selectedMods.forEach((mod) => {
        const childrenErrors = mod.getErrors(errors);
        errors.concat(childrenErrors);
      });
    });
    return errors;
  }
}
