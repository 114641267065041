import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';

// Components
import CustomScroll from 'components/CustomScroll';
import {
    ChildrenWrapper,
    Header,
    StyledCloseButton,
    Title,
    TitleWrapper,
} from 'top-component-library/HalfSheetModal/styles';
import EmptyHalfSheetModal from './EmptyHalfSheetModal';

function HalfSheetModal({
  children,
  className,
  onClose,
  title,
  visible,
  showClose = true,
}: {
  children: React.ReactNode;
  className?: string;
  onClose: () => void;
  title?: string;
  visible: boolean;
  showClose?: boolean;
}) {
  const modalTitleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // A11Y: When the modal is made visible, focus the header.
    if (visible) {
      setTimeout(() => modalTitleRef?.current?.focus());
    }
  }, [visible]);

  return (
    <EmptyHalfSheetModal visible={visible} className={className} onClose={onClose} modalAriaLabel={title}>
      <Header>
        {showClose && <StyledCloseButton onClick={onClose} />}
        {/* A11Y: Need this wrapper because <Title> component doesn't accept the ref prop */}
        <TitleWrapper ref={modalTitleRef} tabIndex={0} aria-label={title}>
          <Title level={1} aria-hidden>
            {title}
          </Title>
        </TitleWrapper>
      </Header>
      <CustomScroll>
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </CustomScroll>
    </EmptyHalfSheetModal>
  );
}

export default observer(HalfSheetModal);
