export { default as DiningIcon } from 'assets/custom-icons/Dining';
export { default as CaretLeftIcon } from 'assets/custom-icons/CaretLeft';
export { default as ThumbsUp } from 'assets/custom-icons/ThumbsUp';
export { default as Receipt } from 'assets/custom-icons/Receipt';

// Tags
export { default as Alcohol } from 'assets/custom-icons/Alcohol';
export { default as GlutenFree } from 'assets/custom-icons/GlutenFree';
export { default as Spicy } from 'assets/custom-icons/Spicy';
export { default as Vegetarian } from 'assets/custom-icons/Vegetarian';
export { default as Vegan } from 'assets/custom-icons/Vegan';
export { default as DropdownArrow } from 'assets/custom-icons/DropdownArrow';
export { default as Server } from 'assets/custom-icons/Server';
export { TextTag } from 'assets/custom-icons/styles';

// Miscellaneous
export { default as TadaCircle } from 'assets/custom-icons/TadaCircle';
export { default as ThreeDotsInProgress } from 'assets/custom-icons/ThreeDotsInProgress';
export { default as Times } from 'assets/custom-icons/Times';
export { default as Plus } from 'assets/custom-icons/Plus';
export { default as RightArrow } from 'assets/custom-icons/RightArrow';
