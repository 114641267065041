import { makeAutoObservable, runInAction } from 'mobx';

// Types
import { Card, GiftCard } from 'models/Types';

export default class User {
  id: string = ''; // Can be either session.anonymous_user_id or User.id
  anonymous_user_id: string = '';
  is_anonymous: boolean = false;
  account_type: string = '';
  cards: Array<Card> = [];
  gift_cards: Array<GiftCard> = [];
  email: string = '';
  name: string = ''; // Not guaranteed to be fullname or unique
  first_name: string = '';
  last_name: string = '';
  is_active: boolean = false;
  login_type: string = '';
  mobile: string = '';
  birthday: string = '';
  picture: string = '';
  remember_cards: boolean = false;
  text_updates: boolean = false;
  ready_for_checkout: boolean = false;

  constructor(userData: User) {
    makeAutoObservable(this);

    this.is_anonymous = User.isAnonymous(userData);
    this.update(userData);
  }

  update(data: User) {
    runInAction(() => {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.hasOwnProperty(key)) {
          // TODO(types): remove this extra typing which removes TS7053 (can't assign as any) by more strictly typing this method
          // @ts-expect-error
          this[key] = data[key];
        }
      }
    });
  }

  get givenName() {
    return this.first_name?.length > 0 ? this.first_name : this.name;
  }

  markReadyForCheckout = (isReady: boolean) => {
    runInAction(() => {
      this.ready_for_checkout = isReady;
    });
  };

  static isAnonymous(userObj: User) {
    return userObj.is_anonymous ?? Boolean(!userObj.email); // Use isAnonymous if left side is nullish
  }
}
