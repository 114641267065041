import _ from 'lodash';

/**
 * Splits a total amount (usually an amount cents) into an array of numbers that add up to the total amount and
 * follow the distribution pattern passed in. Used for splitting checks.
 * @param totalAmount integer
 * @param weights array of numbers [20, 30, 50]
 */
export const splitByDistributions = (totalAmount: number, weights: Array<any>): Array<number> => {
  const distributions: any[] = [];
  let totalRemaining = totalAmount;

  let weightRemaining = _.sum(weights);

  weights.forEach((weight, i) => {
    const fractionOfRemainingWeight = weightRemaining === 0 ? 0 : weight / weightRemaining;
    weightRemaining -= weight;
    const distribution =
      i < weights.length - 1 ? Math.round(totalRemaining * fractionOfRemainingWeight) : totalRemaining;
    totalRemaining -= distribution;
    distributions.push(distribution);
  });

  return distributions;
};
