import { computed, makeAutoObservable, runInAction } from 'mobx';
import { DateTime } from 'luxon';
import { getCookie } from 'utils/Cookie';
import { retrieveFromLocalStorage, saveToLocalStorage } from 'utils/LocalStorage';
import { TODO } from 'utils/Types';
import { uncensorTwilio } from 'utils/Host';

import Order from 'models/Order';
import Customer from 'models/Customer';
import { TabStatus, TabData } from 'api/types';

export default class Tab {
  access_code?: string;
  id: string = '';
  status: TabStatus = TabStatus.Unauthorized;
  customer: Customer;
  tab_name: string = '';
  start_date: DateTime = DateTime.fromSeconds(0);
  end_date: DateTime = DateTime.fromSeconds(0);
  used_cents: number = 0;
  limit_cents: number = 0;
  card_brand: string = '';
  card_exp_month: number = 0;
  card_exp_year: number = 0;
  card_last_4: string = '';
  unpaid_cents: number = 0;
  version_hash: string;
  default_tip: number = 0;
  orders: Array<Order> = [];

  // takes in an object and updates the fields
  constructor(tabData: Partial<Tab> | TabData) {
    this.access_code = tabData.access_code;
    this.version_hash = tabData.version_hash as string;

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    this.customer = new Customer(tabData?.customer ?? ({} as Customer));
    this.update(tabData);

    makeAutoObservable(this, {
      isClosed: computed,
      isOpenForOrders: computed,
      availableCents: computed,
      extraCheckoutInfo: computed,
    });
  }

  get isClosed() {
    return this.status === TabStatus.Settled;
  }

  get isOpenForOrders() {
    return this.status === TabStatus.Open;
  }

  update(data: Partial<Tab>): void {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.hasOwnProperty(key)) {
        // @ts-expect-error
        this[key] = data[key];
      }
    }

    // Format the dates to Luxon dates
    runInAction(() => {
      this.start_date = data?.start_date ? DateTime.fromISO(data?.start_date as unknown as string) : this.start_date;
      this.end_date = data?.start_date ? DateTime.fromISO(data?.end_date as unknown as string) : this.end_date;
    });
  }

  get availableCents(): number {
    return this.limit_cents - this.used_cents;
  }

  get extraCheckoutInfo(): Array<TODO> {
    return retrieveFromLocalStorage(`tabExtraCheckoutInfo:${this.id}`);
  }

  static getCloseTabIdFromURLIfOpenOrClosed() {
    if (!window || !window.location.href) {
      return '';
    }
    const url = new URL(window.location.href);
    return uncensorTwilio(url.searchParams.get('tabId'));
  }

  static getCloseTabIdFromURLIfOpen(): string | null {
    if (!window || !window.location.href) {
      return null;
    }

    const url = new URL(window.location.href);
    const tabId = uncensorTwilio(url.searchParams.get('tabId'));
    const tabAlreadyClosed = Boolean(getCookie(`closedTab:${tabId}`));
    return !tabAlreadyClosed ? tabId : null;
  }

  static saveRequiredCheckoutInfoToLocalStorage(tabId: string, cartPayload: any): void {
    const { extra_checkout_info: extraCheckoutInfo } = cartPayload;
    saveToLocalStorage(`tabExtraCheckoutInfo:${tabId}`, extraCheckoutInfo ?? []);
  }

  // NOTE: Uses existing pre checkout instructions configured for the merchant.
  static getCloseTabInstructionsHtmlTop(tab: Tab): string | undefined {
    const { pre_checkout_instructions: preCheckoutInstructions } = tab.customer?.app_properties ?? {};
    return preCheckoutInstructions?.top_always;
  }

  // NOTE: Uses existing pre checkout instructions configured for the merchant.
  static getCloseTabInstructionsHtmlBottom(tab: Tab): string | undefined {
    const { pre_checkout_instructions: preCheckoutInstructions } = tab.customer?.app_properties ?? {};
    return preCheckoutInstructions?.bottom_always;
  }
}
