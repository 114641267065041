import Customer from 'models/Customer';

export const loadCustomerIntegrations = (customer: Customer) => {
  if (
    customer?.google_tag_manager &&
    (!window.google_tag_manager ||
      (window.google_tag_manager && !window.google_tag_manager[customer.google_tag_manager.id]))
  ) {
    loadCustomerTagManager(customer.google_tag_manager.id);
  }
};

export const removeCustomerIntegrations = (customer: Customer) => {
  if (customer.google_tag_manager?.id && window.google_tag_manager?.[customer.google_tag_manager.id]) {
    removeCustomerTagManager(customer.google_tag_manager.id);
  }
};

export const loadCustomerTagManager = (containerId: string): void => {
  (function (w, d, s, l, i) {
    // @ts-expect-error
    w[l] = w[l] || [];
    // @ts-expect-error
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s) as HTMLScriptElement;
    const dl = l !== 'dataLayer' ? `&l=${l}` : '';
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    j.type = 'text/partytown';
    // @ts-expect-error
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', containerId);
};

export const removeCustomerTagManager = (containerId: string): void => {
  try {
    const gtmDataLayer = window.google_tag_manager?.dataLayer;
    if (!gtmDataLayer) {
      return;
    }

    const numSubscribers = gtmDataLayer.subscribers;
    gtmDataLayer.subscribers = Math.max(numSubscribers - 1, 0);
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete window.google_tag_manager[containerId];
  } catch (err) {
    console.error(err);
  }
};
