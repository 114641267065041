import { cartFormatting } from 'integrations/segment/util/formatting';

export const formatSharedCartForTracking = (sharedCart) => {
  if (!sharedCart) {
    return {};
  }
  return {
    id: sharedCart.id,
    name: sharedCart.name,
    cart_master_id: sharedCart.cart_master_id,
    location_id: sharedCart.location_id,
    status: sharedCart.status,
    time_modified: sharedCart.time_modified,
    members: sharedCart.members,
    shared_cart_items: formatSharedCartItemsForTracking(sharedCart.shared_cart_items),
    // TODO: isLoggedIn (Currently no way to ge this info with what is serialized)
  };
};

export const formatSharedCartItemForTracking = (sharedCartItem) => {
  if (!sharedCartItem) {
    return {};
  }
  return {
    ...cartFormatting.formatCartItemForTracking(sharedCartItem),
    owner_id: sharedCartItem.owner_id,
    shared_cart_id: sharedCartItem.shared_cart_id,
    for_patron: sharedCartItem.for_patron,
    for_anonymous_user: sharedCartItem.for_anonymous_user,
    time_created: sharedCartItem.time_created,
    ready_for_checkout: sharedCartItem.ready_for_checkout,
  };
};

export const formatSharedCartItemsForTracking = (sharedCartItems) => {
  if (!sharedCartItems || sharedCartItems.length === 0) {
    return [];
  }
  return sharedCartItems.map((item) => formatSharedCartItemForTracking(item));
};
