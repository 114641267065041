// Cart View
export enum CART_VIEW_MODES {
  CART_VIEW_DRAWER = 'drawer',
  CART_VIEW_CARD = 'card',
}

// tab polling status for dovetail
export enum TabOpenPollingStatus {
  TabOpenInProgress = 'Tab open operation in progress',
  TabOpened = 'Tab Opened',
  NotFound = 'No active tab found.',
}

export enum TabClosePollingStatus {
  TabCloseInProgress = 'Tab close operation in progress',
  TabClosed = 'Tab Closed',
  NotFound = 'No active tab found.',
}
