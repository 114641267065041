import { SVGIconWrapper } from './styles';

const RightArrowSVG: React.FC<React.SVGProps<SVGElement>> = ({ fill }) => (
  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.29289 8.45891C5.90237 8.84943 5.90237 9.4826 6.29289 9.87312C6.68342 10.2636 7.31658 10.2636 7.70711 9.87312L11.7071 5.87312C12.0976 5.4826 12.0976 4.84943 11.7071 4.45891L7.70711 0.458909C7.31658 0.0683847 6.68342 0.0683846 6.29289 0.458908C5.90237 0.849433 5.90237 1.4826 6.29289 1.87312L8.58579 4.16602L1 4.16602C0.447716 4.16602 4.82825e-08 4.61373 0 5.16602C-4.8282e-08 5.7183 0.447716 6.16602 1 6.16602L8.58579 6.16602L6.29289 8.45891Z"
      fill={fill ?? '#191919'}
    />
  </svg>
);

const RightArrow: React.FC<React.SVGProps<SVGElement>> = ({ fill }) => (
  <SVGIconWrapper width={12} height={11} data-testid="right-arrow-tag" title="Right Arrow" aria-label="Right Arrow">
    <RightArrowSVG fill={fill} />
  </SVGIconWrapper>
);

export default RightArrow;
