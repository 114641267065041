import Icon from '@ant-design/icons';

const ThreeDotsInProgressSVG = (props) => (
  // <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  //   <path
  //     d="M17.6503 2.025C18.3974 1.27758 17.8682 0 16.8118 0H1.18821C0.131753 0 -0.397353 1.27758 0.349724 2.025L7.87499 9.54984V16.3125H5.90622C5.12961 16.3125 4.49996 16.9421 4.49996 17.7188C4.49996 17.8741 4.62582 18 4.78121 18H13.2188C13.3742 18 13.5 17.8741 13.5 17.7188C13.5 16.9421 12.8704 16.3125 12.0938 16.3125H10.125V9.54984L17.6503 2.025ZM15.6014 1.6875L13.9138 3.375H4.08652L2.399 1.6875H15.6014Z"
  //     fill={props.fill || '#292929'}
  //   />
  // </svg>

  <svg
    version="1.1"
    id="L4"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 0 0"
    xmlSpace="preserve"
  >
    <circle fill="#aaa" stroke="none" cx="6" cy="50" r="6">
      <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
    </circle>
    <circle fill="#aaa" stroke="none" cx="26" cy="50" r="6">
      <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.2" />
    </circle>
    <circle fill="#aaa" stroke="none" cx="46" cy="50" r="6">
      <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.3" />
    </circle>
  </svg>
);

const ThreeDotsInProgress = (props) => (
  <Icon
    data-testid="alcohol-tag"
    title="Alcohol"
    aria-label="Alcohol"
    component={() => ThreeDotsInProgressSVG(props)}
    {...props}
  />
);

export default ThreeDotsInProgress;
