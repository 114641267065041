/* eslint-disable @typescript-eslint/no-shadow */
import { TODO } from './Types';

interface Cookie {
  [key: string]: TODO;
}

interface Cookies extends Array<Cookie> {}

// eslint-disable-next-line consistent-return
export const getCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
};

export const getAllMatchingCookies = (name: string): Cookies => {
  const results: Cookies = [];
  const value = `; ${document.cookie}`;
  const parts = value.split('; ');

  parts.forEach((part) => {
    // eslint-disable-next-line no-shadow
    const [key, value] = part.split('=');
    if (key.toLowerCase() === name) {
      results.push({ key, value });
    }
  });

  return results;
};

export const getAllMatchingCookiesWithPrefix = (prefix: string): Cookies => {
  const prefixParam = prefix.toLowerCase();
  const results: Cookies = [];
  const value = `; ${document.cookie}`;
  const parts = value.split('; ');

  parts.forEach((part) => {
    // eslint-disable-next-line no-shadow
    const [key, value] = part.split('=');
    if (key.toLowerCase().startsWith(prefixParam)) {
      results.push({ key, value });
    }
  });

  return results;
};

export const deleteCookie = (name: string): void => {
  try {
    const cookieValue = getCookie(name);
    if (cookieValue) {
      setCookie(name, cookieValue, -1);
    }
  } catch (error) {}
};

/**
 * This code deletes all cookies that clientside JS has access to.
 *
 * NOTE that this code has two limitations:
 * - It will not delete cookies with HttpOnly flag set, as the HttpOnly flag disables Javascript's access to the cookie.
 * - It will not delete cookies that have been set with a Path value. (This is despite the fact that those cookies will
 *   appear in document.cookie, but you can't delete it without specifying the same Path value with which it was set.)
 *
 * Original Author: https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript
 */
export const deleteAllClientCookies = (): void => {
  const cookies = document.cookie.split(';');
  cookies.forEach((cookie) => {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  });
};

export const checkCookieExists = (name: string): boolean =>
  document.cookie.split(';').some((item) => item.trim().startsWith(`${name}=`));

export const setCookie = (
  name: string,
  value: string,
  days: number = 90,
  sameSite: string = 'Lax',
  secure: boolean = process.env.NODE_ENV !== 'development'
): void => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value ?? ''}${expires}; path=/; SameSite=${sameSite}; ${secure ? 'Secure;' : ''}`;
};

export const getCsrfCookieName = (): string => {
  if (process.env.REACT_APP_BBOT_ENVIRONMENT && process.env.REACT_APP_COOKIE_HASH) {
    return `csrftoken${process.env.REACT_APP_BBOT_ENVIRONMENT}_${process.env.REACT_APP_COOKIE_HASH}`;
  } else {
    return `csrftokenprod_${process.env.REACT_APP_COOKIE_HASH ?? ''}`;
  }
};

export const getSessionCookieName = (): string => {
  if (process.env.REACT_APP_BBOT_ENVIRONMENT && process.env.REACT_APP_COOKIE_HASH) {
    return `session${process.env.REACT_APP_BBOT_ENVIRONMENT}_${process.env.REACT_APP_COOKIE_HASH}`;
  } else {
    return `session${process.env.REACT_APP_COOKIE_HASH ?? ''}`;
  }
};
