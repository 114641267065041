declare global {
  interface Window {
    google_tag_manager: any; // Used to track the set of loaded GTM containers
    dataLayer: any; // Used for sending Google Tag Manager events
    fbq: any; // Used for sending Facebook Pixel events
    FB: any; // Use with Facebook SDK
    fbAsyncInit: any; // Use with Facebook SDK
  }
}

export {};
