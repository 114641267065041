export const formatLocaleToValidSyntax = (locale: string = 'en-US'): Intl.Locale => {
  try {
    // TODO: remove this once we run a DB command to fix all backend usage of locale
    const validSyntaxLocale = locale.slice().replace('_', '-');
    return new Intl.Locale(validSyntaxLocale);
  } catch (err) {
    console.error(err);
    return new Intl.Locale('en-US');
  }
};
