import { track } from 'integrations/segment/instrumentation/Track';
import { FieldsType } from './types/types';

// Menu Page
export const trackClickGroupOrderButtonMenuPage = (fields: FieldsType = {}): void =>
  track('track_click_group_order_button_menu_page', fields);

export const trackClickCreateGroupOrderButtonMenuPage = (fields: FieldsType = {}): void =>
  track('track_click_create_group_order_button_menu_page', fields);

export const trackCreateGroupOrderButtonMenuPageSuccess = (fields: FieldsType = {}): void =>
  track('track_create_group_order_button_menu_page_success', fields);

export const trackCreateGroupOrderButtonMenuPageFailure = (fields: FieldsType = {}): void =>
  track('track_create_group_order_button_menu_page_failure', fields);

export const trackClickLeaveGroupOrderButtonMenuPage = (fields: FieldsType = {}): void =>
  track('track_click_leave_group_order_button_menu_page', fields);

export const trackLeaveGroupOrderButtonMenuPageSuccess = (fields: FieldsType = {}): void =>
  track('track_leave_group_order_button_menu_page_success', fields);

export const trackLeaveGroupOrderButtonMenuPageFailure = (fields: FieldsType = {}): void =>
  track('track_leave_group_order_button_menu_page_failure', fields);

// Shared Cart Item
export const trackClickAddSharedCartItem = (fields: FieldsType = {}): void =>
  track('track_click_add_shared_cart_item', fields);

export const trackAddSharedCartItem = (fields: FieldsType = {}): void => track('track_add_shared_cart_item', fields);

export const trackAddSharedCartItemSuccess = (fields: FieldsType = {}): void =>
  track('track_add_shared_cart_item_success', fields);

export const trackAddSharedCartItemFailure = (fields: FieldsType = {}): void =>
  track('track_add_shared_cart_item_failure', fields);

export const trackClickEditSharedCartItem = (fields: FieldsType = {}): void =>
  track('track_click_edit_shared_cart_item', fields);

export const trackEditSharedCartItem = (fields: FieldsType = {}): void => track('track_edit_shared_cart_item', fields);

export const trackEditSharedCartItemSuccess = (fields: FieldsType = {}): void =>
  track('track_edit_shared_cart_item_success', fields);

export const trackRemoveSharedCartItem = (fields: FieldsType = {}): void =>
  track('track_remove_shared_cart_item', fields);
