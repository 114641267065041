import Modifier from 'models/Modifier';
import { MenuItemData } from 'api/types';
import { TODO } from '../utils/Types';
import MenuDataStore from '../stores/MenuDataStore';
import Menu from './Menu';
import MenuHeading from './MenuHeading';

export default class MenuItem extends Modifier {
  description: string = '';
  image_on_front_page: string = '';
  image_on_item_page: string = '';
  imageUrl: string = '';
  integrations = {};
  inventory_item_id: string = '';
  most_loved: boolean = false;
  print_method: string = '';
  printers: Array<TODO> = [];
  skip_ahead_minutes: TODO;
  special_instruction_config = {};
  stations_that_can_fulfill = [];
  recommended_items: string[] = [];
  upsells: string[] = [];
  menu_set: Array<Menu> = []; // The list of menus the item is attached to (NOTE: this may include menus not available at the given location)

  _menuData: Array<Menu> = []; // It is an empty array because menuData can contain several menus

  // Dict of menus that this menu item belongs to (helps prevent traversing menu list)
  _menuDict: Record<string, Menu> = {};

  // Not sure why there are two similar fields here?
  // TODO: reviewer - do we need this if we have the same _ field?
  private menuData: TODO;

  private customerData: TODO;

  constructor(menuDataStore: MenuDataStore, id: string, data: MenuItemData, taxInclusivePricing: boolean) {
    super(menuDataStore, data.menu_heading_id, id, data, taxInclusivePricing);

    this.update(data);
  }

  update = (data: MenuItemData) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.hasOwnProperty(key)) {
        // @ts-expect-error
        this[key] = data[key];
      }
    }
  };

  get name() {
    return this.name_for_customer || this.name_for_bartender;
  }

  get isFulfillable() {
    return super.isFulfillable && !!this.menuDataStore.fulfillableMenuItemIds?.includes(this.id);
  }

  // NOTE: a menu item can belong to several menus
  get menus() {
    if (this._menuData.length > 0) {
      return this._menuData;
    }

    this.menuData = this.menuDataStore.menus.filter((menu: { headings: Array<MenuHeading> }) =>
      menu.headings.map((heading) => heading.id).includes(this.menu_heading_id)
    );
    return this.menuData;
  }

  getMenu = (menuId: string) => {
    if (this._menuDict[menuId]) {
      return this._menuDict[menuId];
    }

    // Else traverse the list of menus to get the correct menu
    const referencedMenu = this.menus.find((menu: Menu) => menu.id === menuId);
    if (!referencedMenu) {
      return null;
    }
    this._menuDict[referencedMenu.id] = referencedMenu;
    return referencedMenu;
  };

  // A menu item can appear on several menus, so we must check the menu_id of the menu that will be displaying the item
  // to confirm whether the item is allowed to be shown on the current menu.
  showOnMenu = (menuId: string) => {
    // Else check what the menu allows
    const menu = this.getMenu(menuId);

    if (!menu) {
      return false;
    }
    return this.getIsVisible(menu);
  };

  /**
   * Criteria Pseudocode:
   * - IMPORTANT: Relevant menus are menus that include the currently viewed menu + children menus AND from those menus
   *              the ones that are menus which contain the menuItem directly (not from a merge)
   * && Is the item enabled
   * && (Is the item in_stock OR one of the relevant menus it belongs to allow showing out of stock items)
   * && the conditional block below
   * ---
   *    (Item is fulfillable AND the relevant menu it is directly a menuItem on is visible)
   * || (Item is fulfillable AND the relevant menu it is directly a menuItem on is not visible but that menu allows
   *      showing unfulfillable items)
   * ---
   * @param menu (The menu that the user is currently viewing, aka the selectedMenu)
   * @returns {boolean}
   */
  getIsVisible = (menu: Menu) =>
    this.enabled &&
    (this.in_stock || menu.show_out_of_stock_items) &&
    ((this.isFulfillable &&
      (this.itemMenusAreVisible(menu) || (!this.itemMenusAreVisible(menu) && menu.showsUnfulfillableItems))) ||
      (!this.isFulfillable && menu.showsUnfulfillableItems));

  itemMenusAreVisible = (menu: Menu) => this.menuDataStore.isOneItemMenuVisible(this.id, menu.menuId, this.menu_set);

  getCustomer(menuId: string) {
    if (this.customerData) {
      return this.customerData;
    }
    const customerData = this.menuDataStore.customersById[this.getMenu(menuId).customer_id];
    this.customerData = customerData;
    return this.customerData;
  }

  /**
   * @param menuId
   * @returns {array} - list of visible menu item tags
   */
  getTags(menuId: string) {
    const customerTags = this.getCustomer(menuId)?.tags ?? [];

    return this.tags
      .filter((tag) => customerTags?.[tag.name]?.visible)
      .map((tag) => ({ ...tag, display_name: customerTags?.[tag.name]?.display_name }));
  }

  get heading() {
    return this.menuDataStore.headingsById[this.menu_heading_id];
  }
}
