import { useStores } from 'hooks/use-stores';
import { useState, useEffect } from 'react';
import { getNetworkStatus } from 'utils/Connectivity';

const ConnectivityDetector = () => {
  const {
    rootStore: {
      uiState: { notification },
    },
  } = useStores();
  const { addEventListener, removeEventListener } = window;
  const [networkStatus, setNetworkStatus] = useState(getNetworkStatus());
  const { isOffline, isSlow } = networkStatus;

  const updateNetworkStatus = () => setNetworkStatus(getNetworkStatus());

  useEffect(() => {
    addEventListener('online', updateNetworkStatus);
    addEventListener('offline', updateNetworkStatus);
    addEventListener('visibilitychange', updateNetworkStatus);
    const intervalId = setInterval(updateNetworkStatus, 15000);
    return () => {
      removeEventListener('online', updateNetworkStatus);
      removeEventListener('offline', updateNetworkStatus);
      removeEventListener('visibilitychange', updateNetworkStatus);
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (isOffline) {
      notification.error({ message: "It looks like you're offline. Please reconnect to continue." });
    }
    if (isSlow) {
      notification.error({
        message:
          'It looks like your internet connection is slow. The site experience might be degraded until your connection improves.',
      });
    }
  }, [isSlow, isOffline]);

  return <span />;
};

export default ConnectivityDetector;
