export const HOSTNAMES_WITH_LOCAL_DEV_FEATURES = [
  '127.0.0.1',
  'localhost',
  'consumer.dev.bbot.menu',
  'consumer.staging.bbot.menu',
  'consumer.demo.bbot.menu',
];

export enum TOP_ENVIRONMENTS {
  LOCALDEV = 'localdev',
  DEV = 'dev',
  STAGING = 'staging',
  DEMO = 'demo',
  PROD = 'prod',
}

export const CurrentEnvironment = {
  isLocalDev: process.env.REACT_APP_BBOT_ENVIRONMENT === TOP_ENVIRONMENTS.LOCALDEV,
  isDev: process.env.REACT_APP_BBOT_ENVIRONMENT === TOP_ENVIRONMENTS.DEV,
  isStaging: process.env.REACT_APP_BBOT_ENVIRONMENT === TOP_ENVIRONMENTS.STAGING,
  isDemo: process.env.REACT_APP_BBOT_ENVIRONMENT === TOP_ENVIRONMENTS.DEMO,
  isProd: process.env.REACT_APP_BBOT_ENVIRONMENT === TOP_ENVIRONMENTS.PROD,
};

export enum DEVICE_ID {
  LABEL = 'dd_device_id',
}

export const isDevelopment = CurrentEnvironment.isLocalDev || CurrentEnvironment.isDev;
