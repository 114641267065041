import styled from 'styled-components';

export const SVGIconWrapper = styled.div<{ width: number; height: number; rotate?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  ${({ rotate }) => (rotate ? `transform: rotate(${rotate}deg)` : '')}
`;

type TagType = 'error' | 'warning' | 'neutral' | 'outline';

const BackgroundColor: Record<TagType, string> = {
  error: 'var(--color-dd-negative-emphasis__background)',
  warning: 'var(--color-dd-warning-emphasis__background)',
  neutral: 'var(--color-dd-informational-emphasis__background)',
  outline: 'transparent',
};

const FontColor: Record<TagType, string> = {
  error: 'var(--color-dd-negative-emphasis__foreground)',
  warning: 'var(--color-dd-warning-emphasis__foreground)',
  neutral: 'var(--color-dd-informational-emphasis__foreground)',
  outline: 'var(--color-dd-system-gray__50)',
};

export const TextTag = styled.div<{ type: TagType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  block-size: fit-content;
  font-size: 0.5rem;
  font-weight: 600;
  padding: 0rem 0.25rem;
  border-radius: 0.25rem;
  background-color: ${({ type }) => BackgroundColor[type]};
  color: ${({ type }) => FontColor[type]};
  border: 1px solid ${({ type }) => (type === 'outline' ? FontColor.outline : BackgroundColor[type])};
`;
