import { customerFormatting } from 'integrations/segment/util/formatting';

// Customers
let host_customer, location_customer;

export const setLocationCustomer = (locationCustomer) => {
  location_customer = locationCustomer;
};

export const setHostCustomer = (hostCustomer) => {
  host_customer = hostCustomer;
};

// Customer that owner the location code
export const getFormattedLocationCustomerForEvent = () => {
  if (!location_customer || Object.keys(location_customer).length === 0) {
    return {};
  }
  return {
    ...customerFormatting.formatCustomer(location_customer),
  };
};

// Customer that owns the BOS/hostname
export const getFormattedHostCustomerForEvent = () => {
  if (!host_customer || Object.keys(host_customer).length === 0) {
    return {};
  }
  return {
    ...customerFormatting.formatCustomer(host_customer),
  };
};
