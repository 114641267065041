/* eslint-disable no-return-assign */
interface ExposureContextType {
  revision_version: number;
  experiment_version: number;
  bucket_key_type: string;
  bucket_key: string;
  segment: string;
  tag: string;
}

interface SuccessType {
  name: string;
  value: boolean;
  exposure_enabled: boolean;
  exposure_context: ExposureContextType;
}

interface ContextType {
  application: string;
  app_version: string;
  os: string;
  userId: string;
  businessId: string;
}

interface ResponseType {
  context: ContextType;
  successes: SuccessType[];
  failures: any[];
}

interface GenericObjectType {
  [key: string]: boolean;
}

const kebabize = (str: string): string =>
  str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? '-' : '') + $.toLowerCase());

const urlParams = (url: string): Record<string, any> =>
  JSON.parse(`{"${url.replace(/&/g, '","').replace(/=/g, '":"')}"}`, (key, value) =>
    key === '' ? value : decodeURIComponent(value)
  );

const extractDVUrlParams = (url?: string): GenericObjectType => {
  if (!url) {
    return {};
  }
  const params = urlParams(url);
  const overwrittenDVs = {};

  Object.keys(params).forEach((param) =>
    param.slice(0, 3) === 'DV_' ? (overwrittenDVs[kebabize(param.slice(3))] = params[param] === 'true') : null
  );

  return overwrittenDVs;
};

const processSuccess = (overwrittenDVs: GenericObjectType, success: SuccessType): boolean | null =>
  Object.prototype.hasOwnProperty.call(overwrittenDVs, success.name)
    ? (success.value = overwrittenDVs[success.name])
    : null;

export const processDVResponseWithOverwrites = (response: ResponseType, url?: string): ResponseType => {
  const overwrittenDVs = extractDVUrlParams(url ?? '');
  if (!response.successes || Object.keys(overwrittenDVs).length === 0) {
    return response;
  }
  const processedResponse = response;
  processedResponse.successes.forEach((success: SuccessType) => processSuccess(overwrittenDVs, success));
  return processedResponse;
};
