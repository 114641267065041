import { observer } from 'mobx-react';

// Icons
import { Plus } from 'assets/custom-icons';

// Styles
import { CloseButton } from './styles';

function CloseButtonComponent({
  className,
  onClick,
  testId,
}: {
  className?: string;
  onClick: () => void;
  testId?: string;
}) {
  return (
    <CloseButton
      data-testid={testId}
      aria-label="Close"
      // `className` is required to pass styles when used as a styled component.
      className={className}
      icon={<Plus />}
      onClick={onClick}
    />
  );
}

export default observer(CloseButtonComponent);
