// Tracking
import trackSegment from 'integrations/segment/trackSegment';
import {
  getFormattedHostCustomerForEvent,
  getFormattedLocationCustomerForEvent,
} from 'integrations/segment/instrumentation/CustomerService';
import { getUserContext } from 'integrations/segment/instrumentation/UserService';

// Constants
import { DEVICE_ID } from 'constants/Environments';

// Utils
import { retrieveFromLocalStorage } from 'utils/LocalStorage';

export const isSeoVisitFunctional = (referrer, queryParams, seoDomains) => {
  seoDomains.forEach((seoDomain) => {
    const { domain, utmAllowed } = seoDomain;

    if (referrer.includes(domain)) {
      if (utmAllowed) {
        return true;
      }

      return queryParams.utm_source === undefined;
    }
    return null;
  });

  return false;
};

// Track custom events using NewRelic as a destination instead of regular Segment.
export const trackNewrelic = (eventName, body) => {
  // uncomment to log warnings for all tracking events
  // console.warn('::: Newrelict Event Fired :::', eventName, body)
  const newrelic = window?.newrelic;
  if (newrelic) {
    newrelic.addPageAction(eventName, body);
  }
};

// augment bundle_context when the events are emitted from a specific page.
// this could potentially helpful to emit any additional parameters specific to a page
export const getAdditionalFieldsForPage = () => {
  if (window?.location?.pathname?.match(/^\/order-status\//) ?? window?.location?.pathname?.match(/^\/thank-you\//)) {
    return { page_type: 'post_checkout' };
  } else if (window?.location?.pathname?.match(/^\/checkout\//)) {
    return { page_type: 'checkout' };
  } else if (window?.location?.pathname === '/') {
    return { page_type: 'bos_landing_page' };
  } else if (window?.location?.pathname?.length > 0 && !window?.location?.pathname?.match(/^\/checkout\//)) {
    return { page_type: 'menu_page' };
  } else {
    return {};
  }
};

// This tells us if the website is loaded using the BOS Builder (aka not a real checkout)
export const getInIframeContext = () => {
  if (window?.location) {
    return {
      in_iframe: window.location !== window.parent.location,
    };
  } else {
    return {};
  }
};

export const getDomainContext = () => {
  if (window?.location) {
    return {
      domain: { href: window.location.href, hostname: window.location.hostname, pathname: window.location.pathname },
    };
  } else {
    return {};
  }
};

export const getEnvironmentContext = (context = {}) => {
  if (context.app) {
    return {
      app: {
        ...context.app,
        environment: process.env.REACT_APP_BBOT_ENVIRONMENT,
      },
      ...context,
    };
  } else {
    return {
      app: {
        environment: process.env.REACT_APP_BBOT_ENVIRONMENT,
      },
      ...context,
    };
  }
};

export const getDeviceContext = () => {
  if (!window) {
    return {};
  }
  const deviceId = retrieveFromLocalStorage(DEVICE_ID.LABEL);
  return { device_id: deviceId };
};

export const trackHelper = (eventName = '', body = {}, context = {}) => {
  if (typeof window === 'undefined') {
    return;
  }

  const location_customer = getFormattedLocationCustomerForEvent();
  const host_customer = getFormattedHostCustomerForEvent();
  const user = getUserContext();
  const device = getDeviceContext();
  const domain = getDomainContext();
  const iframe = getInIframeContext();
  const page = getAdditionalFieldsForPage();

  const payload = {
    ...body,
    page,
    domain,
    iframe,
    device,
    location_customer,
    host_customer,
    user,
    environment: process.env.REACT_APP_BBOT_ENVIRONMENT,
  };

  const formattedContext = {
    ...getEnvironmentContext(context),
    ...getDeviceContext(),
    page,
    domain,
    iframe,
  };

  const injectedEventName = `bb_${eventName}`;

  trackSegment(injectedEventName, payload, formattedContext);
};

export const track = (eventName = '', body = {}, context = {}) => {
  trackHelper(eventName, body, context);
};
