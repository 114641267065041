/* eslint-disable consistent-return */
import { ManagedConfigurations } from '@robingenz/capacitor-managed-configurations';
import { Capacitor } from '@capacitor/core';
import { CurrentEnvironment } from 'constants/Environments';

const readLocalAndroidConfiguration = async (): Promise<{
  hostname: string | undefined;
  locationShortId: string | undefined;
  readerType: string | undefined;
}> =>
  // eslint-disable-next-line no-return-await
  await new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        hostname: process.env.REACT_APP_HOST_AS,
        locationShortId: process.env.REACT_APP_KIOSK_LOCATION_SHORT_ID,
        readerType: process.env.REACT_APP_KIOSK_READER_TYPE,
      });
    });
  });

export const getKioskHostname = async (): Promise<string | undefined> => {
  try {
    if (CurrentEnvironment.isLocalDev) {
      const config = await readLocalAndroidConfiguration();
      return config.hostname;
    } else if (Capacitor.getPlatform() === 'android') {
      const result = await ManagedConfigurations.getString({ key: 'hostname' });
      const hostname = result.value ?? process.env.REACT_APP_HOST_AS;
      return hostname;
    } else {
      return process.env.REACT_APP_HOST_AS;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getKioskLocation = async (): Promise<string | undefined> => {
  try {
    if (CurrentEnvironment.isLocalDev) {
      const config = await readLocalAndroidConfiguration();
      return config.locationShortId;
    } else if (Capacitor.getPlatform() === 'android') {
      const result = await ManagedConfigurations.getString({ key: 'locationShortId' });
      const locationCode = result.value ?? process.env.REACT_APP_KIOSK_LOCATION_SHORT_ID;
      return locationCode;
    } else {
      return process.env.REACT_APP_KIOSK_LOCATION_SHORT_ID;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getKioskReaderType = async (): Promise<string | undefined> => {
  try {
    if (CurrentEnvironment.isLocalDev) {
      const config = await readLocalAndroidConfiguration();
      return config.readerType;
    } else if (Capacitor.getPlatform() === 'android') {
      const result = await ManagedConfigurations.getString({ key: 'readerType' });
      const readerType = result.value ?? 'Internet';
      return readerType;
    } else {
      return process.env.REACT_APP_KIOSK_READER_TYPE;
    }
  } catch (error) {
    console.error(error);
  }
};
