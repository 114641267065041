import { track } from 'integrations/segment/instrumentation/Track';
import { FieldsType } from './types/types';

// Landing Page
export const trackLoadBOSLandingPage = (fields: FieldsType = {}): void => track('track_load_bos_landing_page', fields);

export const trackSearchAddressLandingPage = (fields: FieldsType = {}): void =>
  track('track_search_address_landing_page', fields);

export const trackSearchLocationCodeLandingPage = (fields: FieldsType = {}): void =>
  track('track_search_location_code_landing_page', fields);

export const trackLocationDropdownSelectionLandingPage = (fields: FieldsType = {}): void =>
  track('track_click_location_dropdown_landing_page', fields);

export const trackClickExternalLocationCodeButtonGroupButtonLandingPage = (fields: FieldsType = {}): void =>
  track('track_click_external_location_code_button_group_button_landing_page', fields);

export const trackClickLocationCodeButtonLandingPage = (fields: FieldsType = {}): void =>
  track('track_click_location_code_button_landing_page', fields);

export const trackClickExternalLocationCodeButtonLandingPage = (fields: FieldsType = {}): void =>
  track('track_click_external_location_code_button_landing_page', fields);

export const trackClickCustomerHomeLinkLandingPage = (fields: FieldsType = {}): void => {
  track('track_click_customer_home_link_landing_page', fields);
};

// Map Template
export const trackClickToggleFulfillmentTypeStorePicker = (fields: FieldsType = {}): void =>
  track('track_click_toggle_fulfillment_type_picker_landing_page', fields);

export const trackClickStoreHours = (fields: FieldsType = {}): void =>
  track('track_click_store_hours_landing_page', fields);
