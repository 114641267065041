import React, { Provider, useEffect, useState } from 'react';
import RootStore from 'stores/RootStore';

// Types
import { ContextProps, StoresContext } from 'contexts/types';
import TransportLayer from 'api/TransportLayer';
import CartItemEditorStore from 'stores/CartItemEditorStore';
import GoogleMapsAPI from 'integrations/GoogleMapsAPI';
import DynamicValuesProvider from 'DynamicValues/DynamicValuesProvider';
import { getHostName } from 'utils/Host';

export const createGenericContext = <T extends unknown>(): readonly [() => T, Provider<T | undefined>] => {
  const genericContext = React.createContext<T | undefined>(undefined);

  // Validate that we've assigned the context and we're using it within a provider
  const useGenericContext = (): T => {
    const contextIsDefined = React.useContext<T | undefined>(genericContext);
    if (!contextIsDefined) {
      throw new Error(
        `Asserted context value was not found. Context was not assigned, or was not wrapped in a Provider.`
      );
    }
    return contextIsDefined;
  };

  return [useGenericContext, genericContext.Provider] as const;
};

export const [useStoresContext, StoresContextProvider] = createGenericContext<StoresContext>();
export const [useApiContext, ApiContextProvider] = createGenericContext<TransportLayer>();
export const [useEditorContext, EditorContextProvider] = createGenericContext<CartItemEditorStore>();
export const [useGooglePlacesAPIContext, GooglePlacesAPIContextProvider] = createGenericContext<GoogleMapsAPI>();

export const ContextWrapper: React.FC<{ children: ContextProps['children'] }> = ({ children }) => {
  const [storeContextVal, setStoreContextVal] = useState<StoresContext | undefined>();
  const [apiContextVal, setApiContextVal] = useState<TransportLayer | undefined>();
  const [editorContextVal, setEditorContextVal] = useState<CartItemEditorStore | undefined>();
  const [googlePlaceApiContextVal, setGooglePlaceApiContextVal] = useState<GoogleMapsAPI | undefined>();

  useEffect(() => {
    (async () => {
      try {
        const hostname = (await getHostName()) ?? '';
        const rootStore = new RootStore(hostname);
        RootStore.setInstance(rootStore);
        setStoreContextVal({
          rootStore,
          checkoutStore: rootStore.checkoutStore,
          uiState: rootStore.uiState,
          userStore: rootStore.userStore,
          menuDataStore: rootStore.menuDataStore,
          ordersStore: rootStore.ordersStore,
          locationStore: rootStore.locationStore,
          lockStore: rootStore.lockStore,
          hostStore: rootStore.hostStore,
          tabStore: rootStore.tabStore,
          featuresStore: rootStore.featuresStore,
          editorStore: rootStore.editorStore,
          stripeStore: rootStore.stripeStore,
          GoogleMapsAPI: rootStore.GoogleMapsAPI,
          stripeTerminalStore: rootStore.stripeTerminalStore,
        });
        setApiContextVal(rootStore.api);
        setEditorContextVal(rootStore.editorStore);
        setGooglePlaceApiContextVal(rootStore.GoogleMapsAPI);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const loaded = storeContextVal && apiContextVal && editorContextVal && googlePlaceApiContextVal;

  return loaded ? (
    <StoresContextProvider value={storeContextVal}>
      <DynamicValuesProvider>
        <ApiContextProvider value={apiContextVal}>
          <EditorContextProvider value={editorContextVal}>
            <GooglePlacesAPIContextProvider value={googlePlaceApiContextVal}>{children}</GooglePlacesAPIContextProvider>
          </EditorContextProvider>
        </ApiContextProvider>
      </DynamicValuesProvider>
    </StoresContextProvider>
  ) : (
    <></>
  );
};
