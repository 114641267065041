import * as Sentry from '@sentry/react';
import { getAndroidRequestInterface, type AndroidRequestInterface } from 'capacitor/index';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getCsrfCookieName } from 'utils/Cookie';
import { getAPIUrl } from 'utils/Host';
import { EndpointError } from 'constants/Errors';
import { getIsNetworkError, getNetworkStatus } from 'utils/Connectivity';

export type RequestInterface = AxiosInstance | AndroidRequestInterface;

let defaultRequestInterface: AxiosInstance | null = null;

export const getDefaultRequestInterface = (hostname: string) => {
  if (defaultRequestInterface) {
    return defaultRequestInterface;
  }
  defaultRequestInterface = getAxiosRequestInterface(hostname);
  return defaultRequestInterface;
};

export const getRequestInterface = (hostname: string) =>
  getAndroidRequestInterface(hostname) ?? getAxiosRequestInterface(hostname);

const getAxiosRequestInterface = (hostname: string) => {
  const requestInterface = axios.create();
  requestInterface.defaults.baseURL = getAPIUrl();
  requestInterface.defaults.xsrfCookieName = getCsrfCookieName();
  requestInterface.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
  requestInterface.defaults.withCredentials = true;
  // TODO: figure out if this can be removed
  // TS doesn't think crossDomain exists on axios defaults
  // @ts-expect-error
  requestInterface.defaults.crossDomain = true;
  requestInterface.defaults.params = {
    host: hostname,
  };
  requestInterface.interceptors.response.use((response: AxiosResponse) => response, handleNetworkErrorRetries);
  requestInterface.interceptors.response.use((response: AxiosResponse) => response, getHandleResponseError(hostname));
  return requestInterface;
};

const MAX_INTERVAL_MS = 65_536; // 16 backoffs before we reach the limit

const getRetryDelay = (retries: number) => Math.min(MAX_INTERVAL_MS, 2 ** retries + Math.floor(Math.random() * 1000));

const handleNetworkErrorRetries = async (axiosError: AxiosError) => {
  const { config = {} } = axiosError;

  const shouldRetryOnNetworkError = 'retryOnNetworkError' in config && !!config.retryOnNetworkError;

  if (!shouldRetryOnNetworkError || !getIsNetworkError(axiosError)) {
    return await Promise.reject(axiosError);
  }

  // if there are already retries, this will be overwritten
  const configWithRetries: AxiosRequestConfig & { retries: number } = { retries: 0, ...config };
  const retryDelay = getRetryDelay(configWithRetries.retries);
  const requestInterface = getDefaultRequestInterface('');

  configWithRetries.retries += 1;

  return await new Promise((resolve) => {
    const intervalId = setInterval(() => {
      const { isOffline } = getNetworkStatus();
      if (!isOffline) {
        resolve(requestInterface(configWithRetries));
        clearInterval(intervalId);
      }
    }, retryDelay);
  });
};

const getHandleResponseError = (hostname: string) => async (error: AxiosError) =>
  await handleResponseError(error, hostname);

const handleResponseError = async (axiosError: AxiosError, hostname: string) => {
  const error = axiosError;

  if (error.response?.data && typeof error.response.data === 'object') {
    const { status, data: responseData } = error.response;
    const data = { message: '', ...responseData };

    switch (status) {
      case 302: {
        // redirect?
        const redirectTo = 'redirect' in data && typeof data.redirect === 'string' ? data.redirect : '';
        window.location.href = redirectTo;
        break;
      }
      case 400: // Bad request
      case 401: // Unauthorized
        break;
      case 403: // Forbidden
        error.response.data = { status: 403, ...data };
        break;
      case 404: // Not Found
      case 500: // Server Error
      case 504: // Gateway Timeout
      default:
        break;
    }

    // Parse all errorCodes to message
    if ('errorCode' in data && typeof data.errorCode === 'string') {
      data.message = data.errorCode;
    }

    Sentry.captureException(error);

    return await Promise.reject(
      new EndpointError(data.message, {
        ...data,
        hostname,
        endpoint: error.response.config.url,
        // TODO: figure out if this can be removed
        // cause is expecting an Error, but error.response is an AxiosResponse
        // @ts-expect-error
        cause: error.response,
        status: error.response.status,
      })
    );
  }

  return await Promise.reject(new Error(error.message));
};
