import _ from 'lodash';
import Modifier from 'models/Modifier';
import MenuDataStore from '../stores/MenuDataStore';
import { TODO } from '../utils/Types';
import CartItem from './CartItem';
import CartModifier from './CartModifier';

export default class ModifierGroup {
  id: string;
  order_in_menu: number = 0;

  heading_name: string = '';
  min_selected: number = 0;
  max_selected: number = 999;
  display_position: number = 0;
  archived: boolean = false;
  integrations: Record<string, TODO> = {};
  num_free_mods: number = 0;

  modifiers: Record<string, Modifier> = {};

  private readonly menuDataStore: MenuDataStore;

  constructor(menuDataStore: MenuDataStore, id: string, json: ModifierGroup) {
    this.id = id;
    this.menuDataStore = menuDataStore;
    this.update(json);
  }

  update = (json: ModifierGroup) => {
    this.order_in_menu = json.order_in_menu;
    this.heading_name = json.heading_name;
    this.min_selected = json.min_selected;
    this.max_selected = json.max_selected;
    this.display_position = json.display_position;
    this.archived = json.archived;
    this.integrations = json.integrations;
    this.num_free_mods = json.num_free_mods;
    _.mapValues(json.modifiers, (mod, id) => {
      if (this.modifiers[id]) {
        return;
      }
      this.modifiers[id] = new Modifier(this.menuDataStore, this.id, id, mod);
    });
  };

  get description() {
    if (this.max_selected === 999) {
      if (this.min_selected === 0) {
        return '';
      } else {
        return `Select at least ${this.min_selected}`;
      }
    } else if (this.min_selected === 0) {
      if (this.max_selected === 1) {
        return 'Select 1';
      } else {
        return `Select up to ${this.max_selected}`;
      }
    } else if (this.min_selected > 0) {
      if (this.min_selected < this.max_selected) {
        return `Select between ${this.min_selected} and ${this.max_selected}`;
      } else {
        return `Select ${this.min_selected}`;
      }
    } else {
      return null;
    }
  }

  get is_required() {
    return this.min_selected >= 1;
  }

  get necessity() {
    return this.min_selected >= 1 ? 'Required' : 'Optional';
  }

  get is_radio() {
    return this.min_selected === 1 && this.max_selected === 1;
  }

  isValid(item: CartItem) {
    const subGroup = item.mods[this.id];
    if (!subGroup || subGroup.filter((mod: CartModifier) => mod.selected).length < this.min_selected) {
      return false;
    }
    return _.every(subGroup, (mod) => mod.isValid());
  }
}
