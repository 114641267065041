import { SVGIconWrapper } from './styles';

const PlusSVG: React.FC<React.SVGProps<SVGElement>> = ({ fill }) => (
  <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 10.5C5.55228 10.5 6 10.0523 6 9.5L6 6.5H9C9.55228 6.5 10 6.05228 10 5.5C10 4.94772 9.55228 4.5 9 4.5L6 4.5L6 1.5C6 0.947715 5.55229 0.5 5 0.5C4.44772 0.5 4 0.947715 4 1.5L4 4.5L1 4.5C0.447715 4.5 0 4.94771 0 5.5C0 6.05228 0.447715 6.5 1 6.5H4L4 9.5C4 10.0523 4.44772 10.5 5 10.5Z"
      fill={fill ?? '#191919'}
    />
  </svg>
);

const Plus: React.FC<React.SVGProps<SVGElement>> = ({ fill }) => (
  <SVGIconWrapper width={10} height={11} data-testid="plus-tag" title="Plus" aria-label="Plus">
    <PlusSVG fill={fill} />
  </SVGIconWrapper>
);

export default Plus;
