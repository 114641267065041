import Cart from 'models/Cart';
import Customer from 'models/Customer';

// Utils
import { formatCartForFacebook } from 'integrations/facebook/util/formatting';

export const trackFBPurchaseEvent = (cart: Cart | null, customer: Customer): void => {
  try {
    if (!window || !window.fbq || !cart) {
      return;
    }

    window.fbq('track', 'Purchase', formatCartForFacebook(cart, customer));
  } catch (error) {
    console.error(error);
  }
};
