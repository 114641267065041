import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';

// Components
import { Container } from 'components/CustomScroll/styles';

// Hooks
import { useStores } from 'hooks/use-stores';

/**
 * Custom Scroll Component - Wraps children in a container with a customized
 * scrollbar when viewed at the `md` breakpoint on `-webkit` browsers.
 *
 * @param children
 * @param className
 * @param maxHeight {int} - max height of the scrollable area (in pixels)
 */
function CustomScroll({
  children,
  className,
  maxHeight,
}: {
  children: React.ReactNode;
  className?: string;
  maxHeight?: number;
}) {
  const customScrollRef = useRef<HTMLDivElement>(null);
  const [showScrollbar, setShowScrollbar] = useState<boolean>(false);
  const { uiState } = useStores();
  const { isMobile } = uiState;

  // Omit the scrollbar if the container is not actually scrollable.
  useEffect(() => {
    if (customScrollRef.current) {
      const { clientHeight, scrollHeight } = customScrollRef.current;
      if (scrollHeight > clientHeight) {
        setShowScrollbar(true);
      }
    }
  }, [children]);

  // Reset scroll position to the top of the container when changing between mobile/desktop.
  useEffect(() => {
    if (customScrollRef.current) {
      customScrollRef.current.scrollTop = 0;
    }
  }, [isMobile]);

  return (
    <Container
      className={`${className} ${showScrollbar ? 'show-scroll-bar ' : ''}`}
      maxHeight={maxHeight}
      ref={customScrollRef}
      showScrollbar={showScrollbar}
    >
      {children}
    </Container>
  );
}

export default observer(CustomScroll);
