import { observer } from 'mobx-react';

// Components
import { NumberField } from '@doordash/component-fields';
import { Icon, InlineChildren } from '@doordash/design-language';

// Utils
import { TipChoice } from 'models/Cart';
import { getTipOptionKey } from 'components/checkout-modules/CheckoutTipSelect';

// Types
import { TipType } from 'models/Types';
import { PossibleTipSelections } from '../TipOptions';

// Styles
import { NumerFieldWrapper } from './styles';

function CustomTipField({
  onSelectTip,
  setCustomTipAmount,
  customTipAmount,
}: {
  onSelectTip: (selection: PossibleTipSelections) => void;
  setCustomTipAmount: React.Dispatch<React.SetStateAction<string>>;
  customTipAmount: string;
}) {
  const updateSelectedTip = (input: string) => {
    // Convert input into float and then into cents (Must be in cents). If input is empty, set tip to 0 so cart can be calculted
    const amount = input === '' ? 0 : parseFloat(input) * 100;
    onSelectTip({ type: TipType.Amount, choice: getTipOptionKey(TipChoice.Other), amount });
  };

  const onChange = (input: string) => {
    const [, cents = ''] = input.split('.'); // Control input with decimal places: only allow user to enter 2 decimal places
    if (cents.length <= 2) {
      setCustomTipAmount(input);
      updateSelectedTip(input);
    }
  };

  return (
    <NumerFieldWrapper>
      <NumberField
        label="Enter Custom Tip"
        isLabelHidden
        value={customTipAmount}
        onChange={onChange}
        allowFloats
        data-testid="tip-amount"
        renderBeforeContent={() => (
          <InlineChildren>
            <Icon type={Icon.Types.MoneyDefault} size={Icon.Sizes.Small} shouldAdjustSmallSizesWhitespace />
          </InlineChildren>
        )}
      />
    </NumerFieldWrapper>
  );
}

export default observer(CustomTipField);
