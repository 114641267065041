import { Theming } from '@doordash/design-language';
import { observer } from 'mobx-react';
import { getCssPropertyValue } from 'utils/Css';

const get = (cssValueString: string) => {
  const cssValue = getCssPropertyValue(cssValueString);

  // In prod/staging some 6 char hex colors get shortened into 3, but the Prism Theming
  // cannot interpret 3 char hex colors. So if this happens, convert it back into 6 digits.
  if (cssValue.length === 4) {
    return cssValue.replace(/[^#]/g, (hexVal) => hexVal.repeat(2));
  }
  return cssValue;
};

/*
 * Allow us to style Prism components to match the TOP style.
 * We will continue to add more themeing here as we transition into using more Prism components
 *
 * See Docs Here: https://prism.doordash.com/v/2023-01-27/styles/theming
 */
function PrismTheming({ children }: { children: React.ReactNode }) {
  // Convert "button__border-radius" value from "8px" into 8 as an int
  const borderRadius = Number(get('--button__border-radius').replace(/[^0-9]/g, ''));

  return (
    <Theming
      overrides={{
        BorderRadius: { ButtonDefault: borderRadius },
        Shape: { ButtonDefault: 'Rectangle' },
        Typography: { FontFamily: 'inherit' }, // So that BOS fonts get loaded into all prism components
        Colors: {
          // Buttons
          ButtonPrimaryBackground: get('--color-button-primary__regular'),
          ButtonPrimaryBackgroundHovered: get('--color-button-primary__regular'),
          ButtonPrimaryBackgroundPressed: get('--color-button-primary__regular'),

          ButtonSecondaryBackground: get('--color-neutral__page-background'),
          ButtonSecondaryBackgroundHovered: get('--color-neutral__page-background'),
          ButtonSecondaryBackgroundPressed: get('--color-neutral__page-background'),

          // Fields
          FieldsBackgroundInactive: get('--color-neutral__input-background'),
          FieldsBackgroundActive: get('--color-neutral__input-background'),
          FieldsBorderActive: get('--color-input-border__selected'),
          FieldsBorderInactive: get('--color-neutral__line'),
        },
      }}
    >
      {children}
    </Theming>
  );
}

export default observer(PrismTheming);
