import { AxiosError } from 'axios';

interface NetworkStatus {
  isOffline: boolean;
  isSlow: boolean;
}

export const getNetworkStatus = (): NetworkStatus => ({
  isOffline: !navigator.onLine,
  // @ts-expect-error
  isSlow: navigator?.connection?.effectiveType === 'slow-2g',
});

export const getIsNetworkError = (error: AxiosError) => getNetworkStatus().isOffline || getIsAxiosNetworkError(error);

export const getIsAxiosNetworkError = (error: AxiosError) =>
  'message' in error && (error.message === 'Network Error' || error.message === 'timeout exceeded');
