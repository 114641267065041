import Icon from '@ant-design/icons';

const ReceiptSVG = () => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.625 1.23228L11.5 0.0429897L10.375 1.23228L9.25 0.0429897L8.125 1.23228L7 0.0429897L5.875 1.23228L4.75 0.0429897L3.625 1.23228L2.5 0.0429897L1.375 1.23228L0.25 0.0429897V15.9001L1.375 14.7108L2.5 15.9001L3.625 14.7108L4.75 15.9001L5.875 14.7108L7 15.9001L8.125 14.7108L9.25 15.9001L10.375 14.7108L11.5 15.9001L12.625 14.7108L13.75 15.9001V0.0429897L12.625 1.23228ZM12.25 13.5929H1.75V2.3502H12.25V13.5929ZM2.5 10.3501H11.5V11.9358H2.5V10.3501ZM2.5 7.1787H11.5V8.76442H2.5V7.1787ZM2.5 4.00728H11.5V5.59299H2.5V4.00728Z"
      className="filled-dark"
      fill="black"
    />
  </svg>
);

const Receipt = (props) => <Icon component={ReceiptSVG} {...props} />;

export default Receipt;
