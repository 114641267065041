import User from 'models/User';

export const formatUser = (user) =>
  !user
    ? {}
    : {
        id: user.id,
        account_type: user.account_type,
        is_active: user.is_active,
        mobile_saved: Boolean(user?.mobile),
        remember_cards: user.remember_cards,
        text_updates: user.text_updates,
        is_logged_in: User.isAnonymous(user),
      };
