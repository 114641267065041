import { track } from 'integrations/segment/instrumentation/Track';
import { FieldsType } from './types/types';

// Menu Item
export const trackViewMenuItem = (fields: FieldsType = {}): void => track('track_view_menu_item', fields);

export const trackClickAddToCart = (fields: FieldsType = {}): void => track('track_click_add_to_cart', fields);

export const trackAddToCartSuccess = (fields: FieldsType = {}): void => track('track_add_to_cart_success', fields);

export const trackAddToCartFailure = (fields: FieldsType = {}): void => track('track_add_to_cart_failure', fields);

// Cart Item
export const trackClickApplyEdits = (fields: FieldsType = {}): void => track('track_click_apply_edits', fields);

export const trackApplyEditsFailure = (fields: FieldsType = {}): void =>
  track('track_apply_cart_item_edits_failure', fields);

export const trackViewCartItem = (fields: FieldsType = {}): void => track('track_view_cart_item', fields);

export const trackViewCart = (fields: FieldsType = {}): void => track('track_view_cart', fields);

export const trackClickViewCart = (fields: FieldsType = {}): void => track('track_click_view_cart', fields);

export const trackClickEditCartItem = (fields: FieldsType = {}): void => track('track_click_edit_cart_item', fields);

export const trackEditCartItem = (fields: FieldsType = {}): void => track('track_edit_cart_item', fields);

export const trackClickRemoveCartItem = (fields: FieldsType = {}): void =>
  track('track_click_remove_cart_item', fields);

export const trackRemoveCartItem = (fields: FieldsType = {}): void => track('track_remove_cart_item', fields);

export const trackAddCartItem = (fields: FieldsType = {}): void => track('track_add_cart_item', fields);

export const trackAddCartItemShort = (fields: FieldsType = {}): void => track('track_add_cart_item_short', fields);

// Menu/Cart Item agnostic
export const trackClickQuantityStepper = (fields: FieldsType = {}): void =>
  track('track_click_quantity_stepper', fields);

export const trackClickSelectItemModifier = (fields: FieldsType = {}): void =>
  track('track_click_select_item_modifier', fields);

export const trackClickDeselectItemModifier = (fields: FieldsType = {}): void =>
  track('track_click_deselect_item_modifier', fields);

export const trackAddRecommendedItemToCart = (fields: FieldsType = {}): void =>
  track('track_add_recommended_item_to_cart', fields);
