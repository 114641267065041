import Cart from 'models/Cart';
import CartItem from 'models/CartItem';
import Customer from 'models/Customer';

interface FacebookCart {
  value: number;
  currency: string;
  contents: Array<FacebookCartItem>;
}

interface FacebookCartItem {
  id: string;
  quantity: number;
  content_name: string;
  content_category: string;
  value: number;
}

export const formatCartForFacebook = (cart: Cart, customer: Customer): FacebookCart => ({
  value: cart ? cart.cartTotal / 100 : 0,
  currency: customer.currency ?? 'usd',
  contents: cart.items.map((cartItem) => formatCartItemForFacebook(cartItem)),
});

const formatCartItemForFacebook = (cartItem: CartItem): FacebookCartItem => ({
  id: cartItem.menuItem?.menu_item_id,
  quantity: cartItem.qty,
  content_name: cartItem.menuItem.name_for_customer,
  content_category: cartItem.menuItem?.heading?.heading_name,
  value: cartItem.preDiscountPretaxCents ?? 0,
});
