import { track } from 'integrations/segment/instrumentation/Track';
import { FieldsType } from './types/types';

export const trackClickLoginWithFacebook = (fields: FieldsType = {}): void =>
  track('track_click_login_with_facebook', fields);

export const trackClickForgotYourPassword = (fields: FieldsType = {}): void =>
  track('track_click_forgot_your_password', fields);

export const trackClickSignInSignInPage = (fields: FieldsType = {}): void =>
  track('track_click_sign_in_sign_in_page', fields);

export const trackSignInSuccess = (fields: FieldsType = {}): void => track('track_sign_in_success', fields);

export const trackSignInFailure = (fields: FieldsType = {}): void => track('track_sign_in_failure', fields);

export const trackClickSignUp = (fields: FieldsType = {}): void => track('track_click_sign_up', fields);
