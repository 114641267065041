import { userFormatting } from 'integrations/segment/util/formatting';

let user = null;

export const setUserContext = (obj) => {
  user = obj;
};

export const getUserContext = () => getFormattedUserForEvent(user);

export const getFormattedUserForEvent = (userObj) =>
  !userObj || Object.keys(userObj).length === 0
    ? {}
    : {
        ...userFormatting.formatUser(userObj),
      };
