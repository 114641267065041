import { makeAutoObservable } from 'mobx';

export enum LockType {
  UserDataLoad = 'UserDataLoad',
  MenuLoad = 'MenuLoad',
  DeleteCard = 'DeleteCard',
  RedirectPage = 'RedirectPage',
}

export default class LockStore {
  readonly locks = new Map<LockType, number>();
  readonly subscriptions = new Map<LockType, Subscription>();

  constructor() {
    makeAutoObservable(this);
  }

  public acquire(type: LockType) {
    this.locks.set(type, (this.locks.get(type) ?? 0) + 1);
    this.subscriptions.set(type, this.subscriptions.get(type) ?? getSubscription());

    return this.getReleaseOnce(type);
  }

  public hasLock = (type: LockType) => (this.locks.get(type) ?? 0) > 0;

  public waitForRelease = async (type: LockType) => await this.subscriptions.get(type)?.subscribe();

  private getReleaseOnce(type: LockType) {
    let hasBeenReleased = false;
    return () => {
      if (!hasBeenReleased) {
        this.release(type);
      }
      hasBeenReleased = true;
    };
  }

  private release(type: LockType) {
    const remainingLocks = Math.max(0, (this.locks.get(type) ?? 1) - 1);
    this.locks.set(type, remainingLocks);
    if (!remainingLocks) {
      this.subscriptions.get(type)?.publish();
    }
  }
}

interface Subscription {
  subscribe: () => Promise<void>;
  publish: () => void;
}

const getSubscription = (): Subscription => {
  let resolver = (value: void | PromiseLike<void>) => {};
  const promise = new Promise<void>((resolve) => {
    resolver = resolve;
  });

  return {
    subscribe: async () => await promise,
    publish: () => {
      resolver();
    },
  };
};
