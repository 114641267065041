// Referenced from clientapp/website/models/common.py > STATUS_SEQUENCES
export enum FulfillmentMethod {
  PatronPickup = 'patron_pickup', // Takeout
  RunnerNoFeedback = 'runner_nofeedback', // Runner w/ no feedback to guest
  PickupNoFeedback = 'pickup_nofeedback', // Takeout w/ no feedback to gues
  CounterNoFeedback = 'counter_nofeedback', // Counter Service w/ no feedback to guest
  RunnerWithCC = 'runner_with_cc', // Server Delivery with Expo
  PatronChoice = 'patron_choice', // Delivery or Carryout choice
  PatronPickupWithExpo = 'patron_pickup_with_expo', // Takeout with Expo
  CounterServiceWithExpo = 'counter_service_with_expo', // Counter Service With Expo
  DriverDeliveryWithExpo = 'driver_delivery_with_expo', // Driver Delivery with Expo
  DriverDelivery = 'driver_delivery', // Driver Delivery
  Catering = 'catering', // Catering
}

export const PICKUP_FULFILLMENT_METHODS: Array<FulfillmentMethod> = [
  FulfillmentMethod.PatronPickup,
  FulfillmentMethod.PickupNoFeedback,
  FulfillmentMethod.PatronPickupWithExpo,
];

/** IMPORTANT:
 * These are used to determine if we must perform a validation step for ensuring that the inputted address is within
 * range of customers. If edited, ensure that required checkout info works as expected.
 */
export const DELIVERY_FULFILLMENT_METHODS: Array<FulfillmentMethod> = [
  FulfillmentMethod.DriverDelivery,
  FulfillmentMethod.DriverDeliveryWithExpo,
];

export const MAP_TEMPLATE: Record<string, Array<FulfillmentMethod>> = {
  SUPPORTED_FULFILLMENT_METHODS: [
    FulfillmentMethod.PatronPickup,
    FulfillmentMethod.DriverDelivery,
    FulfillmentMethod.PickupNoFeedback,
    FulfillmentMethod.PatronPickupWithExpo,
    FulfillmentMethod.DriverDeliveryWithExpo,
  ],
  DELIVERY_FULFILLMENT_METHODS,
  PICKUP_FULFILLMENT_METHODS,
};

export const MAP_TEMPLATE_TOGGLE_OPTIONS: Record<string, Array<string> | string> = {
  LIST: ['pickup_toggle_option', 'delivery_toggle_option'],
  PICKUP: 'pickup_toggle_option',
  DELIVERY: 'delivery_toggle_option',
};

export interface FulfillmentMethodObj {
  fulfillment_method: string;
  button_name: string;
}

export interface FulfillmentMethodOption {
  value: FulfillmentMethod;
  label: string;
  order: number;
}
