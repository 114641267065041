import { track } from '../instrumentation/Track';
import { FieldsType } from './types/types';

enum ExternalLoyaltyTrackingEvents {
  TrackClickExternalLoyaltySignInSignUp = 'track_click_sign_in_sign_up',
  TrackClickExternalLoyaltySignIn = 'track_click_external_loyalty_sign_in',
  TrackClickExternalLoyaltySignUp = 'track_click_external_loyalty_sign_up',
  TrackClickExternalLoyaltyRewardsToggle = 'track_click_external_loyalty_rewards_toggle',
}

export const trackClickExternalLoyaltySignInSignUp = (fields: FieldsType = {}) =>
  track(ExternalLoyaltyTrackingEvents.TrackClickExternalLoyaltySignInSignUp, fields);

export const trackClickExternalLoyaltySignIn = (fields: FieldsType = {}) =>
  track(ExternalLoyaltyTrackingEvents.TrackClickExternalLoyaltySignIn, fields);

export const trackClickExternalLoyaltySignUp = (fields: FieldsType = {}) =>
  track(ExternalLoyaltyTrackingEvents.TrackClickExternalLoyaltySignUp, fields);

export const trackClickExternalLoyaltyRewardsToggle = (fields: FieldsType = {}) =>
  track(ExternalLoyaltyTrackingEvents.TrackClickExternalLoyaltyRewardsToggle, fields);
