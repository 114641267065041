import Icon from '@ant-design/icons';

const DiningSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#292929" />
    <path
      d="M14.3333 8.50008V13.1667H16.0833V17.8334H17.25V6.16675C15.64 6.16675 14.3333 7.47341 14.3333 8.50008ZM11.4167 10.2501H10.25V6.16675H9.08333V10.2501H7.91667V6.16675H6.75V10.2501C6.75 11.5392 7.79417 12.5834 9.08333 12.5834V17.8334H10.25V12.5834C11.5392 12.5834 12.5833 11.5392 12.5833 10.2501V6.16675H11.4167V10.2501Z"
      fill="white"
    />
  </svg>
);

const Dining = (props) => <Icon component={DiningSVG} {...props} />;

export default Dining;
