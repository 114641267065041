import styled from 'styled-components';
import { Spacing } from '@doordash/design-language';

export const NumerFieldWrapper = styled.div`
  margin-top: ${Spacing.Small}px;

  // If there are any elements below this, add some spacing
  &:not(:last-child) {
    margin-bottom: ${Spacing.XSmall}px;
  }
`;
