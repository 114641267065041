import styled from 'styled-components';

// Constants
import { container } from 'constants/style';

const Container = styled.div`
  max-width: ${container.width}px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

export default Container;
