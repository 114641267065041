// Utils
import { type MenuItemModifierGroup } from 'api/types';
import { formatCentsToPrice } from 'utils/Currency';
import MenuDataStore from '../stores/MenuDataStore';
import { TODO } from '../utils/Types';
import { TagType } from './Customer';

export default class Modifier {
  display_position: number = 0;
  enabled: boolean = false;
  id: string;
  in_stock: boolean = false;
  inventory_item_id: string = '';
  // TODO(reviewer): should this be an undefined or null value? a default value likely is bad here
  inventory_qty: number | null = null;
  is_default_choice: boolean = false;
  menu_heading_id: string;
  menu_item_id: string;
  menu_item_class: string = '';
  modifierGroups: MenuItemModifierGroup[] = [];
  name_for_bartender: string = '';
  name_for_customer: string = '';
  name_for_owner: string = '';
  pre_selected: boolean = false;
  pretax_cents: number = 0;
  price: number = 0;
  price_in_cents: number = 0;
  // price_string = null;  // Deprecated from backend, logic moved to front-end
  primary_type: string = '';
  report_category: string = '';
  sku: string = '';
  tags: Array<Tag> = [];
  tax_cents: number = 0;
  tax_fraction: number = 0;
  tax_inclusive_pricing: boolean = false;
  modifier_allow_quantities: boolean = false;

  default_modifier_quantity: number = 1;

  private _minMaxRequiredPretaxCents?: { min: number; max: number };
  protected menuDataStore: MenuDataStore;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  constructor(menuDataStore: TODO, menu_heading_id: string, id: string, data: Modifier) {
    this.id = id;
    this.menu_item_id = id;
    this.menu_heading_id = menu_heading_id;
    this.menuDataStore = menuDataStore;

    this.update(data);

    // Update reference in menuDataStore
    // eslint-disable-next-line no-param-reassign
    menuDataStore.modifiersById[id] = this;
  }

  get heading() {
    return this.menuDataStore.modifierGroupsById[this.menu_heading_id];
  }

  // NOTE: price_string is deprecated. Price range logic for showing price ranges when menu item is $0 but
  // required modifiers determine the cost is moved to the front end.
  update(data: Modifier) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.hasOwnProperty(key)) {
        // @ts-expect-error
        this[key] = data[key];
      }
    }
  }

  get name() {
    return this.name_for_customer;
  }

  get isFulfillable() {
    return this.enabled && this.in_stock;
  }

  get isLowStock() {
    return this.inventory_qty !== null && this.inventory_qty < 10;
  }

  hasMods = () => this.modifierGroups.length > 0;

  /** Minimum/Maximum price given all required modifiers
   * NOTE: This does not tak into account several levels of nested modifiers, it only looks one level deep.
   */
  get minMaxRequiredPretaxCents() {
    if (this._minMaxRequiredPretaxCents) {
      return this._minMaxRequiredPretaxCents;
    }

    const modifierGroupMinMax = this.modifierGroups.map((groupMetaData) => {
      const modifierGroup = this.menuDataStore.modifierGroupsById[groupMetaData.modifierGroupId];

      if (modifierGroup.min_selected > 0) {
        // Only care about the modifiers attached to this menu item from the modifier group
        const attachedModifiers = Object.values(modifierGroup.modifiers).filter((mod: Modifier) =>
          groupMetaData.modifierIds.includes(mod.id)
        );

        // Sort by price
        const modifiersByPrice = attachedModifiers.sort((mod1, mod2) => mod1.pretax_cents - mod2.pretax_cents);

        // use sum of starting N mods for min price
        const groupMin = modifiersByPrice
          .slice(0, modifierGroup.min_selected)
          .reduce((subTotal, mod) => subTotal + mod.displayCents, 0);

        // use sum of last N mods for max price
        const groupMax = modifiersByPrice
          .slice(modifiersByPrice.length - modifierGroup.min_selected)
          .reduce((subTotal, mod) => subTotal + mod.displayCents, 0);
        return { groupMin, groupMax };
      } else {
        return { groupMin: 0, groupMax: 0 };
      }
    });

    // Add group min's together
    const menuItemMin = modifierGroupMinMax.reduce((subTotal, group) => subTotal + group.groupMin, 0);

    // Add group max's together
    const menuItemMax = modifierGroupMinMax.reduce((subTotal, group) => subTotal + group.groupMax, 0);

    this._minMaxRequiredPretaxCents = { min: menuItemMin, max: menuItemMax };
    return this._minMaxRequiredPretaxCents;
  }

  /**
   * Display price for menu page only. This is to account for free menu items that have required modifiers which
   * determine the price range.
   *
   * Ex: Draft beer menu item that has $0 cost and then the required modifier determines the cost of the beer. Thus
   * the UI should show a range from $Min - $Max. Must also account for several modifier groups that require a selection
   * of one or more items.
   */
  get displayPrice() {
    if (this.pretax_cents !== 0) {
      return formatCentsToPrice(this.displayCents, this.menuDataStore.currencyCode);
    } else {
      const { min, max } = this.minMaxRequiredPretaxCents;

      if (min === max) {
        return formatCentsToPrice(min, this.menuDataStore.currencyCode);
      } else {
        return `${formatCentsToPrice(min, this.menuDataStore.currencyCode)} - ${formatCentsToPrice(
          max,
          this.menuDataStore.currencyCode
        )}`;
      }
    }
  }

  get displayCents() {
    return this.pretax_cents + (this.menuDataStore.taxInclusivePricing ? this.tax_cents : 0);
  }
}

interface Tag {
  name: TagType;
  qty?: number;
}
