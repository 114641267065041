import _ from 'lodash';
import { DateTime } from 'luxon';
import { computed, makeAutoObservable, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

// Constants
import { SHARED_CART_STATE } from 'constants/Checkout';

// Models
import CartItem from 'models/CartItem';
import Check from 'models/Check';
import SharedCart from 'models/SharedCart';
import SharedCartItem from 'models/SharedCartItem';
import { TipKey } from 'models/Types';
import RootStore from 'stores/RootStore';

// Utils
import { getTipOptionKey } from 'components/checkout-modules/CheckoutTipSelect';
import { getCookie } from 'utils/Cookie';
import { removeFromLocalStorage } from 'utils/LocalStorage';
import { splitByDistributions } from 'utils/SplitChecks';
import { TODO } from 'utils/Types';

// Tracking
import {
  trackGTMEcommAddToCart,
  trackGTMEcommEditItem,
  trackGTMEcommRemoveFromCart,
} from 'integrations/google-tag-manager/tracking-events';
import { itemModalTrackingEvents } from 'integrations/segment/tracking-events';
import { cartFormatting } from 'integrations/segment/util/formatting';

// Types
import { CartFeeData, CartPriceData, LoyaltyReward, PriceCheckData, UnmetPromoCodeCondition } from 'api/types';
import { SummaryLineItemType } from 'components/checkout/CartCalculations/hooks';
import { FulfillmentMethod } from 'constants/FulfillmentMethods';
import { formatCartItemForTrackingShort } from 'integrations/segment/util/formatting/Cart';
import Customer from 'models/Customer';
import Menu from 'models/Menu';
import User from 'models/User';
import { notification } from 'top-component-library';
import { managedFeatures } from '../DynamicValues/DynamicValuesProvider';
import { CartItemChangedError, TOPLoggedError } from '../constants/Errors';

export enum TipType {
  Percentage = 'percentage',
  Amount = 'amount',
}

export enum TipChoice {
  PresetOption = 'presetOption',
  NoTip = 'noTip',
  None = 'none',
  Other = 'other',
}

export interface CartPromoDiscount {
  name: string;
  cents_added: number;
}

export default class Cart {
  // Cart States
  isUpdating: boolean = false;
  loaded: boolean = false;

  // Cart attributes
  id: string = ''; // Used to keep track of the selected cart
  location_id: string = '';
  sevenrooms_res_id: string = '';
  items: Array<CartItem> = [];
  itemsToAddToSharedCart: Array<CartItem> = [];
  fees: CartFeeData[] = [];
  groupedFees: Array<TODO> = [];
  pricechecks: Record<string, PriceCheckData> = {}; // { lineItemId: { discounts: [], lineitem_pretax_cents: 1500, lineitem_tax_cents: 133 }}
  hasBogo: boolean = false;
  pricecheck_extra_data: TODO = {};
  promoDiscounts: CartPromoDiscount[] = [];
  unmet_promo_code_conditions: UnmetPromoCodeCondition[] = [];
  valid_promo_codes: Array<TODO> = [];
  server_available_time_blocks: string[] = []; // This is specific to the cart
  has_available_time_blocks = false;
  time_created: string = ''; // Created by the backend and used by the front end to determine how old/stale the cart is
  time_modified: string = '';
  time_locked: string = '';
  time_checked_out: DateTime = DateTime.fromSeconds(0);
  name: string = '';
  secret_password: string = '';
  publicly_visible: boolean = false;
  prompts_to_guest: Array<TODO> = [];
  insufficient_quantity_items: TODO = {};
  prices_are_final: boolean = true;
  reason_prices_not_final: string = '';

  // Tipping
  user_has_chosen_tip: boolean = false; // ['percentage', 'amount', null] null indicated they have not chosen a tip
  selected_tip_choice: TipKey = getTipOptionKey(TipChoice.PresetOption);
  tip_type: TipType = TipType.Percentage; // 'percentage' // ['percentage', 'amount']
  tip_amount = 0; // Always synchronized with tip percentage
  tip_percentage: number = 0; // Always synchronized with tip amount

  // Shared Cart
  sharedCartReference?: SharedCart;

  // Errors
  error: TODO;

  // Loyalty
  availableLoyaltyRewards?: LoyaltyReward[];
  applied_loyalty_promotion?: LoyaltyReward;
  loyaltyToggled?: boolean | undefined = undefined;

  rootStore: RootStore;
  checkout_id: string = '';
  // TODO(types): change this to just use the enum when converting consts to typed enums
  status:
    | typeof SHARED_CART_STATE.OPEN
    | typeof SHARED_CART_STATE.LOCKED
    | typeof SHARED_CART_STATE.ABANDONED
    | typeof SHARED_CART_STATE.CHECKED_OUT = SHARED_CART_STATE.OPEN;

  checks: Check[] = [];

  constructor(cart: Cart, rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
      itemsCount: computed,
      isSharedCart: computed,
      status: false,
    });

    runInAction(() => {
      this.id = cart?.id || uuidv4();
      cart?.items.forEach((item) => {
        const cartItem = new CartItem(this.rootStore.menuDataStore, item);
        this.items.push(cartItem);
        this.itemsToAddToSharedCart.push(cartItem); // Used to reference items when auto joining a shared cart (we optionally show a modal to add items after you join the shared cart)
      });
      this.time_created = cart?.time_created || new Date();
      this.loaded = true;
    });
  }

  resetCart = (shouldClearItems = true): void => {
    // remove checkout_id and cart from local storage
    removeFromLocalStorage('checkout_id');
    removeFromLocalStorage('cart');
    this.setUserHasChosenTip(false);
    this.applied_loyalty_promotion = undefined;
    runInAction(() => {
      this.checkout_id = null;
      if (shouldClearItems) {
        this.items = [];
        this.fees = [];
        this.tip_amount = 0;
        this.tip_percentage = 0;
        this.groupedFees = [];
        this.user_has_chosen_tip = false;
        this.selected_tip_choice = this.defaultTipChoice;
      }
      this.insufficient_quantity_items = {};

      // shared cart
      this.sharedCartReference = undefined;
      this.status = null;
    });
  };

  get cartOwner(): User {
    if (this.isSharedCart) {
      return this.sharedCartReference.cartOwner;
    } else {
      return this.rootStore.userStore.user_info;
    }
  }

  /**
   * Returns whether this is a shared cart (user facing name Group Order)
   */
  get isSharedCart(): boolean {
    return Boolean(this.sharedCartReference);
  }

  /**
   * Returns whether or not this is a shared cart and if so, if the status of the shared cart is LOCKED
   */
  get isLockedSharedCart(): boolean {
    return this.isSharedCart && this.sharedCartReference.status === SHARED_CART_STATE.LOCKED;
  }

  get selectedTipPercentage() {
    const tipping = this.rootStore.locationStore.customer?.app_properties?.tipping;
    const { default_tip: defaultTip = 0, has_default_tip: hasDefaultTip = true } = tipping ?? {};
    const { user_has_chosen_tip: userHasChosenTip, tip_percentage: tipPercentage } = this;

    const userHasSelectedTip = userHasChosenTip && tipPercentage !== undefined;

    const defaultTipPercentage = hasDefaultTip ? defaultTip : 0;
    const selectedTipPercentage = userHasSelectedTip ? tipPercentage / 100 : defaultTipPercentage;

    return selectedTipPercentage;
  }

  /**
   * this represents the actual radio selection the user clicked on
   * we basically just need a way to make them unique because they
   * can have identical values
   */
  get selectedTipOption() {
    const {
      user_has_chosen_tip: userHasChosenTip,
      tip_percentage: tipPercentage,
      selected_tip_choice: selectedTipChoice,
    } = this;

    const userHasSelectedTip = userHasChosenTip && tipPercentage !== undefined;
    const tipChoice = userHasSelectedTip ? selectedTipChoice : this.defaultTipChoice;

    return tipChoice;
  }

  get defaultTipChoice() {
    const tipping = this.rootStore.locationStore.customer?.app_properties?.tipping;
    const { choices, has_default_tip: hasDefaultTip = true, default_tip: defaultTip = 0 } = tipping ?? {};
    return hasDefaultTip
      ? getTipOptionKey(TipChoice.PresetOption, choices?.indexOf(defaultTip) ?? 0)
      : getTipOptionKey(TipChoice.None);
  }

  getItemByHash = (hash: number) => this.items.find((item) => item.hash() === Number(hash));

  getItems = () => this.items;

  getItemsDict = (): Record<string, CartItem> =>
    this.items.reduce((items: Record<number, CartItem>, item) => {
      items[item.hash()] = item;
      return items;
    }, {});

  getCartMemberByUserId = (userId: string): User => this.sharedCartReference?.getCartMemberByUserId(userId);

  getItemsForGivenUser = (userId: string): Array<CartItem> =>
    this.items.filter((item) => item.user_id === userId) || [];

  get createdAt(): DateTime {
    return DateTime.fromISO(this.time_created);
  }

  get lastModified(): DateTime {
    return DateTime.fromISO(this.time_modified);
  }

  get isLocked(): boolean {
    return Boolean(this.time_locked);
  }

  get hasItems() {
    return this.items.length > 0;
  }

  get fulfillmentMethod(): FulfillmentMethod {
    return this.rootStore.checkoutStore.fulfillment_method;
  }

  get usedMenus(): Array<Menu> {
    // Loop through cartItems, get their menuItems, then get their menus
    // NOTE: if you have items in your cart from menus that are not offered at this location then menuSet will be
    // undefined since menuItem.menu looks for menus loaded in menuDataStore
    const menuSet = new Set(this.items.map((item) => item.menu).filter((menu) => menu));
    return Array.from(menuSet);
  }

  get availableOrderTimes() {
    return this.server_available_time_blocks;
  }

  get hasAvailableTimeBlocks() {
    return this.has_available_time_blocks;
  }

  get someItemsAvailable(): boolean {
    return this.usedMenus?.some((menu) => !menu.allow_order_ahead) || false;
  }

  get usedCustomers(): Array<Customer> {
    // Get the used Menus from the cartItems and then get their customers
    const customerSet = new Set(
      this.usedMenus.map((menu) => this.rootStore.menuDataStore.customersById[menu.customer_id])
    );
    return Array.from(customerSet);
  }

  get itemsCount(): number {
    return this.isSharedCart
      ? this.sharedCartReference.itemsCount
      : this.items.reduce((count, item) => count + item.qty, 0);
  }

  setInsufficientQuantityItems = (items: TODO): void => {
    runInAction(() => {
      this.insufficient_quantity_items = items;
    });
  };

  setPromptsToGuest = (value: Array<TODO>): void => {
    runInAction(() => {
      this.prompts_to_guest = value;
    });
  };

  /**
   * Called from getCartPrice. Updates the cart with what's in the db.
   * @param responseData: The response payload from the getCartPrice api call.
   */
  updateFromGetCartPrice = (responseData: CartPriceData): void => {
    const {
      fees,
      lineItemIdsToRemove,
      prompts_to_guest,
      available_time_blocks,
      pricechecks,
      pricecheck_extra_data,
      prices_are_final,
      reason_prices_not_final,
    } = responseData;

    this.updateCart({
      fees,
      prompts_to_guest,
      available_time_blocks,
      pricechecks,
      pricecheck_extra_data,
      prices_are_final,
      reason_prices_not_final,
    });
    this.setUnavailableItems(lineItemIdsToRemove);
    this.updateCartPrice(responseData);
    this.calculatePromos();
    this.updateTip();

    if (this.isSharedCart) {
      this.sharedCartReference?.updateFromGetCartPrice();
    }
  };

  setLocationId = (locId: string): void => {
    runInAction(() => {
      this.location_id = locId;
    });
  };

  setLoyaltyToggled = (): void =>
    runInAction(() => {
      this.loyaltyToggled = !this.loyaltyToggled;
    });

  createCartItem = (customer_id: string, menu_id: string, menu_item_id: string): CartItem =>
    new CartItem(this.rootStore.menuDataStore, {
      // TODO(types): this isn't even used by CartItem?
      customer_id,
      menu_id,
      menu_item_id,
    });

  // ============= SHARED CART METHODS =============

  /**
   * Adds an item to this cart. This is called for non-shared carts.
   */
  addItemToCart = (item: CartItem): void => {
    runInAction(() => {
      this.items.push(item);
    });

    // Get cart price from backend
    (async () => {
      await this.rootStore.checkoutStore.getCartPrice();
    })();

    const forceServerSideCarts = this.rootStore.locationStore.customer?.getEnabledFeatures(
      managedFeatures.serverSideCarts
    );
    if (forceServerSideCarts && !this.sharedCartReference) {
      // Create a cart for the user and add the item they just created to it.
      (async () => {
        try {
          await this.rootStore.checkoutStore.createSharedCart(null, '', this.rootStore.locationStore.id);
        } catch (error) {
          if (!(error instanceof TOPLoggedError)) {
            console.error(error);
          }
          // Now that it's logged, stop further propagation of the error. We don't want to make the user aware
          // of this logic until after server side carts is fully rolled out.
        }
      })();
    }

    // ====== Send events =======
    // Amplitude Events
    const addCartItemShortFields = formatCartItemForTrackingShort(item);
    // itemModalTrackingEvents.trackAddCartItem({ ...cartFormatting.formatCartItemForTracking(item) });
    itemModalTrackingEvents.trackAddCartItemShort(addCartItemShortFields);

    // GTM Ecommerce
    trackGTMEcommAddToCart(item, this.rootStore.locationStore.customer);
  };

  /**
   * Adds item to this cart or a shared cart.
   * Note: This occurs before the response from get-cart-price is received and
   * therefore line-item amounts are null on the new cartItem being added.
   * @param item: The item to be added to the cart.
   */
  handleAddItemToCart = (item: CartItem): void => {
    const cartItemHash = item.hash();

    if (this.isSharedCart) {
      const matchingItem = this.sharedCartReference?.getExistingItemIfItExists(cartItemHash);

      if (!matchingItem) {
        // Is a shared cart, new item
        this.sharedCartReference?.addCartItemToSharedCart(item);
      } else {
        // Is a shared cart, existing item
        matchingItem.setQty(matchingItem.qty + item.qty);
        this.sharedCartReference?.handleUpdateCartItemInSharedCart(matchingItem);
      }
    } else {
      const existingItem = this.getItemByHash(cartItemHash);
      if (!existingItem) {
        // Not a share cart, new item
        this.addItemToCart(item);
      } else {
        // Not a shared cart, existing item
        existingItem.setQty(existingItem.qty + item.qty);

        itemModalTrackingEvents.trackEditCartItem({ ...cartFormatting.formatCartItemForTracking(item) });
        // Get cart price from backend
        (async () => {
          await this.rootStore.checkoutStore.getCartPrice();
        })();
      }
    }

    this.rootStore.checkoutStore.setCheckoutError(null);

    Cart.saveCartToLocalStorage(this);
  };

  updateItemInCart = (newItemCopy: CartItem | SharedCartItem): void => {
    if (this.isSharedCart) {
      this.sharedCartReference?.handleUpdateCartItemInSharedCart(newItemCopy);
    } else {
      const index = this.items.findIndex((i) => i.id === newItemCopy.id);

      if (index >= 0) {
        const matchingItem = this.items.find(
          (item) => item.hash() === newItemCopy.hash() && item.id !== newItemCopy.id
        );

        if (matchingItem) {
          // If an item has been edited to have the same mod(s) and/or special instruction(s) as another item in the cart,
          // then the current item should be merged into the matching item.
          matchingItem.setQty(matchingItem.qty + newItemCopy.qty);
          this.items.splice(index, 1);
        } else {
          this.items.splice(index, 1, newItemCopy);
        }
      } else {
        this.items.push(newItemCopy);
      }
    }

    itemModalTrackingEvents.trackEditCartItem({ ...cartFormatting.formatCartItemForTracking(newItemCopy) });

    this.rootStore.checkoutStore.setCheckoutError(null);
    trackGTMEcommEditItem(newItemCopy);

    // Update cart price after editing the item.
    (async () => {
      await this.rootStore.checkoutStore.getCartPrice();
    })();
  };

  // Allow for a specific hash to be removed (when deleting an item mid-edit)
  removeItem = (item: CartItem | SharedCartItem, itemHash?: string): void => {
    if (this.isSharedCart) {
      this.sharedCartReference?.removeCartItemFromSharedCart(item);
    } else {
      const hash = itemHash ?? item.hash();
      const remainingItems = this.items.filter((i) => i.hash() !== hash);
      runInAction(() => {
        this.items = remainingItems;
      });
      itemModalTrackingEvents.trackRemoveCartItem({ ...cartFormatting.formatCartItemForTracking(item) });
    }

    this.rootStore.checkoutStore.setCheckoutError(null);
    trackGTMEcommRemoveFromCart(item, this.rootStore.locationStore.customer);

    // Update cart price when removing item.
    (async () => {
      await this.rootStore.checkoutStore.getCartPrice();
    })();
    Cart.saveCartToLocalStorage(this);
  };

  /**
   * Pre-tax, pre-tip, pre-discount - just the total of the item prices as they appear on the menu
   */
  getSubtotal = () => (this.items ?? []).reduce((subtotal, item) => subtotal + item.preDiscountPretaxCents, 0);

  getTax = () =>
    (this.items ?? []).reduce((tax, item) => tax + item.preDiscountTaxCents, 0) +
    (this.fees ?? []).reduce((tax, fee) => tax + fee.tax_cents, 0);

  getTaxWithoutFees = () => (this.items ?? []).reduce((tax, item) => tax + item.preDiscountTaxCents, 0);

  getTip = (): number =>
    // TODO(types): this is used as a number, but parsed here???
    parseInt(this.tip_amount, 10);

  /**
   * Gets the total amount due for the cart.
   * NOTE: This must be a getter so that mobx can treat it as a computed value allowing other components to listen to
   * changes for cartTotal to trigger re-renders. Getters are marked as computed by default when using makeAutoObservable.
   * @returns {Number of cents}
   */
  get cartTotal(): number {
    return (
      this.getSubtotal() + this.getTax() + this.calculateFees() + this.getPromoDiscountsCentsAdded() + this.getTip()
    );
  }

  calcCartTotalPartial = (
    parts: Array<SummaryLineItemType> = [
      SummaryLineItemType.Subtotal,
      SummaryLineItemType.Tax,
      SummaryLineItemType.Fees,
      SummaryLineItemType.Promos,
      SummaryLineItemType.Tip,
    ]
  ) =>
    (parts.includes(SummaryLineItemType.Subtotal) ? this.getSubtotal() : 0) +
    (parts.includes(SummaryLineItemType.Tax) ? this.getTax() : 0) +
    (parts.includes(SummaryLineItemType.Fees) ? this.calculateFees() : 0) +
    (parts.includes(SummaryLineItemType.Promos) ? this.getPromoDiscountsCentsAdded() : 0) +
    (parts.includes(SummaryLineItemType.Tip) ? this.getTip() : 0);

  /**
   * Splits the current cart into the appropriate checks based on the distributions array. Single checks have a
   * distribution array passed in that is single length with the cart total as the only entry.
   * @param distributions array of numbers on which to divide the checks ([20, 30, 40]).
   */
  getCartChecks = (distributions: number[]) => {
    const cartItemPartsDistributed = this.items.map((item) => item.splitItemIntoParts(distributions));

    const feesDistributed = this.fees.map((fee) => this.splitFeeIntoParts(fee, distributions));

    const tipDistributions = CartItem.distributeByWeights(this.getTip(), distributions);

    const allChecks = distributions.map(
      (__, i) =>
        new Check(
          {
            seat: 1,
            tip_cents: tipDistributions[i],
            items: cartItemPartsDistributed.map((itemPartArray) => itemPartArray[i]),
            fees: feesDistributed.map((feePartArray) => feePartArray[i]),
          },
          this
        )
    );

    runInAction(() => {
      this.checks = allChecks;
    });

    return this.checks;
  };

  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, no-param-reassign
  calculateFees = (): number => this.groupedFees.reduce((fees, fee) => (fees += fee.pretax_cents_subtotal), 0);

  getPromoDiscounts = () => this.promoDiscounts;

  // NOTE: these cents are of negative value
  getPromoDiscountsCentsAdded = (): number =>
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    this.promoDiscounts.reduce((promos, promo) => (promos += promo.cents_added), 0);

  setUnmetPromoCodeConditions = (conditions: TODO): void => {
    runInAction(() => {
      this.unmet_promo_code_conditions = conditions;
    });
  };

  getError = (): Error => this.error;

  setError = (value: Error): void => {
    runInAction(() => {
      this.error = value;
    });
  };

  // TODO: Check if this is still needed now that we have MenuDataStore.fulfillableMenuItemIds.
  setUnavailableItems = (lineItemIdsToRemove: number[]): void => {
    if (lineItemIdsToRemove?.length > 0) {
      lineItemIdsToRemove.forEach((id) => {
        const item = this.getItemByHash(id);
        if (item) {
          const key = item.menu_item_id;
          const message = `${item.name_for_customer} has been removed from your cart because it is no longer available at this location.`;
          this.removeItem(item);
          notification.error({ key, message });
        }
      });
    }
  };

  updateCart = ({
    fees,
    prompts_to_guest,
    available_time_blocks,
    pricechecks,
    pricecheck_extra_data,
    prices_are_final,
    reason_prices_not_final,
  }: {
    fees: CartFeeData[];
    prompts_to_guest: TODO;
    available_time_blocks: string[];
    pricechecks: Record<string, PriceCheckData>;
    pricecheck_extra_data: TODO;
    prices_are_final: boolean;
    reason_prices_not_final: string;
  }): void => {
    runInAction(() => {
      this.fees = fees;
      this.prompts_to_guest = prompts_to_guest;
      this.server_available_time_blocks = available_time_blocks;
      this.has_available_time_blocks = true;
      this.pricecheck_extra_data = pricecheck_extra_data;
      this.pricechecks = pricechecks;
      this.hasBogo = this.getBogoFromPriceChecks(pricechecks);
      this.prices_are_final = prices_are_final;
      this.reason_prices_not_final = reason_prices_not_final;
    });
  };

  updateCartPrice = (data: CartPriceData): void => {
    runInAction(() => {
      this.fees = data.fees;
      this.groupedFees = this.createGroupedFees(data.fees);
      this.unmet_promo_code_conditions = data.unmet_promo_code_conditions;
      this.valid_promo_codes = data.valid_promo_codes;
    });

    // Set `discounts`, `lineitem_pretax_cents`, and `lineitem_tax_cents`.
    Object.keys(data.pricechecks).forEach((hash) => {
      const item = this.getItemByHash(parseInt(hash, 10));

      // If the item was removed from the cart by anther member then be sure to not update the item
      if (item) {
        item.updateFromPriceCheck(data.pricechecks[hash]);
      }
    });

    Cart.saveCartToLocalStorage(this);
  };

  getBogoFromPriceChecks = (pricechecks: Record<string, PriceCheckData>): boolean =>
    Object.values(pricechecks).some((pricecheck) =>
      pricecheck?.discounts.some((discount) => discount?.is_item_level_discount)
    );

  // Fee names are not unique; two vendors can have a fee with the same name, e.g., "delivery".
  createGroupedFees = (fees: CartFeeData[]) =>
    _.chain(fees)
      .groupBy('name_for_customer')
      .map((feeList, feeName) => ({
        name_for_customer: feeName,
        pretax_cents_subtotal: _.sumBy(feeList, 'pretax_cents'),
        fees: feeList,
      }))
      .value();

  calculatePromos = (): void => {
    const discountNameToCents = this.items.reduce<Record<string, number>>((nameToCents, item) => {
      item.discounts.forEach(({ name, cents_added: centsAdded }) => {
        nameToCents[name] ??= 0;
        nameToCents[name] += centsAdded as number;
      });
      return nameToCents;
    }, {});

    const promoDiscounts = Object.entries(discountNameToCents).map(([name, centsAdded]) => ({
      name,
      cents_added: centsAdded,
    }));

    runInAction(() => {
      this.promoDiscounts = promoDiscounts;
    });
  };

  /**
   * Splits a single fee into an array multiple fees based on the distribution array.
   * @param fee
   * @param distributions
   */
  splitFeeIntoParts = (fee: CartFeeData, distributions: number[]) => {
    const pretaxAmounts = splitByDistributions(fee.pretax_cents, distributions);
    const taxAmounts = splitByDistributions(fee.tax_cents, distributions);

    return distributions.map((__, i) => ({
      id: fee.id,
      name: fee.name_for_customer,
      total: pretaxAmounts[i] + taxAmounts[i],
      tax_cents: taxAmounts[i],
      pretax_cents: pretaxAmounts[i],
    }));
  };

  updateTip(): void {
    const { customer, forced_tip_fraction } = this.rootStore.locationStore;
    const { tabsEnabled } = this.rootStore.featuresStore;
    const { activeConsumerTab } = this.rootStore.tabStore;

    console.log({ ignoreTabs: !this.rootStore.tabStore.ignoreTabs });

    if (this.rootStore.checkoutStore.isTippingAllowed) {
      if (
        !this.rootStore.tabStore.ignoreTabs &&
        this.rootStore.tabStore.allowTipAtEndOfTab() &&
        this.getSubtotal() + this.getPromoDiscountsCentsAdded() > 0
      ) {
        this.setTipAmount(0);
      } else if (this.user_has_chosen_tip && this.tip_type === 'percentage') {
        this.setTipAmount(this.calculateTipCents(this.tip_percentage / 100));
      } else if (this.user_has_chosen_tip && this.tip_type === 'amount') {
        this.setTipAmount(this.tip_amount); // Call this even though we dont change the amount, that way the percentage is recalculated
      } else {
        this.setTipAmount(this.calculateTipCents(customer.app_properties?.tipping?.default_tip ?? 0));
      }
    } else if (
      forced_tip_fraction !== null &&
      forced_tip_fraction >= 0 &&
      this.rootStore.checkoutStore.tippingAllowedForSelectedFulfillmentMethod
    ) {
      this.setTipAmount(this.calculateTipCents(forced_tip_fraction));
    } else if (this.tip_amount && !this.rootStore.checkoutStore.tippingAllowedForSelectedFulfillmentMethod) {
      this.setTipAmount(0);
    } else if (tabsEnabled && activeConsumerTab) {
      // These cookies are set during the first successful checkout that created the tab
      const tipAmount = parseInt(getCookie(`tab_default_tip_amount:${activeConsumerTab.id}`), 10); // NOTE: uses cents
      const tipPercentage = parseFloat(getCookie(`tab_default_tip_percentage:${activeConsumerTab.id}`));
      const tipType = getCookie(`tab_default_tip_type:${activeConsumerTab.id}`);

      if (tipType) {
        this.setTipAmount(tipType === 'percentage' ? this.calculateTipCents(tipPercentage / 100) : tipAmount);
      }
    }
    // Else tipping is not allowed for the given fulfillment method and therefore we should not let the user tip
  }

  get amountToApplyTipTo(): number {
    const {
      include_fees: includeFees,
      include_discounts: includeDiscounts,
      include_taxes: includeTaxes,
    } = this.rootStore.locationStore.customer.app_properties?.tipping ?? {};
    let subtotal: number = this.getSubtotal();
    if (includeFees) {
      subtotal += this.calculateFees();
    }
    if (includeDiscounts) {
      subtotal += this.getPromoDiscountsCentsAdded();
    }
    if (includeTaxes) {
      subtotal += this.getTax();
    }
    return subtotal;
  }

  setTipType = (val: TipType | null): void =>
    runInAction(() => {
      this.tip_type = val;
    });

  setTipChoice = (val: TipKey): void =>
    runInAction(() => {
      this.selected_tip_choice = val;
    });

  setUserHasChosenTip = (val: boolean): void =>
    runInAction(() => {
      this.user_has_chosen_tip = val;
    });

  setTipAmount = (tipAmount: number): void =>
    runInAction(() => {
      this.tip_amount = tipAmount;
      this.tip_percentage = this.calculateTipPercentage(tipAmount);
    });

  setAvailableLoyaltyRewards = (loyaltyRewards: LoyaltyReward[]): void => {
    runInAction(() => {
      this.availableLoyaltyRewards = loyaltyRewards;
    });
  };

  setAppliedLoyaltyRewards = (loyaltyReward: LoyaltyReward | undefined): void => {
    runInAction(() => {
      this.applied_loyalty_promotion = loyaltyReward;
    });

    // Get cart price from backend
    (async () => {
      await this.rootStore.checkoutStore.getCartPrice();
    })();
  };

  clearAppliedLoyaltyRewards = (): void => {
    runInAction(() => {
      this.applied_loyalty_promotion = undefined;
    });
  };

  // `tipPercentage` - float ranging from 0 to 1.
  calculateTipCents = (tipPercentage: number): number => Math.round(tipPercentage * this.amountToApplyTipTo);

  // `tipCents` - tip in cents
  calculateTipPercentage = (tipCents: number): number => Math.round((tipCents / this.amountToApplyTipTo) * 1000) / 10;

  /**
   * Checks that cart is finished updating adn that every item is fulfillable
   * @returns {boolean}
   */
  get isReadyForCheckout(): boolean {
    return !this.isUpdating && this.itemsCount > 0 && this.items.every((item) => item.isFulfillable);
  }

  // ------------------------------------------------------------------
  //
  // USER DESIRED TIME
  //
  // ------------------------------------------------------------------

  get someItemsAllowOrderAhead(): boolean {
    return this.usedMenus?.some((menu) => !menu.allow_order_ahead) || false;
  }

  /**
   * Check that all items in the cart are from menus that allow for them to be ordered at the current time
   * @returns { boolean }
   */
  isFulfillableNow = (): boolean => {
    const currentTime = DateTime.now();
    const isFulfillable = this.items.every((item) =>
      item.isFulfillableAt(currentTime, currentTime, this.rootStore.checkoutStore.fulfillment_method)
    );
    return isFulfillable;
  };

  isFulfillableAt = async (desiredTime: DateTime, currentTime: DateTime): Promise<boolean> => {
    // If time is before now (aka in the past) then return false
    if (desiredTime < currentTime) {
      return false;
    }

    // Check if any rate limits are exceeded
    const unique_customer_ids = this.usedCustomers.length
      ? this.usedCustomers.map((customer) => customer.customer_id)
      : [];
    const utc_formatted = desiredTime.toUTC();
    unique_customer_ids.forEach((customer_id) => {
      if (this.exceeded_rate_limits[customer_id]) {
        const found_rate_limit = this.exceeded_rate_limits[customer_id].find(
          (erl: TODO) =>
            // TODO(types): this.fulfillment_method is never set?
            (erl.fulfillment_methods.includes(this.fulfillment_method) || erl.fulfillment_methods.includes('all')) &&
            erl.exceeded_times.includes(utc_formatted)
        );
        if (found_rate_limit) {
          return false;
        }
      }
      return null;
    });

    // If no rate limits are exceeded then check that all menus attached to items in the cart are available at the specified time
    // TODO(types): this.fulfillment_method is never set?
    return this.items.every((item) => item.isFulfillableAt(desiredTime, currentTime, this.fulfillment_method));
  };

  loadCartFromLocalStorage = (): void => {
    const data = localStorage.getItem('cart');

    if (data) {
      const cart = JSON.parse(data);

      // If the cart does not have a last modified date then remove it and dont load it
      if (!cart.lastModified) {
        localStorage.removeItem('cart');
        return;
      }

      const cartLastModified = DateTime.fromISO(cart.lastModified);
      const lastModifiedToNowInDays = DateTime.now().diff(cartLastModified, 'days').toObject().days;

      // If the cart is more than 1 day old then remove that cart from local storage and dont load it
      if (lastModifiedToNowInDays >= 1) {
        localStorage.removeItem('cart');
        return;
      }

      runInAction(() => {
        this.valid_promo_codes = cart.valid_promo_codes;

        cart.items.forEach((item: CartItem) => {
          const cartItem = new CartItem(this.rootStore.menuDataStore, item);
          this.items.push(cartItem);
        });
        this.isUpdating = false;
      });

      // NOTE: We only consider the cart to be finished loading once the first getCartPrice call has resolved, this
      // allows for accurate reporting to amplitude of the cart total
    }
  };

  // ------------------------------------------------------------------
  //
  // Shared Cart Endpoints
  //
  // ------------------------------------------------------------------
  setSharedCartReference = (sharedCart: SharedCart, saveToLocalStorage: boolean = true): void => {
    runInAction(() => {
      this.sharedCartReference = sharedCart;
    });

    if (saveToLocalStorage) {
      Cart.saveCartToLocalStorage(this);
    }
  };

  updateReadyForCheckout = (val: boolean): void => {
    const { user_info } = this.rootStore.userStore;
    this.rootStore.api.updateReadyForCheckout(user_info.id, val);
  };

  setItemsFromSharedCart(newItems: Array<CartItem>): void {
    runInAction(() => {
      this.items = newItems;
    });
  }

  setItemsToAddToSharedCart(items: CartItem[] = []): void {
    runInAction(() => {
      this.itemsToAddToSharedCart = items;
    });
  }

  get fulfillableItemsToAddToSharedCart(): Array<CartItem> {
    return this.itemsToAddToSharedCart.filter((i) => i.isFulfillable);
  }

  // ------------------------------------------------------------------
  //
  // Static Methods
  //
  // ------------------------------------------------------------------

  /**
   * Formats cart to a JSON object
   */
  static toJSON = (cart: Cart): Partial<Cart> => {
    const itemsDict: Record<number, TODO> = {};
    cart.items.forEach((item) => {
      itemsDict[item.hash()] = item.toJSON();
    });

    return {
      id: cart.id,
      shared_cart_id: cart.sharedCartReference?.id,
      location_id: cart.location_id,
      fees: cart.fees,
      items: itemsDict, // The instances of CartItems used in checkout
      last_modified: new Date(),
      promo_codes: cart.valid_promo_codes,
      prompts_to_guest: cart.prompts_to_guest,
      pricecheck_extra_data: cart.pricecheck_extra_data,
      applied_loyalty_promotion: cart.applied_loyalty_promotion,
      // This is confusing because subtotal here is not the number we show on the front end
      subtotal_amount: cart.getSubtotal() + cart.getPromoDiscountsCentsAdded() + cart.calculateFees(),
      // tax_amount: this.getTax(), // this already has promo cents removed from it since lineitem_tax_cents get adjusted after adding a promo
      // tip_amount: this.getTip(),
    };
  };

  /**
   * Saves the current cart to localStorage
   */
  static saveCartToLocalStorage = (cart: Cart): void => {
    const menu_ids = cart.usedMenus.length ? cart.usedMenus.map((menu) => menu.id) : [];
    const customer_ids = cart.usedCustomers.length ? cart.usedCustomers.map((customer) => customer.customer_id) : [];

    // NOTE: Dont save the items to localstorage if it is a shared cart because on page load we sync the items from the
    // server and thus use the SharedCart.saveToLocalStorage(cart.sharedCartReference) at the bottom of this function
    const cartItems = cart.isSharedCart ? [] : cart.items.map((item) => item.toJSON());

    const serializedCart = JSON.stringify({
      valid_promo_codes: cart.valid_promo_codes,
      items: cartItems,
      menu_ids,
      customer_ids,
      lastModified: new Date(),
    });

    if (cart.sharedCartReference) {
      removeFromLocalStorage('cart');
      SharedCart.saveToLocalStorage(cart.sharedCartReference);
    } else {
      localStorage.setItem('cart', serializedCart);
    }
  };

  static isEmptyOrUpdating = (cart: Cart): boolean => cart.itemsCount === 0 || cart.isUpdating;

  static validateCartItems = (cart: Cart): Record<string, string> => {
    const errors: Record<string, string> = {};

    runInAction(() => {
      // TODO(types): is this referring to the wrong field?
      cart.errors = {};
    });

    // Ensure that the cart does not have any errors and all items are fulfillable
    if (cart.items.length < 1) {
      errors.cartItems = 'Please add at least one item to your cart before checking out.';
    } else if (!cart.items.every((item) => item.isFulfillable)) {
      const lineItemIdsToRemove = cart.items.filter((item) => !item.isFulfillable).map((item) => item.hash());
      cart.setUnavailableItems(lineItemIdsToRemove);
      // Throw a cart item changed error because the top level checkout try catch has logic to auto-refresh the page
      // when the toast closes if this specific error is thrown.
      // Only shows "refresh and try again" because it relies on setUnavailableItems to show a toast about the specific
      // item(s) removed.
      throw new CartItemChangedError('Please refresh and try again.');
    }

    runInAction(() => {
      // TODO(types): is this referring to the wrong field?
      cart.errors = errors;
    });

    return errors;
  };

  static validateCartChecks = (cart: Cart): Record<string, string> => {
    const errors: Record<string, string> = {};

    runInAction(() => {
      // TODO(types): is this referring to the wrong field?
      cart.paymentInfoErrors = {};
    });

    // Ensure a check with valid charge is applied
    if (cart.checks?.length === 0) {
      errors.paymentInfo = 'Please specify a payment method before proceeding with checkout.';
    }

    // Ensure the total amount in desired checks equals the total amount due
    const chargeAmounts = cart.checks?.map((check) => check.charge?.amount_cents ?? 0);
    const addChargeAmounts = (chargeTotal: number, currentChargeAmount: number): number =>
      chargeTotal + currentChargeAmount;
    const checksTotal = chargeAmounts.reduce(addChargeAmounts);

    if (checksTotal !== cart.cartTotal) {
      errors.cartCharges = `The given charges do not equal the total due to proceed with checkout.`;
    }

    runInAction(() => {
      // TODO(types): is this referring to the wrong field?
      cart.paymentInfoErrors = errors;
    });

    return errors;
  };
}
