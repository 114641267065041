const hostsApprovedForFacebookLogin: Array<string> = [
  '230fifth.menu',
  '5napkinburgerhellskitchen.menu',
  '5napkinburgerues.menu',
  '5napkinburgerusq.menu',
  '5napkinburgeruws.menu',
  'alldaykitchens.menu',
  'arlosoho.menu',
  'avanti.menu',
  'backalleybread.com',
  'ballardpizza.menu',
  'ballastpointchicago.menu',
  'bbgnyc.menu',
  'bbot.menu',
  'bears.menu',
  'bearsnh.menu',
  'benparis.menu',
  'blackbird.menu',
  'bluejacketdc.menu',
  'boathouse.menu',
  'bocce.menu',
  'borisandhorton.menu',
  'bowerybeer.menu',
  'brojos.menu',
  'bungalow.menu',
  'c4eats.menu',
  'caesars.menu',
  'casadelbarco.menu',
  'catria.menu',
  'caveat.menu',
  'chefscape.menu',
  'chefscape180.menu',
  'citywinery.menu',
  'cleotogo.com',
  'cleotogo.menu',
  'cousinsbbq.menu',
  'curryupnow.menu',
  'dead.menu',
  'deadfreddies.menu',
  'dev.bbot.menu',
  'dockside.menu',
  'drakebend.menu',
  'dukesorder.com',
  'eleanormd.menu',
  'elicit.menu',
  'equinox.menu',
  'flemings.menu',
  'foodlab.menu',
  'freeholdbrooklyn.menu',
  'freeholdinthepark.menu',
  'freeholdmiami.menu',
  'frelardpizza.menu',
  'fryingpan.menu',
  'fuku.menu',
  'galleybakerysquare.menu',
  'gardenbar.menu',
  'gertie.menu',
  'gitano.menu',
  'grangehall.menu',
  'greenfig.menu',
  'haus.menu',
  'heavycatering.menu',
  'hiltonrise.menu',
  'hudsonyards.menu',
  'hydelivers.menu',
  'izakaya.menu',
  'jimmys.menu',
  'junzi.menu',
  'kanpai.menu',
  'katch.menu',
  'krispyrice.menu',
  'kumi.menu',
  'langham.aifiorinyc.com',
  'lexington.craftfoodhalls.menu',
  'lgaplaza.menu',
  'localnyc.menu',
  'lucyscantina.menu',
  'luxboxdelray.menu',
  'makina.menu',
  'marufukuirvine.menu',
  'melia.menu',
  'mightysquirrel.menu',
  'minirex.menu',
  'mondrian.menu',
  'mondrianparkavenue.menu',
  'mrpickles.menu',
  'my-hotel.menu',
  'nashbird.menu',
  'neighborhoodprovisions.menu',
  'novikovportocervo.menu',
  'order.aifiorinyc.com',
  'order.deadfreddies.com',
  'order.marea-nyc.com',
  'orders.parkavenuetavern.com',
  'overlook.menu',
  'overlooklodge.menu',
  'oyster.nyc',
  'pilotdtla.menu',
  'pqtogo.menu',
  'printers.menu',
  'quattropassialpescatore.menu',
  'redapron.menu',
  'rnrusc.menu',
  'rooftop.menu',
  'roomserve.menu',
  'runchickenrun.menu',
  'rustico.menu',
  'samsfriedchicken.menu',
  'seltzery.menu',
  'sibling.menu',
  'sirens.menu',
  'sixwest.menu',
  'smokeybones.menu',
  'socialdrinkandfood.menu',
  'soleil.menu',
  'sonsaddition.menu',
  'staging.bbot.menu',
  'staybridge.menu',
  'studiocafe.menu',
  'sundaybk.menu',
  'sundaygp.menu',
  'surfbar.menu',
  'tabmenu.online',
  'taco.menu',
  'tacocina.menu',
  'tailgatebk.menu',
  'taproom307.menu',
  'tgr42.menu',
  'thecastelloplan.menu',
  'thehudson.menu',
  'theoldfashioned.menu',
  'thepost.menu',
  'theroost.menu',
  'theterrace.menu',
  'thewilson.menu',
  'top-golf.menu',
  'trademarktaste.menu',
  'travelingtap.menu',
  'treadwellpark.menu',
  'umamiburger.menu',
  'urbanspacetogo.com',
  'waltham.craftfoodhalls.menu',
  'washingtonbend.menu',
  'westinmh.menu',
  'wework.menu',
  'winterland.menu',
  'woodpecker.menu',
  'wormtown.menu',
  'yotel.menu',
  'yoursite.menu',
  'zuul.menu',
];

export default hostsApprovedForFacebookLogin;
