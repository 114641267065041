import Cart from 'models/Cart';
import CartItem from 'models/CartItem';
import Customer from 'models/Customer';
import Check from 'models/Check';
import { CHARGE_TYPE } from 'constants/Checkout';

// Instrumentation
import {
  sendGTMGA4EcommerceEvent,
  sendGTMUinversalAnalyticsEcommerceEvent,
  sendGTMEvent,
  onCheckoutEvent,
  onCheckoutOption,
} from 'integrations/google-tag-manager/instrumentation';

// Utils
import { formatCartForGTM, formatCartItemForGTM } from 'integrations/google-tag-manager/util/formatting';
import {
  formatViewDetailsEvent,
  formatAddToCartEvent,
  formatRemoveFromCartEvent,
  formatPurchaseEvent,
} from 'integrations/google-tag-manager/util/universal-analytics-formatting';

// Reference: https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#product-data
enum UniversalAnalyticsProductEvents {
  ADD_TO_CART = 'ua.addToCart', //	A user adds one or more products to a shopping cart.
  REMOVE_FROM_CART = 'ua.removeFromCart', //	A user removes one or more products from a shopping cart.

  // Custom event names
  VIEW_ITEM = 'ua.viewItem', //	A user views details for a product.
  PURCHASE = 'ua.purchase', //	A user completes a purchase.
}

// -----------------------------------------------------------------
//
// Ecommerce
//
// -----------------------------------------------------------------
export const trackGTMEcommViewItem = (cartItem: CartItem): void => {
  sendGTMGA4EcommerceEvent('view_item', {
    items: [formatCartItemForGTM(cartItem)],
  });

  sendGTMUinversalAnalyticsEcommerceEvent(UniversalAnalyticsProductEvents.VIEW_ITEM, formatViewDetailsEvent(cartItem));
};

export const trackGTMEcommEditItem = (cartItem: CartItem): void =>
  sendGTMGA4EcommerceEvent('edit_cart_item', {
    items: [formatCartItemForGTM(cartItem)],
  });

export const trackGTMEcommAddToCart = (cartItem: CartItem, locationCustomer: Customer): void => {
  sendGTMGA4EcommerceEvent('add_to_cart', {
    items: [formatCartItemForGTM(cartItem)],
  });

  sendGTMUinversalAnalyticsEcommerceEvent(
    UniversalAnalyticsProductEvents.ADD_TO_CART,
    formatAddToCartEvent(cartItem, locationCustomer)
  );
};

export const trackGTMEcommRemoveFromCart = (cartItem: CartItem, locationCustomer: Customer): void => {
  sendGTMGA4EcommerceEvent('remove_from_cart', {
    items: [formatCartItemForGTM(cartItem)],
  });

  sendGTMUinversalAnalyticsEcommerceEvent(
    UniversalAnalyticsProductEvents.REMOVE_FROM_CART,
    formatRemoveFromCartEvent(cartItem, locationCustomer)
  );
};

export const trackGTMBeginCheckout = (cart: Cart): void =>
  sendGTMGA4EcommerceEvent('begin_checkout', { ...(cart ? formatCartForGTM(cart) : {}) });

export const trackGTMPurchase = (
  cart: Cart,
  check: Check,
  customer: Customer,
  locationShortId: string,
  coupon: string = '',
  checkout_id: string,
  chargeType: CHARGE_TYPE,
  orderIds: Array<string> = []
): void => {
  sendGTMGA4EcommerceEvent('purchase', {
    transaction_id: check.charge?.id ?? '', // Transaction ID. Required for purchases and refunds. TODO: Account for several checks
    affiliation: customer.customer_name,
    location_code: locationShortId,
    currency: customer.currency ?? 'usd',
    shipping: 0,
    coupon, // All the promo-codes used then joined into a large string
    checkout_id,
    charge_type: chargeType,
    orderIds,
    ...formatCartForGTM(cart),
  });

  sendGTMUinversalAnalyticsEcommerceEvent(
    UniversalAnalyticsProductEvents.PURCHASE,
    formatPurchaseEvent(cart, check, customer, coupon)
  );
};

// -----------------------------------------------------------------
//
// Page Views
//
// -----------------------------------------------------------------
export const trackGTMViewMenu = (data: any = {}): void => sendGTMEvent('view_menu', data);

export const trackGTMCheckoutComplete = (data: any = {}): void => sendGTMEvent('checkout_complete', data);

// -----------------------------------------------------------------
//
// Checkout Steps
//
// -----------------------------------------------------------------
export const trackGTMViewCheckoutPage = (cart: Cart): void => onCheckoutEvent(1, 'view_checkout_page', cart);

export const trackGTMSelectTipAmount = (): void => onCheckoutEvent(2, 'select_tip_amount');

export const trackGTMSuccessfulCheckout = (): void => onCheckoutEvent(4, 'successful_checkout');

export const trackGTMFailedCheckout = (): void => onCheckoutOption(4, 'checkout_error');

export const trackGTMViewOrderStatusPage = (): void => onCheckoutEvent(5, 'view_order_status_page');
