/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Capacitor } from '@capacitor/core';
import { snakeCaseObjectKeys } from '@doordash/lib-object-utils';
import * as Sentry from '@sentry/react';
// Constants
import { SHARED_CART_STATE } from 'constants/Checkout';
import { DEVICE_ID } from 'constants/Environments';
import {
  CheckoutError,
  ConsumerTabError,
  CSRFError,
  DrinkRefillError,
  NetworkError,
  PricecheckError,
  TOPLoggedError,
} from 'constants/Errors';
import { getSharedCartError } from 'constants/SharedCartErrors';

// Utils
import { AxiosResponse } from 'axios';
import * as TabError from 'constants/TabErrors';
import { TabClosePollingStatus, TabOpenPollingStatus } from 'constants/UIState';
import CartItem from 'models/CartItem';
import { ChargeDistribution } from 'models/Charge';
import { ServiceRequestType } from 'models/ServiceRequest';
import SharedCartItem from 'models/SharedCartItem';
import RootStore from 'stores/RootStore';
import { getIsAxiosNetworkError, getIsNetworkError } from 'utils/Connectivity';
import { getAllMatchingCookiesWithPrefix } from 'utils/Cookie';
import { convertDistanceToMiles } from 'utils/GooglePlaceUtils';
import { retrieveFromLocalStorage } from 'utils/LocalStorage';
import { TODO } from 'utils/Types';
import { getDefaultRequestInterface, getRequestInterface } from './RequestInterface';
import { SharedCartMetadataResponse } from './types';

// eslint-disable-next-line import/no-mutable-exports
export let api: TODO;

export default class TransportLayer {
  deviceId = null;
  rootStore = undefined as unknown as RootStore;
  host = '';
  csrfCookieName = null;
  requestInterfaceWithoutInterceptors = getRequestInterface('');
  requestInterface = getRequestInterface('');
  get = getRequestInterface('').get;
  post = getRequestInterface('').post;
  customer_id = '';
  platform = Capacitor.getPlatform();

  constructor(rootStore: RootStore, hostname: string) {
    this.rootStore = rootStore;
    this.host = hostname;
    this.deviceId = retrieveFromLocalStorage(DEVICE_ID.LABEL); // Sets the device id for tracking

    this.requestInterface = getDefaultRequestInterface(hostname);
    this.requestInterfaceWithoutInterceptors = getRequestInterface(hostname);

    this.get = this.requestInterface.get;
    this.post = this.requestInterface.post;
  }

  static createInstance(rootStore: RootStore, hostname: string) {
    if (api) {
      return api;
    }

    api = new TransportLayer(rootStore, hostname);
    return api;
  }

  /**
   * Clears sessionid and csrf cookies
   * @returns {Promise<void>}
   */
  clearSessionCookies = async () => {
    try {
      await this.requestInterfaceWithoutInterceptors.get(`api/clear-session`);
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Unable to clear session.`, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  getHostData = async () => {
    const document_id = localStorage.getItem('document_id');
    // Sending document_id='null' caused errors if another get param was included afterwards, so lets not send anything
    // if document_id is falsey after fetching from localStorage
    const params = document_id ? { document_id } : {};
    try {
      const result = await this.requestInterfaceWithoutInterceptors.get(`api/cached/host-data`, {
        params,
      });
      const { data } = result;
      Sentry.setExtra('host_customer_id', data?.settings?.host_customer?.customer_id ?? 'doordash');
      return data;
    } catch (error: TODO) {
      if (error.error === 'Network Error') {
        throw new NetworkError('Error connecting to DoorDash Servers.', {
          customer_id: null, // We don't have the host customer
          ...error,
        });
      }

      throw new TOPLoggedError(error.message || `Unable to fetch host data for ${this.host}.`, {
        ...error,
        customer_id: null, // We don't have the host customer
      });
    }
  };

  getLocationData = async (locationCode: string) => {
    try {
      const result = await this.requestInterface.get(`api/cached/location-config`, {
        params: { code: locationCode },
      });
      Sentry.setExtra('location_customer_id', result.data?.customer?.customer_id);
      return result.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(
        error.message || `An error occurred while loading table info for table ${locationCode}.`,
        {
          ...error,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        }
      );
    }
  };

  getMenuData = async (menuPks: string[]) => {
    const ids = menuPks.sort();
    try {
      const result = await this.requestInterface.get(`api/cached/menu-data`, {
        params: { ids },
      });

      const { data } = result;
      return data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Failed retrieving menu data for menu with id ${menuPks}.`, {
        ...error,
        customer_id: this.rootStore?.locationStore?.customer?.customer_id,
      });
    }
  };

  pollMenuItems = async (locationId: string, menuItemHash: string | null, onNetworkError: () => void) => {
    try {
      const response = await this.requestInterface.get('api/poll/fulfillable-menu-items', {
        params: { locationId, lastHash: menuItemHash },
        retryOnNetworkError: true,
      });
      const { data, headers } = response;
      const pollSecondsKey = Object.keys(headers).find((key) => key.toLowerCase() === 'correct-poll-seconds') ?? '';
      const pollInterval = parseFloat(headers[pollSecondsKey]) * 1000;
      return { ...data, pollInterval };
    } catch (error: TODO) {
      if (getIsAxiosNetworkError(error)) {
        onNetworkError();
        throw error;
      }

      throw new TOPLoggedError(
        `Failed to confirm menu items are available to order. ${
          error.message ? error.message : 'Please refresh the page.'
        }`,
        {
          ...error,
          customer_id: this.rootStore?.locationStore?.customer?.customer_id,
        }
      );
    }
  };

  getUser = async () => {
    try {
      const response = await this.requestInterfaceWithoutInterceptors.get('api/user');
      const { data } = response;
      if (data.user_info.login_type) {
        Sentry.setUser({ email: data.user_info.email });
      }
      return data;
    } catch (error: TODO) {
      if (error.error === 'Network Error') {
        throw new NetworkError('Error connecting to DoorDash Servers.', {
          ...error,
          customer_id: null, // We don't have the host/location customer
        });
      }

      throw new TOPLoggedError(error.message || `Unable to fetch user info from ${this.host}.`, {
        ...error,
        customer_id: null, // We don't have the host/location customer
      });
    }
  };

  thirdPartyCookieCheck = async () => {
    try {
      const response = await this.requestInterface.get('api/third-party-cookies');
      const { data } = response;
      return data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Could not verify if session exists.`, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  login = async (userInput: TODO) => {
    try {
      const response = await this.requestInterface.post('api/login', userInput);
      const { data } = response;
      return data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `An unexpected error occurred while logging in.`, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  logout = async () => {
    try {
      const response = await this.requestInterface.get('api/logout');
      return response.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || 'An unexpected error occurred while logging out.', {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  register = async (userInput: TODO) => {
    try {
      const response = await this.requestInterface.post('api/register', userInput);
      const { data } = response;
      return data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || 'An unexpected error occurred while creating a new account.', {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  verifyUserAccount = async (userId: string, token: string) => {
    try {
      const response = await this.requestInterface.get('api/verify-user-account', {
        params: {
          userId,
          token,
        },
      });
      return response.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || 'An unexpected error occurred while verifying your account.', {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        redirect: error?.redirect,
      });
    }
  };

  sendPasswordResetEmail = async (email: string) => {
    try {
      const response = await this.requestInterface.post('api/send-password-reset-email', {
        email_address: email,
      });
      return response.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(
        error.message || 'An unexpected error occurred while sending your password reset email.',
        {
          endpoint: `api/send-password-reset-email`,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
          cause: error,
        }
      );
    }
  };

  resetUserPassword = async (params: { userId: string; token: TODO; password: TODO; repeatPassword: TODO }) => {
    try {
      const response = await this.requestInterface.post('api/reset-password', params);
      return response.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || 'An unexpected error occurred while resetting your password.', {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  updateAccountDetails = async (accountDetails: TODO) => {
    try {
      const response = await this.requestInterface.post('api/update-account-details', accountDetails);
      return response.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || 'An unexpected error occurred while updating your account.', {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  sendVerificationEmail = async () => {
    try {
      const response: TODO = await this.requestInterface.post('api/send-verification-email', {});
      if (response.error_message) {
        throw new Error(response.error_message); // Leave as Error message
      }
      return response.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(
        error.message || 'An unexpected error occurred while sending your verification email.',
        {
          ...error,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        }
      );
    }
  };

  checkIfAddressIsDeliverableTo = async (customerParam: TODO, address: TODO) => {
    const customer = customerParam;
    try {
      const payload = {
        customerId: customer.customer_id,
        addressObj: address,
      };
      const response = await this.requestInterface.post('/api/check-if-address-is-deliverable-to', payload);
      const { can_deliver, haversine, distance } = response.data;
      customer.canDeliver = can_deliver;
      customer.haversine = haversine;
      customer.distance = distance;
      customer.distanceInMiles = distance ? convertDistanceToMiles(distance.value) : haversine;
      return customer;
    } catch (error: TODO) {
      // eslint-disable-next-line no-new
      new TOPLoggedError(
        error.message || `There was an error checking if ${customer?.customer_name}'s address is deliverable.`,
        {
          ...error,
          customer_id: customer?.customer_id,
        }
      );
      customer.canDeliver = false;
      return customer;
    }
  };

  setActiveConsumerSessionWithTabId = async (tabId: string) => {
    try {
      const response = await this.requestInterfaceWithoutInterceptors.post('api/set-session-with-tab-id', {
        tab_id: tabId,
      });
      const { data } = response;
      return data;
    } catch (error: TODO) {
      if (error.error === 'Network Error') {
        throw new NetworkError('Error connecting to internet.', {
          ...error,
          customer_id: null, // We don't have the host/location customer
        });
      }

      throw new ConsumerTabError(error.message || `Unable to fetch consumer tab info from ${this.host}.`, {
        ...error,
        customer_id: null, // We don't have the host/location customer
      });
    }
  };

  // -------------------------------------------------------------------------------------
  //
  // Payment Method Endpoints
  //
  // -------------------------------------------------------------------------------------

  getSavedCards = async () => {
    const params = { customer_id: this?.rootStore?.locationStore?.customer?.customer_id };
    try {
      const response = await this.requestInterface.get('api/get-saved-cards', { params });
      return response.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `There was an error fetching saved cards.`, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  saveGiftCard = async (gift_card_number_token: string, gift_card_pin_token: string) => {
    try {
      const payload = {
        gift_card_number_token,
        gift_card_pin_token,
        customer_id: this?.rootStore?.locationStore?.customer.customer_id,
      };
      await this.requestInterface.post('api/gift-cards/save-gift-card', payload);
    } catch (error: TODO) {
      throw new TOPLoggedError(
        error.message ||
          `There was an error saving gift card with number token ${gift_card_number_token} for this customer.`,
        {
          ...error,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        }
      );
    }
  };

  fetchStripeConnectionToken = async () => {
    const params = { customer_id: this?.rootStore?.locationStore?.customer.customer_id };
    try {
      const response = await this.requestInterface.get('api/stripe-connection-token', { params });
      const { secret } = response.data;
      return secret;
    } catch (error: TODO) {
      throw new TOPLoggedError(`Failed to get the stripe connection token.`, {
        ...error,
        customer_id: this?.rootStore?.locationStore?.customer.customer_id,
        originalError: error,
      });
    }
  };

  /** For use by Stripe Terminal **/
  createPaymentIntent = async (amount_cents: number) => {
    try {
      const response = await this.requestInterface.post('api/create-stripe-payment-intent', {
        amount_cents,
        customer_id: this.rootStore?.locationStore?.customer?.customer_id,
      });

      return response.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Failed to create terminal stripe payment intent.`, {
        ...error,
        customer_id: this.rootStore?.locationStore?.customer?.customer_id,
      });
    }
  };

  getStripeIntent = async () => {
    try {
      const response = await this.requestInterface.get('api/get-stripe-setup-intent');
      const { intent_client_secret } = response.data;
      return intent_client_secret;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Failed to get the stripe intent.`, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  updateDefaultCard = async (cardId: string) => {
    // save the payment method to customer in the backend
    try {
      const payload = {
        default_card_id: cardId,
      };
      await this.requestInterface.post('api/set-default-card', payload);
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Failed to update the default card.`, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  saveCard = async (paymentMethodId: string = '', paymentMethodToken: string = '') => {
    // save the payment method to customer in the backend
    try {
      const payload = {
        stripe_payment_method_id: paymentMethodId,
        payment_method_token: paymentMethodToken,
      };
      const res = await this.requestInterface.post('api/save-card', payload);
      return res.data.cardId;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Failed to save card.`, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  deleteCard = async (paymentMethodId: string) => {
    // save the payment method to customer in the backend
    try {
      const payload = {
        id: paymentMethodId,
      };
      await this.requestInterface.post('api/remove-card', payload);
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Sorry, we could not delete the saved card.`, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  // -------------------------------------------------------------------------------------
  //
  // Checkout related api calls
  //
  // -------------------------------------------------------------------------------------

  /**
   * Gets the updated cart item prices from the server
   * @param body
   * @returns {Promise<any>}
   */
  getCartPrice = async (body: TODO) => {
    try {
      const response = await this.requestInterface.post('api/get-cart-price', body, { retryOnNetworkError: true });
      return response.data;
    } catch (error: TODO) {
      if (error.status === 403) {
        console.error('Restaurant was unable to verify your cart due to CSRF errors.');
        throw new CSRFError('Restaurant was unable to verify your cart. Please refresh and try again.', {
          ...error,
          customer_id: this.rootStore?.locationStore?.customer?.customer_id,
        });
      } else if ('errorCode' in error) {
        throw new PricecheckError(error.errorCode, {
          ...error,
          customer_id: this.rootStore?.locationStore?.customer?.customer_id,
        });
      } else {
        throw new TOPLoggedError(
          error.message || `Restaurant was unable to verify your cart. Please refresh and try again.`,
          {
            ...error,
            customer_id: this.rootStore?.locationStore?.customer?.customer_id,
          }
        );
      }
    }
  };

  /**
   * Sends order payload to server
   * @param payload
   */
  checkout = async (payload: TODO) => {
    try {
      const response = await this.requestInterface.post('api/checkout', payload);
      return response.data;
    } catch (error: TODO) {
      if (error.error_id in TabError.TabErrorId) {
        TabError.throwTabError(error, {
          ...error,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        });
      }
      // This is triggered by serverside errors
      // Let the user know that their card was not charged
      throw new CheckoutError(
        error.message || `An error occurred during checkout. Your card was not charged in the process.`,
        {
          ...error,
          customer_id: this.rootStore?.locationStore?.customer?.customer_id,
        }
      );
    }
  };

  // -------------------------------------------------------------------------------------
  //
  // Order Status Page API Calls
  //
  // -------------------------------------------------------------------------------------

  /**
   * Get order items from server
   */
  getOrderIds = async (params: TODO) => {
    try {
      const response = await this.requestInterface.get('api/order-ids', { params, retryOnNetworkError: true });
      const { data, headers } = response;
      const pollSecondsKey = Object.keys(headers).find((key) => key.toLowerCase() === 'correct-poll-seconds') ?? '';
      const pollInterval = parseFloat(headers[pollSecondsKey]) * 1000;
      return { ...data, pollInterval };
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Error getting order ids.`, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  /**
   * Get order items from server
   * @param orderIdList
   * @param fetch_shared_cart_orders
   */
  getOrderDetails = async (
    order_ids: string[] = [],
    past_shared_cart_ids: string[] = [],
    close_tab_id: string | null = null
  ) => {
    try {
      const payload = { order_ids, past_shared_cart_ids, close_tab_id };
      const response = await this.requestInterface.post('api/order-details', payload, { retryOnNetworkError: true });

      const { data, headers } = response;
      const pollSecondsKey = Object.keys(headers).find((key) => key.toLowerCase() === 'correct-poll-seconds') ?? '';
      const pollInterval = parseFloat(headers[pollSecondsKey]) * 1000;
      return { ...data, pollInterval };
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || 'There was an error retrieving order details.', {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  getDrinkRefill = async (checkoutId: string) => {
    try {
      const response = await this.requestInterface.get(`api/drink-refill/?checkout_id=${checkoutId}`);
      return response.data;
    } catch (error: TODO) {
      throw new DrinkRefillError(error.message || 'There was an error retrieving order details by checkout id.', {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  getFulfillmentMethodsAndStatusNames = async () => {
    try {
      const response = await this.requestInterface.get('api/get-fulfillment-methods-and-status-names');
      return response.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || 'Error getting fulfillment methods and status names.', {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  sendReceiptEmails = async (params: { email: string; orderIds: string[]; includeRelatedOrders: boolean }) => {
    try {
      const sharedCartIds = getAllMatchingCookiesWithPrefix('checkedOutSharedCart:').map((cookie) => cookie.value);
      const payload = { ...params, past_shared_cart_ids: sharedCartIds };
      return await this.requestInterface.post('api/send-receipt-email', payload);
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || 'Error sending receipt via email. Please try again.', {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  sendReceiptText = async (params: {
    phoneNumber: string;
    orderIds: string[];
    customerId: string;
    includeRelatedOrders: boolean;
  }) => {
    try {
      const sharedCartIds = getAllMatchingCookiesWithPrefix('checkedOutSharedCart:').map((cookie) => cookie.value);
      const payload = { past_shared_cart_ids: sharedCartIds, ...params };
      return await this.requestInterface.post('api/send-receipt-text', payload);
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || 'Error sending receipt via text. Please try again.', {
        ...error,
        customer_id: params.customerId,
      });
    }
  };

  savePhoneForReceipt = async (phone: string, sharedCartIds = []) => {
    try {
      const payload = {
        mobile: phone,
        shared_cart_ids: sharedCartIds,
      };
      await this.requestInterface.post('api/set-mobile-number', payload);
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `There was an error saving your phone number.`, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  showReceipt = async (orderId: string) => {
    try {
      const payload = { orderId };
      const response = await this.requestInterface.get('api/order-receipt', { params: payload });
      return response.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || 'Could not retrieve order(s).', {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  getSmartBarOrderDetails = async (orderId: string) => {
    try {
      const payload = { orderId };
      const response = await this.requestInterface.get('api/smart-bar-order', { params: payload });
      return response.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || 'Could not retrieve smart bar order(s).', {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  getPhoneNumber = async () => {
    try {
      const res = await this.requestInterfaceWithoutInterceptors.get('api/get-mobile-number', {});
      return res.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Could not retrieve guest phone number.`, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  getOrderHistory = async () => {
    try {
      const response = await this.requestInterface.get('api/order-history-for-user');
      return response.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Could not retrieve order history.`, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  // -------------------------------------------------------------------------------------
  // 🚧 ⚠️ 🚧
  // Shared Cart endpoints
  // 🚧 ⚠️ 🚧
  // -------------------------------------------------------------------------------------

  /**
   * Polls for updates with the shared cart
   * @returns {Promise<Shared Cart Object>}
   */
  pollSharedCartUpdates = async (shared_cart_id: string, location_id: string, last_modified: string | null = null) => {
    try {
      const params = { shared_cart_id, location_id, last_modified };
      const response = await this.requestInterface.get('api/poll/shared-cart', { params });
      const { data, headers } = response;
      const pollSecondsKey = Object.keys(headers).find((key) => key.toLowerCase() === 'correct-poll-seconds') ?? '';
      const pollInterval = parseFloat(headers[pollSecondsKey]) * 1000;
      return { ...data, pollInterval };
    } catch (error: TODO) {
      throw getSharedCartError(error, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  /**
   * Updates the server with new status of shared cart.
   * @returns {Promise<Shared Cart Object>}
   */
  updateSharedCartStatus = async (sharedCartId: string, newStatus: TODO) => {
    try {
      const payload = {
        shared_cart_id: sharedCartId,
      };

      const endpoint = newStatus === SHARED_CART_STATE.LOCKED ? 'api/lock-shared-cart' : 'api/unlock-shared-cart';
      const response = await this.requestInterface.post(endpoint, payload);
      return response.data;
    } catch (error: TODO) {
      throw getSharedCartError(
        error,
        {
          ...error,

          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        },
        this.rootStore?.checkoutStore?.selectedCart?.sharedCartReference?.getGuestFriendlyErrorHelper()
      );
    }
  };

  /**
   * Marks checkout as ready by the guest.
   * @returns {Promise<Shared Cart Object>}
   */
  updateReadyForCheckout = async (sharedCartId: string, isReady: boolean) => {
    try {
      const payload = {
        shared_cart_id: sharedCartId,
        ready_for_checkout: isReady,
      };
      const response = await this.requestInterface.post('api/set-cart-member-ready-status', payload);
      return response.data;
    } catch (error: TODO) {
      throw getSharedCartError(
        error,
        {
          ...error,

          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        },
        this.rootStore?.checkoutStore?.selectedCart?.sharedCartReference?.getGuestFriendlyErrorHelper()
      );
    }
  };

  /**
   * Marks checkout as ready by the guest.
   * @returns {Promise<Shared Cart Object>}
   */
  leaveSharedCart = async (sharedCartId: string) => {
    try {
      const payload = {
        shared_cart_id: sharedCartId,
      };
      const response = await this.requestInterface.post('api/leave-shared-cart', payload);
      return response.data;
    } catch (error: TODO) {
      throw getSharedCartError(
        error,
        {
          ...error,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        },
        this.rootStore?.checkoutStore?.selectedCart?.sharedCartReference?.getGuestFriendlyErrorHelper()
      );
    }
  };

  createSharedCartFromExistingSharedCart = async (oldCartId: string) => {
    const payload = { shared_cart_id: oldCartId };
    try {
      const response = await this.requestInterface.post('api/create-shared-cart-from-existing', payload);
      return response.data.new_shared_cart_id;
    } catch (error: TODO) {
      throw getSharedCartError(error, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  createSharedCart = async (anonymous_user_name: TODO, cart_name: string, location_id: string, cart_items: TODO) => {
    const payload = { anonymous_user_name, cart_name, location_id, cart_items };
    try {
      const response = await this.requestInterface.post('api/create-shared-cart', payload);
      return response.data;
    } catch (error: TODO) {
      throw getSharedCartError(error, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  joinSharedCart = async (params: {
    anonymousUserName: string;
    locationId: string;
    sharedCartId: string;
    secretPassword: string;
    secretForLink: string;
  }) => {
    try {
      const response = await this.requestInterface.post('api/join-shared-cart', snakeCaseObjectKeys(params));
      return response.data;
    } catch (error: TODO) {
      throw getSharedCartError(error, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  reclaimSharedCart = async (params: {
    locationId: string;
    sharedCartId: string;
    cartOwnerPhoneNumber: string;
    secretForLink: string;
  }) => {
    try {
      const response = await this.requestInterface.post('api/join-shared-cart', snakeCaseObjectKeys(params));
      return response.data;
    } catch (error: TODO) {
      if (getIsNetworkError(error)) {
        throw new NetworkError('Error connecting to DoorDash Servers.', {
          ...error,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        });
      }
      throw getSharedCartError(error, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  getSharedCartMetaData = async (token: string, locationId: string) => {
    const payload = { secret_for_link: token, location_id: locationId };
    try {
      const response = await this.requestInterface.get('api/get-secret-for-link-cart-metadata', { params: payload });
      return response.data as SharedCartMetadataResponse;
    } catch (error: TODO) {
      throw getSharedCartError(error, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  addCartItemToSharedCart = async (shared_cart_id: string, cart_line_items: CartItem[]) => {
    const payload = {
      shared_cart_id,
      cart_line_items,
    };
    try {
      const response = await this.requestInterface.post('api/add-item-to-shared-cart', payload);
      return response.data;
    } catch (error: TODO) {
      throw getSharedCartError(
        error,
        {
          ...error,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        },
        this.rootStore?.checkoutStore?.selectedCart?.sharedCartReference?.getGuestFriendlyErrorHelper()
      );
    }
  };

  editItemInSharedCart = async (items_to_edit: SharedCartItem[]) => {
    const payload = { items_to_edit };
    try {
      const response = await this.requestInterface.post('api/edit-shared-cart-item', payload);
      return response.data;
    } catch (error: TODO) {
      throw getSharedCartError(
        error,
        {
          ...error,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        },
        this.rootStore?.checkoutStore?.selectedCart?.sharedCartReference?.getGuestFriendlyErrorHelper()
      );
    }
  };

  removeItemFromSharedCart = async (cartId: string, itemHash: string | null) => {
    const payload = {
      cart_id: cartId,
      item_hash: itemHash,
    };
    try {
      const response = await this.requestInterface.post('api/remove-item-from-shared-cart', payload);
      return response.data;
    } catch (error: TODO) {
      throw getSharedCartError(
        error,
        {
          ...error,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        },
        this.rootStore?.checkoutStore?.selectedCart?.sharedCartReference?.getGuestFriendlyErrorHelper()
      );
    }
  };

  // -------------------------------------------------------------------------------------
  //
  // Party Tab endpoints
  //
  // -------------------------------------------------------------------------------------

  checkPartyCode = async (partyCode: string) => {
    try {
      const payload = { party_code: partyCode };
      const res = await this.requestInterface.post('/api/join-party-tab', payload);
      return res.data.party_tab;
    } catch (error: TODO) {
      throw new TOPLoggedError(
        error.message || `The party you tried to join was not valid. Please double check the party invite link.`,
        {
          ...error,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        }
      );
    }
  };

  createAndFundPartyTab = async (payload: TODO) => {
    try {
      const res = await this.requestInterface.post('/api/create-and-fund-party-tab', payload);
      return res.data.party_tab;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Could not create and fund party tab.`, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  closePartyTab = async (tabId: string) => {
    try {
      const payload = { tab_id: tabId };
      await this.requestInterface.post('/api/close-party-tab', payload);
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Could not close party tab.`, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  getPartyTabs = async () => {
    try {
      const res = await this.requestInterface.get('/api/party-tabs');
      return res.data.partyTabs;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Error getting party tabs.`, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  joinSmartTab = async (secretKey: string) => {
    try {
      const res = await this.requestInterface.get(`api/party-tab/join/`, {
        params: {
          code: secretKey,
        },
      });
      return res.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Error joining tab. Please try again.`, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  verifyThanxAccount = async (secretKey: string, customerId: string) => {
    try {
      const res = await this.requestInterface.get(`api/verify-loyalty-account/`, {
        params: {
          code: secretKey,
          customer_id: customerId,
        },
      });
      return res.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(
        error.message || `Sorry, this verification link has expired. Please request another verification link.`,
        {
          ...error,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        }
      );
    }
  };

  requestService = async (params: {
    code: string;
    type: ServiceRequestType;
    message: string;
    requestId: string | undefined;
  }) => {
    try {
      const response = await this.requestInterface.post('/api/request-service', params);
      const { data } = response;
      return data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Error requesting service.`, {
        ...error,
        customer_id: this.rootStore?.locationStore?.customer?.customer_id,
      });
    }
  };

  // -------------------------------------------------------------------------------------
  //
  // Consumer Tab endpoints
  //
  // -------------------------------------------------------------------------------------

  getConsumerTabSummary = async (tabId: string) => {
    try {
      const res = await this.requestInterface.get('/api/consumer-tab-summary', {
        params: { id: tabId },
      });
      return res.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Error getting your tab summary.`, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        cause: error,
      });
    }
  };

  getTabAsConsumer = async () => {
    try {
      const res = await this.requestInterface.get('/api/consumer-tab');
      return res.data.tab;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Error getting your tab.`, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  getUnclaimedPosOnlyTabsForLocation = async (locationId: string) => {
    try {
      const res = await this.requestInterface.post('/api/get-unclaimed-pos-only-tabs-for-location', {
        location_id: locationId,
      });
      return res.data.unclaimed_pos_tabs;
    } catch (error: TODO) {
      if (error.error_id in TabError.TabErrorId) {
        TabError.throwTabError(error, {
          ...error,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        });
      }
      throw new ConsumerTabError(error.message ?? 'There was a error closing your tab.', {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  /**
   *
   * @param locationId {optional}
   * @returns {Promise<{message: *}|any>}
   */
  getTabShareURL = async (locationId: string | null = null) => {
    try {
      const res = await this.requestInterface.get('/api/consumer-tab/share', {
        params: { locationId },
      });
      return res.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Error sharing your tab.`, {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  closeTabAsConsumer = async ({
    tabId,
    versionHash,
    chargeDistributions,
    newTipCents,
  }: {
    tabId: string;
    versionHash: string;
    chargeDistributions: ChargeDistribution[];
    newTipCents: number | null;
  }) => {
    try {
      await this.requestInterface.post('/api/close-tab-as-consumer', {
        tab_id: tabId,
        charge_distributions: chargeDistributions,
        version_hash: versionHash,
        new_tip_cents: newTipCents,
      });
    } catch (error: TODO) {
      if (error.error_id in TabError.TabErrorId) {
        TabError.throwTabError(error, {
          ...error,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        });
      }
      throw new ConsumerTabError(error.message ?? 'There was a error closing your tab.', {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  closePOSInitiatedTab = async (params: {
    guid: string;
    locationId: string;
    defaultCardId: string;
    tabName: string;
    chargeDistributions: ChargeDistribution[];
    newTipCents: number;
  }) => {
    try {
      // For some reason the backend wants the keys in chargeDistributions in camel case but everything else
      // in the params in snake case.
      // Fulfill that requirement before sending the payload.
      const { chargeDistributions } = params;

      await this.requestInterface.post('/api/close-pos-initiated-tabs', {
        ...snakeCaseObjectKeys(params),
        charge_distributions: chargeDistributions,
      });
    } catch (error: TODO) {
      if (error.error_id in TabError.TabErrorId) {
        TabError.throwTabError(error, {
          ...error,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        });
      }
      throw new ConsumerTabError(error.message ?? 'There was a error closing your tab.', {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  updateConsumerTabTip = async (params: {
    tabId: string | undefined;
    newTipCents: number;
    orderIds: number | string[];
  }) => {
    try {
      const response = await this.requestInterface.post(
        '/api/update-tab-tip-for-consumer',
        snakeCaseObjectKeys(params)
      );
      return response.data;
    } catch (error: TODO) {
      if (error.error_id in TabError.TabErrorId) {
        TabError.throwTabError(error, {
          ...error,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        });
      }
      throw new ConsumerTabError(error.message || 'Error updating tip on tab.', {
        ...error,
        customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
      });
    }
  };

  createConsumerTab = async ({
    locationCustomerId,
    locationId,
    tabName,
    defaultTip,
    defaultCardId,
    amount,
    phoneNumber,
  }: {
    locationCustomerId: string;
    locationId: string;
    tabName: string;
    defaultTip: number;
    defaultCardId: string;
    amount: number;
    phoneNumber: string;
  }) => {
    try {
      const response = await this.requestInterface.post('/api/create-tab-as-consumer', {
        location_customer_id: locationCustomerId,
        location_id: locationId,
        default_tip: defaultTip,
        tab_name: tabName,
        default_card_id: defaultCardId,
        pre_auth_cents: amount,
        phone_number: phoneNumber,
      });
      return response.data;
    } catch (error: TODO) {
      if (error.error_id in TabError.TabErrorId) {
        TabError.throwTabError(error, {
          ...error,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        });
      }
      throw new ConsumerTabError(error.message || `Error opening a new tab.`, {
        ...error,
        customer_id: this.rootStore?.locationStore?.customer?.customer_id,
        cause: error,
      });
    }
  };

  /**
   * Checks the DD order status of the opened tab from the server.
   * Either returns a pending status, a success status, or one of many failure statuses.
   * @param body
   * @returns {Promise<any>}
   */
  openTabStatus = async (tabId: string) => {
    try {
      const response = (await this.requestInterface.get('api/poll/open-tab-status', {
        params: { tab_id: tabId },
        retryOnNetworkError: true,
      })) as AxiosResponse<{ message: TabOpenPollingStatus }>;
      return response.data;
    } catch (error: TODO) {
      if (getIsNetworkError(error)) {
        throw error;
      } else if (error.error_id in TabError.TabErrorId) {
        TabError.throwTabError(error, {
          ...error,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        });
      } else {
        throw new TOPLoggedError(
          error.message || `Restaurant was unable to verify your cart. Please refresh and try again.`,
          {
            ...error,
            customer_id: this.rootStore?.locationStore?.customer?.customer_id,
          }
        );
      }
    }
  };

  /**
   * Checks the DD order status of the associated tab from the server.
   * Either returns a pending status, a success status, or one of many failure statuses.
   * @param body
   * @returns {Promise<any>}
   */
  closeTabStatus = async (tabId: string) => {
    try {
      const response = (await this.requestInterface.get('api/poll/close-tab-status', {
        params: { tab_id: tabId },
        retryOnNetworkError: true,
      })) as AxiosResponse<{ message: TabClosePollingStatus }>;
      return response.data;
    } catch (error: TODO) {
      if (getIsNetworkError(error)) {
        throw error;
      } else if (error.error_id in TabError.TabErrorId) {
        TabError.throwTabError(error, {
          ...error,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        });
      } else {
        throw new TOPLoggedError(
          error.message || `Restaurant was unable to verify your cart. Please refresh and try again.`,
          {
            ...error,
            customer_id: this.rootStore?.locationStore?.customer?.customer_id,
          }
        );
      }
    }
  };

  claimPOSInitiatedTab = async ({
    posTabId,
    locationId,
    tabName,
    defaultCardId,
    phoneNumber,
  }: {
    posTabId: string;
    locationId: string;
    tabName: string;
    defaultCardId: string;
    phoneNumber: string;
  }) => {
    try {
      const response = await this.requestInterface.post('/api/order-more-on-pos-initiated-tabs', {
        guid: posTabId,
        location_id: locationId,
        tab_name: tabName,
        default_card_id: defaultCardId,
        phone_number: phoneNumber,
      });
      return response.data;
    } catch (error: TODO) {
      if (error.error_id in TabError.TabErrorId) {
        TabError.throwTabError(error, {
          ...error,
          customer_id: this.rootStore?.hostStore?.host_customer?.customer_id,
        });
      }
      throw new ConsumerTabError(error.message || `Error opening a new tab.`, {
        ...error,
        customer_id: this.rootStore?.locationStore?.customer?.customer_id,
        cause: error,
      });
    }
  };

  createFundedTab = async (params: { locationId: string; paymentIntentId: string; amountCents: number }) => {
    try {
      const response = (await this.requestInterface.post('api/create-funded-tab', snakeCaseObjectKeys(params))) as TODO;

      if (response?.error) {
        throw new TOPLoggedError(response.error, {
          ...response?.error,
          endpoint: `api/create-funded-tab`,
          customer_id: this.rootStore?.locationStore?.customer?.customer_id,
          cause: response.error,
        });
      }

      return response.data;
    } catch (error: TODO) {
      if (error instanceof TOPLoggedError) {
        throw error;
      }

      throw new TOPLoggedError(error.message ?? `Error creating order please try again.`, {
        ...error,
        customer_id: this.rootStore?.locationStore?.customer?.customer_id,
      });
    }
  };

  pairStripeInternetReader = async (locationId: string, reader_registration_code: string) => {
    try {
      const response = await this.requestInterface.post('api/add-internet-reader-to-customer', {
        locationId,
        reader_registration_code,
      });
      return response.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Error connecting stripe reader please try again.`, {
        ...error,
        customer_id: this.rootStore?.locationStore?.customer?.customer_id,
      });
    }
  };

  createExternalLoyaltyAccount = async (params: {
    phoneNumber: string;
    customerId: string;
    name: string;
    email?: string;
    checkoutId?: string | null;
  }) => {
    try {
      const response = await this.requestInterface.post(
        '/api/loyalty/create-user-loyalty-account',
        snakeCaseObjectKeys(params)
      );
      return response.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Error creating or saving loyalty account`, {
        ...error,
        customer_id: params.customerId,
      });
    }
  };

  completeExternalLoyaltyAuthentication = async (params: { oauthToken: string; customerId: string }) => {
    try {
      const response = await this.requestInterface.post('/api/loyalty/complete-oauth', snakeCaseObjectKeys(params));
      return response.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(
        error.message || `Error completing athentication sequence, please restart browser and try again.`,
        {
          ...error,
          customer_id: params.customerId,
        }
      );
    }
  };

  getLoyaltyRewardsForUser = async (params: {
    customerId: string;
    pretaxCents: number;
    taxCents: number;
    tipCents: number;
    discountsCents: number;
  }) => {
    try {
      const response = await this.requestInterface.get('/api/loyalty/get-available-rewards', {
        params: snakeCaseObjectKeys(params),
      });
      // return { rewards: [{ external_id: '123', promotion_name: 'Test Promo', amount_cents: 500 }] };
      return response.data;
      // eslint-disable-next-line no-unreachable
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Error getting loyalty rewards, please refresh and try again`, {
        ...error,
        customer_id: params.customerId,
      });
    }
  };

  getMerchantLoyaltyInfo = async (params: { customerId: string }) => {
    try {
      const response = await this.requestInterface.get('/api/loyalty/get-merchant-loyalty-info', {
        params: snakeCaseObjectKeys(params),
      });
      return response.data;
    } catch (error: TODO) {
      throw new TOPLoggedError(error.message || `Error getting merchant loyalty info, please refresh and try again`, {
        ...error,
        customer_id: params.customerId,
      });
    }
  };
}
