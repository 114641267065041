import { track } from 'integrations/segment/instrumentation/Track';
import { FieldsType } from './types/types';

export const trackClickSignInSignUpPage = (fields: FieldsType = {}): void =>
  track('track_click_sign_in_sign_up_page', fields);

export const trackClickContinueWithFacebook = (fields: FieldsType = {}): void =>
  track('track_click_continue_with_facebook', fields);

export const trackClickEmailOptIn = (fields: FieldsType = {}): void => track('track_click_email_opt_in', fields);

export const trackClickTermsAndConditionsSignUpPage = (fields: FieldsType = {}): void =>
  track('track_click_terms_and_conditions_sign_up_page', fields);

export const trackClickCreateMyAccount = (fields: FieldsType = {}): void =>
  track('track_click_create_my_account', fields);

export const trackCreateAccountSuccess = (fields: FieldsType = {}): void =>
  track('track_create_account_success', fields);

export const trackCreateAccountFailure = (fields: FieldsType = {}): void =>
  track('track_create_account_failure', fields);
