import { makeAutoObservable, runInAction } from 'mobx';
import SharedCartItem from 'models/SharedCartItem';
import CartItem from '../models/CartItem';
import RootStore from './RootStore';

export default class CartItemEditorStore {
  cartItem?: CartItem;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  setCartItem = (cartItem: CartItem | SharedCartItem) => {
    runInAction(() => {
      this.cartItem = cartItem;
    });
  };

  getCartItem = () => this.cartItem;

  removeCartItem = () => {
    runInAction(() => {
      this.cartItem = undefined;
    });
  };
}
