import { observer } from 'mobx-react';

// Components
import { Button, Form } from 'top-component-library/index';

// Constants
import { InlineForm, Label } from './styles';

/**
 * Custom Inline Form Component - an inline form containing a single input
 * @param buttonText {string}
 * @param buttonTestId {string}
 * @param formInput {JSX} - a <Form.Item> input from top-component-library
 * @param formRef {Form.useForm()} - a Form instance from Antd
 * @param label {string}
 * @param loading {boolean}
 * @param onSubmit {function}
 */
function CustomInlineForm({
  buttonText = 'Submit',
  buttonTestId = 'submit-button',
  formTestId = '',
  formInput,
  formRef = null,
  label,
  loading,
  onSubmit,
}) {
  const [form] = Form.useForm(formRef);

  const submitForm = (values) => {
    const inputValue = Object.values(values)[0];
    onSubmit(inputValue);
  };

  return (
    <>
      {label ? <Label>{label}</Label> : null}
      <InlineForm form={form} data-testid={`inline-form__${formTestId}`} layout="inline" onFinish={submitForm}>
        {formInput}
        <Form.Item>
          <Button data-testid={buttonTestId} htmlType="submit" size="large" type="primary" loading={loading}>
            {buttonText}
          </Button>
        </Form.Item>
      </InlineForm>
    </>
  );
}

export default observer(CustomInlineForm);
