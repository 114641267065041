import Icon from '@ant-design/icons';
import { TODO } from 'utils/Types';

const VegetarianSVG = ({ fill }: { fill: string }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="9" r="9" fill={fill || '#292929'} />
    <path
      d="M7.47656 6.28906H8.69922L6.75781 12H5.4375L3.5 6.28906H4.72266L5.79688 9.6875C5.85677 9.88802 5.91797 10.1224 5.98047 10.3906C6.04557 10.6562 6.08594 10.8411 6.10156 10.9453C6.13021 10.7057 6.22786 10.2865 6.39453 9.6875L7.47656 6.28906ZM11.5898 8.79297H13.8555V11.7539C13.4883 11.8737 13.1419 11.957 12.8164 12.0039C12.4935 12.0534 12.1628 12.0781 11.8242 12.0781C10.9622 12.0781 10.3034 11.8255 9.84766 11.3203C9.39453 10.8125 9.16797 10.0846 9.16797 9.13672C9.16797 8.21484 9.43099 7.49609 9.95703 6.98047C10.4857 6.46484 11.2174 6.20703 12.1523 6.20703C12.7383 6.20703 13.3034 6.32422 13.8477 6.55859L13.4453 7.52734C13.0286 7.31901 12.5951 7.21484 12.1445 7.21484C11.6211 7.21484 11.2018 7.39062 10.8867 7.74219C10.5716 8.09375 10.4141 8.56641 10.4141 9.16016C10.4141 9.77995 10.5404 10.2539 10.793 10.582C11.0482 10.9076 11.418 11.0703 11.9023 11.0703C12.1549 11.0703 12.4115 11.0443 12.6719 10.9922V9.80078H11.5898V8.79297Z"
      fill="white"
    />
  </svg>
);
const Vegetarian = (props: TODO) => (
  <Icon
    data-testid="vegetarian-tag"
    title="Vegetarian"
    aria-label="Vegetarian"
    component={() => VegetarianSVG(props)}
    {...props}
  />
);

export default Vegetarian;
