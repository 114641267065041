import { DEVICE_ID } from 'constants/Environments';
import React, { useEffect } from 'react';
import { retrieveFromLocalStorage } from 'utils/LocalStorage';
import DVSClient from '../lib-dvs-client/core';
import { DVSClientProvider } from '../lib-dvs-client/react/context';

/*
Example implementation

import React from 'react'
import { DVSClientContext } from '../lib-dvs-client/react'
import useBbotConsumerTest from './FeatureFlags/useBbotConsumerTest'

export const TestComponent = () => {
  const { state, client } = React.useContext(DVSClientContext)
  const val = useBbotConsumerTest()
  console.log('val of bbotConsumerTest bucketing is', val)

  if (state !== 'ready') {
    return null
  }
  console.log('client is ', client)
  return null
}

export default TestComponent
*/

export interface SuccessResponseType {
  name: string;
  value: boolean;
  exposure_enabled: boolean;
  exposure_context: {
    revision_version: number;
    experiment_version: number;
    bucket_key_type: string;
    bucket_key: string;
    segment: string;
    tag: string;
  };
}

// deprecated 1/18/2024
export enum dynamicValuesNames {
  // owner daniel.shafer@doordash.com
  orderNowPayLater = 'order-now-pay-later',
  onPremiseUpsells = 'on-premise-upsells',
  onPremiseProgrammaticUpsells = 'on-premise-programmatic-upsells',
  onPremiseMostLovedCarousel = 'on-premise-most-loved-carousel',
  bbotConsumerTest = 'bbot-consumer-test',
  bbLoyaltyToggle = 'bb-loyalty-toggle',
  upsellsDrinkRefillsPatron = 'bbot-grab-another-drink-sms-enabled-patron',
  upsellsDrinkRefillsCustomer = 'bbot-grab-another-drink-sms-enabled',
  forceServerSideCarts = 'bbot-server-side-carts',
}

export enum managedFeatures {
  // owner daniel.shafer@doordash.com
  serviceRequests = 'service_requests',
  csatSurvey = 'post_checkout_csat_survey',
  consumerMfa = 'consumer_mfa',
  programmaticUpsells = 'programmatic_upsells',
  mostLovedCarousel = 'most_loved_carousel',
  serverSideCarts = 'server_side_carts',
  loyaltyToggle = 'loyalty_toggle',
  oneClickAdd = 'one_click_stepper',
  dualCheckoutOptions = 'dual_checkout_options',
  hideMxNameOnMenuPage = 'hide_mx_name_on_menu_page',
  hideLocationNameOnMenuPage = 'hide_location_name_on_menu_page',
  hideAddressOnMenuPage = 'hide_address_on_menu_page',
  hideSearchBarOnMenuPage = 'hide_menu_search_bar_on_menu_page',
  centerAlignHeroElements = 'center_align_hero_elements',
}

const successResponse = (name: string): SuccessResponseType => ({
  name,
  value: false,
  exposure_enabled: true,
  exposure_context: {
    revision_version: 1,
    experiment_version: 1,
    bucket_key_type: 'unset_bucket_key',
    bucket_key: 'unset_bucket_value',
    segment: 'unset_segment',
    tag: '',
  },
});

const successes = (): Array<SuccessResponseType> =>
  Object.values(dynamicValuesNames).map((dvName: string) => successResponse(dvName));

export const defaultResponse = {
  context: {
    application: 'bbot',
    app_version: '1',
    os: 'web',
    userId: '',
    businessId: '',
  },
  successes: successes(),
  failures: [],
};

interface Props {
  children: React.ReactNode;
}

const bbot = 'bbot';
export const DynamicValuesProvider: React.FC<Props> = ({ children }) => {
  DVSClient.init({
    // Logging fields:
    application: bbot,
    appVersion: '1',
    // Telemetry:
    // exposuresEnabled controls server-generated `experiment_exposure` events
    exposuresEnabled: true,
    enableSentry: true,
    // namespaces control which DVs to evaluate
    namespaces: [bbot],
  });
  const userId = retrieveFromLocalStorage(DEVICE_ID.LABEL);
  useEffect(() => {
    DVSClient.evaluate({
      userId,
      user_id: userId,
    });
  });

  return <DVSClientProvider>{children}</DVSClientProvider>;
};

export default DynamicValuesProvider;
