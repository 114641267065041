import combineURLs from 'axios/lib/helpers/combineURLs';
import { observer } from 'mobx-react';

// Hooks
import { useStores } from 'hooks/use-stores';
import {
  CustomSelectContainer,
  CustomSelectImage,
  CustomSelectInlineLabel,
  CustomSelectOption,
  CustomizedSelect,
  CustomSelectDropdownArrow,
} from './styles';

/**
 * Refer to Ant Design's `Select` documentation for more information about all available props:
 * https://ant.design/components/select/#API
 **/
function CustomSelect({
  className = '',
  filterSort = (a, b) => a.label?.localeCompare(b.label), // Sort options alphabetically by `label` by default.
  id = '',
  inlineLabel = '',
  listHeight = 256,
  optionFilterProp = 'label',
  options = [{ imagePath: '', label: '', value: '' }], // Differs from the standard Ant Design `options` prop.
  ...props
}) {
  const popupContainerId = `${id || Math.random()}-popup-container`;
  const { uiState } = useStores();
  const { isMobile, windowDimensions } = uiState;

  return (
    <CustomSelectContainer className={className} id={popupContainerId}>
      {inlineLabel && (
        <CustomSelectInlineLabel htmlFor={id} className="custom-select-label">
          {inlineLabel}
        </CustomSelectInlineLabel>
      )}
      <CustomizedSelect
        data-testid={id ? `${id}-select` : null}
        filterSort={filterSort}
        getPopupContainer={() => document.getElementById(popupContainerId)}
        id={id}
        listHeight={isMobile ? windowDimensions.height * 0.4 : listHeight}
        optionFilterProp={optionFilterProp}
        showArrow={false}
        {...props}
      >
        {options.sort(filterSort).map(({ imagePath, label, value }) => (
          <CustomSelectOption data-testid={id ? `${id}-option` : null} key={value} label={label} value={value}>
            {imagePath && <CustomSelectImage alt="" src={combineURLs(process.env.REACT_APP_STATIC_ROOT, imagePath)} />}
            <span>{label}</span>
          </CustomSelectOption>
        ))}
      </CustomizedSelect>
      {props.mode !== 'multiple' && props.mode !== 'tags' && <CustomSelectDropdownArrow />}
    </CustomSelectContainer>
  );
}

export default observer(CustomSelect);
