import { makeAutoObservable, runInAction } from 'mobx';

// Constants
import hostsApprovedForFacebookLogin from 'constants/hostsApprovedForFacebookLogin';

// Utils
import {
  loadCustomerIntegrations,
  removeCustomerIntegrations,
} from 'integrations/google-tag-manager/instrumentation/load';
import { parseTemplateComponents } from 'utils/BOSBuilderPreview';
import { addVariablesToRoot, injectFonts } from 'utils/Theme';

// Types
import RootStore from 'stores/RootStore';
import TransportLayer from 'api/TransportLayer';
import Customer from 'models/Customer';
import Location from 'models/Location';
import {
  AssetProps,
  LandingPageTemplateProps,
  Settings,
  MenuPageConfig,
  Components,
  ExternalLoyaltyIntegration,
} from 'stores/Types';

// Tracking
import { setHostCustomer } from 'integrations/segment/instrumentation/CustomerService';

// Utils
import { getBoseImagesFromCssVariables, removeBoseImagesFromCssVariables } from './utils';

export default class HostStore {
  rootStore: RootStore;
  api: TransportLayer;

  loading: boolean = false;
  loaded: boolean = false;
  theme: any = {};
  landing_page_template: LandingPageTemplateProps | null = null;
  settings: Settings | null = null;
  assets: AssetProps | null = null;
  host_customer: Customer | null = null;
  loadedIntegrationsByCustomerID: { [customer_id: string]: any } = {};
  menu_page_config: MenuPageConfig | null = null;
  external_loyalty_integration: ExternalLoyaltyIntegration = {
    integration_connected: false,
    sign_in_url: '',
    sign_up_url: '',
    sign_up_modal_bottom_text: '',
    sign_up_modal_top_text: '',
    sign_up_modal_title: '',
    program_name: 'Frequent Diner Club',
    program_benefits_list: [''],
    external_store_id: '',
    external_merchant_id: '',
  };

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.api = rootStore.api;

    makeAutoObservable(this, {
      rootStore: false,
      api: false,
    });

    this.api.requestInterface.interceptors.request.use(
      this.ensureHostDataExists,
      async (e: unknown) => await Promise.reject(e)
    );
  }

  get landingPageLocations(): Array<Location> {
    return this.settings?.location_data?.locations ?? [];
  }

  get landingPageComponents(): Array<any> {
    return this.landing_page_template?.layout?.components ?? [];
  }

  get customersById(): { [key: string]: Customer } {
    return this.settings?.location_data?.customers_by_id ?? {};
  }

  get hostIntegrations(): Array<any> {
    return this.settings?.host_customer?.integrations;
  }

  isDevMode(): boolean {
    return this.settings?.devMode ?? false;
  }

  isHostApprovedForFacebookLogin(): boolean {
    const approved = Boolean(this.settings && hostsApprovedForFacebookLogin.includes(this.settings.hostname));
    return this.isDevMode() || approved;
  }

  ensureHostDataExists = async (config?: Record<string, any>) => {
    if (this.loaded) {
      return config;
    }

    await this.loadHostData();

    return config;
  };

  async loadHostData() {
    if (this.loaded || this.loading) {
      return;
    }
    runInAction(() => {
      this.loading = true;
    });

    const data = await this.api.getHostData();

    setHostCustomer(data.settings?.host_customer);

    const filtedCssVariables = removeBoseImagesFromCssVariables(data?.theme?.css_variables);
    const parsedBoseImages = getBoseImagesFromCssVariables(data?.theme?.css_variables);

    // TODO: remove these functions and load a css sheet from S3 /customer_themes/{ hostname }/{ document_id }/theme.css
    await addVariablesToRoot(filtedCssVariables);
    await injectFonts(data.theme?.css_variables?.fonts);

    const hostCustomer = new Customer(data?.settings?.host_customer);
    const lpLocations = data?.settings?.location_data?.locations?.map((l: any) => new Location(l)) ?? [];
    const parsedComponents = parseTemplateComponents(
      hostCustomer,
      data?.landing_page_template?.layout?.components ?? [],
      lpLocations
    );
    runInAction(() => {
      this.loaded = true;
      this.loading = false;
      this.theme = data.theme;
      this.assets = { ...data.assets, ...parsedBoseImages };
      this.landing_page_template = {
        ...data.landing_page_template,
        layout: { ...data.landing_page_template.layout, components: parsedComponents },
      };
      this.settings = data.settings;
      if (this.settings) {
        this.settings.show_account_sign_in = this.rootStore.userStore.isDDMerchant
          ? false
          : this.settings.show_account_sign_in;
      }
      this.host_customer = hostCustomer;
      this.menu_page_config = data.menu_page_config;
    });

    if (this.host_customer) {
      this.updateIntegrations(this.host_customer);
    }
  }

  async getMerchantLoyaltyInfo(params: { customerId: string }) {
    const data = await this.api.getMerchantLoyaltyInfo({ customerId: params.customerId });
    runInAction(() => {
      this.external_loyalty_integration = data.integration_connected ? data : null;
    });
  }

  // Creates a customerById dict of customer integrations
  updateIntegrations(customer: Customer): void {
    // If you haven't loaded the integrations yet then load their integrations
    if (customer?.customer_id && !this.loadedIntegrationsByCustomerID[customer.customer_id]) {
      runInAction(() => {
        this.loadedIntegrationsByCustomerID[customer.customer_id] = customer.enabled_integrations;
        loadCustomerIntegrations(customer);
      });
    }

    // Go through the loaded integrations and double check there aren't any integrations tied to customers that aren't
    // loaded anymore. This can happen on bbot.menu when you go to a location for customer A -> go to bbot.menu LP
    // -> go to location for customer B
    Object.keys(this.loadedIntegrationsByCustomerID).forEach((customerId) => {
      // Remove integrations that dont belong
      if (
        customerId &&
        this.host_customer?.customer_id !== customerId &&
        this.rootStore.locationStore.customer &&
        customerId !== this.rootStore.locationStore.customer.customer_id
      ) {
        removeCustomerIntegrations(this.loadedIntegrationsByCustomerID[customerId]);
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this.loadedIntegrationsByCustomerID[customerId];
      }
    });
  }

  // Updates host data with info received from the BOS Builder Post Message (i-frame only)
  updateHostDataFromBOSBuilder = (cssVariables: any, assets: AssetProps, components: Components[]) => {
    if (this.loaded) {
      runInAction(async () => {
        this.assets = assets;
        if (this.landing_page_template) {
          this.landing_page_template.layout.components = components;
        }
        await addVariablesToRoot(cssVariables);
        await injectFonts(cssVariables?.fonts);
      });
    }
  };

  getEnabledFeatures = (featureName: string) =>
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    this.host_customer?.getEnabledFeatures(featureName) ||
    this.rootStore.locationStore.customer.getEnabledFeatures(featureName);
}
