import { track } from '../instrumentation/Track';
import { FieldsType } from './types/types';

enum DrinkRefillTrackingEvents {
  ViewCarousel = 'track_drink_refill_view_carousel',
  ViewCarouselError = 'track_drink_refill_view_carousel_error',
  ViewItemSheet = 'track_drink_refill_click_view_item_sheet',
  AddItem = 'track_drink_refill_click_add_item_directly_to_cart',
  ViewMoreDrinks = 'track_drink_refill_click_view_more_drinks',
}

export const viewCarousel = (fields: FieldsType = {}) => track(DrinkRefillTrackingEvents.ViewCarousel, fields);

export const viewCarouselError = (fields: FieldsType = {}) =>
  track(DrinkRefillTrackingEvents.ViewCarouselError, fields);

export const clickViewItem = (fields: FieldsType = {}) => track(DrinkRefillTrackingEvents.ViewItemSheet, fields);

export const clickAddItem = (fields: FieldsType = {}) => track(DrinkRefillTrackingEvents.AddItem, fields);

export const clickViewMoreDrinks = (fields: FieldsType = {}) => track(DrinkRefillTrackingEvents.ViewMoreDrinks, fields);
