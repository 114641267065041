import { v4 as uuidv4 } from 'uuid';

import { TOPLoggedError } from 'constants/Errors';
import { TODO } from '../utils/Types';
import CartItem from './CartItem';

interface Distribution {
  numerator: number;
  denominator: number;
}

export default class CartItemPart {
  id: string;
  cartItemId: number;
  item: CartItem;
  numerator: number = 0; // CartItem qty
  denominator: number = 1; // Number of patrons splitting the CartItem

  // The amount pretax cents that is tied to this Cart Item Part (if Cart Item is
  // split 3 ways then it should be a 3rd of the total pretax_cents for the CartItem)
  lineitem_pretax_cents: number = 0;
  lineitem_tax_cents: number = 0;
  pretax_total: number = 0;
  tax_total: number = 0;

  discounts: Array<TODO> = [];

  constructor(cartItem: CartItem, distribution: Distribution) {
    if (!cartItem) {
      throw new TOPLoggedError('No cart item supplied to new cart item part.', {
        endpoint: 'CartItemPart.constructor',
      });
    }

    this.id = uuidv4();
    this.item = cartItem;
    this.cartItemId = cartItem.hash(); // TODO: rename this to be cartItemId
    this.discounts = [];
    this.numerator = distribution.numerator;
    this.denominator = distribution.denominator;

    if (this.denominator === 1) {
      this.numerator = this.item.qty;
      this.lineitem_pretax_cents = this.item.lineitem_pretax_cents;
      this.lineitem_tax_cents = this.item.lineitem_tax_cents;
      this.pretax_total = this.item.lineitem_pretax_cents;
      this.tax_total = this.item.lineitem_tax_cents;
    }

    // Once the Cart Item Part is created, attach it to the _parts list for the Cart Item
    this.item.addItemPart(this);
  }

  toJSON = () => ({
    cartItemId: this.cartItemId,
    denominator: this.denominator,
    numerator: this.numerator,
    pretax_total: this.pretax_total,
    tax_total: this.tax_total,
  });
}
