import { FieldsType } from './types/types';
import { track } from '../instrumentation/Track';

enum POSTabsTrackingEvent {
  ClaimTabsPageView = 'claim_tabs_page_view',
  UnclaimedTabClick = 'unclaimed_tab_button_click',
  StartNewTabButtonClick = 'start_new_tab_button_click',
  ClaimTabOrderMoreClick = 'claim_tab_order_more_click',
  ClaimTabsCloseTabClick = 'claim_tab_close_tab_click',
  RemainingUnclaimedTabsView = 'remaining_unclaimed_tabs_view',
}

export const claimTabsPageView = (fields: FieldsType = {}) => track(POSTabsTrackingEvent.ClaimTabsPageView, fields);

export const unclaimedTabClick = (fields: FieldsType = {}) => track(POSTabsTrackingEvent.UnclaimedTabClick, fields);

export const startNewTabButttonClick = (fields: FieldsType = {}) =>
  track(POSTabsTrackingEvent.StartNewTabButtonClick, fields);

export const claimTabsOrderMoreClick = (fields: FieldsType = {}) =>
  track(POSTabsTrackingEvent.ClaimTabOrderMoreClick, fields);

export const claimTabsCloseTabClick = (fields: FieldsType = {}) =>
  track(POSTabsTrackingEvent.ClaimTabsCloseTabClick, fields);

export const remainingUnclaimedTabsView = (fields: FieldsType = {}) =>
  track(POSTabsTrackingEvent.RemainingUnclaimedTabsView, fields);
